import { ApiTreeNode } from 'sfportal_services_api/apiSchemas'
import { FlatTreeNode } from '../../../../components/Tree/Tree'
import { useCallback } from 'react'

export type CustomizeTreeList = (
  items: Array<FlatTreeNode<ApiTreeNode>>,
) => Array<FlatTreeNode<ApiTreeNode>>

/**
 * Durch Überschreiben dieser Funktion in Kunden-Projekten können die Baumknoten
 * an die Bedürfnisse des Projekts angepasst werden.
 */
export function useCustomizeTreeList (): CustomizeTreeList {
  //the return function must be a callback
  return useCallback((items) => items, [])
}
