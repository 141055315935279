import { FlatTreeNode } from './Tree'

export interface ToFlatTreeNodeParams<T> {
  /**
   * Die ID des aktuellen Knotens in neutraler Form und
   * unabhängig von `item` vom Typ `T`.
   */
  id: string
  /**
   * Der Knoten selbst, wie er von der API geliefert wurde.
   */
  item: T
  /**
   * Die aktuelle Verschachtelungsebene.
   */
  level: number
  /**
   * Der derzeitige (Breadcrumb-)Pfad zum aktuellen Knoten.
   */
  path: string[]
  /**
   * Eine Getter-Funktion zum holen der Kind-Elemente des aktuellen Knotens.
   */
  getChildren: (item: T) => T[]
  /**
   * Die rekursive Funktion, die aufgerufen werden soll, wenn Kindelemente
   * vorhanden sind.
   */
  recursiveCallback: (node: {
    id: string
    currentNodes: T[]
    level: number
    path: string[]
  }) => void
}
/**
 * Wandelt einen vom Server kommenden Knoten in einen `FlatTreeNode<T>` um.
 * Diese Funktion ist zur Verwendung innerhalb einer rekursiven Funktion
 * gedacht, die eine JSON-Baumstruktur durchgeht, um alle Elemente rauszuziehen.
 */
export function toFlatTreeNode<T> ({
  id,
  item,
  level,
  path,
  getChildren,
  recursiveCallback
}: ToFlatTreeNodeParams<T>): FlatTreeNode<T> {
  const children = getChildren(item)
  const hasChildren = children.length > 0

  if (hasChildren) {
    recursiveCallback({
      id,
      currentNodes: children,
      level: level + 1,
      path: [...path, id]
    })
  }

  return { id, item, level, path, hasChildren }
}
