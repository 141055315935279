import { isFalsy } from 'sfportal_utils/boolean'
import { apiGetIsTaskCandidateMapping } from '../../services/api/workflowApiService'
import { loadNewTasks, removeTasks, useProductDetailStore } from '../../stores/productDetailStore'
import { useUserStore } from '../../stores/userStore'
import { noop } from '../../utils/function'
import { useAddSubscriptionToArea } from '../../hooks/useAddSubscriptionToArea'
import { useCallback } from 'react'

/**
 * Hier werden alle Subscriptions zusammengefasst.
 */
export function useSubscriptionsForProductView (): void {
  const { product } = useProductDetailStore()
  const { currentUser } = useUserStore()

  const taskCreateCallback = useCallback((message) => {
    const messageBody = JSON.parse(message.body)
    if (messageBody.publiationchannelId !== product?.id) return

    if (process.env.REACT_APP_CANDIDATE_MAPPING_DEFAULT !== 'true') {
      loadNewTasks(messageBody.id).catch(noop)
      return
    }

    if (currentUser === null) return

    apiGetIsTaskCandidateMapping(messageBody.id, currentUser.id)
      .then((result) => {
        if (isFalsy(result.body)) return
        loadNewTasks(messageBody.id).catch(noop)
      })
      .catch(noop)
  }, [currentUser, product])

  // Wird getriggert wenn ein Task mit der Extension 'isExternal' = true
  // erstellt wird.
  useAddSubscriptionToArea(
    'task',
    'create',
    '*',
    taskCreateCallback,
    'workflow_extern'
  )

  const taskCompleteCallback = useCallback((message) => {
    const messageBody = JSON.parse(message.body)
    removeTasks(messageBody.id).catch(noop)
  }, [])

  // Wird getriggert wenn ein Task mit der Extension 'isExternal' = true
  // abgeschlossen wird.
  useAddSubscriptionToArea(
    'historictask',
    'complete',
    '*',
    taskCompleteCallback,
    'workflow_extern'
  )
}
