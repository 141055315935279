import { createStore, createReactHook } from '@alinnert/tstate'

// #region store
export interface UiStateStore {
  productsScrollPosition: number
  messagesScrollPosition: number
  /**
   * Flag, das `true` wird, sobald man die Anwendung verlassen hat.
   * Z. B. durch einen Klick auf einen Link.
   * Das ist ein reiner Hack für den Firefox.
   */
  appExited: boolean
}

function getInitialState (): UiStateStore {
  return {
    productsScrollPosition: 0,
    messagesScrollPosition: 0,
    appExited: false
  }
}

const store = createStore(getInitialState())
export const useUiStateStore = createReactHook(store)

const mutations = {
  reset (): void {
    store.set(getInitialState())
  }
}
// #endregion store

// #region actions
export function resetUiStateStore (): void {
  mutations.reset()
}

export function setProductsScrollPosition (
  productsScrollPosition: UiStateStore['productsScrollPosition']
): void {
  store.set({ productsScrollPosition })
}

export function setMessagesScrollPosition (
  messagesScrollPosition: UiStateStore['messagesScrollPosition']
): void {
  store.set({ messagesScrollPosition })
}

export function setAppExited (
  appExited: UiStateStore['appExited'] = true
): void {
  store.set({ appExited })
}
// #endregion actions
