import React, { FC, Fragment, useCallback, useState } from 'react'
import { ChildrenProp } from '../../jsx'
import './ItemSelect.scss'
import { Listbox } from '@headlessui/react'

interface Item {
  label: string
  icon: string
}

interface Props extends ChildrenProp {
  name: string
  id?: string
  label?: string
  items: Record<string, Item>
  value?: string
  onChange?: (value: string) => void
}

export const ItemSelect: FC<Props> = ({
  name,
  id = name,
  label = '',
  items,
  value = '',
  onChange
}: Props) => {
  const [selectedItem, setSelectedItem] = useState<Item | null>(value !== '' ? items[value] : null)

  const [selectedValue, setSelectedValue] = useState<string>(value)

  const changeSelected = useCallback((newValue: string): void => {
    setSelectedItem(items[newValue])
    setSelectedValue(newValue)
    if (onChange !== undefined) {
      onChange(newValue)
    }
  }, [items, onChange])

  return <div>
    <div className={'item-select'}>
      <Listbox value={selectedValue} onChange={changeSelected}>
        {label !== '' ? (
          <Listbox.Label className="item-select__label">{label}</Listbox.Label>
        ) : null}
        <Listbox.Button className={'item-select__button'}>
          <span className={selectedItem?.icon} />
          <span>{selectedItem?.label}</span>
        </Listbox.Button>
        <Listbox.Options className={'item-select__content'}>
          {Object.entries(items).map(([key, item], index) =>
            <Listbox.Option key={index} value={key} as={Fragment}>
              {({ active }) => (
                <li className={`item-select__content-listitem ${active ? 'item-select__content-listitem--active' : ''}`}>
                  <span className={item.icon} />
                  <span>{item.label}</span>
                </li>
              )}
            </Listbox.Option>)}
        </Listbox.Options>
      </Listbox>
    </div>
  </div>
}
