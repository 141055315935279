import { useMemo } from 'react'
import { ApiAssetPrivileges } from 'sfportal_services_api/apiSchemas'

interface UseAssetPrivilegesResult {
  canUserDownloadPreview: boolean
}

export function useAssetPrivileges (
  privileges: ApiAssetPrivileges
): UseAssetPrivilegesResult {
  const canUserDownloadPreview = useMemo((): boolean => {
    return privileges.canUserDownloadPreview ?? privileges.canUserPreview
  }, [privileges.canUserDownloadPreview, privileges.canUserPreview])

  return { canUserDownloadPreview }
}
