import React, { FC } from 'react'
import { ChildrenProp } from '../../jsx'
import { Modal } from '../../components/Generic/Modal'
import { EMK } from '../../services/api/apiSchemas'
import { Value } from '../../services/api/entityApiService'
import { AssetForm } from '../Form/AssetForm'

interface Props extends ChildrenProp {
  title?: string
  formTypes?: string[]
  overrideValues?: Value[]
  entityMetakeys?: EMK[]
  onSave: (values: Value[]) => void
  onValueChanges?: (value: Value, index: number) => void
  onCancel: () => void
}

export const AssetDialogForm: FC<Props> = ({
  entityMetakeys = [],
  title,
  onSave,
  onCancel,
  onValueChanges,
  formTypes,
  overrideValues = []
}: Props) => {
  return (
    <Modal
      visible={true}
      type="asset-form"
      width="big"
      title={title}
      padding="small"
      verticalAlign="top"
    >
      <AssetForm onValueChanges={onValueChanges} entityMetakeys={entityMetakeys} onSave={onSave} onCancel={onCancel} formTypes={formTypes} overrideValues={overrideValues} />
    </Modal>
  )
}
