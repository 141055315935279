import React, { FC } from 'react'
import './HiddenIframe.scss'

interface Props {
  /** Quelle des iFrames. `null` übergeben, wenn iFrame nicht benötigt wird. */
  src: string | null
  /**
   * Titel des iFrames. Dieser muss übergeben werden und
   * muss in der Anwendung eindeutig sein.
   */
  title: string
}

export const HiddenIframe: FC<Props> = ({ src, title }) => {
  if (src === null) return null

  return (
    <iframe src={src} frameBorder="0" title={title} className="hidden-iframe" />
  )
}
