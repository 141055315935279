import { mdiClose } from '@mdi/js'
import classNames from 'classnames'
import React, { FC, MouseEvent, useMemo } from 'react'
import { MdiIcon } from '../Generic/MdiIcon'
import './Tab.scss'

interface Props {
  isCurrent: boolean
  label: string
  labelEllipsis?: boolean
  count?: number
  icon?: string
  onActivate: () => void
  onMiddleMouseButtonClick?: () => void
  onClose?: () => void
}

export const Tab: FC<Props> = function Tab ({
  icon,
  label,
  labelEllipsis = false,
  count,
  isCurrent,
  onActivate,
  onMiddleMouseButtonClick,
  onClose
}) {
  const tabClasses = useMemo(() => {
    return classNames(
      'tab',
      { 'tab--current': isCurrent },
      { 'tab--ellipsis': labelEllipsis }
    )
  }, [isCurrent, labelEllipsis])

  function handleTabClick (event: MouseEvent<HTMLDivElement>): void {
    if (isCurrent) return
    onActivate()
  }

  function handleTabAuxClick (event: MouseEvent<HTMLDivElement>): void {
    if (event.button === 1) {
      onMiddleMouseButtonClick?.()
    }
  }

  function handleCloseClick (event: MouseEvent<HTMLDivElement>): void {
    if (onClose === undefined) return
    event.stopPropagation()
    onClose()
  }

  return (
    <div
      className={tabClasses}
      onAuxClick={handleTabAuxClick}
      onClick={handleTabClick}
    >
      {icon !== undefined ? (
        <MdiIcon className="tab__icon" path={icon} />
      ) : null}
      <div className="tab__label">{label}</div>

      {count !== undefined && count > 0 ? (
        <div className="tab__count">{count}</div>
      ) : null}

      {onClose !== undefined ? (
        <div className="tab__close-button" onClick={handleCloseClick}>
          <MdiIcon path={mdiClose} />
        </div>
      ) : null}
    </div>
  )
}
