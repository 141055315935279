// Der API-Kontext wird genutzt, um alle laufenden Requests ungültig zu machen.
// Wenn das passieren soll, muss lediglich die Funktion `switchContext`
// aufgerufen werden. Dies wird aktuell beim Ein- und Ausloggen verwendet.
const getContextDescription = (): string => `api context #${contextIndex}`

let contextIndex = 0
export let currentContext = Symbol(getContextDescription())

export function switchContext (): void {
  contextIndex += 1
  currentContext = Symbol(getContextDescription())
}

export const contextError = new Error('api context has changed')
