import React, { memo, useMemo } from 'react'
import { getRequestStatusData } from '../../data/requestStatusData'
import { ChildrenProp } from '../../jsx'
import { RequestStatus } from '../../services/api/generic/types'
import './RequestErrorMessage.scss'
import { useTranslation } from 'react-i18next'

interface Props extends ChildrenProp {
  status: RequestStatus
  customMessage?: (status: RequestStatus) => string | undefined
}

export const RequestErrorMessage = memo(function RequestErrorMessage ({
  status, customMessage: customMessageFn
}: Props) {
  const { t } = useTranslation()

  const message = useMemo(
    (): string => {
      // Einen möglichen String aus `customMessage` bevorzugen.
      const customMessage = customMessageFn?.(status)
      if (customMessage !== undefined) { return customMessage }

      // Wenn keine Funktion an `customMessage` übergeben wurde,
      // oder diese keinen String zurückliefert, wird stattdessen
      // ein Standard-Wert verwendet.
      if (status === RequestStatus.ok) { return '' }
      if (status === RequestStatus.pending) { return '' }
      return getRequestStatusData(status, t).longText
    },
    [customMessageFn, status, t]
  )

  return (
    <div className="request-error-message">
      <div className="request-error-message__text">{message}</div>
    </div>
  )
})
