import React, { FC, useEffect, useState } from 'react'
import { SharedFolderListItem } from '../../../../components/ItemViews/SharedFolderListItem'
import { LoadingContainer } from '../../../../components/Layout/LoadingContainer'
import { ChildrenProp } from '../../../../jsx'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import { loadProductFiles, useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { noop } from 'sfportal_utils/function'
import { useTranslation } from 'react-i18next'
import { DockLayout } from '../../../../components/Layout/DockLayout'
import { Toolbar } from '../../../../components/Toolbar/Toolbar'
import { ToolbarDropdown } from '../../../../components/Toolbar/ToolbarDropdown'
import { MenuItem } from '../../../../components/Menu/MenuItem'
import { useSubscriptionsForTabContents } from '../../useSubscriptionsForTabContents'

interface Props extends ChildrenProp {
}

export const ProductShares: FC<Props> = () => {
  const {
    product,
    files,
    filesStatus
  } = useProductDetailStore()
  const { t } = useTranslation()
  const defaultSort = process.env.REACT_APP_INITIAL_SORTBY_FOR_FOLDER ?? 'identifier'
  const [reverse, setReverse] = useState<boolean>(defaultSort.startsWith('-'))
  const [orderBy, setOrderBy] = useState<string>(defaultSort.replace('-', ''))

  useEffect(() => {
    const _orderBy = reverse ? `-${orderBy}` : orderBy
    loadProductFiles(_orderBy).catch(noop)
  }, [orderBy, reverse])

  useSubscriptionsForTabContents()

  if (product === null) return null

  const sortFiles = (sortBy: string): void => {
    const _reverse = sortBy === orderBy && !reverse
    setReverse(_reverse)
    setOrderBy(sortBy)
  }

  const header = () => {
    const labelSuffix = t(`${reverse ? 'global.sort.desc' : 'global.sort.asc'}`)
    return (
      <Toolbar>
        <ToolbarDropdown
          label={`${t('global.field.' + orderBy)} (${labelSuffix})`}
        >
          <MenuItem
            label={t('global.field.identifier')}
            highlight={orderBy === 'identifier'}
            action={() => sortFiles('identifier')}
          />
          <MenuItem
            label={t('global.field.modidate')}
            highlight={orderBy === 'modidate'}
            action={() => sortFiles('modidate')}
          />
        </ToolbarDropdown>
      </Toolbar>
    )
  }

  return (
    <div className="product-detail-view/shares-list-view">

      <DockLayout
        className="product-share-view"
        header={header}
        center={
          <LoadingContainer
            loadingText={t('shares.loading')}
            status={filesStatus}
            onRetry={() => {
              loadProductFiles().catch(noop)
            }}
          >
            {filesStatus === RequestStatus.ok && files.length > 0
              ? files.map((file) => (
                <SharedFolderListItem
                  key={file.id}
                  id={file.id}
                  readOnly={file.readOnly}
                  configkey={file.configkey}
                  name={file.rootFolderIdentifier ? `${file.name} (${file.rootFolderIdentifier})` : file.name}
                  content={file.content}
                  rootLevel={true}
                  productId={product.id}
                />
              ))
              : null}
          </LoadingContainer>

        }
      />
    </div>
  )
}
