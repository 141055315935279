import { mdiCloseCircle } from '@mdi/js'
import React, { memo, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { RequestStatus } from '../../services/api/generic/types'
import { noop } from '../../utils/function'
import { LoadingContainer } from '../Layout/LoadingContainer'
import { EmptyIndicator } from './EmptyIndicator'
import './FilePreview.scss'
import { useTranslation } from 'react-i18next'
import { useProductDetailStore } from '../../stores/productDetailStore'
import { highlighting } from '../../utils/highlightingHelper'
import { SearchSwitch } from '../../views/ProductDetail/productTree/detail/SearchSwitch'
import { SearchHighlighting } from '../../interfaces/Search'
import classNames from 'classnames'
import { LoadingSpinner } from '../Animations/LoadingSpinner'

interface Props {
  path: string
  loading?: boolean
  highlightingAvailable?:boolean
}

export const FilePreview = memo(function FilePreview ({
  path,
  loading = false,
  highlightingAvailable = false
}: Props) {
  const { t } = useTranslation()
  const {search} = useProductDetailStore()
  const ref = useRef<HTMLIFrameElement>(null)
  const [highlightingResult, setHighlightingResult] = useState<SearchHighlighting | null>(null)
  const [currentFindingIndex, setCurrentFindingIndex] = useState<number>(0)
  const [searchHighlighting, setSearchHighlighting] = useState<boolean>(false)

  useEffect(() => {
    setCurrentFindingIndex(0)
    setHighlightingResult(null)
    setSearchHighlighting(false)
  }, [path])

  useEffect(() => {
    if (ref.current === null || highlightingResult === null || highlightingResult.ids.length === 0){
      return
    }
    const element = ref.current?.contentDocument?.getElementById(highlightingResult.ids[currentFindingIndex])
    if (element === null || element === undefined){
      return
    }
    element.style.backgroundColor = '#f39200'
    element.scrollIntoView({block:'center'})
  }, [currentFindingIndex, highlightingResult])

  /**
   * Verändert den Content des Iframes nachdem dieser geladen wurde.
   * Das wird benötigt um zum Beispiel styles im iframe dokument zu verändern,
   * da css files den iframe content nicht verändern.
   *
   * @param event Das on Load Event des Iframes.
   */
  const handleIFrameLoad = (
    event: SyntheticEvent<HTMLIFrameElement, Event>
  ): void => {
    const { contentDocument } = event.currentTarget
    if (contentDocument === null) return

    const styles: Partial<CSSStyleDeclaration> = {
      margin: '30px',
    }

    if (highlightingAvailable && search) {
      setSearchHighlighting(true)
      //We need a little timeout because if the document is extremely large, the highlighting needs much power and the loader is not showing correctly
      setTimeout(() => {
        const tempHighlight: SearchHighlighting = {
          counter: 0,
          ids: []
        }

        //Function to check for highlighting and add the highlighting result to the tempHighlighting
        const checkHighlighting = (element: Element): void => {
          const highlight = highlighting(search.searchTerms, element.innerHTML)
          if (highlight.matching.counter !== 0) {
            element.innerHTML = highlight.text
            tempHighlight.counter += highlight.matching.counter
            tempHighlight.ids.push(...highlight.matching.ids)
          }
        }

        Array.from(ref.current?.contentDocument?.body.getElementsByTagName('p') ?? []).forEach(single => {
          if (single.children.length !== 0) {
            Array.from(single.getElementsByTagName('span') ?? []).forEach(singleChild => {
              checkHighlighting(singleChild)
            })
          } else {
            checkHighlighting(single)
          }
        })
        if (tempHighlight.counter !== 0) {
          setHighlightingResult(tempHighlight)
        } else {
          setHighlightingResult(null)
        }
        setSearchHighlighting(false)
      }, 500)
    }else {
      setHighlightingResult(null)
    }
    Object.assign(contentDocument.body.style, styles)
  }

  /**
   * switch to the next index and sets the background color of the previous selected back to yellow
   */
  const switchCurrentFinding = useCallback((index: number)=>{
    if (currentFindingIndex !== index && highlightingResult !== null){
      const element = ref.current?.contentDocument?.getElementById(highlightingResult.ids[currentFindingIndex])
        if(element !== null && element !== undefined) {
          element.style.backgroundColor = 'yellow'
        }
    }
    setCurrentFindingIndex(index)
  },[currentFindingIndex, highlightingResult])

  return (
    <LoadingContainer
      loadingText={t('generic.filePreview.loading')}
      status={loading ? RequestStatus.pending : RequestStatus.ok}
      onRetry={noop}
    >
      {path === null || path === '' || path === '/api/files/' ? (
        <EmptyIndicator
          compact={true}
          stretch={true}
          dimmed={true}
          icon={mdiCloseCircle}
          text={t('generic.filePreview.empty')}
        />
      ) : (
        <div className={classNames('file-preview', {'file-preview-search-active': highlightingResult !== null})}>
          {searchHighlighting? <LoadingSpinner/> : null}
          {highlightingResult !== null
            ?<SearchSwitch currentFindingIndex={currentFindingIndex} switchResultCallback={switchCurrentFinding} totalCount={highlightingResult?.counter ?? 0}/>
            :null}

          <iframe
            ref={ref}
            id={'file-preview-iframe'}
            src={path}
            onLoad={handleIFrameLoad}
            title="preview"
            className="file-preview__iframe"
          />
        </div>
      )}
    </LoadingContainer>
  )
})
