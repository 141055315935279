import { noop } from 'sfportal_utils/function'

/**
 * Wenn kundenprojektseitig Stores erstellt werden, die bei einem Logout
 * zurückgesetzt werden sollen, dann muss diese Funktion im Projekt
 * implementiert werden und die Stores in dieser zurückgesetzt werden, ähnlich
 * wie es in der Datei `allStores.ts` im Produkt gemacht wird.
 *
 * @example
 * ~~~ ts
 * // src/custom/stores/allProjectStores.ts
 * export function resetProjectStores (): void {
 *   resetSomeProjectSpecificStore()
 * }
 * ~~~
 */
export function resetProjectStores (): void {
  noop()
}
