import React, { FC } from 'react'
import './TabularData.scss'

interface Props {
  data: Array<[string, string]>
  headingRow?: boolean
  headingCol?: boolean
}

export const TabularData: FC<Props> = ({
  data,
  headingRow = false,
  headingCol = false
}) => {
  function isHeadingRow (index: number): boolean {
    return headingRow && index === 0
  }

  return (
    <div className="tabular-data">
      <table className="tabular-data__table">
        <tbody>
          {data.map(([key, value], index) => (
            <tr className="tabular-data__row" key={index}>
              {headingCol || isHeadingRow(index) ? (
                <th className="tabular-data__key tabular-data__heading">
                  {key}
                </th>
              ) : (
                <td className="tabular-data__key">{key}</td>
              )}

              {isHeadingRow(index) ? (
                <th className="tabular-data__value tabular-data__heading">
                  {value}
                </th>
              ) : (
                <td className="tabular-data__value">{value}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
