import React, { memo, ReactNode, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { usePortalMountTarget } from '../../hooks/usePortalMountTarget'
import { ChildrenProp } from '../../jsx'
import classNames from 'classnames'
import { CenteredLayer, CenteredLayerProps } from '../Layout/CenteredLayer'
import './Modal.scss'

interface Props extends ChildrenProp {
  title?: string
  header?: ReactNode
  footer?: ReactNode
  visible: boolean
  type?: string
  width?: CenteredLayerProps['width']
  padding?: CenteredLayerProps['padding']
  verticalAlign?: CenteredLayerProps['verticalAlign']
}

export const Modal = memo(function Modal ({
  children, header, footer,
  title, visible, type, width, padding,
  verticalAlign = 'center'
}: Props) {
  const { mountTarget } = usePortalMountTarget()

  const modalClasses = useMemo(
    () => {
      return classNames(
        'modal',
        type !== undefined ? { [`modal--type-${type}`]: true } : null
      )
    },
    [type]
  )

  const modal = useMemo(
    () => {
      if (!visible) { return null }

      return (
        <div className={modalClasses}>
          <CenteredLayer
            width={width}
            padding={padding}
            verticalAlign={verticalAlign}
            header={<>
              {title ? <div className="modal__title">{title}</div> : null}
              {header}
            </>}
            children={children}
            footer={footer}
          />
        </div>
      )
    },
    [children, footer, header, modalClasses, padding, title, verticalAlign, visible, width]
  )

  return createPortal(modal, mountTarget)
})
