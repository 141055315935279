/**
 * @author Andreas Linnert
 * @file Diese Datei dient der Berechnung der Position von
 * absolut positionierten Elementen (z. B. Popups)
 * relativ zu anderen Elementen.
 */

/** Eine Richtungsangabe im zweidimensionalen Raum. */
// prettier-ignore
export enum Alignment2D {
  /* eslint-disable no-multi-spaces */
  LEFT_TOP,     TOP,      RIGHT_TOP,
  LEFT,         CENTER,   RIGHT,
  LEFT_BOTTOM,  BOTTOM,   RIGHT_BOTTOM
  /* eslint-enable no-multi-spaces */
}

/**
 * Eine Ausrichtung im eindimensionalen Raum. Der Wert wird als Multiplikator
 * zur weiteren Berechnung der Position verwendet.
 */
export enum Alignment1D {
  /** Entspricht "links" auf der X-Achse und "oben" auf der Y-Achse */
  START = 0,
  /** Entspricht der Mitte auf allen Achsen */
  MIDDLE = 0.5,
  /** Entspricht "rechts" auf der X-Achse und "unten" auf der Y-Achse */
  END = 1,
}

/** Eine Achse im zweidimensionalen Raum. */
// prettier-ignore
export enum Axis2D { X, Y }

interface GetPositionParams {
  /** Start-Koordinate des Ziel-Elements auf der aktuellen Achse. */
  targetStart: number
  /** Größe des Ziel-Elements auf der aktuellen Achse. */
  targetSize: number
  /** Ausrichtung des Ankerpunkts am Ziel-Element auf der aktuellen Achse. */
  targetAlignment: Alignment1D
  /** Größe des Menü-Elements auf der aktuellen Achse. */
  menuSize: number
  /** Ausrichtung des Ankerpunkts am Menü-Element auf der aktuellen Achse. */
  menuAlignment: Alignment1D
  /** Kleinstmögliche Koordinate für die Positionierung. */
  containerStart: number
  /** Größtmögliche Koordinate für die Positionierung. */
  containerEnd: number
}

/**
 * Diese Funktion berechnet eine mögliche Position eines Elements (`menu`)
 * innerhalb eines Containers (`startMin` und `endMax`) und relativ zu einem
 * Ziel-Element (`target`). Die Berechnung findet nur auf einer Achse statt.
 * @returns Start- und End-Koordinaten des Menüs auf der aktuellen Achse.
 */
export function getSafePosition ({
  targetStart,
  targetSize,
  targetAlignment,
  menuSize,
  menuAlignment,
  containerStart,
  containerEnd
}: GetPositionParams): { start: number, end: number } {
  const targetAnchorPoint = targetStart + targetSize * targetAlignment
  const menuAnchorPoint = menuSize * menuAlignment
  const position = targetAnchorPoint - menuAnchorPoint
  const endPoint = position + menuSize

  const hasEnoughSpaceAfterTarget = endPoint <= containerEnd

  if (hasEnoughSpaceAfterTarget) {
    // Menü dort positionieren, wo es lt. Konfiguration platziert werden soll.
    const start = Math.round(position)
    return { start, end: start + menuSize }
  }

  const alternativePosition = targetStart - menuSize
  const hasEnoughSpaceBeforeTarget = alternativePosition >= containerStart

  if (hasEnoughSpaceBeforeTarget) {
    // Alternative Position, wenn an der konfigurierten Stelle nicht genügend
    // Platz zur Verfügung steht. Das entspricht der Position vor dem
    // Target-Element (d. h. links davon oder darüber).
    const start = Math.round(alternativePosition)
    return { start, end: start + menuSize }
  }

  const availableSpaceBefore = targetStart
  const availableSpaceAfter = containerEnd - targetStart - targetSize

  if (availableSpaceBefore > availableSpaceAfter) {
    // Wenn weder vor noch nach dem Element genügend Platz zur Verfügung
    // steht, wird es dort angezeigt, wo mehr Platz zur Verfügung steht.
    // In diesem Fall vor dem Target-Element...
    return { start: 0, end: targetStart }
  }

  // ...und in diesem Fall nach dem Target-Element.
  const start = Math.round(targetStart + targetSize)
  return { start, end: containerEnd }
}

/**
 * Wandelt eine 2D-Richtungsangabe in eine 1D-Richtungsangabe um.
 * @param alignment die 2D-Richtungsangabe, die umgewandelt werden soll.
 * @param axis die Achse, die zur Umwandlung berücksichtigt werden soll.
 */
export function getAlignment1D (
  alignment: Alignment2D,
  axis: Axis2D
): Alignment1D {
  if (axis === Axis2D.X) {
    switch (alignment) {
      case Alignment2D.LEFT_TOP:
      case Alignment2D.LEFT:
      case Alignment2D.LEFT_BOTTOM:
        return Alignment1D.START

      case Alignment2D.TOP:
      case Alignment2D.CENTER:
      case Alignment2D.BOTTOM:
        return Alignment1D.MIDDLE

      case Alignment2D.RIGHT_TOP:
      case Alignment2D.RIGHT:
      case Alignment2D.RIGHT_BOTTOM:
        return Alignment1D.END
    }
  } else {
    switch (alignment) {
      case Alignment2D.LEFT_TOP:
      case Alignment2D.TOP:
      case Alignment2D.RIGHT_TOP:
        return Alignment1D.START

      case Alignment2D.LEFT:
      case Alignment2D.CENTER:
      case Alignment2D.RIGHT:
        return Alignment1D.MIDDLE

      case Alignment2D.LEFT_BOTTOM:
      case Alignment2D.BOTTOM:
      case Alignment2D.RIGHT_BOTTOM:
        return Alignment1D.END
    }
  }
}
