import {getWorkflowInstanceIdByUploadId} from '../stores/workflowStore'

/**
 * Helper welcher die Workflows Benachrichtigt bzw beendet, wenn der Upload noch nicht vollständig ist, die Seite aber neu lädt
 */

// Object mit den Callbacks für den Server zur jeweiligen UploadId
let callBacks: Record<string, string> = {}

// falls der Upload schneller ist, als der Request für die Workflows
let alreadyDone: string[] = []

const headers = {
    type: 'application/json',
}
let blob = new Blob()
/**
 * Fügt einen Callback hinzu und fügt den Event Listener hinzu
 * @param id die upload id
 */
export const addCancelUploadWorkflowCallback = (id: string) => {
    if (alreadyDone.includes(id)) {
        return
    }
    if (Object.values(callBacks).length === 0) {
        window.addEventListener('beforeunload', sendNotifications)
    }
    callBacks[id] = getWorkflowInstanceIdByUploadId(id)

    blob = new Blob([JSON.stringify(Object.values(callBacks))], headers)
}

/**
 * Entfernt einen Callback ,fügt die id in das alreadyDone Array ein und entfernt den Event Listener, wenn keine Callbacks mehr vorhanden sind
 * @param id die upload id
 */
export const removeCancelUploadWorkflowCallback = (id: string) => {
    delete callBacks[id]
    alreadyDone.push(id)
    if (Object.values(callBacks).length === 0) {
        window.removeEventListener('beforeunload', sendNotifications)
        return
    }
    blob = new Blob([JSON.stringify(Object.values(callBacks))], headers)
}

/**
 * Beendet die Workflows für Laufende Uploads bei Reload
 */
const sendNotifications = () => {
    navigator.sendBeacon(`/api/workflow/delete?deleteHistoricdata=false`, blob)
    window.removeEventListener('beforeunload', sendNotifications)
    callBacks = {}
    alreadyDone = []
}