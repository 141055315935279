import React, { memo, useCallback, useMemo, useState } from 'react'
import { ChildrenProp } from '../../jsx'
import classNames from 'classnames'
import { Checkbox } from '../Forms/Checkbox'
import './FilterItem.scss'
import { FilterItemListItemWrapper } from './FilterItemListItemWrapper'

interface Props extends ChildrenProp {
  type?: string
  title: string
  initiallyEnabled: boolean
  onEnabledChange: (value: boolean) => void
  renderItem?: (wrapper: typeof FilterItemListItemWrapper) => JSX.Element
}

export const FilterItem = memo(function FilterItem ({
  children, title, initiallyEnabled,
  type = 'default',
  onEnabledChange, renderItem
}: Props) {
  const [enabled, setEnabled] = useState<boolean>(initiallyEnabled)

  const filterItemClasses = useMemo(
    () => classNames(
      'filter-item',
      `filter-item--type-${type}`,
      { 'filter-item--is-enabled': enabled }
    ),
    [enabled, type]
  )

  const handleHeaderClick = useCallback(
    () => {
      const newValue = !enabled
      setEnabled(newValue)
      onEnabledChange(newValue)
    },
    [enabled, onEnabledChange]
  )

  return (
    <div className={filterItemClasses}>
      <div className="filter-item__header" onClick={handleHeaderClick}>
        <div className="filter-item__checkbox">
          <Checkbox
            checked={enabled}
            onChange={value => setEnabled(value)}
          />
        </div>

        <div className="filter-item__title">{title}</div>
      </div>

      <div className="filter-item__content">
        {
          typeof renderItem === 'function'
            ? renderItem(FilterItemListItemWrapper)
            : children
        }
      </div>
    </div>
  )
})
