import React, { FC, FormEvent, useContext, useState } from 'react'
import { ZoomFadeDiv } from '../../components/Animations/ZoomFadeDiv'
import { Button } from '../../components/Forms/Button'
import { CenteredLayer } from '../../components/Layout/CenteredLayer'
import { login, LoginViewScreen, setCurrentLoginViewScreen, useUserStore } from '../../stores/userStore'
import { useTranslation } from 'react-i18next'
import { LoginInformationContext, LoginInformationProvider } from './LoginView'
import { Input } from '../../components/Forms/Input'
import { Checkbox } from '../../components/Forms/Checkbox'
import { RequestErrorMessage } from '../../components/Generic/RequestErrorMessage'

/**
 * Ein Screen der eine Multifaktor Code Eingabe zeigt.
 *
 * @returns {FC} Die Komponente.
 */
export const MultifactorScreen: FC = () => {
  const { currentLoginViewScreen, mfaValidDaysSetting, status } = useUserStore()

  const { t } = useTranslation()

  const [verificationCode, setVerificationCode] = useState<string>('')

  const [rememberMfa, setRememberMfa] = useState<boolean>(false)

  const { password, username } = useContext<LoginInformationProvider>(LoginInformationContext)

  /**
   * Setzt den Login View zurück auf die Login Seite.
   */
  const handleToLoginClick = (): void => { setCurrentLoginViewScreen(LoginViewScreen.login) }

  /**
   * Handelt den Form Submit.
   *
   * @param {FormEvent} event Das Event.
   */
  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()
    try {
      const headers = new Headers()
      headers.append('verificationCode', verificationCode)
      headers.append('rememberMfa', rememberMfa.toString())
      await login(username, password, headers)
    } catch {
    }
  }

  return (
    <ZoomFadeDiv
      className="login-view__view-item"
      visible={currentLoginViewScreen === LoginViewScreen.multifactor}
    >
      <CenteredLayer>
        <form className="login-view__form" onSubmit={handleSubmit}>
          <h1 className="login-view__title">{t('login.heading')}</h1>

          <div className="login-view__inputs">
            <Input
              label={t('login.field.validationCode')}
              id={'verificationCode'}
              name={'verificationCode'}
              value={verificationCode}
              required={true}
              onInput={setVerificationCode}
              autoFocus={true}
            />

            {mfaValidDaysSetting !== -1 && <Checkbox checked={rememberMfa} onChange={setRememberMfa}>
              {t('login.field.keepAliveMfa', { days: mfaValidDaysSetting })}
            </Checkbox>}
          </div>

          <RequestErrorMessage status={status} />

          <div className="login-view__buttons">
            <Button type="submit">{t('login.buttons.login')}</Button>
            <Button
              type="button"
              buttonStyle="primary"
              onClick={handleToLoginClick}
            >{t('login.buttons.backToLogin')}</Button>
          </div>
        </form>
      </CenteredLayer>
    </ZoomFadeDiv>
  )
}
