import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import { MdiIcon } from './MdiIcon'

interface Props extends ChildrenProp {
  text: string
  icon?: string
}

export const ModalHeadline = memo(function ModalHeadline ({
  text, icon
}: Props) {
  return (
    <div className="modal__headline">
      {icon !== undefined ? (
        <MdiIcon path={icon} className="modal__headline-icon" />
      ) : null}
      <span className="modal__text">{text}</span>
    </div>
  )
})
