import { RouteComponentProps } from '@reach/router'
import React, { memo } from 'react'
import { routes } from '../routes'
import { LoginView } from './Login/LoginView'

/**
 * Profil-Detail-Seite
 */
export const Profile = memo(function BackgroundTasks (
  params: RouteComponentProps
) {
  return (
    // AKtuell wird die Passwort vergessen Komponente angezeigt.
    <LoginView path={routes.login.absolute} showLogin={false} connectingSocket={false}/>
  )
})
