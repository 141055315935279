import { v4 as uuidV4 } from 'uuid'
import { SearchTerm } from '../interfaces/Search'

type Highlighting = { text: string, matching: { counter: number, ids: string[] } }

/**
 * checks if the search term is suitable for the word
 *
 * @param {SearchTerm} term the search term to check
 * @param {string} word the word to check
 * @param {boolean} exact if the search term contains multiple words that must be checked together
 * @returns {boolean} if the search term condition is matching
 */
const findCase = (term: SearchTerm, word: string, exact?: boolean): boolean => {
  if ((exact || term.exact) && term.keyword.split(' ').length !== word.split(' ').length) {
    //the word/sentence is not long enough for the exact search term (multiple words)
    return false
  }
  if (term.wildcard) {
    //the wildcard char is only at the beginning
    if (term.wildcardPosition === 'start') {
      return word.toLowerCase().endsWith(term.keyword.replace('*', '').toLowerCase())
    }
    //the wildcard char is only at the end
    if (term.wildcardPosition === 'end') {
      return word.toLowerCase().startsWith(term.keyword.replace('*', '').toLowerCase())
    }

    //multiple wildcard chars
    //Simplest Case where the search term starts and ends with a wildcard and no wildcard between
    if (term.keyword.startsWith('*') && term.keyword.endsWith('*') && !term.keyword.substring(1, term.keyword.length - 1).includes('*')) {
      return word.toLowerCase().includes(term.keyword.toLowerCase().replaceAll('*', ''))
    }

    /**
     * split the search term by the wildcard chars and check if the "splits" matches completely with the right order
     */
    const splitted = term.keyword.split('*')
    //creates a boolean array if the single partial search term matches exactly the word to its given wildcards
    const verificationArray = splitted.map((splitPart, index) => {
      const searchIndex = word.toLowerCase().indexOf(splitPart.toLowerCase())
      if (searchIndex === -1) {
        return false
      }
      //check if the current index is lower than the array length of the splitted search term otherwise we must look if the word ends with the partial search term
      if (index < splitted.length - 1) {
        //if the index is lower or equals it means the found term is before the wildcard that word needs to be ahead of,
        //otherwise the found word is after the wildcard and the word itself does not match the term in the correct order
        return word.substring(searchIndex, searchIndex+splitted[index].length).includes(splitted[index])
      }
      //if the search term ends with a wildcard the last split part is always empty string,
      //therefore the endsWith produces the right result for if the search term ends with a wildcard or not.
      return word.toLowerCase().endsWith(splitPart.toLowerCase())
    })
    //check if the whole word is matching
    return verificationArray.filter(single => !single).length === 0
  }
  return word.toLowerCase() === term.keyword.toLowerCase()
}

/**
 * Adds whitespaces on both sides of the special character
 * @param {string} text the text to check/replace the special chars
 * @returns string
 */
const editSpecialChars = (text: string): string => {
  return text
    .replaceAll('\n', ' \n ')
    .replaceAll('\r', ' \r ')
    .replaceAll('&nbsp;', ' ')
    //charcode 160 represents also &nbsp but in a string it can happen that only the charcode will be recognised
    .replaceAll(String.fromCharCode(160), ' ')
}

/**
 * checks the given array if a number is in range between the given numbers
 *
 * @param {number[]} entries the array to check
 * @param {number} start the start number
 * @param {number} end the end number
 * @returns {boolean} returns the result
 */
const checkArrayContainsNumberRange = (entries: number[], start: number, end: number): boolean => {
  for (const element of entries) {
    if (element >= start && element <= end) {
      return true
    }
  }
  return false
}

/**
 * Function for highlighting text
 *
 * @param {SearchTerm[]} terms the search terms
 * @param {string} text the text to check for matches
 * @param {boolean} wrapWithoutFinding if the whole text should be wrapped if no matching is found
 * @param {boolean} alternateColor if the findings should be colorized in another color
 *
 * @returns {{ text :string, matching:{counter:number, ids:string[]} }} the new text, the matching count and the ids
 */
export const highlighting = (terms: SearchTerm[], text: string, wrapWithoutFinding?: boolean, alternateColor?: boolean): Highlighting => {
  let counter = 0
  const ids: string[] = []
  let foundCase = false

  // search the words for a matching case
  const findMatching = (word: string, exact?: boolean): string => {
    const findingCase = terms.find(single => findCase(single, word,exact))
    //A search term matches the word
    if (findingCase) {
      foundCase = true
      //if a matching case is found, the whole word getting wrapped
      counter += 1
      const uuid = uuidV4()
      ids.push(uuid)
      return `<em id="${uuid}"  class="search-highlighted" style=background-color:${alternateColor? '#f39200' :'yellow'}>${word}</em>`
    }
    // There is no matching search term, the word returns without changes
    return word
  }

  //replace special chars and split the text by whitespaces
  const textSplitted = editSpecialChars(text).split(' ')
  let newString = ''
  const matchingArr: number[] = []
  //Loop through the Array
  for (let i = 0; i < textSplitted.length; i++) {
    //check if we have exact search terms (multiple words) and go through
      terms.filter(single=>single.exact).every(single => {
        const keywordSplitted = single.keyword.split(' ')
        //check if the current word index from the splitted text is already been wrapped
        if (!checkArrayContainsNumberRange(matchingArr, i, i + keywordSplitted.length - 1)) {
          //check if the index plus the exact term length is longer as the splitted text length, otherwise there cant be a matching
          if (i + keywordSplitted.length-1 < textSplitted.length) {
            //check for matching, if a matching is found, the sentence got wrapped
            const temp = findMatching(textSplitted.slice(i, i + keywordSplitted.length).join(' '), true)
            if (/<em>|<\/em>/.test(temp)) {
              //add the indexes to the array, the single words from the splitted array dont gets multiple wraps (multi ids and counter)
              matchingArr.push(i, i + keywordSplitted.length - 1)
              newString += temp + (i + keywordSplitted.length - 1 !== textSplitted.length - 1 ? ' ' : '')
              return false
            }
          }
        }
        return true
      })
    //check if the word/index is in the matching Array, if not, look if a case is suitable for the current word
      if (!checkArrayContainsNumberRange(matchingArr, i, i)) {
        newString += findMatching(textSplitted[i], false) + (i !== textSplitted.length - 1 ? ' ' : '')
        matchingArr.push(i)
      }
  }

  if (wrapWithoutFinding && !foundCase) {
    newString = `<em class="search-highlighted" style="background-color:${alternateColor? '#f39200' :'yellow'}">${newString}</em>`
  }
  return {
    text: newString,
    matching: {
      counter,
      ids
    }
  }
}

/**
 * Generates the normal search terms
 *
 * @param {string} fixedSearchTerm the search term
 * @param {boolean} asWholeSentence if the search term should be checked together
 * @returns {SearchTerm[]} the generated search terms
 */
const generateTerms = (fixedSearchTerm: string, asWholeSentence?: boolean): SearchTerm[] => {
  const generate = (term: string) => {
    if (!term.includes('*')) {
      return {
        keyword: term,
        wildcard: false
      } as SearchTerm
    }
    const searchTerm: SearchTerm = {
      keyword: term,
      wildcard: true,
      wildcardPosition: 'multiple'
    }
    if (term.startsWith('*') && !term.substring(1).includes('*')) {
      searchTerm.wildcardPosition = 'start'
      return searchTerm
    }
    if (term.endsWith('*') && !term.substring(0, term.length - 1).includes('*')) {
      searchTerm.wildcardPosition = 'end'
      return searchTerm
    }
    return searchTerm
  }

  if (asWholeSentence) {
    return [generate(fixedSearchTerm)]
  }

  return fixedSearchTerm.split(' ').map(single => generate(single))
}

/**
 * search the search term for words in double quotes, generates the Search Terms and remove the founded terms from the string
 *
 * @param {string} term the search term
 * @returns {{ termWithout: string, searchTerms: SearchTerm[] }} the generated search terms and the new search term without the exact terms
 */
const generateExactSearchTerms = (term: string): { termWithout: string, searchTerms: SearchTerm[] } => {
  const indexMatches: { first: number, last: number }[] = []
  let foundFirst = false
  let tempIndex: { first: number, last: number } = {
    first: 0,
    last: 0
  }

  let index = 0
  //looking for double quotes
  for (const char of term) {
    if (char === '"') {
      if (foundFirst) {
        tempIndex.last = index
        indexMatches.push({ ...tempIndex })
        foundFirst = false
        tempIndex = {
          first: 0,
          last: 0
        }
      } else {
        tempIndex.first = index
        foundFirst = true
      }
    }
    index += 1
  }
  const temp: SearchTerm[] = indexMatches.map(single => {
    // get the exact terms without the "
    const sub = term.substring(single.first + 1, single.last)
    const termCalc = generateTerms(sub, true)[0]

    //if the search term is one word that is the normal/default search term
    if (sub.split(' ').length < 2) {
      if (termCalc.wildcard) {
        return {
          keyword: sub,
          wildcard: true,
          wildcardPosition: termCalc.wildcardPosition
        }
      }
      return {
        keyword: sub,
        wildcard: false
      }
    }

    //two or more words getting own cases, because we must search the string different
    if (termCalc.wildcard) {
      return {
        exact: true,
        keyword: sub,
        wildcard: true,
        wildcardPosition: termCalc.wildcardPosition
      }
    }
    return {
      exact: true,
      keyword: sub,
      wildcard: false
    }
  })
  let termWithout = `${term}`

  temp.forEach(single => termWithout = termWithout.replace(`"${single.keyword}"`, ''))

  //removing all double, start and ending whitespaces
  termWithout = termWithout.replaceAll('  ', ' ').trim()

  return {
    termWithout: termWithout,
    searchTerms: temp
  }
}

/**
 * Generates the single Search Terms from the Search Term
 *
 * @param {string} term the search term
 * @returns {SearchTerm[]} the generated search terms
 */
export const generateSearchTerms = (term: string): SearchTerm[] => {
  let searchTermWithoutExact
  const searchTerms: SearchTerm[] = []
  if (/".*"/.test(term)) {
    const result = generateExactSearchTerms(term)
    searchTermWithoutExact = result.termWithout
    searchTerms.push(...result.searchTerms)
  }
  if (searchTermWithoutExact !== undefined && searchTermWithoutExact.length === 0){
    return searchTerms
  }
  return [...searchTerms, ...generateTerms(searchTermWithoutExact ?? term)]
}
