import React, {FC} from 'react'
import './Spinner.scss'

export const LoadingSpinner: FC = () => {
    return <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
}