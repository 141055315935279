import { FC, ReactElement, useMemo } from 'react'

export type SubviewsRecord = Record<string, () => ReactElement>

interface Props<Views extends SubviewsRecord> {
  views: Views
  current: keyof Views | null
}

export const Subviews = <Views extends SubviewsRecord>({
  views,
  current
}: Props<Views>): ReturnType<FC> => {
  const subview = useMemo<ReactElement | null>(() => {
    if (typeof views !== 'object') return null
    if (current === null) return null
    const createSubview = views[current]

    return createSubview?.() ?? null
  }, [current, views])

  return subview
}
