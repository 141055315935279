import React, { ReactElement } from 'react'
import { MdiIcon } from '../Generic/MdiIcon'
import { AlertComponentPropsWithStyle, positions, transitions } from 'react-alert'
import { mdiCheckCircleOutline, mdiCloseCircleOutline, mdiInformationOutline } from '@mdi/js'
import './AlertTemplate.scss'
import classNames from 'classnames'

/**
 * Alert Template Komponente, um einheitliche Alerts im Portal anzeigen zu können.
 *
 * @param style Styles für den Dialog. (werden vom AlertProvider übergeben)
 * @param options Options mit dem Type des Alerts (info, succes, error)
 * @param message Die Nachricht, die im Dialog angezeigt wird. Es darf auch html/React Komponenten übergeben werden.
 * @constructor Standard Komponent Konstruktor.
 */
export const AlertTemplate = ({ style, options, message }: AlertComponentPropsWithStyle): ReactElement => {
  // Styling Classes für die alert templates.
  const alertTemplateClasses = classNames(
    'alert-template',
    {
      [`alert-template--type-${String(options.type)}`]:
        options.type !== undefined
    }
  )

  /**
   * Liefert das richtige Icon zum alert Dialog.
   */
  const getAlertIcon = (): string => {
    switch (options.type) {
      case 'info':
        return mdiInformationOutline
      case 'success':
        return mdiCheckCircleOutline
      default:
        return mdiCloseCircleOutline
    }
  }

  return <div style={style} className={alertTemplateClasses}>
    <MdiIcon path={getAlertIcon()} className={'alert-template__icon'}/>
    {message}
  </div>
}

/**
 * Default Options für den AlertProvider.
 */
export const defaultOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE
}
