import { useEffect, useMemo, useState } from 'react'
import { ApiTaskDataResourceAsset, EMK } from 'sfportal_services_api/apiSchemas'
import { apiGetDataresourceByIdentifier } from 'sfportal_services_api/dataresourceApiService'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { noop } from 'sfportal_utils/function'
import { isDefined } from 'sfportal_utils/global'
import { getFileUriFromEMK } from '../../../../routes'
import { typesToShowDirectly, viewableImages } from './constants'

export interface UseDetailPathsReturn {
  imagePath: string | null
  filePreviewPath: string | null
  correctionPreviewPath: string | null
}

export function useDetailPaths (): UseDetailPathsReturn {
  const { currentFile } = useProductDetailStore()

  const [smfilesDataresourceId, setSmfilesDataresourceId] = useState<
  number | null
  >(null)

  useEffect(() => {
    getSmfilesDataresource().catch(noop)
  }, [])

  async function getSmfilesDataresource (): Promise<void> {
    const result = await apiGetDataresourceByIdentifier('smfiles')
    setSmfilesDataresourceId(result.body.id)
  }

  const imagePath = useMemo(() => {
    if (currentFile === null) {
      return null
    }
    const contentType = (currentFile as ApiTaskDataResourceAsset).contentType
    if (contentType === null) {
      return null
    }

    // Assets vom Typ Bild direkt anzeigen
    if (viewableImages.includes(contentType)) {
      return getFileUriFromEMK(currentFile.id)
    }

    // Nicht-Bild-Assets mit einer Vorschau im Bild-Format anzeigen.
    if (currentFile.type !== 'asset' && currentFile.type !== 'smashdocs') {
      return null
    }
    if (currentFile.previewPath === null) {
      return null
    }
    return getFileUriFromEMK(currentFile.previewPath)
  }, [currentFile])

  const filePreviewPath = useMemo(() => {
    if (currentFile === null) {
      return null
    }

    const { cstFkPreviewPdf, contentType } =
      currentFile as ApiTaskDataResourceAsset

    if (isDefined(cstFkPreviewPdf) && cstFkPreviewPdf !== 0) {
      if (smfilesDataresourceId === null) {
        return null
      }
      const sfId = `sf${smfilesDataresourceId}-${cstFkPreviewPdf}`
      return getFileUriFromEMK(sfId)
    }

    if (contentType !== null && typesToShowDirectly.includes(contentType)) {
      return getFileUriFromEMK(currentFile.id)
    }

    // `previewPath` verwenden
    if (currentFile.type !== 'asset' && currentFile.type !== 'editordocument') {
      return null
    }
    if (
      currentFile.previewPath !== null && currentFile.previewPath !== undefined &&
      currentFile.previewPath.trim() !== ''
    ) {
      return getFileUriFromEMK(currentFile.previewPath)
    }

    return null
  }, [currentFile, smfilesDataresourceId])

  const correctionPreviewPath = useMemo((): EMK | null => {
    if (currentFile === null) {
      return null
    }

    const currentFileDataResourceAsset = currentFile as ApiTaskDataResourceAsset

    if (
      currentFileDataResourceAsset.cstFkChangePdf !== null &&
      currentFileDataResourceAsset.cstFkChangePdf !== undefined
    ) {
      if (smfilesDataresourceId === null) {
        return null
      }
      return getFileUriFromEMK(
        `sf${smfilesDataresourceId}-${(
          (currentFile as ApiTaskDataResourceAsset).cstFkChangePdf ?? ''
        ).toString()}`
      )
    }

    // `correctionPdfPath` verwenden
    if (currentFileDataResourceAsset.type !== 'asset') {
      return null
    }
    if (
      currentFileDataResourceAsset.correctionPdfPath === undefined ||
      currentFileDataResourceAsset.correctionPdfPath === null
    ) {
      return null
    }
    return getFileUriFromEMK(currentFileDataResourceAsset.correctionPdfPath)
  }, [currentFile, smfilesDataresourceId])

  return { imagePath, filePreviewPath, correctionPreviewPath }
}
