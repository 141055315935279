import { LocationProvider } from '@reach/router'
import React, { Suspense } from 'react'
import * as ReactDOM from 'react-dom'
import { App } from './App'
import './sass/base/elements.scss'
import './sass/base/fonts.scss'
import './sass/base/material-design-icons.scss'
import { setupAllStores } from './stores/allStores'
import { AlertTemplate, defaultOptions } from './components/Alert/AlertTemplate'
import { Provider as AlertProvider } from 'react-alert'

document.title = process.env.REACT_APP_TITLE ?? 'SiteFusion Portal'

const jwtToken = decodeURIComponent(
  window.location.search.replace(
    new RegExp('^(?:.*[&\\?]' + encodeURIComponent('login_jwt').replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
    '$1')
)
if (jwtToken !== '') {
  document.cookie = `sfportal-jwt = ${jwtToken};secure;path=/`
}

setupAllStores()

ReactDOM.render(
  <LocationProvider>
    <AlertProvider template={AlertTemplate} {...defaultOptions}>
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    </AlertProvider>
  </LocationProvider>,
  document.getElementById('root')
)
