import {createReactHook, createStore} from '@alinnert/tstate'
import {deleteWorkflowTasks, getAllWorkflowTasks} from '../services/api/workflowApiService'
import {noop} from '../utils/function'

export interface FileFormat {
    id: number
    identifier: string
    fksfdataresources: number
    fksfclassifications: number
}

export interface WfFiles {
    fileNotSupported: boolean
    filename: string
    fileformats: FileFormat[]
    sffileformat: FileFormat
    uploadid: string
    entityMetakey?: string
}

interface Variables {
    dialogKey: string
    allDuplicates: boolean
    fileNotSupported: boolean
    fileTypeDuplicates: boolean
    fileAvailable?: boolean
    filename: string
    filepath: string
    files: WfFiles[]
    folderToLinkId: number
    formDialogRequired: boolean
    lastProcessModiuser: number
    publicationChannelId: number
    uploadid: string
    userId: number
    fileFormatsSelectedDefaultValue: Record<string, string>
}

export interface UploadWorkflow {
    businesskey: string | null
    incidentid: string
    duration: number
    endtime: string
    processdefinitionkey: string
    processinstanceid: string
    starttime: string
    startuserid: number
    state: 'ACTIVE' | 'COMPLETED' | 'INTERNALLY_TERMINATED'
    variables: Variables
    taskid: string
}

export interface WorkflowStore {
    /** Eine Liste aller Datei-Übertragungen. */
    workflows: UploadWorkflow[]
}

function getInitialState(): WorkflowStore {
    return {
        workflows: []
    }
}

const workflowStore = createStore(getInitialState())
export const useWorkflowStore = createReactHook(workflowStore)

const mutations = {
    reset(): void {
        workflowStore.set(getInitialState())
    },

    setUploadWorkflows(workflows: UploadWorkflow[]): void {
        workflowStore.set({workflows: workflows})
    }
}

export function resetWorkflowStore(): void {
    mutations.reset()
}

export const getWorkflowLength = () => {
    return workflowStore.state.workflows.length
}

export const loadWorkflows = async (userId: number, definitionKey: string) => {
    const result = await getAllWorkflowTasks(userId, definitionKey)
    const workflows = result.body as UploadWorkflow[]
    workflows.sort((a, b) => a.starttime.localeCompare(b.starttime))
    mutations.setUploadWorkflows(workflows.filter(single => single.variables?.filename !== undefined))
}

export const deleteWorkflowsByState = (state: UploadWorkflow['state']) => {
    let wfsToDelete
    let others
    if (state === 'INTERNALLY_TERMINATED') {
        wfsToDelete = workflowStore.state.workflows.filter(single => single.state === state || single.incidentid !== null)
        others = workflowStore.state.workflows.filter(single => single.state !== state && single.incidentid === null)
    } else {
        wfsToDelete = workflowStore.state.workflows.filter(single => single.state === state)
        others = workflowStore.state.workflows.filter(single => single.state !== state)
    }
    if (wfsToDelete.length === 0) {
        return
    }
    deleteWorkflowTasks(wfsToDelete.map(single => single.processinstanceid))
        .catch(noop)
    mutations.setUploadWorkflows(others)
}

export const updateWorkflow = (processId: string, newWorkflow: UploadWorkflow) => {
    const workflows = workflowStore.state.workflows
    const indexToReplace = workflowStore.state.workflows.findIndex(single => single.processinstanceid === processId)
    workflows[indexToReplace] = newWorkflow
    mutations.setUploadWorkflows(workflows)
}

export const getWorkflowInstanceIdByUploadId = (uploadid: string) => {
    return workflowStore.state.workflows.filter(single => single.variables.uploadid === uploadid)[0]?.processinstanceid
}

export const deleteWorkflow = (processInstanceId: string) => {
    const wfs = workflowStore.state.workflows
    const indexToRemove = wfs.findIndex(single => single.processinstanceid === processInstanceId)
    wfs.splice(indexToRemove, 1)
    mutations.setUploadWorkflows(wfs)
}
