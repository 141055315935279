import React, { FC, FormEvent, useContext, useState } from 'react'
import { ZoomFadeDiv } from '../../components/Animations/ZoomFadeDiv'
import { Button } from '../../components/Forms/Button'
import { CenteredLayer } from '../../components/Layout/CenteredLayer'
import { login, LoginViewScreen, setCurrentLoginViewScreen, useUserStore } from '../../stores/userStore'
import { useTranslation } from 'react-i18next'
import { Select } from '../../components/Forms/Select'
import { LoginInformationContext, LoginInformationProvider } from './LoginView'

/**
 * Screen der die Auswahl einer Multifaktor Methode erzwingt.
 *
 * @return {FC} Die Komponente.
 */
export const MultifactorMethodScreen: FC = () => {
  const { currentLoginViewScreen, mfaMethods } = useUserStore()

  const { t } = useTranslation()

  const { password, username } = useContext<LoginInformationProvider>(LoginInformationContext)

  const [selectedMfaMethod, setSelectedMfaMethod] = useState<string>('')

  /**
   * Setzt den Login View zurück zum Login.
   */
  const handleToLoginClick = (): void => { setCurrentLoginViewScreen(LoginViewScreen.login) }

  /**
   * Handelt den Submit der Form.
   *
   * @param {FormEvent} event Das Event der Form.
   */
  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()
    try {
      const headers = new Headers()
      headers.append('authentificationMethod', selectedMfaMethod)
      await login(username, password, headers)
    } catch {
    }
  }

  return (
    <ZoomFadeDiv
      className="login-view__view-item"
      visible={currentLoginViewScreen === LoginViewScreen.multifactorMethod}
    >
      <CenteredLayer>
        <form className="login-view__form" onSubmit={handleSubmit}>
          <h1 className="login-view__title">{t('login.heading')}</h1>

          <div className="login-view__inputs">
            <Select
              label={t('login.multifactor.mfaMethodSelect')}
              name={'chooseMfaMethod'}
              items={ Object.fromEntries<string>(mfaMethods.map(mfaMethod => [mfaMethod, t(`login.multifactor.${mfaMethod}`)])) }
              value={ selectedMfaMethod }
              required={true}
              placeholder={t('global.formfield.select.placeholder')}
              onChange={setSelectedMfaMethod}
            />
          </div>

          <div className="login-view__buttons">
            <Button
              type="button"
              buttonStyle="primary"
              onClick={handleToLoginClick}
            >{t('login.buttons.backToLogin')}</Button>
            <Button type="submit">{t('login.buttons.login')}</Button>
          </div>
        </form>
      </CenteredLayer>
    </ZoomFadeDiv>
  )
}
