import { ApiJwtAuthenticationDetails, ApiPortalData, ApiValuesData, ApiValuesStart } from './api/apiSchemas'

/**
 * @author Andreas Linnert
 * @file Dieser Service erlaubt typisierten Zugriff auf den LocalStorage.
 */

interface StorageTypes {
  'users:token': ApiJwtAuthenticationDetails
  /** Gibt an ob der Token in users:token über die Login Maske erstellt wurde, oder über die Url. (true => Login mit Token in URL, false => Token in Session durch Login.) */
  'users:tokenLogin': boolean
  'i18nextLng': string
  /** Timestamp, wann die Daten vom Server zuletzt geladen wurden. */
  'values:timestamp': number
  /** Die Daten der Values-API */
  'values:data': ApiValuesData
  /** Die Daten des Portals */
  'portal-data:data': ApiPortalData
  /** Die Daten der Values-Start-API */
  'values-start:data': ApiValuesStart
}

export function getStorageValue<T extends keyof StorageTypes> (
  key: T, { parse }: { parse: true }
): StorageTypes[T] | null
export function getStorageValue<T extends keyof StorageTypes> (
  key: T, { parse }: { parse: false }
): string | null
export function getStorageValue<T extends keyof StorageTypes> (
  key: T, { parse }: { parse: boolean }
): StorageTypes[T] | string | null {
  const value = localStorage.getItem(key)

  if (!parse || value === null) { return value }

  try {
    return JSON.parse(value) as StorageTypes[T]
  } catch {
    return null
  }
}

export function setStorageValue<T extends keyof StorageTypes> (
  key: T, value: StorageTypes[T]
): void {
  const storageValue = typeof value === 'string'
    ? value
    : JSON.stringify(value)

  localStorage.setItem(key, storageValue)
}

export function removeStorageValue (key: keyof StorageTypes): void {
  localStorage.removeItem(key)
}
