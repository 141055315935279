import { mdiAccountCircle } from '@mdi/js'
import React, { memo } from 'react'
import { MdiIcon } from '../Generic/MdiIcon'
import { LabelItem } from './LabelItem'
import './Person.scss'

interface Props {
  name: string
  label?: string
}

export const Person = memo(function Person ({
  name, label
}: Props) {
  return (
    <LabelItem label={label}>
      <div className="person">
        <MdiIcon className="person__icon" path={mdiAccountCircle} />
        <span className="person__label">{name === null ? '‒' : name}</span>
      </div>
    </LabelItem>
  )
})
