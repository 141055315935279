import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  loadCurrentItemMetadata,
  useProductTreeStore
} from 'sfportal_stores/productTreeStore'
import { noop } from 'sfportal_utils/function'
import { LoadingContainer } from '../../../../components/Layout/LoadingContainer'
import {
  KeyValueTable,
  KeyValueTableData
} from '../../../../components/Table/KeyValueTable'
import { ChildrenProp } from '../../../../jsx'

interface Props extends ChildrenProp {}

export const Metadata: FC<Props> = () => {
  const { t } = useTranslation()

  const { selectedItemMetadata, selectedItemMetadataStatus } =
    useProductTreeStore()

  const metadata = useMemo<KeyValueTableData>(() => {
    return [
      [t('metadata.properties.filename'), selectedItemMetadata?.fileName],
      [t('metadata.properties.filesize'), selectedItemMetadata?.fileSize],
      [t('metadata.properties.createdBy'), selectedItemMetadata?.createdBy],
      [t('metadata.properties.createdDate'), selectedItemMetadata?.createdDate],
      [t('metadata.properties.modifiedBy'), selectedItemMetadata?.modifiedBy],
      [
        t('metadata.properties.modifiedDate'),
        selectedItemMetadata?.modifiedDate
      ],
      [t('metadata.properties.description'), selectedItemMetadata?.description]
    ]
  }, [selectedItemMetadata, t])

  function handleRetry (): void {
    loadCurrentItemMetadata().catch(noop)
  }

  return (
    <div className="product-detail-view/product-tree-detail/metadata">
      <LoadingContainer
        status={selectedItemMetadataStatus}
        loadingText={t('global.loading')}
        onRetry={handleRetry}
      >
        <KeyValueTable data={metadata} />
      </LoadingContainer>
    </div>
  )
}
