import { mdiFileSearchOutline } from '@mdi/js'
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { EmptyIndicator } from 'sfportal_components_generic/EmptyIndicator'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { ChildrenProp } from '../../../../jsx'
import './TasksDetail.scss'
import { usePreviewContent } from './usePreviewContent'
import { usePreviewGenerationCheck } from './usePreviewGenerationCheck'
import { useSubscriptions } from './useSubscriptions'
import { useTranslation } from 'react-i18next'

interface Props extends ChildrenProp {
}

export const TasksDetail = memo<Props>(function TasksDetail () {
  const { currentFile } = useProductDetailStore()
  const { previewContent } = usePreviewContent()

  const { t } = useTranslation()

  useSubscriptions()
  usePreviewGenerationCheck()

  const detailScreenClasses = useMemo(
    () =>
      classNames('product-detail-view/task-detail', {
        'product-detail-view/task-detail--is-centered': previewContent === null
      }),
    [previewContent]
  )

  return (
    <div className={detailScreenClasses}>
      {currentFile !== null ? (
        <div className="product-detail-view/task-detail__title">
          {currentFile.name}
        </div>
      ) : null}

      <div className="product-detail-view/task-detail__body">
        {currentFile === null ? (
          <EmptyIndicator
            compact
            stretch
            dimmed
            text={t('productTree.detailView.empty')}
            icon={mdiFileSearchOutline}
          />
        ) : (
          previewContent
        )}
      </div>
    </div>
  )
})
