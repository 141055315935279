import React, { memo, useMemo } from 'react'
import classNames from 'classnames'
import { MdiIcon } from '../Generic/MdiIcon'

interface Props {
  label?: string
  icon?: string
  iconOnly?: boolean
  uri: string | null
  download?: boolean
}

export const ItemViewLink = memo(function ItemViewLink ({
  label, icon, uri, iconOnly = false, download = false
}: Props) {
  const actionClasses = useMemo(() => {
    return classNames(
      'item-view__action',
      'item-view__action--type-link',
      {
        'item-view__action--icon-only':
            icon !== undefined && (label === undefined || iconOnly)
      }
    )
  }, [icon, iconOnly, label])

  const titleAttr = useMemo(
    () => label !== undefined && iconOnly ? label : undefined,
    [iconOnly, label]
  )

  if (uri === null || uri === '') { return null }

  return (
    <a
      className={actionClasses}
      href={uri}
      download={download}
      title={titleAttr}
    >
      {icon !== undefined ? (
        <MdiIcon path={icon} className="item-view__action-icon" />
      ) : null}

      {label !== undefined && !iconOnly ? (
        <div className="item-view__action-label">{label}</div>
      ) : null}
    </a>
  )
})
