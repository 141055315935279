import { useEffect } from 'react'
import { apiGetHasWorkflowStarted } from 'sfportal_services_api/workflowApiService'
import {
  setCurrentFileReloading,
  useProductDetailStore
} from 'sfportal_stores/productDetailStore'
import { noop } from 'sfportal_utils/function'
import { useIsMounted } from 'sfportal_utils/useIsMounted'

export function usePreviewGenerationCheck (): void {
  const isMounted = useIsMounted()

  const { currentFile } = useProductDetailStore()

  // Beim ändern der Datei wird geprüft ob die Preview gerade erstellt wird.
  useEffect(() => {
    if (currentFile === undefined || currentFile?.id === undefined) {
      return
    }
    apiGetHasWorkflowStarted('metadataextraction', currentFile?.id)
      .then((response) => {
        if (!isMounted()) return
        setCurrentFileReloading(response.body)
      })
      .catch(noop)
  }, [currentFile, isMounted])
}
