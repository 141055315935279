import { ApiTask } from '../../../services/api/apiSchemas'
import React, { Dispatch, ReactElement, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompleteTaskModalSubmitParams } from '../../Modals/CompleteTaskModal'
import { completeTasks } from '../../../stores/productDetailStore'
import { noop } from '../../../utils/function'
import { ConfirmModal } from '../../Modals/ConfirmModal'

interface UseCompleteMultipleTasksModalParams {
  tasks: ApiTask[]
}

interface UseCompleteMultipleTasksModalReturn {
  completeMultipleTasksModal: ReactElement
  setCompleteMultipleTasksModalVisible: Dispatch<SetStateAction<boolean>>
}

export function useCompleteMultipleTasksModal ({
  tasks
}: UseCompleteMultipleTasksModalParams): UseCompleteMultipleTasksModalReturn {
  const [modalVisible, setModalVisible] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = useCallback(async (completeTaskParams?: CompleteTaskModalSubmitParams) => {
    let taskIds
    if (completeTaskParams === undefined) {
      taskIds = tasks.map((task) => ({ taskId: task.id }))
    } else {
      const formFields = (({ comment, ...o }) => o)(completeTaskParams)
      taskIds = tasks.map((task) => ({ taskId: task.id, formFields }))
    }

    completeTasks(...taskIds).catch(noop)
    setModalVisible(false)
  }, [tasks])

  function handleCancel (): void {
    setModalVisible(false)
  }

  const modal = useMemo(
    () => (
      <ConfirmModal
        title={t('completeMultipleTasksModal.title', { count: tasks.length })}
        confirmButtonLabel={t('completeMultipleTasksModal.confirmButton', {
          count: tasks.length
        })}
        visible={modalVisible}
        onConfirm={handleSubmit}
        onCancel={handleCancel}
        tasks={tasks}
      >
        {t('completeMultipleTasksModal.body', {
          count: tasks.length,
          taskName: tasks[0]?.name ?? ''
        })}
      </ConfirmModal>
    ),
    [handleSubmit, modalVisible, t, tasks]
  )

  return {
    completeMultipleTasksModal: modal,
    setCompleteMultipleTasksModalVisible: setModalVisible
  }
}
