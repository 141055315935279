import {RouteComponentProps} from '@reach/router'
import React, {memo, useState} from 'react'
import {WorkflowList} from '../components/ListItem/WorkflowList'
import {Button} from '../components/Forms/Button'
import {deleteWorkflowsByState, loadWorkflows} from '../stores/workflowStore'
import {getUserId} from '../stores/userStore'
import {noop} from '../utils/function'
import {LoadingSpinner} from '../components/Animations/LoadingSpinner'
import {useTranslation} from 'react-i18next'

export const sfOrange = '#f39200'
export const BackgroundTasks = memo(function BackgroundTasks(
    params: RouteComponentProps
) {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const {t} = useTranslation()
    const refreshWorkflows = () => {
        const userId = getUserId()
        if (userId) {
            setIsLoading(true)
            loadWorkflows(userId, 'simpleUpload')
                .catch(noop)
                .finally(() => setIsLoading(false))
        }
    }

    return <>
        <div style={{display: 'flex', justifyContent: 'start', gap: '10px', margin: '10px 10px'}}>
            <Button buttonColor={sfOrange} onClick={() => deleteWorkflowsByState('COMPLETED')}>{t('buttons.delete.finished')}</Button>
            <Button buttonColor={sfOrange} onClick={() => deleteWorkflowsByState('INTERNALLY_TERMINATED')}>{t('buttons.delete.failed')}</Button>
            <Button buttonColor={sfOrange} onClick={refreshWorkflows}>{t('buttons.reload.list')}</Button>
            {isLoading
                ? <div style={{zoom: '50%', height: '37px'}}><LoadingSpinner /></div>
                : null}
        </div>
        <WorkflowList loadWorkflows={refreshWorkflows} />
    </>
})
