import React, { FormEvent, memo, useCallback, useRef, useState } from 'react'
import { useAutoFocus } from '../../hooks/useAutoFocus'
import { ChildrenProp } from '../../jsx'
import './Select.scss'

interface Props extends ChildrenProp {
  name: string
  id?: string
  label?: string
  items: Record<string, string>
  value?: string
  placeholder?: string
  autoFocus?: boolean
  required?: boolean
  disabled?: boolean
  selectProps?: Record<string, unknown>
  onChange?: (value: string) => void
}

export const Select = memo(function Select ({
  name,
  placeholder,
  items,
  disabled,
  selectProps,
  required,
  label = '',
  value = '',
  id = name,
  autoFocus = false,
  onChange
}: Props) {
  const selectRef = useRef<HTMLSelectElement>(null)

  useAutoFocus({
    ref: selectRef,
    enabled: autoFocus
  })

  const [valueState, setValueState] = useState<string>(value)

  const handleChange = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      setValueState(event.currentTarget.value)
      onChange?.(event.currentTarget.value)
    },
    [onChange]
  )

  return (
    <div className="select">
      {label !== '' ? (
        <label htmlFor={id} className="select__label">{label}</label>
      ) : null}

      <select
        className="select__select"
        ref={selectRef}
        value={valueState}
        id={id}
        required={required}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        {...selectProps}
      >
        {placeholder !== undefined && placeholder !== '' ? (
          <option value="">{placeholder}</option>
        ) : null}

        {Object.entries(items).map(([key, value]) => (
          <option key={key} value={key}>{value}</option>
        ))}
      </select>
    </div>
  )
})
