import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiTask } from "sfportal_services_api/apiSchemas";
import { RequestStatus } from "sfportal_services_api/generic/types";
import {
  isTaskGroupExpanded,
  loadProductTasks,
  toggleExpandedTaskGroup,
  useProductDetailStore,
} from "sfportal_stores/productDetailStore";
import { sortedIndex, sortWithGetter } from "sfportal_utils/array";
import { noop } from "sfportal_utils/function";
import { useIsMounted } from "sfportal_utils/useIsMounted";
import { ProductDetailViewUtils } from "sfportal_views_productdetail_utils/ProductDetailViewUtils";
import { LoadingContainer } from "../../../../components/Layout/LoadingContainer";
import { ListItemGroupTasks } from "../../../../components/ListItem/ListItemGroupTasks";
import { ChildrenProp } from "../../../../jsx";
import { sortTasksFlatTree } from "../../../common/tasks/sortTasksFlatTree";
import { useHighlightedAsset } from "../../useHighlightedAsset";
import { useSubscriptionsForTabContents } from "../../useSubscriptionsForTabContents";

type Props = ChildrenProp;

export const ProductTasks: FC<Props> = () => {
  const { t } = useTranslation();

  const isMounted = useIsMounted();

  const [renderAdditionalContent, setRenderAdditionalContent] = useState(false);

  useSubscriptionsForTabContents();

  const { product, tasks, tasksStatus } = useProductDetailStore();
  const { highlightedAssetTaskName, highlightedAssetId } = useHighlightedAsset(
    Object.values(tasks),
  );

  const getTaskCountString = useCallback(
    (taskCount: number): string => {
      if (taskCount <= 0) {
        return t("tasks.zero");
      }
      return t("tasks.list.tasksCount", {
        count: taskCount,
      }).trim();
    },
    [t],
  );

  const groupedTasks = useMemo(() => {
    const taskGroupsObject: Record<ApiTask["name"], ApiTask[]> = {};

    for (const task of Object.values(tasks)) {
      if (taskGroupsObject[task.name] === undefined) {
        taskGroupsObject[task.name] = [];
      }

      taskGroupsObject[task.name].splice(
        sortedIndex(taskGroupsObject[task.name], task, sortTasksFlatTree),
        0,
        task,
      );
    }

    const taskGroups = Object.entries(taskGroupsObject);
    taskGroups.sort(sortWithGetter(([, tasks]) => tasks[0].sortOrder));

    return Object.fromEntries(taskGroups);
  }, [tasks]);

  const renderAdditionalContentMemoized = useMemo(async () => {
    return await ProductDetailViewUtils.additionalContentSectionsRequirement(
      product,
    );
  }, [product]);

  useEffect(() => {
    renderAdditionalContentMemoized
      .then((result) => {
        if (!isMounted()) return;
        setRenderAdditionalContent(result);
      })
      .catch(noop);
  }, [isMounted, renderAdditionalContentMemoized]);

  if (product === null) return null;

  return (
    <div className="product-detail-view/tasks-list-view">
      <LoadingContainer
        loadingText={t("tasks.loading")}
        status={tasksStatus}
        onRetry={() => {
          loadProductTasks().catch(noop);
        }}
      >
        {tasksStatus === RequestStatus.ok && Object.keys(tasks).length > 0 ? (
          <ProductDetailViewUtils.ContentSectionProductDetail>
            {Object.entries(groupedTasks).map(([taskName, tasks]) => (
              <ListItemGroupTasks
                key={taskName}
                title={taskName}
                titleSuffix={` (${getTaskCountString(tasks.length)})`}
                highlightGroup={taskName === highlightedAssetTaskName}
                tasks={tasks}
                highlightTasks={
                  highlightedAssetId !== null ? [highlightedAssetId] : undefined
                }
                isExpanded={isTaskGroupExpanded(
                  taskName,
                  tasks[0].expanded || highlightedAssetTaskName === taskName,
                )}
                onIsExpandedChange={() => {
                  toggleExpandedTaskGroup(taskName, tasks[0].expanded);
                }}
              />
            ))}
          </ProductDetailViewUtils.ContentSectionProductDetail>
        ) : null}

        {renderAdditionalContent ? (
          <ProductDetailViewUtils.AdditionalContentSections product={product} />
        ) : null}
      </LoadingContainer>
    </div>
  );
};
