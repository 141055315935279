import { mdiAccount, mdiEmailMultiple, mdiInformation, mdiPower } from '@mdi/js'
import { useLocation } from '@reach/router'
import React, { FC, useCallback, useMemo } from 'react'
import { TabularData } from 'sfportal_components_generic/TabularData'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import { usePortalDataStore } from 'sfportal_stores/portalDataStore'
import { routes } from '../../routes'
import { useMessageStore } from '../../stores/messageStore'
import { logout, useUserStore } from '../../stores/userStore'
import './AppHeader.scss'
import { AppHeaderButton } from './AppHeaderButton'
import { AppHeaderDropdown } from './AppHeaderDropdown'
import { AppHeaderLogo } from './AppHeaderLogo'
import { useTranslation } from 'react-i18next'
import { AppHeaderEnvironmentInfo } from './AppHeaderEnvironmentInfo'

export const AppHeader: FC = function AppHeader () {
  const location = useLocation()
  const { currentUser } = useUserStore()
  const { messages } = useMessageStore()
  const { data: portalData, status: portalDataStatus } = usePortalDataStore()

  const { t } = useTranslation()

  const messagesCounter = useMemo(
    () => messages.filter((message) => message.unread).length,
    [messages]
  )

  const isCurrent = useCallback(
    (path: string): boolean => location.pathname === path,
    [location.pathname]
  )

  return (
    <div className="app-header">
      <div className="app-header__logo-area">
        <AppHeaderLogo />
      </div>

      <div className="app-header__app-menu-area">
        <AppHeaderDropdown icon={mdiInformation} label="Info">
          <div className="app-header__dropdown-content">
            {portalDataStatus === RequestStatus.pending ||
            portalData === null ? (
                <div>{t('appheader.status.loading')}</div>
              ) : portalDataStatus !== RequestStatus.ok ? (
                <div>{t('appheader.status.error')}</div>
              ) : (
                <TabularData
                  headingCol={true}
                  data={[
                    [t('appheader.meta.version'), portalData.version],
                    [t('appheader.meta.number'), portalData.timestamp]
                  ]}
                />
              )}
          </div>
        </AppHeaderDropdown>
      </div>
      <AppHeaderEnvironmentInfo/>
      <div className="app-header__user-menu-area">
        {currentUser !== null ? (
          <>
            {process.env.REACT_APP_ENABLE_MESSAGES !== 'false' ? (
              <AppHeaderButton
                icon={mdiEmailMultiple}
                counter={messagesCounter}
                isCurrent={isCurrent(routes.messages.absolute)}
                action={routes.messages.absolute}
              />
            ) : null}

            {process.env.REACT_APP_ENABLE_USER_AREA !== 'false' ? (
              <AppHeaderButton
                icon={mdiAccount}
                label={currentUser.username}
                isCurrent={isCurrent(routes.user.absolute)}
                action={routes.user.absolute}
              />
            ) : null}

            <AppHeaderButton icon={mdiPower} label={t('appheader.logout')} action={logout} />
          </>
        ) : null}
      </div>
    </div>
  )
}
