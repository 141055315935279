import { ApiProduct } from 'sfportal_services_api/apiSchemas'
import { LabelItem } from 'sfportal_components_badges/LabelItem'
import React, { FC, memo } from 'react'
import { Status } from 'sfportal_components_badges/Status'
import { useTranslation } from 'react-i18next'

/**
 * Die Properties für die Kompoenten innerhalb der Utils Klasse.
 */
interface Props {

  /**
   * Das Produkt, für welches die Metadaten / Status Label gerendert werden.
   */
  product: ApiProduct
}

interface AdditionalMetadataType {
  name: string
  label?: string
  type?: string
}

/**
 * Komponente für Metadaten der einzelnen Produkte.
 */
export const MetadataRender = memo(function MetadataRender ({ product }: Props) {
  const { t } = useTranslation()
  const { additionalMetadata } = product

  return <>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.edition')}>{product.edition?.toString()}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.isbn')}>{product.isbn}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.publisher')}>{product.publisher}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.author')}>{product.author}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.lectorTeam')}>{product.lectorTeam}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.clientIdentifier')}>{product.clientIdentifier}</LabelItem>
    {
      additionalMetadata !== undefined && additionalMetadata !== null &&
        Object.keys(additionalMetadata).map(key => {
          const config = additionalMetadata[key]?.hasOwnProperty('config') ? (JSON.parse(additionalMetadata[key].config)).metadata[0] as AdditionalMetadataType : null
          let label = t('listItem.utils.listItemProduct.metadata.' + key)
          if (config?.label !== null && config?.label !== undefined) {
            label = config.label
          }
          return (
            <LabelItem key={key} label={label}>{additionalMetadata[key]['']}</LabelItem>
          )
        })
    }
  </>
})

/**
 * Rendert den Status des Produkts.
 */
export const StatusRender = memo(function StatusRender ({ product }: Props) {
  return <div className="list-item-product__status-col">
    <Status value={product.statusId} />
  </div>
})

/**
 * Komponente für Teams der einzelnen Produkte.
 */
export const Teams: FC<Props> = ({ product }) => {
  const { t } = useTranslation()
  const { teams } = product
  return (
    <>
      {
        teams !== undefined && teams !== null &&
        Object.entries(teams).map(([key, value]) => {
          return <LabelItem key={key} label={t('listItem.utils.listItemProduct.teams.' + key)}>{value.map((user) => `${user.firstname} ${user.lastname}`).join(',')}</LabelItem>
        })
      }
    </>
  )
}
