import React, { memo } from 'react'
import { LoadingAnimation } from '../Generic/LoadingAnimation'
import { CenteredLayer } from './CenteredLayer'
import './LoadingLayer.scss'

interface Props {
  text: string
}

export const LoadingLayer = memo(function LoadingLayer ({ text }: Props) {
  return (
    <CenteredLayer width="small">
      <div className="loading-layer">
        <div className="loading-layer__text">{text}</div>
        <LoadingAnimation/>
      </div>
    </CenteredLayer>
  )
})
