import React, { memo, useCallback, useEffect } from 'react'
import { ChildrenProp } from '../../jsx'
import { Button } from '../Forms/Button'
import { Modal } from '../Generic/Modal'
import './FilterModal.scss'
import { useTranslation } from 'react-i18next'

interface Props extends ChildrenProp {
  title: string
  modalInstance?: string
  onFilter: () => void
  onCancel: () => void
}

export const FilterModal = memo(function FilterModal ({
  children, title, modalInstance, onFilter, onCancel
}: Props) {
  const handleFilterClick = useCallback(
    () => { onFilter() },
    [onFilter]
  )

  const { t } = useTranslation()

  useEffect(
    () => {
      function handleKeydown (event: KeyboardEvent): void {
        if (event.key === 'Escape') { onCancel() }
        if (event.key === 'Enter') { onFilter() }
      }

      globalThis.addEventListener('keydown', handleKeydown)
      return () => {
        globalThis.removeEventListener('keydown', handleKeydown)
      }
    },
    [onCancel, onFilter]
  )

  return (
    <Modal
      visible={true}
      title={title}
      type="filter"
      width="big"
      padding="small"
      verticalAlign="top"
    >
      <div className="filter-modal__content">{children}</div>

      <div className="filter-modal__buttons">
        <Button buttonStyle="primary" onClick={handleFilterClick}>
          {t('common.ok')}
        </Button>

        <Button buttonStyle="secondary" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </div>
    </Modal>
  )
})
