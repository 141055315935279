import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import { ZoomFadeDiv } from '../../components/Animations/ZoomFadeDiv'
import { LoadingLayer } from '../../components/Layout/LoadingLayer'

interface Props extends ChildrenProp {
  text: string
  visible: boolean
}

export const LoadingScreen = memo(function LoadingScreen ({
  text, visible
}: Props) {
  return (
    <ZoomFadeDiv className="login-view__view-item" visible={visible}>
      <LoadingLayer text={text} />
    </ZoomFadeDiv>
  )
})
