import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import './ImagePreview.scss'

interface Props extends ChildrenProp {
  src: string
  alt: string
}

export const ImagePreview = memo(function ImagePreview ({ src, alt }: Props) {
  return <div className="image-preview">
    <div className="image-preview__image-container">
      <img src={src} key={src} alt={alt} className="image-preview__image"/>
    </div>
  </div>
})
