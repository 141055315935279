import React, {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChildrenProp } from "../../jsx";
import { noop } from "../../utils/function";
import classNames from "classnames";
import "./ListItem.scss";

interface Props extends ChildrenProp {
  className?: string;
  compact?: boolean;
  highlight?: boolean;
  current?: boolean;
  hoverEffect?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const ListItem = memo(function ListItem({
  children,
  className,
  compact = false,
  highlight = false,
  current = false,
  hoverEffect = false,
  onClick = noop,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [intersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  const listItemClasses = useMemo(() => {
    const classesObject: Record<string, boolean> = {
      "list-item--is-highlighted": highlight,
      "list-item--is-current": current,
      "list-item--is-hoverable": hoverEffect,
      "list-item--is-compact": compact,
    };

    if (className !== undefined) {
      classesObject[className] = true;
    }

    return classNames("list-item", classesObject);
  }, [className, compact, current, highlight, hoverEffect]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      onClick(event);
    },
    [onClick],
  );

  return (
    <div className={listItemClasses} onClick={handleClick} ref={ref}>
      {intersecting && children}
    </div>
  );
});
