import { ApiTaskDataResourceSmashdocs } from './apiSchemas'
import { sendRequest } from './superagent'
import { Response } from 'superagent'

interface GetSmashdocsLinkParams {
  /** Die ID des Smashdocs-Assets */
  id: ApiTaskDataResourceSmashdocs['id']
}

export async function getSmashdocsLink (
  params: GetSmashdocsLinkParams
): Promise<Response> {
  const { id } = params
  const query = { id }
  return await sendRequest('get', 'smashdocs', { query })
}
