const baseUrl = process.env.REACT_APP_API_BASE_URL
if (baseUrl === undefined) {
  throw new Error('Environment variable "REACT_APP_API_BASE_URL" is not defined')
}

const urlPrefix = baseUrl.replace(/\/$/, '')

export const getApiUrl = (path: string): string => {
  const safePath = path.replace(/^\//, '')
  return `${urlPrefix}/${safePath}`
}
