import { navigate, useLocation } from '@reach/router'
import qs from 'query-string'
import React, { FormEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ZoomFadeDiv } from '../../components/Animations/ZoomFadeDiv'
import { Button } from '../../components/Forms/Button'
import { ExternalInputRef, Input } from '../../components/Forms/Input'
import { RequestErrorMessage } from '../../components/Generic/RequestErrorMessage'
import { CenteredLayer } from '../../components/Layout/CenteredLayer'
import { RequestStatus } from '../../services/api/generic/types'
import { LoginViewScreen, sendNewPassword, setCurrentLoginViewScreen, useUserStore } from '../../stores/userStore'
import { noop } from '../../utils/function'
import { currentURLWithoutQueryParams } from '../../utils/url'
import { LoadingScreen } from './LoadingScreen'
import { useTranslation } from 'react-i18next'

export enum LoginViewQueryParams {
  passwordResetToken = 'passwordResetToken'
}

export const SetNewPasswordScreen = memo(function SetNewPasswordScreen () {
  const [token, setToken] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  const { t } = useTranslation()

  const location = useLocation()
  const { status, statusMessage, currentLoginViewScreen } = useUserStore()

  const tokenInputRef = useRef<ExternalInputRef>(null)

  useEffect(
    () => {
      const queryParams = qs.parse(location.search)
      const passwordResetToken = queryParams.passwordResetToken
      if (typeof passwordResetToken !== 'string') { return }
      setToken(passwordResetToken)
      tokenInputRef.current?.changeInputValue(passwordResetToken)
      navigate(
        currentURLWithoutQueryParams(LoginViewQueryParams.passwordResetToken)
      ).catch(noop)
    },
    [location.search]
  )

  const isCurrentScreen = useMemo(
    () => currentLoginViewScreen === LoginViewScreen.setNewPassword,
    [currentLoginViewScreen]
  )

  const isLoading = useMemo(
    () => status === RequestStatus.pending,
    [status]
  )

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      await sendNewPassword(token, password)
    },
    [password, token]
  )

  const handleBackToSendEmail = useCallback(
    () => { setCurrentLoginViewScreen(LoginViewScreen.sendEMail) },
    []
  )

  const customMessage = useCallback(
    (status: RequestStatus): string | undefined => {
      if (status === RequestStatus.badRequest) {
        if (statusMessage === 'The provided password is unsafe.') {
          return t('login.setNewPasswordScreen.unsafe')
        }
      }
    },
    [statusMessage, t]
  )

  return <>
    <LoadingScreen
      text={t('login.setNewPasswordScreen.loading')}
      visible={isCurrentScreen && isLoading}
    />

    <ZoomFadeDiv
      className="login-view__view-item"
      visible={isCurrentScreen && !isLoading}
    >
      <CenteredLayer>
        <form className="login-view__form" onSubmit={handleSubmit}>
          <h1 className="login-view__title">{t('login.setNewPasswordScreen.title')}</h1>

          <div dangerouslySetInnerHTML={
            { __html: t('login.setNewPasswordScreen.content', { interpolation: { escapeValue: false } }) }
          } />

          <div className="login-view__inputs">
            <Input
              ref={tokenInputRef}
              label={t('login.setNewPasswordScreen.inputLabels.token')}
              id="token"
              name="token"
              autoComplete="no"
              value={token}
              onInput={setToken}
              autoFocus={true}
            />

            <Input
              label={t('login.setNewPasswordScreen.inputLabels.newPassword')}
              type="password"
              id="password"
              name="password"
              autoComplete="no"
              value={password}
              onInput={setPassword}
              autoFocus={true}
            />

            <Input
              label={t('login.setNewPasswordScreen.inputLabels.repeatPassword')}
              type="password"
              id="password-repeat"
              name="password-repeat"
              autoComplete="no"
              value={passwordRepeat}
              onInput={setPasswordRepeat}
              autoFocus={true}
            />
          </div>

          <RequestErrorMessage status={status} customMessage={customMessage} />

          <div className="login-view__buttons">
            <Button type="submit">{t('login.setNewPasswordScreen.submit')}</Button>

            <Button
              type="button"
              buttonStyle="secondary"
              onClick={handleBackToSendEmail}
            >
              {t('login.setNewPasswordScreen.back')}
            </Button>
          </div>
        </form>
      </CenteredLayer>
    </ZoomFadeDiv>
  </>
})
