import React, { memo, useCallback, useMemo, useState } from 'react'
import { getProductData } from '../../data/productData'
import { getStatusData, StatusData } from '../../data/statusData'
import { ProductFilter, setFilter, useProductStore } from '../../stores/productStore'
import { useValuesStore } from '../../stores/valuesStore'
import { isNonNull } from '../../utils/array'
import { FilterModal } from './FilterModal'
import { SelectFilterField } from './SelectFilterField'
import { TextFilterField } from './TextFilterField'
import { SelectInputFilterField } from './SelectInputFilterField'
import { useClientStore } from '../../stores/clientStore'
import { useTranslation } from 'react-i18next'

interface Props {
  onFilter: () => void
  onCancel: () => void
}

export const ProductsFilter = memo(function ProductsFilter ({
  onCancel, onFilter
}: Props) {
  const { data: values } = useValuesStore()
  const { filter: currentFilter } = useProductStore()
  const { clientsForSelectItems } = useClientStore()
  const [filterInputs, setFilterInputs] =
      useState<ProductFilter>(currentFilter)
  const { t } = useTranslation()

  const descriptionFilter = getProductData('description', t)
  const publicationDateFilter = getProductData('publicationDate', t)
  const statusFilter = getProductData('status', t)
  const editionFilter = getProductData('edition', t)
  const isbnFilter = getProductData('isbn', t)
  const clientsFilter = getProductData('sfclients', t)

  const productStatusData = useMemo(() => {
    if (values === null) { return [] }
    return values.productStatus
      .map(status => status.id)
      .map(id => getStatusData(id))
  }, [values])

  const handleFilterChange = useCallback(
    <P extends keyof ProductFilter> (
      property: P, value: ProductFilter[P]
    ) => {
      setFilterInputs({ ...filterInputs, [property]: value })
    },
    [filterInputs]
  )

  const handleFilter = useCallback(
    () => {
      setFilter(filterInputs)
      onFilter()
    },
    [filterInputs, onFilter]
  )

  const handleCancel = useCallback(
    () => {
      setFilterInputs(currentFilter)
      onCancel()
    },
    [currentFilter, onCancel]
  )

  if (values === null) { return null }

  return (
    <FilterModal
      title={t('filters.productsFilter.filterTitle')}
      modalInstance="products"
      onFilter={handleFilter}
      onCancel={handleCancel}
    >
      {descriptionFilter !== null ? (
        <TextFilterField
          label={t(descriptionFilter.label)}
          name={descriptionFilter.id}
          value={filterInputs.description ?? ''}
          placeholder={`${t(descriptionFilter.label)}...`}
          onChange={value => handleFilterChange('description', value)}
        />
      ) : null}
      {publicationDateFilter !== null ? (
        <TextFilterField
          label={publicationDateFilter.label}
          name={publicationDateFilter.id}
          value={filterInputs.publicationDate ?? ''}
          placeholder={t('filters.productsFilter.placeholders.publicationDate')}
          onChange={value => handleFilterChange('publicationDate', value)}
        />
      ) : null}

      {statusFilter !== null ? (
        <SelectFilterField
          label={statusFilter.label}
          items={productStatusData.filter(isNonNull<StatusData>())}
          value={filterInputs.status ?? ''}
          onChange={value => handleFilterChange('status', value)}
        />
      ) : null}
      {editionFilter !== null ? (
        <TextFilterField
          label={editionFilter.label}
          name={editionFilter.id}
          value={filterInputs.edition ?? ''}
          placeholder={t('filters.productsFilter.placeholders.edition')}
          onChange={value => handleFilterChange('edition', value)}
        />
      ) : null}
      {isbnFilter !== null ? (
        <TextFilterField
          label={isbnFilter.label}
          name={isbnFilter.id}
          value={filterInputs.isbn ?? ''}
          placeholder={t('filters.productsFilter.placeholders.isbn')}
          onChange={value => handleFilterChange('isbn', value)}
        />
      ) : null}
      {clientsFilter !== null ? (
        <SelectInputFilterField
          label={t(clientsFilter.label)}
          name={clientsFilter.id}
          items={clientsForSelectItems}
          value={filterInputs.sfclients ?? ''}
          onChange={value => handleFilterChange('sfclients', value)}
        />
      ) : null}
    </FilterModal>
  )
})
