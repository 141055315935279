import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import { isDefined } from '../../utils/global'
import './LabelItem.scss'

interface Props extends ChildrenProp {
  label?: string
}

export const LabelItem = memo(function LabelItem ({
  children, label
}: Props) {
  if (!isDefined(children) || children === '') return null

  return (
    <div className="label-item">
      {label ? <div className="label-item__label">{label}</div> : null}
      <div className="label-item__content">{children}</div>
    </div>
  )
})
