import {createReactHook, createStore} from '@alinnert/tstate'
import {ProgressEvent} from 'superagent'
import {ApiProduct} from '../services/api/apiSchemas'
import {apiStartUploadAfterTransferWF, apiTransferFile, UploadMetadataWfFile} from '../services/api/filesApiService'
import {RequestStatus} from '../services/api/generic/types'
import {noop} from '../utils/function'
import {handleRequestError} from './allStores'
import {refreshProductFiles, updateTaskStates} from './productDetailStore'
import {loadWorkflows} from './workflowStore'
import {getUserId} from './userStore'
import {addCancelUploadWorkflowCallback, removeCancelUploadWorkflowCallback} from '../utils/cancelUploadHelper'

/**
 * @author Andreas Linnert
 * @file Dieser Store enthält Infos zu allen laufenden Dateitransfers.
 * Aktuell primär Dateiuploads nach SiteFusion.
 */

// #region store
export interface Transfer {
    /** Eine Referenz auf das zu übertragende `File`-Objekt. */
    file: File
    /** Der aktuelle Fortschritt der Übertragung. Wert zwischen `0` und `100`. */
    progress: number
    product: ApiProduct['id']
    status: RequestStatus
}

export interface Upload {
    file: File,
    uploadid: string
}

export interface TransferStore {
    /** Eine Liste aller Datei-Übertragungen. */
    transfers: Record<string, Transfer>
}

function getInitialState(): TransferStore {
    return {
        transfers: {}
    }
}

const transferStore = createStore(getInitialState())
export const useTransferStore = createReactHook(transferStore)

const mutations = {
    reset(): void {
        transferStore.set(getInitialState())
    },
    removeTransfer(uploadid: string): void {
        const tempTransfer = transferStore.state.transfers
        delete tempTransfer[uploadid]
        transferStore.set({transfers: tempTransfer})
    },

    addFiles(files: Upload[], product: ApiProduct['id']): void {
        const tempTransfer: Record<string, Transfer> = {}
        files.forEach(single => {
            tempTransfer[single.uploadid] = {
                file: single.file,
                progress: 0,
                status: RequestStatus.pending,
                product
            }
        })

        transferStore.set({
            transfers: {
                ...transferStore.state.transfers,
                ...tempTransfer
            }
        })
    },

    setStatus(file: File, status: RequestStatus, uploadid: string): void {
        const transferToUpdate = transferStore.state.transfers[uploadid]
        if (status === RequestStatus.ok) {
            // Wenn eine Datei hochgeladen wurde, soll sich die Dateiliste
            // in der Produkt-Detail-Seite aktualisieren.
            refreshProductFiles(transferToUpdate.product)
        }

        transferStore.set({
            transfers: {
                ...transferStore.state.transfers,
                [uploadid]: transferToUpdate
            }
        })
    },

    updateProgress(file: File, progress: Transfer['progress'], uploadid: string): void {
        const transfers = transferStore.state.transfers
        transfers[uploadid] = {
            ...transfers[uploadid],
            progress
        }
        if (progress === 100) {
            removeCancelUploadWorkflowCallback(uploadid)
        }

        transferStore.set({transfers: transfers})
    }
}
// #endregion store

// #region actions
export function resetTransferStore(): void {
    mutations.reset()
}

export async function uploadFiles(
    uploadingFiles: Array<{ file: File, uploadid: string }>,
    productId: ApiProduct['id']
): Promise<void> {
    mutations.addFiles(uploadingFiles.map(uploadingFile => {
        return {
            file: uploadingFile.file,
            uploadid: uploadingFile.uploadid
        }
    }), productId)

    for (const {
        file,
        uploadid
    } of uploadingFiles) {
        const progressCallback = getProgressCallback(file, uploadid)
        apiTransferFile(file, uploadid, progressCallback).then(_ => {
            mutations.setStatus(file, RequestStatus.ok, uploadid)
        }).catch(error => {
            mutations.setStatus(file, handleRequestError(error), uploadid)
        })
    }
    const userId = getUserId()
    if (userId) {
        loadWorkflows(userId, 'simpleUpload')
            .catch(noop)
            .finally(() => {
                uploadingFiles.forEach(single => addCancelUploadWorkflowCallback(single.uploadid))
            })
    }
    type ProgressCallback = (event: ProgressEvent) => void

    function getProgressCallback(file: File, uploadid: string): ProgressCallback {
        return event => {
            if (event.percent === undefined) {
                return
            }
            mutations.updateProgress(file, event.percent, uploadid)
        }
    }
}

export async function startMetadataWf(uploadingMetadataFile: UploadMetadataWfFile[], replace = false): Promise<void> {
    let variables = '{}'
    if (!replace && process.env.REACT_APP_UPLOAD_WF_VARIABLES !== null) {
        const uploadVariables = process.env.REACT_APP_UPLOAD_WF_VARIABLES
        variables = uploadVariables ?? variables
    }
    await apiStartUploadAfterTransferWF(uploadingMetadataFile, variables)
}

interface UploadAndReplaceFileOptions {
    productId: ApiProduct['id']
}

export async function uploadAndReplaceFile(
    file: File,
    uploadid: string,
    {productId}: UploadAndReplaceFileOptions,
    taskId?: string
): Promise<void> {
    mutations.addFiles([{file, uploadid}], productId)

    apiTransferFile(file, uploadid, (e) => progressCallback(e, uploadid)).then(_ => {
        mutations.setStatus(file, RequestStatus.ok, uploadid)
        if (taskId !== undefined) {
            updateTaskStates(taskId).catch(noop)
        }
    }).catch(error => {
        mutations.setStatus(file, handleRequestError(error), uploadid)
    })

    function progressCallback(event: ProgressEvent, uploadid: string): void {
        if (event.percent === undefined) {
            return
        }
        mutations.updateProgress(file, event.percent, uploadid)
    }
}

export const getTransferLength = () => {
    return Object.values(transferStore.state.transfers).length
}
// #endregion actions
