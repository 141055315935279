import React, { memo, ReactNode, useMemo } from 'react'
import { ChildrenProp } from '../../jsx'
import classNames from 'classnames'
import './CenteredLayer.scss'

export interface CenteredLayerProps extends ChildrenProp {
  width?: 'small' | 'normal' | 'big' | 'full'
  padding?: 'normal' | 'small' | 'none'
  verticalAlign?: 'top' | 'center'
  header?: ReactNode
  footer?: ReactNode
  modalStyle?: boolean
}

export const CenteredLayer = memo(function CenteredLayer ({
  children,
  header = null,
  footer = null,
  width = 'normal',
  padding = 'normal',
  verticalAlign = 'center',
  modalStyle = true
}: CenteredLayerProps) {
  const centeredLayerClasses = useMemo(
    () =>
      classNames(
        'centered-layer',
        `centered-layer--padding-${padding}`,
        `centered-layer--size-${width}`,
        { 'centered-layer--vertical-top': verticalAlign === 'top' }
      ),
    [padding, verticalAlign, width]
  )

  const contentBoxClasses = classNames([
    `centered-layer__content-${modalStyle ? 'box' : 'flat'}`
  ])

  return (
    <div className={centeredLayerClasses}>
      <div className={contentBoxClasses}>
        {header !== null ? (
          <div className="centered-layer__content-header">{header}</div>
        ) : null}
        <div className="centered-layer__content-body">{children}</div>
        {footer !== null ? (
          <div className="centered-layer__content-footer">{footer}</div>
        ) : null}
      </div>
    </div>
  )
})
