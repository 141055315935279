import React, { ReactElement } from 'react'
import { ChildrenProp } from '../../jsx'
import { Button } from '../Forms/Button'
import './LoadingContainer.scss'

/**
 * Interface für die Props des Error Containers.
 */
interface Props extends ChildrenProp {
  // Fehler Text.
  errorText: string
  // Text für den retry Button.
  retryText: string
  // Function, die beim Klick des retry Buttons ausgeführt wird.
  onRetry: () => void
}

/**
 * Container für Fehlermeldungen mit Retry Button.
 *
 * @param children Children, die unterhalb des retry Buttons noch angezeigt werden.
 * @param errorText Die Fehlermeldung.
 * @param retryText Der Text auf dem Retry Button.
 * @param onRetry Function, die beim Klick des retry Buttons ausgeführt wird.
 * @constructor Standard functional Constructor.
 */
export const ErrorContainer = ({
  children,
  errorText,
  retryText,
  onRetry
}: Props): ReactElement => {
  return (
    <div className={'error-container'}>
      <div className="error-container__indicator">
        <div className="error-container__window">
          <div className="error-container__text">
            {errorText}
          </div>
          <Button onClick={onRetry}>{retryText}</Button>
        </div>
      </div>
      <div className="error-container__content">{children}</div>
    </div>
  )
}
