import { useLayoutEffect } from 'react'
import { isDefined } from '../utils/global'

interface UseStoredScrollPositionParams {
  /** Das Element, dessen Scrollposition überwacht und verändert werden soll. */
  elementRef: Element | undefined | null
  /** Die Variable, die die aktuelle Scroll-Position enthält. */
  scrollPosition: number
  /** Eine Funktion, über die die aktuelle Scrollposition aktualisiert wird. */
  setScrollPosition: (value: number) => void
}

/**
 * Dieser Hook synchronisiert die Scrollposition eines Elements
 * mit einer State-Variable oder einem Store.
 */
export function useStoredScrollPosition ({
  elementRef,
  scrollPosition,
  setScrollPosition
}: UseStoredScrollPositionParams): void {
  useLayoutEffect(
    () => {
      if (!isDefined(elementRef)) { return }

      elementRef.scrollTop = scrollPosition
      return () => { setScrollPosition(elementRef.scrollTop) }
    },
    [elementRef, scrollPosition, setScrollPosition]
  )
}
