import React, { ReactElement, ReactNode } from 'react'
import { LabelItem } from 'sfportal_components_badges/LabelItem'
import { ApiExternalMetadata } from 'sfportal_services_api/apiSchemas'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { isDefined } from 'sfportal_utils/global'
import { MetadataDescriptor } from './MetadataDescriptor'

interface Props<T extends ApiExternalMetadata> {
  data: Array<MetadataDescriptor<T>> | null
}

export const MetadataItems = <T extends ApiExternalMetadata>({
  data
}: Props<T>): ReactElement | null => {
  const { currentFileMetadata } = useProductDetailStore()

  if (data === null) return null

  return (
    <>
      {data
        .map((item, index): ReactNode => {
          if (currentFileMetadata === null) {
            return null
          }
          const shouldShow = item.show?.(currentFileMetadata as T) ?? true
          if (!shouldShow) {
            return null
          }

          const value = item.getValue(currentFileMetadata as T)
          if (value === null) {
            return null
          }

          return (
            <LabelItem key={index} label={item.label}>
              {value}
            </LabelItem>
          )
        })
        .filter(isDefined)}
    </>
  )
}
