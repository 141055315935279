import { isDefined } from '../../utils/global'
import { FlatTreeNode } from './Tree'
import { TreeState } from '../../stores/TreeState'

/**
 * Wandelt die Daten aus einem Store (der `TreeState<T>` extended) in eine
 * flache Liste um, die für die Verwendung in virtuellen Listen geeignet ist.
 * @param nodes Eine aktuelle Ebene von Knoten
 * @param state Der State, wovon die Daten extrahiert werden sollen.
 */
export function createTreeList<T extends FlatTreeNode<unknown>> (
  state: TreeState<T>,
  nodes?: T[]
): T[] {
  nodes =
    nodes ??
    state.relations.root?.map((id) => state.nodes[id]).filter(isDefined) ??
    []

  return nodes.flatMap((node) => {
    const childNodeIds = state.relations[node.id]
    if (childNodeIds === undefined) return [node]

    const childNodes = childNodeIds
      .map((id) => state.nodes[id])
      .filter(isDefined)
    const childrenAreInCache = Array.isArray(childNodeIds)
    const parentIsOpen = state.openNodeIds.includes(node.id)

    if (childrenAreInCache && parentIsOpen) {
      return [node, ...createTreeList(state, childNodes)]
    }

    return [node]
  })
}
