import { RefObject, useEffect } from 'react'
import { noop } from '../utils/function'
import { sleep } from '../utils/global'

interface UseAutoFocusParams {
  ref: RefObject<HTMLElement>
  enabled?: boolean
}

export function useAutoFocus ({
  enabled = false, ref
}: UseAutoFocusParams): void {
  useEffect(
    () => {
      if (!enabled) { return }

      (async () => {
        const inputElement = ref.current
        if (inputElement === null) { return }
        await sleep(100)
        inputElement.focus()
      })().catch(noop)
    },
    [enabled, ref]
  )
}
