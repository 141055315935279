import { RouteComponentProps, useLocation } from '@reach/router'
import qs from 'query-string'
import React, { createContext, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { LoginViewScreen, setCurrentLoginViewScreen, useUserStore } from '../../stores/userStore'
import { ConfirmScreen } from './ConfirmScreen'
import { LoginScreen } from './LoginScreen'
import './LoginView.scss'
import { SendEmailScreen } from './SendEmailScreen'
import { SetNewPasswordScreen } from './SetNewPasswordScreen'
import { MultifactorMethodScreen } from './MultifactorMethodScreen'
import { noop } from '../../utils/function'
import { MultifactorSetupScreen } from './MultifactorSetupScreen'
import { MultifactorScreen } from './MultifactorScreen'

export interface LoginInformationProvider {
  username: string
  password: string
  setUsername: Dispatch<SetStateAction<string>>
  setPassword: Dispatch<SetStateAction<string>>
}

export const LoginInformationContext = createContext<LoginInformationProvider>({
  username: '',
  password: '',
  setUsername: noop,
  setPassword: noop
})

/**
 * Interface für die Login View Properties.
 */
interface Props extends RouteComponentProps {
  /**
   * Gibt an ob der Login Screen gezeigt werden soll.
   * Falls true kommt der normale Login Screen,
   * Falls false wird direkt die Passwort vergessen Seite angezeigt.
   */
  showLogin?: boolean
  /**
   * Gibt an ob der Websocket noch beim connect ist.
   */
  connectingSocket: boolean
}

export const LoginView: FC<Props> = ({
  showLogin = true,
  connectingSocket
}) => {
  useResetTokenRedirect()

  const { currentLoginViewScreen } = useUserStore()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  useEffect(() => {
    if (currentLoginViewScreen === LoginViewScreen.login) {
      setCurrentLoginViewScreen(
        showLogin ? LoginViewScreen.login : LoginViewScreen.sendEMail
      )
    }
  }, [currentLoginViewScreen, showLogin])

  const loginInformationProviderValue = useMemo<LoginInformationProvider>(() => ({
    username,
    password,
    setPassword,
    setUsername
  }), [password, username])

  return (
    <div className="login-view">
      <LoginInformationContext.Provider value={loginInformationProviderValue}>
        {showLogin ? <LoginScreen connectingSocket={connectingSocket} /> : null}
        <SendEmailScreen showBackToLogin={showLogin} />
        <SetNewPasswordScreen />
        <ConfirmScreen />
        <MultifactorMethodScreen />
        <MultifactorSetupScreen />
        <MultifactorScreen />
      </LoginInformationContext.Provider>
    </div>
  )
}

function useResetTokenRedirect (): void {
  const location = useLocation()

  useEffect(() => {
    const queryParams = qs.parse(location.search)
    const passwordResetToken = queryParams.passwordResetToken
    if (typeof passwordResetToken !== 'string') {
      return
    }
    setCurrentLoginViewScreen(LoginViewScreen.setNewPassword)
  }, [location.search])
}
