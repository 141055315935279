import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import { animated, useSpring } from 'react-spring'
import { zoomFadeSpring } from '../../values/springPresets'

interface Props extends ChildrenProp {
  visible: boolean
  className?: string
}

export const ZoomFadeDiv = memo(function ZoomFadeDiv ({
  children, visible, className
}: Props) {
  const styles = useSpring({
    ...zoomFadeSpring({ visible })
  })

  return (
    <animated.div
      children={children}
      className={className}
      style={{
        ...styles,
        visibility: styles.opacity?.interpolate(
        opacity => opacity === 0 ? 'hidden' : 'visible'
      )
      }}
    />
  )
})
