import { useNavigate } from '@reach/router'
import React, { FC, MouseEvent, ReactElement, useMemo } from 'react'
import { Button } from 'sfportal_components_forms/Button'
import { setAppExited } from 'sfportal_stores/uiStateStore'
import { noop } from 'sfportal_utils/function'

export interface SSOLinkData {
  /**
   * Der Link-Typ.
   */
  type?: string
  /**
   * Die URL, die beim Klick auf den SSO-Link aufgerufen werden soll.
   */
  url: string
  /**
   * Ist `iconPath` ein `ReactElement`, wird es direkt ausgegeben.
   * Ist `iconPath` ein `string`, wird es als `src` in einem `<img>` verwendet.
   */
  iconPath?: string | ReactElement
  /**
   * Das Button-Label des SSO-Links.
   */
  label: string
  /**
   * Die Farbe des Buttons.
   */
  color: string
}

interface Props extends SSOLinkData {}

export const SSOLink: FC<Props> = ({ url, color, label, iconPath }) => {
  const navigate = useNavigate()

  const icon = useMemo(() => {
    return typeof iconPath === 'string' ? (
      <img src={iconPath} alt="" />
    ) : (
      iconPath
    )
  }, [iconPath])

  function handleClick (event: MouseEvent): void {
    event.preventDefault()
    setAppExited()
    navigate(url).catch(noop)
  }

  return (
    <Button
      onClick={handleClick}
      iconType="si"
      icon={icon}
      buttonColor={color}
    >
      {label}
    </Button>
  )
}
