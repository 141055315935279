import { IAppUtils } from 'sfportal_core_utils_interfaces/IAppUtils'
import { ReactElement } from 'react'

/**
 * Liefert zusätzliche Homes.
 */
const getAdditionalHomes = (): ReactElement[] => {
  return []
}

/**
 * Das Utils Object.
 */
export const AppUtils: IAppUtils = {
  getAdditionalHomes
}
