import {AjaxError, NetworkError} from '../services/api/generic/error'
import {RequestStatus} from '../services/api/generic/types'
import {resetMessageStore} from './messageStore'
import {resetProductDetailStore} from './productDetailStore'
import {resetProductStore} from './productStore'
import {resetTransferStore} from './transferStore'
import {resetUiStateStore} from './uiStateStore'
import {logout, resetUserStore, setupUserStore} from './userStore'
import {resetClientStore} from './clientStore'
import {resetProductTreeStore} from './productTreeStore'
import {resetProjectStores} from './allProjectStores'
import {noop} from '../utils/function'

/**
 * @author Andreas Linnert
 * @file Diese Datei steuert alle store-übergreifenden Aktionen.
 */

export function setupAllStores (): void {
  setupUserStore()
}

/**
 * Setzt alle Stores auf ihre Default-Werte zurück. Dies löscht alle Daten.
 * Dies wird z. B. beim Ein- und Ausloggen verwendet.
 */
export async function resetAllStores (): Promise<void> {
  resetMessageStore()
  resetProductStore()
  await resetProductDetailStore().catch(noop)
  resetProductTreeStore()
  resetTransferStore()
  resetUserStore()
  resetUiStateStore()
  resetClientStore()
  // valuesStore nicht resetten!

  // Sicherstellen, dass die Projektimplementierungen dieser Funktion
  // den richtigen Typen aufweisen.
  const _resetProjectStores: () => void = resetProjectStores
  _resetProjectStores()
}

/**
 * Behandelt Fehlerobjekte, die von Ajax-Requests geworfen werden und liefert
 * einen entsprechenden `RequestStatus` zurück.
 * Die Funktion ist für den Einsatz in Data-Stores in Zusammenhang mit dem
 * Laden von Daten konzipiert.
 * @param error
 * Das `error`-Objekt, das von einem Ajax-Request im Fehlerfall geworfen wird.
 * @example
 * try {
 *   const result = await makeRequest()
 * } catch (error) {
 *   mutations.setStatus(handleRequestError(error))
 * }
 */
export function handleRequestError (error: unknown): RequestStatus {
  if (error instanceof AjaxError) {
    if (error.status >= 500) {
      return RequestStatus.serverError
    }

    switch (error.status) {
      case 400:
        return RequestStatus.badRequest
      case 401: {
        logout()
        return RequestStatus.unauthorizedError
      }
      case 403:
        return RequestStatus.forbiddenError
      case 404:
        return RequestStatus.notFoundError
      case 440:
        return RequestStatus.userIsLocked
      default:
        return RequestStatus.unknownRequestError
    }
  }

  if (error instanceof NetworkError) {
    return RequestStatus.networkError
  }

  return RequestStatus.unknownError
}
