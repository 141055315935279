import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { ChildrenProp } from '../../jsx'
import { Button } from '../../components/Forms/Button'
import { FormInput } from '../../components/Forms/FormInput'
import { getDataresourceItems } from '../../services/api/dataresourceApiService'
import { EMK } from '../../services/api/apiSchemas'
import { getEntities, Value, ValueEntity } from '../../services/api/entityApiService'
import { useTranslation } from 'react-i18next'
import '../Form/AssetForm.scss'
import { splitEMK } from '../../utils/system'
import { noop } from '../../utils/function'
import { Response } from 'superagent'

interface Props extends ChildrenProp {
  className?: string
  formTypes?: string[]
  readonly?: boolean
  overrideValues?: Value[]
  entityMetakeys?: EMK[]
  onSave?: (values: Value[]) => void
  onCancel?: () => void
  onValueChanges?: (value: Value, index: number) => void
}

interface DataresourceItem {
  id: number
  identifier: string
  dataresourceIdentifier: string
  label: string

  [key: string]: any
}

interface EntityMetakey {
  dataresourceId: number
  entityId: number
}

export const AssetForm: FC<Props> = ({
  className = '',
  entityMetakeys = [],
  onSave,
  onCancel,
  onValueChanges,
  readonly = false,
  formTypes,
  overrideValues = []
}) => {
  const [dataresourceItems, setDataresourceItems] = useState<DataresourceItem[]>([])
  const [currentDataresourceItems, setCurrentDataresourceItems] = useState<DataresourceItem[]>()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [requiredInput, setRequiredInput] = useState<string>('')
  const [values, setValues] = useState<Value[]>(overrideValues)
  const { t } = useTranslation()

  const handleChange = (name: string, value: string): void => {
    if (currentDataresourceItems === undefined || values.length === 0) {
      return
    }
    const _values = [...values]
    if (!_values[currentIndex].entity) {
      _values[currentIndex].entity = {} as ValueEntity
    }
    _values[currentIndex].entity[name] = value
    _values[currentIndex].dataresource = parseInt(currentDataresourceItems[0].fksfdataresources, 10)

    setValues(_values)

    if (typeof onValueChanges === 'function') {
      onValueChanges(values[currentIndex], currentIndex)
    }
  }

  useEffect(() => {
    if (typeof onValueChanges === 'function') {
      onValueChanges(values[currentIndex], currentIndex)
    }
  }, [values, currentIndex, onValueChanges])

  const handleSave = (): void => {
    const _formTypes = formTypes || ['type']

    let formIndex = 0
    let _requiredInput: (string | null) = null
    _formTypes.forEach((formType) => {
      for (let i = 0; i < values.length; i++) {
        if (_requiredInput) {
          formIndex = i
          break
        }
        for (let j = 0; j < dataresourceItems.length; j++) {
          if (_requiredInput) {
            formIndex = i
            break
          }
          const drItem = dataresourceItems[j]
          const config = drItem.configjson[formType]
          if (typeof config === 'object') {
            const value = values[i].entity[drItem.identifier]
            _requiredInput = config.required === 'true' && (value === null || value === undefined || value.toString().length === 0) ? drItem.identifier : null
          }
        }
      }
    })

    if (!_requiredInput) {
      if (typeof onSave === 'function') {
        onSave(values)
      }
    } else {
      setCurrentIndex(formIndex)
      setRequiredInput(_requiredInput)
    }
  }

  const getEntityMetakey = (entityMetakey: EMK): EntityMetakey | null => {
    if (entityMetakey === null || entityMetakey === undefined) {
      return null
    }
    let separator = '|'
    let _entityMetakey = entityMetakey.toString()
    if (entityMetakey.includes('sf')) {
      separator = '-'
      _entityMetakey = entityMetakey.replace('sf', '')
    }

    const emk = splitEMK(_entityMetakey, { separator })

    if (!emk) {
      return null
    }

    return {
      dataresourceId: parseInt(emk.dataResourceId, 10),
      entityId: parseInt(emk.contentId, 10)
    }
  }

  const getApiAliasFromIdentifier = (identifier: string): (string | null) => {
    if (!identifier || identifier.length === 0) {
      return null
    }
    return identifier.replace(/_([a-z])/g, g => g[1].toUpperCase())
  }

  const loadDataresourceItems = useCallback(async (dataresourceIds: number[]): Promise<void> => {
    const response = await getDataresourceItems(dataresourceIds).catch(() => {
    })
    if (response) {
      const body = response.body
      const _dataresourceItems: DataresourceItem[] = []
      for (let i = 0; i < body.length; i++) {
        const dataresourceItem = body[i]
        dataresourceItem.name = dataresourceItem.apialias || getApiAliasFromIdentifier(dataresourceItem.identifier)
        _dataresourceItems.push(dataresourceItem)
      }
      setDataresourceItems(_dataresourceItems)
      setCurrentDataresourceItems(_dataresourceItems.filter(drItem => drItem.fksfdataresources === dataresourceIds[0].toString()))
      const entitiesResponse = await getEntities(entityMetakeys).catch(noop)
      const defaultValues = (entitiesResponse as Response).body
      if (Object.keys(defaultValues[currentIndex]).length > 0) {
        setValues((entitiesResponse as Response).body)
      }
    }
  }, [entityMetakeys, currentIndex])

  useEffect(() => {
    if (entityMetakeys?.length > 0) {
      const dataresourceIds: number[] = []
      entityMetakeys.forEach(entityMetakey => {
        const emk = getEntityMetakey(entityMetakey)
        if (!emk) {
          return
        }
        if (dataresourceIds.findIndex(drId => drId === emk.dataresourceId) === -1) {
          dataresourceIds.push(emk.dataresourceId)
        }
      })
      loadDataresourceItems(dataresourceIds).catch(noop)
    }
  }, [entityMetakeys, loadDataresourceItems])

  const getValue = (field: string): string => {
    if (values.length > 0) {
      const entity = values[currentIndex].entity
      if (entity) {
        return entity[field]?.toString()
      }
    }
    return ''
  }

  const getButtonLayout = (): ReactElement | null => {
    if (readonly) {
      return null
    }
    return (
      <div className="buttons">
        <Button buttonStyle="secondary" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        {values.length > 1
          ? <>
            <Button disabled={currentIndex === 0} buttonStyle="secondary" onClick={() => {
              const newIndex = currentIndex - 1
              setCurrentIndex(newIndex)
              const entityMetakey = getEntityMetakey(entityMetakeys[newIndex])
              if (!entityMetakey) {
                return
              }
              setCurrentDataresourceItems(dataresourceItems.filter((drItem: DataresourceItem) => drItem.fksfdataresources === entityMetakey.dataresourceId.toString()))
            }}>
              {t('global.button.back')}
            </Button>
            <Button disabled={entityMetakeys.length === (currentIndex + 1)} buttonStyle="secondary" onClick={() => {
              const newIndex = currentIndex + 1
              setCurrentIndex(newIndex)
              const entityMetakey = getEntityMetakey(entityMetakeys[newIndex])
              if (!entityMetakey) {
                return
              }
              setCurrentDataresourceItems(dataresourceItems.filter((drItem: DataresourceItem) => drItem.fksfdataresources === entityMetakey.dataresourceId.toString()))
            }}>
              {t('global.button.next')}
            </Button>
          </>
          : null
        }

        <Button buttonStyle="primary" disabled={entityMetakeys.length > (currentIndex + 1)} onClick={handleSave}>
          {t('global.save')}
        </Button>
      </div>
    )
  }

  return (
    currentDataresourceItems
      ? <div className={`asset-form ${className}`}>
        {currentDataresourceItems.map((drItem: DataresourceItem) => {
          const _formTypes = formTypes || ['type']
          if (drItem.configjson) {
            for (let i = 0; i < _formTypes.length; i++) {
              const _formType = _formTypes[i]
              const formConfig = drItem.configjson[_formType]
              if (formConfig) {
                const typeConfig = typeof formConfig === 'string' ? { name: formConfig } : formConfig

                if (typeConfig.name === 'ignore') {
                  return null
                }

                const required = requiredInput === drItem.identifier
                let value = getValue(drItem.name)

                if (['creauser', 'modiuser'].includes(drItem.identifier)) {
                  typeConfig.role = 'user'
                  typeConfig.name = 'static'
                } else if (['modidate', 'creadate', 'fileModidate'].includes(drItem.identifier)) {
                  typeConfig.role = 'date'
                  typeConfig.name = 'static'
                }

                if ((typeConfig.role || typeConfig.name) === 'datetime' || (typeConfig.role || typeConfig.name) === 'date') {
                  value = new Date(value).toLocaleString()
                }

                return (
                  <FormInput
                    type={typeConfig.name}
                    readonly={readonly}
                    role={typeConfig.role}
                    key={drItem.identifier + '-' + currentIndex}
                    datamap={typeConfig.datamap}
                    info={required ? {
                      description: t('global.field.required'),
                      type: 'error'
                    } : null}
                    required={required}
                    defaultValue={value}
                    label={drItem.label || drItem.identifier}
                    name={drItem.name}
                    onChange={handleChange}
                  />
                )
              }
            }
          }
          return null
        })}
        {getButtonLayout()}
      </div>
      : null
  )
}
