import React, { FC } from 'react'
import { MdiIcon } from '../../../../components/Generic/MdiIcon'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js/commonjs/mdi'

type Props = {
  totalCount: number
  switchResultCallback: (index: number) => void
  currentFindingIndex: number
}

/**
 * Component for circling through the results
 * @param totalCount the total amount of results
 * @param switchResultCallback change index callback
 * @param currentFindingIndex current index
 * @returns {FC<Props> | null} the Search Switch
 */
export const SearchSwitch: FC<Props> = (
  {
    totalCount,
    switchResultCallback,
    currentFindingIndex
  }
) => {

  /**
   * function for switching through the search findings index
   *
   * @param {'forward' | 'backward'} type if the index should go up or down
   */
  const switchEntry = (type: 'forward' | 'backward'): void => {
    if (type === 'forward') {
      switchResultCallback(currentFindingIndex + 1 < totalCount ? currentFindingIndex + 1 : 0)
      return
    }

    switchResultCallback(currentFindingIndex > 0 ? currentFindingIndex - 1 : totalCount - 1)
  }

  if (totalCount === undefined || totalCount === 0) {
    return null
  }

  return <div>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end'
    }}>
      <div className={'search-icon'} onClick={() => switchEntry('backward')}>
        <MdiIcon path={mdiChevronLeft} scale={1} />
      </div>

      <span>
        <strong>
          {`${currentFindingIndex + 1} / ${totalCount}`}
        </strong>
      </span>

      <div className={'search-icon'} onClick={() => switchEntry('forward')}>
        <MdiIcon path={mdiChevronRight} scale={1} />
      </div>
    </div>
  </div>
}
