import {ApiProduct, EMK} from './apiSchemas'
import {Response} from 'superagent'
import {sendRequest} from './superagent'

/**
 * Startet einen Workflow.
 *
 * @param workflowKey Der Key des Workflows.
 * @param entityMetaKey Der EntityMetakey
 * @param variables Die WF-Variablen.
 * @param productId Die Id des Produkts.
 */
export async function startWorkflow(
    productId: ApiProduct['id'],
    workflowKey: string,
    entityMetaKey: EMK | null,
    variables: Record<string, unknown>
): Promise<Response> {
    if (!variables?.hasOwnProperty('publicationChannelId')) {
        variables.publicationChannelId = productId.toString()
    }
    return await sendRequest('post', '/workflow/start', {
        query: {
            workflowID: workflowKey,
            entitymetakeys: entityMetaKey !== null ? [entityMetaKey] : []
        },
        body: variables
    })
}

/**
 * Prüft ob der Workflow mit dem übergebenen Key und entityMetakey als Businesskey
 * aktuell läuft.
 *
 * @param processDefinitionKey Der ProcessDefinitionKey des Workflows.
 * @param entityMetakey Der EntityMetakey der geprüft wird.
 */
export async function apiGetHasWorkflowStarted(
    processDefinitionKey: string,
    entityMetakey: EMK
): Promise<Response> {
    return await sendRequest('get', '/workflow/has-started', {
        query: {
            processDefinitionKey: processDefinitionKey,
            entityMetaKey: entityMetakey
        }
    })
}

/**
 * Prüft ob der User ein Candidatemapping auf den übergebenen Task hat.
 *
 * @param taskId Die Id des Tasks.
 * @param userId Die Id des Users.
 */
export async function apiGetIsTaskCandidateMapping(
    taskId: string,
    userId: number
): Promise<Response> {
    return await sendRequest('get', '/workflow/isCandidateMapping', {
        query: {
            taskId: taskId,
            userId: userId.toString()
        }
    })
}

/**
 * Hebt den Task auf.
 *
 * @param taskId Die Id des Tasks.
 * @param workerId Die Id des Workers.
 * @param variables Die variablen des Workflows.
 */
export async function releaseTask(
    taskId: string,
    workerId: string,
    variables?: any
): Promise<Response> {
    return await sendRequest('put', '/workflow/releaseTask', {
        query: {
            taskId: taskId,
            workerId: workerId
        },
        body: variables
    })
}

/**
 * Liefert alle Prozess Instanzen des jeweiligen User und ProcessDefinitionKey
 *
 * @param userId Die Id des Users.
 * @param definitionKey Der Process Definition Key, nach welchem gesucht werden soll.
 */
export async function getAllWorkflowTasks(userId: number, definitionKey: string): Promise<Response> {
    return await sendRequest('get', '/workflow/list', {
        query: {
            userId: userId.toString(),
            processDefinitionKey: definitionKey,
            withVariables: 'true'
        }
    })
}

/**
 * Löscht alle Prozess Instanzen mit den übergebenen Process Instanz Ids
 *
 * @param processinstanceIds Die Liste der Process Instanz Ids.
 * @param deleteHistoric optional, ob Historic Data gelöscht werden soll.
 */
export async function deleteWorkflowTasks(processinstanceIds: string[], deleteHistoric = true): Promise<Response> {
    return sendRequest('post', '/workflow/delete', {
        body: processinstanceIds,
        query: {
            deleteHistoricdata: JSON.stringify(deleteHistoric)
        }
    })
}

/**
 * Lädt eine Workflow Instanz via Processinstanz Id
 * @param processinstanceId die Prozess Instanz Id
 */
export async function loadWorkflowByProcessinstanceId(processinstanceId: string): Promise<Response> {
    return await sendRequest('get', '/workflow/list', {
        query: {
            processInstanceId: processinstanceId,
            withVariables: 'true'
        }
    })
}
