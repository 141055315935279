import { removeFromArray } from './array'
import { useEffect } from 'react'

// Eine simple PubSub-Implementierung. Kann genutzt werden, um simple
// Events von A nach B zu senden.
// Z. B. wenn ein Button in Komponente A gedrückt wurde, die Auswirkung aber
// in Komponente B stattfindet. (bspw. öffnen/schließen von Menüs, Popups etc.)

/**
 * Eine Subscription Function, die an `Signal.subscribe`
 * und `Signal.unsubscribe` übergeben wird. Dient als Callback, der aufgerufen
 * wird, wenn ein Signal gesendet wird.
 */
type Subscription<T> = (...params: T extends never ? [] : [T]) => void

/**
 * Eine Signal-Instanz, die zum Senden und Empfangen von Signalen verwendet
 * wird.
 */
interface Signal<T> {
  subscribe: (subscription: Subscription<T>) => void
  unsubscribe: (subscription: Subscription<T>) => void
  send: (...params: T extends never ? [] : [T]) => void
}

/**
 * Erstellt eine neue Signal-Instanz. Dieses kann genutzt werden, um Signale
 * zu senden und zu empfangen. Der Typ-Parameter `T` ist der Typ der Daten,
 * die über das Signal gesendet werden können.
 *
 * @example
 * ``` ts
 * const mySignal = createSignal<string>()
 *
 * function sub (value: string): void {
 *  console.log(value)
 * }
 *
 * mySignal.subscribe(sub)
 * mySignal.send('Hello')
 * mySignal.unsubscribe(sub)
 * ```
 */
export function createSignal<T = never> (): Signal<T> {
  const subscriptions: Array<Subscription<T>> = []

  return {
    subscribe (subscription) {
      if (subscriptions.includes(subscription)) { return }
      subscriptions.push(subscription)
    },

    unsubscribe (subscription) {
      removeFromArray(subscriptions, subscription)
    },

    send (...params) {
      for (const subscription of subscriptions) {
        subscription(...params)
      }
    }
  }
}

/**
 * Ein React-Hook, der automatisch ein Signal abonniert und deabonniert.
 * @param signal Das Signal, das abonniert werden soll.
 * @param subscription Die Subscription Function, die bei Signalen aufgerufen
 * werden soll.
 *
 * @example
 * ``` ts
 * const mySignal = createSignal<string>()
 * // ...
 * useSignal(mySignal, (value) => {
 *   console.log(value)
 * })
 * ```
 */
export function useSignal<T> (
  signal: Signal<T>,
  subscription: Subscription<T>
): void {
  useEffect(
    () => {
      signal.subscribe(subscription)
      return () => {
        signal.unsubscribe(subscription)
      }
    },
    [signal, subscription]
  )
}
