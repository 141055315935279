import React from 'react'
import ReactDOM from 'react-dom'
import { IMenuItemUtils } from '../interfaces/IMenuItemUtils'
import { HiddenIframe } from '../../../Generic/HiddenIframe'

/**
 * Handelt den Klick auf eine Menu Item, welches einen String offnen soll und keine Funktion ausführt. (z.B. DownloadUrl)
 *
 * @param action Der zu offnende String.
 */
async function handleMouseUpString (action: string): Promise<void> {
  // Iframe wird vorm erstellen immer leer gemacht, da sonst beim zweiten Upload die
  // Component bereits existiert und der Download nicht mehr getriggert wird.
  ReactDOM.render(
    <></>,
    document.getElementById('download-anchor')
  )
  ReactDOM.render(
    <HiddenIframe title={'downloadFrame'} src={action} />,
    document.getElementById('download-anchor')
  )

  return await Promise.resolve()
}

/**
 * Utils mit Funktionen und Komponenten zu Menu Items.
 */
export const MenuItemUtils: IMenuItemUtils = {

  /**
   * Handelt den Klick auf eine Menu Item, welches einen String offnen soll und keine Funktion ausführt. (z.B. DownloadUrl)
   */
  handleMouseUpString: handleMouseUpString
}
