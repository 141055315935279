import { useState, useEffect, useCallback } from 'react'
import { noop } from '../utils/function'
import { sleep } from '../utils/global'

interface Options {
  waitTime: number
}

export function useAnimationFrame (
  callback: () => void | Promise<void>,
  options?: Partial<Options>
): void {
  const [active, setActive] = useState(false)
  const defaultOptions: Options = {
    waitTime: 0
  }
  const { waitTime }: Options = { ...defaultOptions, ...options }

  const tick = useCallback(() => {
    run().catch(noop)

    async function run (): Promise<void> {
      await callback()
      if (!active) return
      await sleep(waitTime)
      requestAnimationFrame(tick)
    }
  }, [active, callback, waitTime])

  useEffect(() => {
    setActive(true)
    requestAnimationFrame(tick)

    return () => {
      setActive(false)
    }
  }, [tick])
}
