import { createContext, useState } from 'react'
import { noop } from 'sfportal_utils/function'

interface ProductTreeSubviewsContextValue {
  currentListSubview: string | null
  currentDetailSubview: string | null
  setCurrentListSubview: (view: string | null) => void
  setCurrentDetailSubview: (view: string | null) => void
}

export const ProductTreeSubviewsContext =
  createContext<ProductTreeSubviewsContextValue>({
    currentListSubview: null,
    currentDetailSubview: null,
    setCurrentListSubview: noop,
    setCurrentDetailSubview: noop
  })

export function useProductTreeSubviewsContext (): ProductTreeSubviewsContextValue {
  const [currentListSubview, setCurrentListSubview] = useState<string | null>(
    null
  )
  const [currentDetailSubview, setCurrentDetailSubview] = useState<
  string | null
  >(null)

  return {
    currentListSubview,
    currentDetailSubview,
    setCurrentListSubview,
    setCurrentDetailSubview
  }
}
