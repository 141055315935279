import { Response } from 'superagent'
import { sendRequest } from './superagent'

/**
 * Holt die Dataresource zur übergebenen Id.
 *
 * @param id Die Id der Dataresource.
 */
export async function apiGetDataresourceById (
  id: number
): Promise<Response> {
  return await sendRequest('get', '/dataresource/id/' + id.toString())
}

/**
 * Holt die Dataresource zum übergebenen Identifier.
 *
 * @param identifier Der Identifier.
 */
export async function apiGetDataresourceByIdentifier (
  identifier: string
): Promise<Response> {
  return await sendRequest('get', `/dataresource/${identifier}`)
}

/**
 * Liefert die Dataresourceitems für Form-Struktur.
 *
 * @param dataresources Die Liste der dataresource Ids.
 */
export async function getDataresourceItems(
    dataresources: Array<number>
): Promise<Response | null> {
  return sendRequest('post', 'dataresource/get-dataresource-items', {body: dataresources})
}

