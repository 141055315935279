import { mdiFilter, mdiInbox, mdiRefresh } from '@mdi/js'
import { RouteComponentProps } from '@reach/router'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { FilterItemBadge } from '../components/Filters/FilterItemBadge'
import { ProductsFilter } from '../components/Filters/ProductsFilter'
import { EmptyIndicator } from '../components/Generic/EmptyIndicator'
import { DockLayout, DockLayoutRef } from '../components/Layout/DockLayout'
import { LoadingContainer } from '../components/Layout/LoadingContainer'
import { ListItemProduct } from '../components/ListItem/ListItemProduct'
import { MenuDivider } from '../components/Menu/MenuDivider'
import { MenuItem } from '../components/Menu/MenuItem'
import { Toolbar } from '../components/Toolbar/Toolbar'
import { ToolbarButton } from '../components/Toolbar/ToolbarButton'
import { ToolbarDropdown } from '../components/Toolbar/ToolbarDropdown'
import { ToolbarLabel } from '../components/Toolbar/ToolbarLabel'
import { getAllProductData, getProductData, ProductData, ProductProperty } from '../data/productData'
import { useStoredScrollPosition } from '../hooks/useStoredScrollPosition'
import { RequestStatus } from '../services/api/generic/types'
import { loadProducts, ProductFilter, removeFilter, setSort, sortOrderValues, useProductStore } from '../stores/productStore'
import { setProductsScrollPosition, useUiStateStore } from '../stores/uiStateStore'
import { cycleValue } from '../utils/array'
import { noop } from '../utils/function'
import { useTranslation } from 'react-i18next'

export const Products = memo(function Products (params: RouteComponentProps) {
  const productStore = useProductStore()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const dockLayoutRef = useRef<DockLayoutRef>(null)
  const { productsScrollPosition } = useUiStateStore()

  useStoredScrollPosition({
    elementRef: dockLayoutRef.current?.centerRef,
    scrollPosition: productsScrollPosition,
    setScrollPosition: setProductsScrollPosition
  })

  const { t } = useTranslation()

  const sortDropdownLabel = useMemo(() => {
    const sortBy = productStore.sort.sortColumn
    if (sortBy === null) {
      return t('products.sort.empty')
    }

    const productData = getProductData(sortBy, t)
    if (productData === null) {
      return t('products.sort.label', { label: sortBy })
    }
    return t('products.sort.label', { label: productData.label })
  }, [productStore.sort.sortColumn, t])

  const handleRefreshClick = useCallback(() => {
    loadProducts().catch(noop)
  }, [])

  const handleFilterClick = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleFilterApply = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleFilterCancel = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleRemoveFilterClick = useCallback(
    (filterName: keyof ProductFilter) => {
      removeFilter(filterName)
    },
    []
  )

  const handleSortMenuItemClick = useCallback(
    (sort: ProductData): void => {
      if (
        productStore.sort.sortColumn === sort.id &&
        productStore.sort.sortOrder === 'asc'
      ) {
        const currentSortOrder = productStore.sort.sortOrder
        const sortOrder = cycleValue(sortOrderValues, currentSortOrder)
        if (sortOrder === null) {
          // Konsolen ausgaben müssen nicht übersetzt werden.
          console.error(
            `Der Wert "${currentSortOrder}" ist nicht im Array ${JSON.stringify(
              sortOrderValues
            )} enthalten.`
          )
          return
        }
        setSort({
          sortColumn: sort.id,
          sortOrder
        })
        return
      }

      setSort({
        sortColumn: sort.id,
        sortOrder: 'asc'
      })
    },
    [productStore.sort.sortColumn, productStore.sort.sortOrder]
  )

  const header = useCallback(
    () => (
      <>
        <Toolbar>
          <ToolbarButton
            icon={mdiRefresh}
            label={t('common.refresh')}
            onClick={handleRefreshClick}
            disabled={productStore.status === RequestStatus.pending}
          />

          <ToolbarDropdown
            label={sortDropdownLabel}
            disabled={productStore.status !== RequestStatus.ok}
          >
            <MenuItem
              label={t('common.default')}
              highlight={productStore.sort.sortColumn === null}
              action={() => setSort({ sortColumn: null })}
            />

            <MenuDivider />

            {getAllProductData(t).map((sort) => (
              <MenuItem
                key={sort.id}
                label={
                  sort.label +
                  (productStore.sort.sortColumn !== null &&
                  productStore.sort.sortColumn === sort.id
                    ? ` (${productStore.sort.sortOrder})`
                    : '')
                }
                highlight={productStore.sort.sortColumn === sort.id}
                action={() => handleSortMenuItemClick(sort)}
              />
            ))}
          </ToolbarDropdown>

          <ToolbarButton
            icon={mdiFilter}
            label={t('products.filter.button')}
            onClick={handleFilterClick}
            disabled={productStore.status !== RequestStatus.ok}
          />
        </Toolbar>

        {Object.entries(productStore.filter).length > 0 ? (
          <Toolbar>
            <ToolbarLabel label={t('products.filter.active')} />

            {Object.entries(productStore.filter).map(
              ([filterKey, value]): JSX.Element | null =>
                value !== undefined && value !== '' ? (
                  <FilterItemBadge
                    key={filterKey}
                    filterKey={filterKey as ProductProperty}
                    filterValue={value}
                    onRemove={handleRemoveFilterClick}
                  />
                ) : null
            )}
          </Toolbar>
        ) : null}
      </>
    ),
    [
      handleFilterClick,
      handleRefreshClick,
      handleRemoveFilterClick,
      handleSortMenuItemClick,
      productStore.filter,
      productStore.sort.sortColumn,
      productStore.sort.sortOrder,
      productStore.status,
      sortDropdownLabel,
      t
    ]
  )

  const center = useCallback(
    () => (
      <LoadingContainer
        status={productStore.status}
        loadingText={t('products.loading')}
        onRetry={() => {
          loadProducts().catch(noop)
        }}
      >
        {productStore.status === RequestStatus.ok ? (
          <>
            {productStore.products.map((product) => (
              <ListItemProduct
                key={product.publicationTargetId}
                product={product}
              />
            ))}

            {productStore.products.length === 0 ? (
              <EmptyIndicator
                text={t('products.empty')}
                icon={mdiInbox}
              />
            ) : null}
          </>
        ) : null}
      </LoadingContainer>
    ),
    [productStore.products, productStore.status, t]
  )

  const cover = useCallback(() => {
    if (!isModalOpen) {
      return null
    }

    return (
      <ProductsFilter
        onFilter={handleFilterApply}
        onCancel={handleFilterCancel}
      />
    )
  }, [handleFilterApply, handleFilterCancel, isModalOpen])

  return (
    <DockLayout
      className="products-view"
      ref={dockLayoutRef}
      header={header}
      center={center}
      cover={cover}
    />
  )
})
