import { SuperAgentRequest } from 'superagent'
import { getStorageValue } from '../../localStorageService'
import { FormBody, QueryParams } from '../generic/types'

interface RequestObject { request: SuperAgentRequest }

type SetAuthorizationHeader = (request: SuperAgentRequest) => RequestObject

export const setAuthorizationHeader: SetAuthorizationHeader = (request) => {
  const token = getStorageValue('users:token', { parse: true })
  if (token !== null) {
    request = request.set('Authorization', `Bearer ${token.token}`)
  }

  // Workaround, um (wg. ESLint) das Promise nicht awaiten zu müssen.
  // Das würde sonst den Request abschicken, was hier zu früh ist.
  return { request }
}

export function setQueryParams (
  request: SuperAgentRequest,
  query: QueryParams
): RequestObject {
  for (const [key, value] of Object.entries(query)) {
    if (value === undefined) { continue }

    if (Array.isArray(value)) {
      for (const item of value) {
        request = request.query({ [key]: item })
      }
    } else if (value[0] === '+' || value[0] === '-') {
      // Workaround, da `+` und `-` beim `sort`-Parameter
      // nicht URL-kodiert werden dürfen.
      request = request.query(`${key}=${value}`)
    } else {
      request = request.query({ [key]: value })
    }
  }

  // Workaround, um (wg. ESLint) das Promise nicht awaiten zu müssen.
  // Das würde sonst den Request abschicken, was hier zu früh ist.
  return { request }
}

type SetHeaders =
  (request: SuperAgentRequest, headers: Headers) => RequestObject

export const setHeaders: SetHeaders = (request, headers) => {
  headers.forEach((value, key) => {
    if (value === undefined) { return }
    request = request.set(key, value)
  })

  // Workaround, um (wg. ESLint) das Promise nicht awaiten zu müssen.
  // Das würde sonst den Request abschicken, was hier zu früh ist.
  return { request }
}

type SetFormBody = (request: SuperAgentRequest, body: FormBody) => RequestObject

export const setFormBody: SetFormBody = (request, body) => {
  for (const [key, value] of Object.entries(body)) {
    if (value === undefined) { continue }

    if (typeof value === 'string') {
      request = request.field(key, value)
    } else {
      request = request.attach(key, value)
    }
  }

  // Workaround, um (wg. ESLint) das Promise nicht awaiten zu müssen.
  // Das würde sonst den Request abschicken, was hier zu früh ist.
  return { request }
}
