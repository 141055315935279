import { mdiMenuDown, mdiMenuRight } from '@mdi/js'
import classNames from 'classnames'
import React, { FC, useMemo } from 'react'
import { noop } from 'sfportal_utils/function'
import { MdiIcon } from '../Generic/MdiIcon'
import './TreeNode.scss'

// #region types
export interface TreeNodeAction {
  label: string
  icon: string
  enabled?: boolean
  action: () => void
}

interface GeneralProps {
  index: number
  label: string
  highlightedLabel?: string
  icon?: string
  current?: boolean
  level?: number
  disabled?: boolean
  actions?: TreeNodeAction[]
  onClick?: (index: number) => void
}

interface PropsWithChildren extends GeneralProps {
  hasChildren: true
  open: boolean
  onToggle: (index: number, isOpen: boolean) => void
}

interface PropsWithoutChildren extends GeneralProps {
  hasChildren: false
  open?: undefined
  onToggle?: undefined
}

type Props = PropsWithChildren | PropsWithoutChildren
// #endregion types

export const TreeNode: FC<Props> = (props) => {
  const {
    index,
    label,
    highlightedLabel,
    icon,
    current = false,
    level = 0,
    disabled = false,
    actions = [],
    onClick
  } = props

  function handleToggleClick (): void {
    if (!props.hasChildren) return
    props.onToggle(index, !props.open)
  }

  function handleLabelClick (): void {
    if (disabled) return
    onClick?.(index)
  }

  const spacerWidth = useMemo<number>(() => 30 * level, [level])

  const toggleIconPath = useMemo(() => {
    if (props.hasChildren) {
      return props.open ? mdiMenuDown : mdiMenuRight
    }

    return null
  }, [props.hasChildren, props.open])

  return (
    <div
      className={classNames('tree-node', {
        'tree-node--current': current,
        'tree-node--disabled': disabled
      })}
    >
      <div
        className="tree-node__spacer"
        style={{ width: spacerWidth }}
        onClick={handleToggleClick}
      />

      <div
        className={classNames('tree-node__toggle', {
          'tree-node__toggle--visible': props.hasChildren
        })}
        onClick={handleToggleClick}
      >
        <MdiIcon path={toggleIconPath} />
      </div>

      <div
        className={classNames('tree-node__label', {
          'tree-node__label--hoverable': onClick !== undefined
        })}
        onClick={handleLabelClick}
      >
        {icon !== undefined ? <div className="tree-node__icon"></div> : null}
        <div className="tree-node__label-text" title={label} dangerouslySetInnerHTML={{ __html: highlightedLabel ?? label }}/>
      </div>

      <div className="tree-node__actions">
        {actions.map(({ label, icon, enabled = true, action }, index) => (
          <div
            key={index}
            className={classNames('tree-node__action', {
              'tree-node__action--disabled': !enabled
            })}
            title={label}
            onClick={(enabled && action) || noop}
          >
            <MdiIcon path={icon} />
          </div>
        ))}
      </div>
    </div>
  )
}
