import React, {FC, useEffect} from 'react'
import {WorkflowItem} from './WorkflowItem'
import {getWorkflowLength, useWorkflowStore} from '../../stores/workflowStore'
import {getTransferLength} from '../../stores/transferStore'
import {EmptyIndicator} from '../Generic/EmptyIndicator'
import {mdiBroom} from '@mdi/js/commonjs/mdi'
import {useTranslation} from 'react-i18next'

interface Props {
    loadWorkflows: () => void
}

export const WorkflowList: FC<Props> = (
    {
        loadWorkflows
    }
) => {
    const {workflows} = useWorkflowStore()
    const {t} = useTranslation()

    useEffect(() => {
        loadWorkflows()
    }, [])

    return <div className={'workflow-list'}>
        {workflows.map((single, index) => <WorkflowItem key={index} workflow={single} />)}
        {getWorkflowLength() === 0 && getTransferLength() === 0
            ?
            <EmptyIndicator text={t('views.backgroundTasks.empty')} icon={mdiBroom} />
            : null}
    </div>
}