import SockJS from 'sockjs-client'
import { noop } from '../../utils/function'
import { createAreaSubscription, staticAreaId } from './staticAreaSubscription'
import { Client, Stomp } from '@stomp/stompjs'

/**
 * Static Client, der den Multicast Connect auf Singleton Basis bereitstellt.
 */
// Typescript Eslint Plugin bemängelt classes mit nur static membern.
// Das liegt daran, dass static member stage 3 sind und das @typescript-eslint Plugin nur bis stage 4 geht.
// Diese syntax wäre mit z.B.: @babel/eslint-parser in Ordnung, da dieser Stage 3 unterstützt.
// Die Warnung wurde somit ignoriert, da es funktionelles typescript ist.
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class StaticStompClient {
  /**
   * Die Instanz.
   *
   * @type {null} initial null.
   */
  static instance: Promise<Client> | null = null

  /**
   * Die Interne Instanz ist eine Kopie der normalen Instanz.
   * Die Instanz wird aber ohne Promise Referenz gespeichert.
   * Dies wird benötigt um beim onbeforeunload Event den disconnect durchführen zu können, da der Browser bei async functions vor dem resolve geschlossen wird.
   */
  static internalInstance: Client | null = null

  /**
   * Liefert die Client Instanz.
   *
   * @param errorCallback Callback der getriggert wird, wenn der connect einen Fehler wirft.
   * @return {null} returned die Stompjs Instanz. Falls diese noch nicht resolved ist,
   * wird der Promise zurückgegeben.
   */
  static async getInstance (errorCallback?: () => void, forceReconnect = false): Promise<Client> {
    if (StaticStompClient.instance === null || forceReconnect) {
      StaticStompClient.instance = this.connect(errorCallback)
    }

    return await StaticStompClient.instance
  }

  static async connect (errorCallback?: () => void): Promise<Client> {
    return await new Promise(resolve => {
      const client = Stomp.over(() => {
        const socket = new SockJS((process.env.REACT_APP_PUBLIC_URL ?? '') + '/socketConnect')
        socket.onerror = () => {
          if (errorCallback !== undefined) {
            errorCallback()
          }
        }
        return socket
      })
      client.reconnectDelay = 0
      const heartbeatTime = parseInt(process.env.REACT_APP_HEARTBEAT_TIME ?? '30000', 10)
      client.heartbeat.incoming = heartbeatTime
      client.heartbeat.outgoing = heartbeatTime
      client.debug = noop
      client.connect({}, () => {
        StaticStompClient.internalInstance = client
        resolve(client)
      }, () => {
        StaticStompClient.getInstance(errorCallback, true).then(_ => {
          if (staticAreaId !== null) {
            createAreaSubscription(staticAreaId).catch(noop)
          }
        }).catch(noop)
      }, () => {
        StaticStompClient.getInstance(errorCallback, true).then(_ => {
          if (staticAreaId !== null) {
            createAreaSubscription(staticAreaId).catch(noop)
          }
        }).catch(noop)
      })
    })
  }

  /**
   * Disconnected den Client vom Server.
   */
  static disconnect (): void {
    const clientInstance = StaticStompClient.internalInstance

    if (clientInstance != null) {
      clientInstance.forceDisconnect()
      StaticStompClient.instance = null
    }
  }
}
