import { createReactHook, createStore } from "@alinnert/tstate";
import {
  ApiExternalMetadata,
  ApiFolderAsset,
  ApiProduct,
  ApiSharedFolder,
  ApiTask,
  ApiTaskDataResourceAsset,
  ApiTaskDataResourceEditorDocument,
  instanceOfApiTaskDataResourceAsset,
} from "../services/api/apiSchemas";
import {
  fetchFileInfo,
  fetchFileMetadata,
  fetchShares,
  checkSharesAvailable,
} from "../services/api/filesApiService";
import { RequestStatus } from "../services/api/generic/types";
import { fetchProductDetail } from "../services/api/productsApiService";
import {
  apiClaimTasks,
  apiCompleteTasks,
  apiGetCorrectionPdf,
  apiGetTaskAssetEditorUrl,
  apiGetTaskformfields,
  apiGetTasks,
  apiPostTasks,
  apiSendTaskComment,
  apiUnclaimTasks,
} from "../services/api/tasksApiService";
import { noop } from "../utils/function";
import { handleRequestError } from "./allStores";
import { resetSubStores } from "sfportal_stores_extensions/productDetailStoreExtension";
import { resetProductTreeStore } from "./productTreeStore";
import {
  resetAreaSubscription,
  setAreaSubscrptionId,
} from "../services/multicast/staticAreaSubscription";
import {cloneDeep} from "lodash";
import { SearchInternal } from "../interfaces/Search"

/**
 * Konstante für die Anzahl an Tasks die im Infinite Loader gleichzeitig
 * geladen werden.
 */
const TASK_LOAD_COUNT = 100;

/**
 * @file Dieser Store verwaltet das aktuell geöffnete Produkt
 */

let loadTasksByIdTimeout: number | null = null;
let loadTasksByIdIds: Array<ApiTask["id"]> = [];

// #region store
export interface ProductDetailStore {
  /**
   * Das Produkt, das auf der Detail-Seite angezeigt werden soll.
   * `null` beduetet, dass kein Produkt angezeigt wird.
   * Auch wird dann kein Produkt-Detail-Tab angezeigt.
   */
  product: ApiProduct | null;
  /** Status für das Produkt, das auf der Detail-Seite angezeigt werden soll. */
  productStatus: RequestStatus;

  /** Alle Aufgaben des aktuellen Produkts als Map mit taskId, Task damit indexzugriffe bei Task mutations möglich sind. */
  tasks: Record<string, ApiTask>;
  /** Ladestatus der Aufgaben */
  tasksStatus: RequestStatus;
  /** Status für Aktionen, die Aufgaben betreffen (z. B. Task abschließen) */
  taskActionStatus: RequestStatus;
  /** Liste aller Gruppen, die geöffnet dargestellt werden sollen */
  taskExpandedGroups: Record<ApiTask["name"], boolean>;
  /**
   * Der aktuelle Start für das Limit Offset Laden der Tasks.
   */
  tasksStart: number;
  /**
   * Der aktuell ausgewählte Task.
   */
  currentTask: ApiTask | null;

  /** Flag, ob freigegebene Ordner vorhanden sind */
  sharedFilesAvailable: boolean;
  /** Ladestatus des Flags für freigegebene Ordner */
  sharedFilesAvailableStatus: RequestStatus;

  /** Alle Dateien eines Produkts */
  files: ApiSharedFolder[];
  /** Ladestatus der Dateien */
  filesStatus: RequestStatus;

  /** Datei, von der eine Vorschau dargestellt werden soll. */
  currentFile: ApiTask["dataResource"] | ApiFolderAsset | null;
  /** Metadaten der aktuellen Datei. */
  currentFileMetadata: ApiExternalMetadata | null;
  /** Ladestatus der Metadaten für die aktuelle Datei. */
  currentFileMetadataStatus: RequestStatus;
  /** Gibt an, ob die aktuell angezeigte Datei neu erstellt wird. */
  currentFileReloading: boolean;
  /** The result of the Search-Tab from the product */
  search: null | SearchInternal;
}

function getInitialState(): ProductDetailStore {
  return {
    product: null,
    productStatus: RequestStatus.ok,

    tasks: {},
    tasksStatus: RequestStatus.ok,
    taskActionStatus: RequestStatus.ok,
    taskExpandedGroups: {},
    tasksStart: 1,
    currentTask: null,

    sharedFilesAvailable: false,
    sharedFilesAvailableStatus: RequestStatus.ok,

    files: [],
    filesStatus: RequestStatus.ok,

    currentFile: null,
    currentFileMetadata: null,
    currentFileMetadataStatus: RequestStatus.ok,
    currentFileReloading: false,
    search: null,
  };
}

const store = createStore(getInitialState());
export const useProductDetailStore = createReactHook(store);

/**
 * Exportiert einen Readyonly Zugriff auf den State.
 * Dieser kann dann Customseitig erweitert werden.
 * Readonly dient hierbei als Sicherheit, dass niemand die States außerhalb
 * der mutations verändert.
 */
export const productDetailStoreStateAccess: Readonly<ProductDetailStore> =
  store.state;

const mutations = {
  async reset(): Promise<void> {
    await resetAreaSubscription().catch(noop);
    store.set(getInitialState());
    resetSubStores();
    resetProductTreeStore();
    setCurrentFile(null).catch(noop);
  },

  // #region Produkt
  loadProduct(): void {
    store.set({ productStatus: RequestStatus.pending });
  },

  async setProduct(product: ApiProduct | null): Promise<void> {
    if (product === null) {
      await mutations.reset();
      return;
    }

    store.set({
      product,
      productStatus: RequestStatus.ok,
    });

    setCurrentFile(null).catch(noop);
  },

  setProductStatus(productStatus: RequestStatus): void {
    store.set({ productStatus });
  },
  // #endregion Produkt

  // #region Aufgaben / Tasks
  setTasks(tasks: ProductDetailStore["tasks"]): void {
    store.set({ tasks });
  },

  /**
   * Fügt Tasks zu den bereits bestehenden Tasks hinzu.
   *
   * @param newTasks Die neuen Tasks.
   */
  addTasks(newTasks: ProductDetailStore["tasks"]): void {
    // Es muss auf existingIds geprüft werden, da beim initialen laden durch eine Subscription bereits
    // Einträge in die Liste kommen könnten, die durch das Endlosloading erst am Ende geladen werden,
    // wordurch Tasks zweimal vorkommen kónnen.
    const existingIds = Object.keys(store.state.tasks);
    const filteredNewTasks: Record<string, ApiTask> = {};
    Object.entries(newTasks)
      .filter(([key]) => !existingIds.includes(key))
      .forEach(([key, value]) => {
        filteredNewTasks[key] = value;
      });
    const tasks: Record<string, ApiTask> = {
      ...store.state.tasks,
      ...filteredNewTasks,
    };

    store.set({ tasks });
  },

  /**
   * Ersetzt Tasks, welche die gleiche Id haben wie die übergebenen.
   *
   * @param newTasks Die neuen Tasks, welche als Update gelten.
   */
  replaceExistingTasks(newTasks: ProductDetailStore["tasks"]): void {
    const tasksTemp = { ...store.state.tasks };
    Object.entries(newTasks).forEach(([key, value]) => {
      tasksTemp[key] = value;
    });
    this.setTasks(tasksTemp);
  },

  /**
   * Entfernt Tasks mit den übergebenen Ids aus dem store.
   *
   * @param removingTasks Array mit den Ids der Tasks die entfernt werden.
   */
  removeTasks(removingTasks: Array<ApiTask["id"]>): void {
    const tasks: Record<string, ApiTask> = {};
    Object.entries(store.state.tasks)
      .filter(([key, task]) => !removingTasks.includes(key))
      .forEach(([key, task]) => {
        tasks[key] = task;
      });
    store.set({ tasks });
  },

  setTasksStatus(tasksStatus: ProductDetailStore["tasksStatus"]): void {
    store.set({ tasksStatus });
  },

  setTaskActionStatus(
    taskActionStatus: ProductDetailStore["taskActionStatus"],
  ): void {
    store.set({ taskActionStatus });
  },

  /**
   * Setzt den Startwert für Limit Offset Loading von Tasks.
   *
   * @param tasksStart Der neue Startwert.
   */
  setTasksStart(tasksStart: number): void {
    store.set({ tasksStart });
  },

  /**
   * Setzt den discard Pending Status eines Tasks. Falls dieser true ist, wird ein modal getriggert
   * der die herungergeladene Änderung bei Bestätigung aus dem System freigibt.
   *
   * @param task Der zu ändernde Task
   * @param discardPending ob der discard gezeigt werden soll oder nicht
   */
  setTaskDiscardPending(task: ApiTask, discardPending: boolean): void {
    const tasks: Record<string, ApiTask> = JSON.parse(
      JSON.stringify(store.state.tasks),
    );
    Object.entries(store.state.tasks).forEach(([key, stateTask]) => {
      if (stateTask.id === task.id) {
        stateTask.discardPending = discardPending;
      }
      tasks[key] = cloneDeep(stateTask);
    });
    store.set({ tasks });
  },

  /**
   * Setzt einen Status innerhalb der übergebenen Tasks.
   *
   * @param tasks Die neuen Tasks.
   * @param state Der neue State.
   */
  setTasksStatusInTasks(
    tasks: Array<ApiTask["id"]>,
    state: RequestStatus,
  ): void {
    const tasksCopy: Record<string, ApiTask> = { ...store.state.tasks };
    Object.entries(tasksCopy).forEach(([key, value]) => {
      if (tasks.includes(key)) {
        value.taskStatus = state;
      }
      tasksCopy[key] = cloneDeep(value);
    });

    this.setTasks(tasksCopy);
  },

  /**
   * Setzt den aktuell ausgewählten Task.
   *
   * @param currentTask Der neue Tasks.
   */
  setCurrentTask(currentTask: ApiTask): void {
    store.set({ currentTask });
  },

  toggleExpandedTaskGroup(taskName: ApiTask["name"], value: boolean): void {
    store.state.taskExpandedGroups[taskName] = value;
    store.set(store.state.taskExpandedGroups);
  },
  // #endregion Aufgaben / Tasks

  // #region Freigegebene Ordner / Files
  setSharedFilesAvailable(
    sharedFilesAvailable: ProductDetailStore["sharedFilesAvailable"],
  ): void {
    store.set({
      sharedFilesAvailable,
      sharedFilesAvailableStatus: RequestStatus.ok,
    });
  },

  setSharedFilesAvailableStatus(
    sharedFilesAvailableStatus: ProductDetailStore["sharedFilesAvailableStatus"],
  ): void {
    store.set({ sharedFilesAvailableStatus });
  },

  setFiles(files: ProductDetailStore["files"]): void {
    store.set({
      files,
      filesStatus: RequestStatus.ok,
    });
  },

  setFilesStatus(filesStatus: ProductDetailStore["filesStatus"]): void {
    store.set({ filesStatus });
  },

  setCurrentFile(currentFile: ProductDetailStore["currentFile"]): void {
    store.set({ currentFile });
  },

  setCurrentFileMetadata(
    currentFileMetadata: ProductDetailStore["currentFileMetadata"],
  ): void {
    store.set({
      currentFileMetadata,
      currentFileMetadataStatus: RequestStatus.ok,
    });
  },

  setCurrentFileMetadataStatus(
    currentFileMetadataStatus: ProductDetailStore["currentFileMetadataStatus"],
  ): void {
    store.set({ currentFileMetadataStatus });
  },

  setFormfields(
    formFields: ApiTask["formFields"],
    taskId: ApiTask["id"],
  ): void {
    this.replaceExistingTasks({
      [taskId]: {
        ...(getStoreTaskById(taskId) as ApiTask),
        formFields: formFields,
      },
    });
  },

  setEditorUrl(
    editorUrl: ApiTaskDataResourceEditorDocument["editorUrl"],
    taskId: ApiTask["id"],
  ): void {
    const currentTask = getStoreTaskById(taskId);
    this.replaceExistingTasks({
      [taskId]: {
        ...(currentTask as ApiTask),
        dataResource: {
          ...(currentTask as ApiTask).dataResource,
          editorUrl: editorUrl,
        },
      } as ApiTask,
    });
  },

  setCurrentFileReloading(isLoading: boolean): void {
    store.set({ currentFileReloading: isLoading });
  },

  setSearch(search: ProductDetailStore['search']): void {
    store.set({ search });
  }
  // #endregion Freigegebene Ordner / Files
};

export const productDetailStoreMutationsAccess: Readonly<typeof mutations> =
  mutations;
// #endregion store

// #region actions
export async function resetProductDetailStore(): Promise<void> {
  await mutations.setProduct(null);
}

// #region Geöffnetes Produkt
export async function loadProductDetail(
  productId: ApiProduct["id"],
): Promise<void> {
  const isDifferentProduct = (store.state.product?.id ?? null) !== productId;
  const isProductLoading = store.state.productStatus === RequestStatus.pending;

  if (isDifferentProduct && isProductLoading) {
    return;
  }
  if (isDifferentProduct) {
    await mutations.reset();
  }

  mutations.loadProduct();

  try {
    const result = await fetchProductDetail({ productId });
    await mutations.setProduct(result.body as ApiProduct);
    setAreaSubscrptionId((result.body as ApiProduct).id);
  } catch (error) {
    mutations.setProductStatus(handleRequestError(error));
  }
}

export function refreshAllData(): void {
  const currentProductId = store.state.product?.id ?? null;
  if (currentProductId === null) {
    return;
  }
  loadProductDetail(currentProductId).catch(noop);
}

export function getCurrentProduct(): ProductDetailStore["product"] {
  return store.state.product;
}

// #endregion Geöffnetes Produkt

// #region Aufgaben / Tasks zum aktuellen Produkt
export async function loadProductTasks(): Promise<void> {
  const id = store.state.product?.id ?? null;
  if (id === null) {
    return;
  }

  mutations.setTasksStatus(RequestStatus.pending);
  mutations.setTaskActionStatus(RequestStatus.pending);
  mutations.setTasks({});
  mutations.setTasksStart(1);

  await loadProductTasksRecursive(id);
}

export async function completeTasks(
  ...tasks: Array<{
    taskId: ApiTask["id"];
    formFields?: Record<string, string>;
  }>
): Promise<void> {
  try {
    const taskIds = tasks.map((task) => task.taskId);
    mutations.setTasksStatusInTasks(taskIds, RequestStatus.pending);
    await apiCompleteTasks(...tasks);
    mutations.removeTasks(taskIds);
    if (store.state.tasksStatus !== RequestStatus.ok) {
      // Solange noch die gesamten Tasks geladen werden, dürfen Multiactions noch nicht freigegeben werden.
      mutations.setTaskActionStatus(RequestStatus.ok);
    }
  } catch (error) {
    mutations.setTaskActionStatus(handleRequestError(error));
  }
}

/**
 * Ändert den Assignee eines Tasks. Es wird eine Map an TaskIds und entsprechenden Assignees übergeben
 * um mehrere Assignees gleichzeitig ändern zu können.
 *
 * @param assigneeChangedEntries Die TaskIdAssignee Map.
 */
export function changeAssignee(
  assigneeChangedEntries: Record<ApiTask["id"], ApiTask["assignee"]>,
): void {
  const tasks = { ...store.state.tasks };
  Object.entries(assigneeChangedEntries).forEach(([taskId, newAssignee]) => {
    const taskTemp = tasks[taskId];
    tasks[taskId] = {
      ...taskTemp,
      assignee: newAssignee,
      taskStatus: RequestStatus.ok,
    };
  });

  store.set({ tasks });
}

/**
 * Ändert den Lock von Tasks.
 *
 * @param taskIds Die Ids der Tasks die geändert werden.
 * @param lockCreate Falls true wird der Task als gesperrt angezeigt, falls false wird der Task entsperrt angezeigt.
 */
export function setLock(
  taskIds: Array<ApiTask["id"]>,
  lockCreate: boolean,
): void {
  const tasks = { ...store.state.tasks };
  taskIds
    .filter((taskId) =>
      instanceOfApiTaskDataResourceAsset(tasks[taskId].dataResource),
    )
    .forEach((taskId) => {
      const taskTemp = tasks[taskId];
      tasks[taskId] = {
        ...taskTemp,
        dataResource: {
          ...(taskTemp.dataResource as ApiTaskDataResourceAsset),
          locked: lockCreate,
        },
      };
    });

  store.set({ tasks });
}

interface CompleteTaskWithCommentParams {
  comment: string;
  formFields?: Record<string, string>;
}

export async function completeTaskWithComment(
  taskId: ApiTask["id"],
  { comment, formFields }: CompleteTaskWithCommentParams,
): Promise<void> {
  const trimmedComment = comment.trim();

  try {
    mutations.setTasksStatusInTasks([taskId], RequestStatus.pending);
    if (trimmedComment !== "") {
      await apiSendTaskComment({
        taskId,
        comment: trimmedComment,
      });
    }
    await apiCompleteTasks({
      taskId,
      formFields,
    });
    mutations.removeTasks([taskId]);
    if (store.state.tasksStatus !== RequestStatus.ok) {
      // Solange noch die gesamten Tasks geladen werden, dürfen Multiactions noch nicht freigegeben werden.
      mutations.setTaskActionStatus(RequestStatus.ok);
    }
  } catch (error) {
    mutations.setTaskActionStatus(handleRequestError(error));
  }
}

/**
 * Entfernt alle Tasks mit der übergebenen Id aus der Taskliste.
 *
 * @param tasks Die Ids der Tasks.
 */
export async function removeTasks(
  ...tasks: Array<ApiTask["id"]>
): Promise<void> {
  try {
    mutations.setTasksStatusInTasks(tasks, RequestStatus.pending);
    mutations.removeTasks(tasks);
    if (store.state.tasksStatus !== RequestStatus.ok) {
      // Solange noch die gesamten Tasks geladen werden, dürfen Multiactions noch nicht freigegeben werden.
      mutations.setTaskActionStatus(RequestStatus.ok);
    }
  } catch (error) {
    mutations.setTaskActionStatus(handleRequestError(error));
  }
}

export async function claimTasks(
  ...tasks: Array<ApiTask["id"]>
): Promise<void> {
  try {
    mutations.setTasksStatusInTasks(tasks, RequestStatus.pending);
    await apiClaimTasks(...tasks);
  } catch (error) {
    mutations.setTaskActionStatus(handleRequestError(error));
  }
}

export async function unclaimTasks(
  ...tasks: Array<ApiTask["id"]>
): Promise<void> {
  try {
    mutations.setTasksStatusInTasks(tasks, RequestStatus.pending);
    await apiUnclaimTasks(...tasks);
  } catch (error) {
    mutations.setTaskActionStatus(handleRequestError(error));
  }
}

/**
 * Lädt die Status der übergebenen Tasks aus der Datenbank nach.
 *
 * @param tasks Die Tasks, die nachgelden werden sollen.
 */
export async function updateTaskStates(
  ...tasks: Array<ApiTask["id"]>
): Promise<void> {
  try {
    mutations.setTasksStatusInTasks(tasks, RequestStatus.pending);
    await loadTasksByIds(tasks);
    mutations.setTasksStatusInTasks(tasks, RequestStatus.ok);
    if (store.state.tasksStatus !== RequestStatus.ok) {
      // Solange noch die gesamten Tasks geladen werden, dürfen Multiactions noch nicht freigegeben werden.
      mutations.setTaskActionStatus(RequestStatus.ok);
    }
  } catch (error) {
    mutations.setTaskActionStatus(handleRequestError(error));
  }
}

export async function updateTaskStateSingle(
  taskId: ApiTask["id"],
): Promise<void> {
  try {
    mutations.setTasksStatusInTasks([taskId], RequestStatus.pending);
    await loadTaskById(taskId);
    mutations.setTasksStatusInTasks([taskId], RequestStatus.ok);
  } catch (error) {
    mutations.setTaskActionStatus(handleRequestError(error));
  }
}

/**
 * Lädt neue Tasks mit den übergebenen Ids.
 *
 * @param tasks Die Tasks, die neu geladen werden und an die Taskliste angefügt werden.
 */
export async function loadNewTasks(
  ...tasks: Array<ApiTask["id"]>
): Promise<void> {
  try {
    await loadNewTasksByIds(tasks);
    mutations.setTasksStatusInTasks(tasks, RequestStatus.ok);
    if (store.state.tasksStatus !== RequestStatus.ok) {
      // Solange noch die gesamten Tasks geladen werden, dürfen Multiactions noch nicht freigegeben werden.
      mutations.setTaskActionStatus(RequestStatus.ok);
    }
  } catch (error) {
    mutations.setTaskActionStatus(handleRequestError(error));
  }
}

/**
 * Lädt einen Task aus dem Store.
 *
 * @param taskId Die Id des Tasks.
 */
export function getStoreTaskById(taskId: ApiTask["id"]): ApiTask | null {
  const filteredTask = store.state.tasks[taskId];
  return filteredTask === undefined ? null : filteredTask;
}

export function toggleExpandedTaskGroup(
  taskName: ApiTask["name"],
  defaultValue: boolean | undefined,
): void {
  const current = store.state.taskExpandedGroups[taskName];
  const newValue = !(current ?? defaultValue ?? false);
  mutations.toggleExpandedTaskGroup(taskName, newValue);
}

export function isTaskGroupExpanded(
  taskName: ApiTask["name"],
  defaultValue: boolean | undefined,
): boolean {
  return store.state.taskExpandedGroups[taskName] ?? defaultValue ?? false;
}

// #endregion Aufgaben / Tasks zum aktuellen Produkt

// #region Freigegebene Ordner / Shares zum aktuellen Produkt
export async function loadSharedFilesAvailable(): Promise<void> {
  const id = store.state.product?.id ?? null;
  if (id === null) return;

  mutations.setSharedFilesAvailableStatus(RequestStatus.pending);

  try {
    const result = await checkSharesAvailable(id);
    if (result === null) {
      mutations.setSharedFilesAvailableStatus(RequestStatus.unknownError);
      return;
    }
    mutations.setSharedFilesAvailable(result.body as boolean);
  } catch (error) {
    mutations.setSharedFilesAvailableStatus(handleRequestError(error));
  }
}

export async function loadProductFiles(
  orderBy?: string | undefined,
): Promise<void> {
  const id = store.state.product?.id ?? null;
  if (id === null) return;

  mutations.setFilesStatus(RequestStatus.pending);

  try {
    const result = await fetchShares(
      id,
      () => store.state.product?.id ?? null,
      orderBy ?? process.env.REACT_APP_INITIAL_SORTBY_FOR_FOLDER,
    );
    if (result === null) return;
    mutations.setFiles(result.body as ApiSharedFolder[]);
  } catch (error) {
    mutations.setFilesStatus(handleRequestError(error));
  }
}

export function refreshProductFiles(productId: ApiProduct["id"]): void {
  // Wenn ein Upload fertiggestellt wurde, der zu einem anderen Produkt
  // als dem aktuell geöffneten gehört, dann soll die Liste
  // nicht aktualisiert werden.
  if (productId !== (store.state.product?.id ?? null)) {
    return;
  }
  loadProductFiles().catch(noop);
}

// #endregion Freigegebene Ordner / Shares zum aktuellen Produkt

// #region Ausgewählte Datei im aktuellen Produkt
export async function setCurrentFile(
  file: ProductDetailStore["currentFile"],
): Promise<void> {
  mutations.setCurrentFile(file);
  if (file === null) return;

  loadCurrentFileMetadata().catch(noop);
}

/**
 * Setzt den aktuell ausgewählten Task.
 *
 * @param task Der neue Task der als ausgewählt gesetzt wird.
 */
export function setCurrentTask(task: ApiTask): void {
  mutations.setCurrentTask(task);
}

export async function reloadCurrentFile(): Promise<void> {
  await setCurrentFile(store.state.currentFile);
}

/**
 * Lädt zuerst den aktuellen EntityMetakey der Preview einer Datei und anschließend den Inhalt des EntityMetakeys.
 */
export async function reloadPreview(): Promise<void> {
  if (store.state.currentFile === null) {
    return;
  }
  const result = await fetchFileInfo(store.state.currentFile.id);
  if (
    result !== null &&
    result.status === 200 &&
    result.body[0].previewPath !== null &&
    result.body[0].previewPath !== ""
  ) {
    const copy = JSON.parse(JSON.stringify(store.state.currentFile));
    copy.previewPath = result.body[0].previewPath;
    await setCurrentFile(copy);
  }
}

/**
 * Ändert den reloading Status der Taskdatei.
 *
 * @param isReloading Der neue reloading Status.
 */
export function setCurrentFileReloading(isReloading: boolean): void {
  mutations.setCurrentFileReloading(isReloading);
}

// #endregion Ausgewählte Datei im aktuellen Produkt
// #endregion actions

// #region functions
/**
 * Lädt Tasks rekursiv um bereits die ersten Ergebnisse anzeigen
 * und bearbeiten zu können.
 * @param id Die Id des Produkts.
 */
async function loadProductTasksRecursive(id: number): Promise<void> {
  try {
    const result = await apiGetTasks(
      id,
      store.state.tasksStart,
      TASK_LOAD_COUNT,
      () => store.state.product?.id ?? null,
      undefined,
      process.env.REACT_APP_TASK_GET_SORT_COLUMN,
    );

    // if (store.state.tasksStatus !== RequestStatus.ok) {
    //   // Ladebalken entfernen, wenn die ersten Tasks erfolgreich geladen wurden.
    //   mutations.setTasksStatus(RequestStatus.ok)
    // }

    if (result === null || result.body.length === 0) {
      // Aktionen freischalten, die mehrere Tasks betreffen.
      mutations.setTaskActionStatus(RequestStatus.ok);
      mutations.setTasksStatus(RequestStatus.ok);
      return;
    }

    const newTasks: Record<string, ApiTask> = {};

    (result.body as ApiTask[]).forEach((task: ApiTask) => {
      newTasks[task.id] = task;
    });

    mutations.addTasks(newTasks);

    mutations.setTasksStatusInTasks(Object.keys(newTasks), RequestStatus.ok);

    mutations.setTasksStart(store.state.tasksStart + TASK_LOAD_COUNT);
    await loadProductTasksRecursive(id);
  } catch (error) {
    mutations.setTasksStatus(handleRequestError(error));
  }
}

/**
 * Lädt Tasks für die übergebenen Ids und ersetzt vorhandene Tasks mit der gleichen Id mit den neuen Tasks.
 *
 * @param tasks Die Ids der Tasks.
 */
async function loadTasksByIds(tasks: Array<ApiTask["id"]>): Promise<void> {
  const id = store.state.product?.id ?? null;
  if (id === null) {
    return;
  }

  return await new Promise((resolve) => {
    loadTasksByIdIds.push(...tasks);
    if (loadTasksByIdTimeout !== null) {
      window.clearTimeout(loadTasksByIdTimeout);
    }
    loadTasksByIdTimeout = window.setTimeout(() => {
      apiPostTasks(id, loadTasksByIdIds, () => store.state.product?.id ?? null)
        .then((result) => {
          const tasks = result?.body as ApiTask[];
          const tasksTempMap: ProductDetailStore["tasks"] = {};
          tasks.forEach((task) => {
            tasksTempMap[task.id] = task;
          });
          mutations.replaceExistingTasks(tasksTempMap);
          resolve();
        })
        .catch(noop);
      loadTasksByIdIds = [];
      loadTasksByIdTimeout = null;
    }, 100);
  });
}

/**
 * Lädt Tasks für die übergebenen Ids und ersetzt vorhandene Tasks mit der gleichen Id mit den neuen Tasks.
 *
 * @param tasks Die Ids der Tasks.
 */
async function loadTaskById(taskId: ApiTask["id"]): Promise<void> {
  const id = store.state.product?.id ?? null;
  if (id === null) {
    return;
  }

  const result = await apiPostTasks(
    id,
    [taskId],
    () => store.state.product?.id ?? null,
  );

  const tasks = result?.body as ApiTask[];
  if (tasks.length === 0) {
    // Der User darf den angefragte Task nicht mehr sehen, da der state geändert wurde. Der Task wird somit aus dem State entfernt anstatt ihn zu updaten.
    mutations.removeTasks([taskId]);
  }
  const tasksTempMap: ProductDetailStore["tasks"] = {};
  tasks.forEach((task) => {
    tasksTempMap[task.id] = task;
  });
  mutations.replaceExistingTasks(tasksTempMap);
}

/**
 * Lädt ein Correction Pdf zu einem Task.
 *
 * @param taskId Die Id des Tasks.
 */
export async function loadCorrectionPdf(taskId: ApiTask["id"]): Promise<void> {
  const storeTask = getStoreTaskById(taskId);
  if (storeTask?.dataResource.type !== "asset") {
    return;
  }

  const correctionPdfResult = await apiGetCorrectionPdf(
    storeTask.dataResource.id,
  );
  if (correctionPdfResult !== null) {
    storeTask.dataResource.correctionPdfPath = correctionPdfResult.text;
    mutations.replaceExistingTasks({ [storeTask.id]: storeTask });
  }
}

/**
 * Lädt die neue Tasks aus der Datenbank.
 *
 * @param tasks Die Ids der neuen Tasks.
 */
async function loadNewTasksByIds(tasks: Array<ApiTask["id"]>): Promise<void> {
  const id = store.state.product?.id ?? null;
  if (id === null) {
    return;
  }
  const result = await apiPostTasks(
    id,
    tasks,
    () => store.state.product?.id ?? null,
  );

  const tasksResult = result?.body as ApiTask[];
  const tasksTempMap: ProductDetailStore["tasks"] = {};
  tasksResult.forEach((task) => {
    tasksTempMap[task.id] = task;
  });

  mutations.addTasks(tasksTempMap);
}

async function loadCurrentFileMetadata(): Promise<void> {
  const file = store.state.currentFile;
  if (file === null) return;

  mutations.setCurrentFileMetadataStatus(RequestStatus.pending);

  try {
    const result = await fetchFileMetadata(
      file.id,
      () => store.state.currentFile,
    );
    if (result === null) return;

    const fileMetadata =
      result.body as ProductDetailStore["currentFileMetadata"];
    mutations.setCurrentFileMetadata(fileMetadata);
  } catch (error) {
    mutations.setCurrentFileMetadataStatus(handleRequestError(error));
  }
}

export async function loadFormfieldsForTask(
  taskId: ApiTask["id"],
): Promise<void> {
  const result = await apiGetTaskformfields(taskId);
  mutations.setFormfields(result.body as ApiTask["formFields"], taskId);
}

export async function loadEditorUrlForTaskAsset(
  taskId: ApiTask["id"],
): Promise<void> {
  const result = await apiGetTaskAssetEditorUrl(
    taskId,
    getStoreTaskById(taskId)?.dataResource.id,
  );
  mutations.setEditorUrl(
    result.body.editorUrl as ApiTaskDataResourceEditorDocument["editorUrl"],
    taskId,
  );
}

export const setSearch = (search: ProductDetailStore['search']): void => {
  mutations.setSearch(search)
}

// #endregion functions
