import React, { memo } from 'react'
import './StatusbarLabel.scss'
import { ChildrenProp } from '../../jsx'

interface Props extends ChildrenProp {}

export const StatusbarLabel = memo(function StatusbarLabel ({
  children
}: Props) {
  return <div className="statusbar-label">{children}</div>
})
