import { mdiFileDocumentOutline, mdiPencil } from '@mdi/js'
import React, { memo, useCallback } from 'react'
import { ChildrenProp } from '../../jsx'
import { ApiTask, ApiTaskDataResourceEditorDocument, } from '../../services/api/apiSchemas'
import { claimTasks, getStoreTaskById, loadEditorUrlForTaskAsset, ProductDetailStore, setCurrentFile, setCurrentTask, } from '../../stores/productDetailStore'
import { noop } from '../../utils/function'
import { Button } from '../Forms/Button'
import { ListItemTask } from './ListItemTask'
import { useTaskActions } from './useTaskActions'
import { useTranslation } from 'react-i18next'
import {MdiIcon} from "../Generic/MdiIcon";

interface Props extends ChildrenProp {
  task: ApiTask;
  showTaskName?: boolean;
  highlight?: boolean;
  taskActionStatus: ProductDetailStore['taskActionStatus'];
  currentFile: ProductDetailStore['currentFile'];
}

export const ListItemTaskEditorDocument = memo(
  function ListItemTaskEditorDocument ({
    task,
    highlight,
    showTaskName = false,
    taskActionStatus,
    currentFile,
  }: Props) {
    const taskActions = useTaskActions({
      task,
      taskActionStatus
    })

    const { t } = useTranslation()

    const dataResource = task.dataResource as ApiTaskDataResourceEditorDocument

    const handleItemClick = useCallback(() => {
      setCurrentFile(dataResource).catch(noop)
    }, [dataResource])

    const handleEditAssetClick = useCallback(async () => {
      /* Typ 'smashdocs' wird anders behandelt */
      await loadEditorUrlForTaskAsset(task.id)
      setCurrentTask(task)
      // Die Referenz auf den currentTasks ist hier noch nicht geupdated. Deswegen wird der Task explizit
      // nach dem Update aus der Datenbank aus dem Store geladen.
      const updatedTask = getStoreTaskById(task.id)
      const dataResourceTemp =
        updatedTask?.dataResource as ApiTaskDataResourceEditorDocument
      setCurrentFile(
        updatedTask === null ? null : updatedTask.dataResource,
      ).catch(noop)
      if (
        dataResourceTemp.editorType === 'fonto' ||
        dataResourceTemp.editorType === 'xeditor' ||
        dataResourceTemp.editorType === 'xopus'
      ) {
        const hasAssignee =
          typeof updatedTask?.assignee === 'string' &&
          updatedTask.assignee.length > 0

        if (!hasAssignee) {
          claimTasks(updatedTask?.id ?? '').catch(noop)
        }
        globalThis.open(dataResourceTemp.editorUrl)
      }
    }, [task])

    return (
      <>
        {taskActions.taskModalElement}

        <ListItemTask
          listItemType="editordocument"
          icon={<MdiIcon path={mdiFileDocumentOutline} />}
          title={dataResource.name}
          titleSuffix={showTaskName ? task.name : undefined}
          highlight={highlight}
          current={currentFile?.id === dataResource.id}
          dueDate={task.dueDate}
          onItemClick={handleItemClick}
          pubtargetIdentifier={task.pubtargetIdentifier}
          actions={
            <>
              {taskActions.taskActionElements}

              {dataResource.assetPrivileges.canUserEdit ? (
                <Button
                  buttonStyle="inline"
                  title={t('listItem.listItemTaskEditorDocument.editDocument')}
                  icon={mdiPencil}
                  onClick={handleEditAssetClick}
                />
              ) : null}
            </>
          }
        />
      </>
    )
  },
)
