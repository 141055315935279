import { RequestStatus } from './generic/types'
import { SortableBy } from '../../utils/array'

export interface EMKObject {
  dataResourceId: string;
  contentId: string;
}

export type EMK = string;

export interface ApiFolderAsset {
  /** EMK des Assets */
  id: EMK;
  /** Dateiname des Assets */
  name: string;
  /** Name des Nutzers, der das Asset zuletzt geändert hat */
  modifiedBy: string | null;
  /** Datum, wann das Asset zuletzt geändert wurde */
  modifiedDate: string | null;
  /** Dateityp des Assets */
  contentType: string | null;
  configkey: string | null;
  /** Pfad zur Vorschaudatei */
  previewPath: string | null;
  /** Pfad zur Datei über WebDAV */
  webdavPath: string;
  /** Der Status des Assets. */
  status: string | null;
  /** Typ der DataResource */
  type: 'folderasset';
  /** Gibt an ob auf dem Asset ein Lock existiert. */
  locked: boolean;
  /** Custom PreviewPdf, welches den normalen Preview Path überschreibt. */
  cstFkPreviewPdf: number | null;
}

/**
 * Typeguard function für ApiFolderAsset.
 *
 * @param value Das zu testende object
 */
export function instanceOfApiFolderAsset (
  value: unknown,
): value is ApiFolderAsset {
  if (typeof value !== 'object') return false
  return (value as { type?: unknown }).type === 'folderasset'
}

export type ApiExternalMetadata =
  | ApiExternalFileMetadata
  | ApiExternalImageMetadata;

interface ApiExternalMetadataBase {
  identifier: string;
  fileName: string;
  fileSize: number | null;
  description: string | null;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
}

export interface ApiExternalFileMetadata extends ApiExternalMetadataBase {
  metadataType: 'file';
}

export interface ApiExternalImageMetadata extends ApiExternalMetadataBase {
  metadataType: 'image';
  copyright: string | null;
  exif: string | null;
  iptc: string | null;
  width: number | null;
  height: number | null;
  resolutionX: number | null;
  resolutionY: number | null;
}

export interface ApiSharedFolder {
  /** EMK des Ordners */
  id: EMK;
  /** Name des Ordners */
  name: string;
  /** Rollennamen */
  sharedRoleNames: string[] | null;
  /** Identifier des Root-Ordners */
  rootFolderIdentifier: string | null;
  configkey: string | null;
  readOnly: boolean;
  /** Inhalt des Ordners */
  content: Array<ApiSharedFolder | ApiFolderAsset> | null;
  /** Art des Eintrags */
  type: 'folder';
}

export interface ApiProductStatus {
  /** ID des Status */
  id: number;
  /** Name des Status */
  value: string;
}

export interface ApiImageFormat {
  /** ID des Bildformats */
  id: number;
  /** Name des Bildformats */
  value: string;
}

export interface ApiMessage {
  /** ID der Nachricht */
  id: number;
  /** Name des Absenders */
  sender: string;
  /** Betreff */
  subject: string;
  /** Nachrichteninhalt */
  message: string;
  /** Wann wurde die Nachricht generiert */
  creationDate: string;
  /** Ob die Nachricht gelesen wurde */
  unread: boolean;
}

export interface ClientModel {
  /** ID des Clients */
  id: number;
  /** Name des Clients */
  identifier: string;
}

export interface ApiProduct {
  /** ID des Produkts */
  id: number;
  /** ID der aktuellen Publikation */
  publicationTargetId: number;
  /** Veröffentlichungsdatum */
  publicationDate: string | null;
  /** Name des Formats, in dem das Produkt angelegt wurde */
  publicationTarget: 'PRINTBOOK' | 'EPUB';
  /** Beschreibung des Produkts */
  description: string;
  /** EMK des Cover-Bildes */
  cover: EMK | null;
  /** ID des aktuellen Status des Produkts */
  statusId: number | null;
  /** Verlag */
  publisher: string | null;
  /** Lektor */
  lectorTeam: string | null;
  /** Author */
  author: string | null;
  /** Datenpool */
  clientIdentifier: string;
  /** Produzent */
  producer: string | null;
  /** Satzdienstleister */
  typesettingProvider: string | null;
  /** ISBN */
  isbn: string | null;
  /** Annehmbare Aufgaben */
  claimableTasksCount: number | null;
  /** Angenommene Aufgaben */
  claimedTasksCount: number | null;
  /** Auflagen-Nummer */
  edition: number | null;
  /** Zusätzliche Metadaten. */
  additionalMetadata: Record<string, Record<string, string>>;
  teams: Record<string, ApiUser[]>;
}

/**
 * Der Root-Knoten des Produktbaums
 */
export interface ApiTreeNodeRoot {
  id: number;
  name: string;
  entityMetaKey: null;
  nodeType: null;
  sortOrder: null;
  treenodes: ApiTreeNode[] | null;
}

interface ApiDataResourceAuthor {
  id: number;
  connectionId: number;
  firstname: string;
  lastname: string;
  identifier: string;
  type: null; // ?
}

export interface ApiUser {
  id: number;
  firstname: string;
  lastname: string;
  identifier: string;
}

interface ApiDataResource {
  id: string;
  name: string;
  type: string;
  contentType: string;
  status: null; // ?
  modifiedBy: string;
  modifiedDate: string;
  correctionPdfPath: string | null;
  previewPath: string | null;
  webdavPath: string;
  locked: boolean;
  hasGrantedReleaseForCoAuthor: boolean;
  assetPrivileges: ApiAssetPrivileges;
  authors: ApiDataResourceAuthor[];
  drsIdentifier: string
}

export type ApiEmptyDataResource = {
  [key in keyof Omit<ApiDataResource, 'id'>]: null;
} & Pick<ApiDataResource, 'id'>;

/**
 * Ein Produktbaumknoten (nicht aber der Root-Knoten)
 */
export interface ApiTreeNode {
  id: number;
  name: string;
  level: null; // ?
  entityMetaKey: string;
  nodeType: 'ASSET' | 'NODE';
  pathorder: string;
  globalOrder: null; // ?
  sortOrder: number;
  dataResource: ApiEmptyDataResource | ApiDataResource | null;
  treenodes: ApiTreeNode[] | null;
  /** Flag, ob Mitautorenfreigabe erteilt wurde (Nur bei C.H.Beck) */
  hasGrantedReleaseForCoAuthor?: boolean;
}

/**
 * Der Root-Knoten der Dokumentstruktur
 */
export interface ApiDocumentStructureRoot {
  id: 'documentRoot';
  heading: null;
  tagvalue: null;
  content: string;
  children: ApiDocumentStructureElement[];
}

/**
 * Ein Dokumentstrukturknoten (nicht aber der Root-Knoten)
 */
export interface ApiDocumentStructureElement {
  id: string;
  heading: string;
  tagvalue: string;
  content: string;
  children: ApiDocumentStructureElement[];
}

export interface ApiJwtAuthenticationDetails {
  /** Id des Users */
  id: number;
  /** Benutzername */
  username: string;
  /** JWT des eingeloggten Nutzers */
  token: string;
}

export interface ApiGenericResponse {
  /** Nachrichteninhalt */
  message: string;
}

export interface ApiAssetPrivileges {
  /** Kann der Nutzer das Asset herunterladen? */
  canUserDownload: boolean;
  /** Kann der Nutzer das Asset bearbeiten? */
  canUserEdit: boolean;
  /**
   * Kann der Nutzer eine Vorschau des Assets anzeigen bzw. herunterladen?
   * @deprecated Stattdessen `canUserDownloadPreview` verwenden.
   */
  canUserPreview: boolean;
  /** Kann der Nutzer eine Vorschau des Assets anzeigen? */
  canUserViewPreview: boolean;
  /** Kann der Nutzer eine Vorschau des Assets herunterladen? */
  canUserDownloadPreview: boolean;
  /** Kann der Nutzer die Datei per Upload ersetzen? */
  canUserUpload: boolean;
  /** Kann der Nutzer die Datei für seine Mitautoren freigeben? */
  canUserGrantReleaseForCoAuthor: boolean;
  /** Kann der Nutzer das Korrektur Pdf anzeigen? */
  canUserViewCorrection: boolean;
}

interface ApiTaskDataResourceAssetAuthors {
  /** Die Id des Users (equivalent zur User Id im BO) */
  id: number;
  /** Identifier des Autors. */
  identifier: string;
  /** Der Nachname des Autors. */
  lastname: string;
  /** Der Type des Autors. */
  type?: string;
  /** Die ConnectionId des Autors. */
  connectionId: number;
}

export interface ApiTaskDataResourceAsset {
  /** EMK des Assets */
  id: EMK;
  /** The identifier of the dataresource */
  drsIdentifier: string;
  /** Dateiname des Assets */
  name: string;
  /** Name des Nutzers, der das Asset zuletzt geändert hat */
  modifiedBy: string | null;
  /** Datum, wann das Asset zuletzt geändert wurde */
  modifiedDate: string | null;
  /** Dateityp des Assets */
  contentType: string | null;
  /** Pfad zur Vorschaudatei */
  previewPath: string | null;
  /** Pfad zur Datei über WebDAV */
  webdavPath: string | null;
  /** Berechtigungen des aktuellen Nutzers */
  assetPrivileges: ApiAssetPrivileges;
  /** Typ der DataResource */
  type: 'asset';
  /** Liste aller Autoren eines Assets. */
  authors: ApiTaskDataResourceAssetAuthors[];
  /** Gibt an ob auf dem Asset ein Lock existiert. */
  locked: boolean;
  /** Pfad zum Korrektur Pdf. */
  correctionPdfPath: string | null;
  /** Custom ChangePdf, welches als Korrekturdaten Tab geladen wird. */
  cstFkChangePdf: number | null;
  /** Custom PreviewPdf, welches den normalen Preview Path überschreibt. */
  cstFkPreviewPdf: number | null;
}

/**
 * Typeguard function für TaskDataResourceAsset.
 *
 * @param value Das zu testende object
 */
export function instanceOfApiTaskDataResourceAsset (
  value: unknown,
): value is ApiTaskDataResourceAsset {
  if (typeof value !== 'object') return false
  return (value as { locked?: unknown }).locked !== undefined
}

export interface ApiTaskDataResourceSmashdocs {
  /** EMK des Smashdocs-Dokuments */
  id: EMK;
  /** The identifier of the dataresource */
  drsIdentifier: string;
  /** UUID des Dokuments */
  uuid: string;
  /** Dateiname des Assets */
  name: string;
  /** Basis-URL für den Smashdocs-Editor */
  editorUrl: string;
  /** Berechtigungen des aktuellen Nutzers */
  assetPrivileges: ApiAssetPrivileges;
  /** Pfad zur Vorschaudatei */
  previewPath: string | null;
  /** Typ des Tasks */
  type: 'smashdocs';
}

export interface ApiTaskDataResourceProduct {
  /** EMK des Produkts */
  id: EMK;
  /** The identifier of the dataresource */
  drsIdentifier: string;
  /** Name des Produkts */
  name: string;
  /** Typ der DataResource */
  type: 'product';
}

export interface ApiTaskDataResourceEditorDocument {
  /** EMK des Produkts */
  id: EMK;
  /** The identifier of the dataresource */
  drsIdentifier: string;
  /** Name des Produkts */
  name: string;
  /** Typ der DataResource */
  type: 'editordocument';
  /** Welcher Editor verwendet wird */
  editorType: 'fonto' | 'xeditor';
  /** Die URL zum Editor */
  editorUrl: string;
  /** Pfad zur Vorschaudatei */
  previewPath: string | null;
  /** Berechtigungen des aktuellen Nutzers */
  assetPrivileges: ApiAssetPrivileges;
}

export interface ApiComment {
  /** ID des Kommentars */
  id: string;
  /** Verfasser des Kommentars */
  userName: string;
  /** Erstellungsdatum des Kommentars */
  creationDate: string;
  /** Inhalt des Kommentars */
  fullMessage: string;
}

export interface ApiTaskFormFieldBase {
  id: string;
  label: string;
  constraints: Array<
    | { name: 'required'; config: 'true' | 'false' }
    | { name: 'readonly'; config: 'true' | 'false' }
    | { name: 'minlength'; config: string }
    | { name: 'maxlength'; config: string }
    | { name: 'min'; config: string }
    | { name: 'max'; config: string }
  >;
  properties: Array<{
    id: string;
    name: string;
    value: string;
  }>;
  currentValue: string | null;
  defaultValue: string | null;
}

export interface ApiTaskFormFieldBoolean extends ApiTaskFormFieldBase {
  type: 'boolean';
  currentValue: 'true' | 'false' | null;
  defaultValue: 'true' | 'false' | null;
}

export interface ApiTaskFormFieldLong extends ApiTaskFormFieldBase {
  type: 'long';
}

export interface ApiTaskFormFieldString extends ApiTaskFormFieldBase {
  type: 'string';
}

export interface ApiTaskFormFieldDate extends ApiTaskFormFieldBase {
  type: 'date';
}

export interface ApiTaskFormFieldEnumValue {
  id: string;
  value: string;
}

export interface ApiTaskFormFieldEnum extends ApiTaskFormFieldBase {
  type: 'enum';
  enumValues: ApiTaskFormFieldEnumValue[];
}

export type ApiTaskFormField =
  | ApiTaskFormFieldBoolean
  | ApiTaskFormFieldLong
  | ApiTaskFormFieldString
  | ApiTaskFormFieldDate
  | ApiTaskFormFieldEnum;

export type ApiTaskDataResource =
  | ApiTaskDataResourceAsset
  | ApiTaskDataResourceSmashdocs
  | ApiTaskDataResourceProduct
  | ApiTaskDataResourceEditorDocument;

export interface ApiTask extends SortableBy<'pathorder'> {
  /** ID der Aufgabe */
  id: string;
  /** Name der Aufgabe */
  name: string;
  /** Produktedition (identifier des pubtargettypes) */
  pubtargetIdentifier: string;
  /** Benutzername des Nutzers, dem diese Aufgabe zugeordnet ist */
  assignee: string | null;
  /** Datum, wann das Asset erstellt wurde */
  creationDate: string;
  /** Fälligkeitsdatum */
  dueDate: string;
  /** Das Asset der Aufgabe */
  dataResource: ApiTaskDataResource;
  expanded: boolean;
  /** Gewichtung des aktuellen Eintrags für die Sortierung */
  sortOrder: number;
  /** Die Sortierung des Parents */
  parentOrder: number;
  /** Das "Baumlevel" (In welcher Ebene des Baums ist das Asset zum Task) */
  level: number;
  /**
   * Der Weg zum Asset im Baum, anhand der Sortierung des Weges
   * z.B. 10-20-10-10 Task ist in der 4. Ebene und mit den Parents die im Pfad angegeben sind.
   */
  pathorder: string;
  /** Sortierung der Taskgruppe */
  groupSortOrder: number;
  /** Berechtigungen */
  taskPrivileges: {
    /** Kann der Nutzer die Aufgabe annehmen? */
    canUserClaim: boolean;
  };
  /**
   * Formularfelder, die vor dem Abschließen einer Aufgabe
   * angezeigt werden sollen
   */
  formFields: ApiTaskFormField[];
  /** Kommentare */
  comments: ApiComment[];
  /** Der aktuelle Status des einzelnen Tasks. */
  taskStatus?: RequestStatus;
  /** Gibt an ob für den Task gerade ein Discard am laufen ist (Discard Dialog öffnet sich). */
  discardPending?: boolean;
}

export interface ApiValuesData {
  /** Eine Liste aller Produktstatus */
  productStatus: ApiProductStatus[];
  /** Eine Liste aller Bildformate */
  imageFormats: ApiImageFormat[];
  /** Der Basis-Pfad für die API */
  apiurl: string;
  /** Die Basis für WebDAV-Pfade */
  webdavassetpath: string;
}

export interface ApiValuesStart {
  ssoProviders: Record<string, string>;
}

export interface ApiSmashdocsLink {
  /** ID des Smashdocs-Assets */
  id: string;
  /** Der temporäre Link zum Bearbeiten des Smashdocs-Dokuments */
  url: string;
}

export interface ApiPortalData {
  /** Die Versionsnummer des Portals */
  version: string;
  /** Die Build-Nr. des Portals */
  timestamp: string;
  /** Der aktuelle VCS-Branch */
  branch: string;
}
