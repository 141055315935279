import { EMK, EMKObject } from '../services/api/apiSchemas'

/**
 * Teilt einen EntityMetaKey-String in seine Bestandteile
 * `dataResourceId` und `contentId` auf.
 * Wenn kein gültiger EntityMetaKey übergeben wird,
 * liefert die Funktion `null` zurück.
 * Optional kann ein alternatives Trennzeichen übergeben werden.
 */
export function splitEMK (
  emk: string,
  { separator = '|' } = {}
): EMKObject | null {
  const emkParts = emk.split(separator)
  if (
    emkParts.length !== 2 ||
    emkParts.includes('') ||
    emkParts.some((part) => part.match(/\d+/) === null)
  ) {
    return null
  }
  const [dataResourceId, contentId] = emkParts
  return { dataResourceId, contentId }
}

/**
 * Fügt die Bestandteile `dataResourceId` und `contentId`
 * zu einem vollständigen EntitymetaKey zusammen.
 * Optional kann ein alternatives Trennzeichen übergeben werden.
 */
export function joinEMK (
  dataResourceId: string,
  contentId: string,
  { separator = '|' } = {}
): string | null {
  if (dataResourceId === '' || contentId === '') {
    return null
  }
  return `${dataResourceId}${separator}${contentId}`
}

interface TransformEMKOptions {
  inputSeparator: string
  outputSeparator: string
}

export function emkToSfid (emk: string): string | null {
  const id = transformEMK(emk, { inputSeparator: '|', outputSeparator: '-' })
  if (id === null) return null
  return `sf${id}`
}

export function transformEMK (
  emk: EMK,
  { inputSeparator, outputSeparator }: TransformEMKOptions
): EMK | null {
  const emkObject = splitEMK(emk, { separator: inputSeparator })
  if (emkObject === null) {
    return null
  }

  return joinEMK(emkObject.dataResourceId, emkObject.contentId, {
    separator: outputSeparator
  })
}

export interface DatetimeObject {
  date: string
  time: string
}

export function splitDatetimeString (datetime: string): DatetimeObject | null {
  const datetimeParts = datetime.split(' ')
  if (datetimeParts.length !== 2) {
    return null
  }
  const [date, time] = datetimeParts
  if (date.length === 0 || time.length === 0) {
    return null
  }
  return { date, time }
}
