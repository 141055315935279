import { ApiExternalMetadata } from 'sfportal_services_api/apiSchemas'
import { IProductDetailViewUtilsDetail } from '../../interfaces/IProductDetailViewUtils.Detail'
import { FileTaskMetadataDescriptor } from '../../tasks/detail/TaskMetadataDescriptors'
import { TFunction } from 'i18next'

/**
 * Komponente für die Erstellung der Metadaten
 */
function getFileMetadataDescriptors (t: TFunction): FileTaskMetadataDescriptor[] {
  const metadataDescriptors: FileTaskMetadataDescriptor[] = [
    {
      label: t('metadata.properties.identifier'),
      getValue: (item) => item.identifier
    },
    {
      label: t('metadata.properties.description'),
      getValue: (item) => item.description
    },
    {
      label: t('metadata.properties.createdBy'),
      getValue: (item: ApiExternalMetadata) => item.createdBy
    },
    {
      label: t('metadata.properties.createdDate'),
      getValue: (item: ApiExternalMetadata) => item.createdDate
    },
    {
      label: t('metadata.properties.lastModifiedBy'),
      getValue: (item: ApiExternalMetadata) => item.modifiedBy
    },
    {
      label: t('metadata.properties.lastModifiedDate'),
      getValue: (item: ApiExternalMetadata) => item.modifiedDate
    }
  ]
  return metadataDescriptors
}

/**
 * Gibt die Implementierungen des Interfaces an
 */
export const ProductDetailViewUtilsDetail: IProductDetailViewUtilsDetail = {
  getFileMetadataDescriptors
}
