import React, { FC, useEffect, useState } from 'react'
import { sendRequest } from '../../services/api/superagent'
import './AppHeaderEnvironmentInfo.scss'
import { useTranslation } from 'react-i18next'
import { useUserStore } from '../../stores/userStore'

type Settings = {
  color: string | undefined,
  backgroundColor: string | undefined,
  text: string | undefined
}

/**
 * component for the environment info
 *
 * @returns {FC} the environment info
 */
export const AppHeaderEnvironmentInfo: FC = () => {
  const {currentUser} = useUserStore()

  const { t } = useTranslation()

  const [info, setInfo] = useState<Settings>({
    color: undefined,
    backgroundColor: undefined,
    text: undefined
  })

  useEffect(() => {
    //if no user available return
    if (currentUser === null){
      return
    }

    sendRequest(
      'post',
      '/environmentsettings/uisettings',
      { body: ['headertitel', 'headertitelcolor', 'headertitelbgcolor'] })
      .then(response => {
        const result:{ headertitel?: string, headertitelcolor?: string, headertitelbgcolor?: string } = response.body
        const text = result.headertitel
        const bg = result.headertitelbgcolor ?? '#E5391D'
        setInfo({
          color: result.headertitelcolor ?? '#FFFFFF',
          backgroundColor: bg !== '#E5391D' || text !== undefined ? bg : undefined,
          text: t(text ?? '')
        })
      })
  }, [currentUser])

  return <div className={'environment-info'} style={{ backgroundColor: info.backgroundColor }}>
    {info.text
      ? <span title={info.text} className={'env-info'} style={{color: info.color}}>{info.text}</span>
      : null}
  </div>
}
