import { RefObject, useCallback, useEffect } from 'react'

export interface Params {
  /**
   * Eine Funktion, die ausgeführt wird, wenn man die Maustaste nach unten
   * drückt.
   */
  handler: (event: MouseEvent) => void
  /** Die Optionen für das Event. */
  eventOptions?: boolean | AddEventListenerOptions
  /**
   * Ein `RefObject` zu einem Element, innerhalb dem das Event **nicht**
   * ausgelöst werden soll.
   */
  excludeRef?: RefObject<HTMLElement>
}

/**
 * Registriert ein `mousedown`-Event im globalen Scope.
 *
 * `excludeRef` kann verwendet werden, falls der Handler nicht ausgeführt werden
 * soll, wenn innerhalb dieses Elements geklickt wird.
 * Bspw. wenn das Event nur getriggert werden soll, wenn man **außerhalb**
 * eines Menüs oder Dialogs klickt.
 */
export function useGlobalMouseDown ({ handler, excludeRef }: Params): void {
  const handleGlobalMouseDown = useCallback(
    (event: MouseEvent): void => {
      if (excludeRef === undefined) {
        handler(event)
        return
      }

      const excludeElement = excludeRef.current
      if (excludeElement === null) return

      const clickedInsideElement =
        excludeElement.contains(event.target as Node) ||
        excludeElement === event.target
      if (clickedInsideElement) return

      handler(event)
    },
    [handler, excludeRef]
  )

  useEffect(() => {
    window.addEventListener('mousedown', handleGlobalMouseDown)

    return () => {
      window.removeEventListener('mousedown', handleGlobalMouseDown)
    }
  }, [handleGlobalMouseDown])
}
