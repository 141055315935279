import { useCallback, useEffect, useMemo } from 'react'

export type FileInputChangeListener =
  (this: HTMLInputElement, event: Event) => unknown

interface UseFileInputParams {
  listener: FileInputChangeListener
  multiple?: boolean
}

interface UseFileInputReturn { handleFileUploadClick: () => void }

type UseFileInput = (params: UseFileInputParams) => UseFileInputReturn

export const useFileInput: UseFileInput = ({ listener, multiple = false }) => {
  const inputElement = useMemo(() => {
    const element = globalThis.document.createElement('input')
    element.setAttribute('type', 'file')
    if (multiple) { element.setAttribute('multiple', 'multiple') }
    return element
  }, [multiple])

  const handleFileUploadClick =
    useCallback(() => { inputElement.click() }, [inputElement])

  useEffect(() => {
    inputElement.addEventListener('change', listener)
    return () => { inputElement.removeEventListener('change', listener) }
  }, [inputElement, listener])

  return { handleFileUploadClick }
}
