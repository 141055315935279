import React, { ButtonHTMLAttributes, DOMAttributes, memo, MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import { ChildrenProp } from '../../jsx'
import { Alignment2D } from '../../utils/alignment'
import classNames from 'classnames'
import { Menu } from '../Menu/Menu'
import { Button } from './Button'
import { ButtonType } from './ButtonType'

interface Props extends
  ChildrenProp,
  DOMAttributes<HTMLButtonElement>,
  ButtonHTMLAttributes<HTMLButtonElement> {
  buttonLabel?: string
  buttonIcon?: string
  buttonStyle?: ButtonType
}

export const ButtonDropdown = memo(function ButtonDropdown ({
  children, buttonIcon, buttonLabel, buttonStyle, className,
  ...buttonProps
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const buttonDropdownClasses = useMemo(
    () => classNames(
      'button-dropdown',
      { 'button-dropdown--is-open': isOpen }
    ),
    [isOpen]
  )

  const handleButtonMouseDown = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (event.button !== 0) { return }
      event.stopPropagation()
      setIsOpen(!isOpen)
    },
    [isOpen]
  )

  return (
    <div className={buttonDropdownClasses}>
      <div className="button-dropdown__button" ref={buttonRef}>
        <Button
          className="button-dropdown__button"
          buttonStyle={buttonStyle}
          icon={buttonIcon}
          children={buttonLabel}
          onMouseDown={handleButtonMouseDown}
          {...buttonProps}
        />
      </div>

      <div className="button-dropdown__menu">
        <Menu
          open={isOpen}
          targetElement={buttonRef.current}
          targetAlignment={Alignment2D.RIGHT_BOTTOM}
          menuAlignment={Alignment2D.RIGHT_TOP}
          onOpenChange={open => setIsOpen(open)}
        >
          {children}
        </Menu>
      </div>
    </div>
  )
})
