import React, { memo } from 'react'
import { getPath } from '../../routes'
import './AppHeaderLogo.scss'
import { useTranslation } from 'react-i18next'

export const AppHeaderLogo = memo(function AppHeaderLogo () {
  const { t } = useTranslation()

  return (
    <div className="app-header-logo">
      <img
        src={getPath(process.env.REACT_APP_LOGO_PATH ?? '/assets/images/sitefusion-logo.svg').absolute}
        alt="SiteFusion"
        className="app-header-logo__image"
      />
      <div className="app-header-logo__text">{t('headerlogo.text')}</div>
    </div>
  )
})
