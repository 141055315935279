import { createReactHook, createStore } from '@alinnert/tstate'
import { ApiValuesStart } from '../services/api/apiSchemas'
import { RequestStatus } from '../services/api/generic/types'
import { apiGetValuesStart } from '../services/api/valuesstartApiService'
import { setStorageValue } from '../services/localStorageService'
import { handleRequestError } from './allStores'

/**
 * @file Dieser Store enthält die Daten vom Portal, die über den API-Aufruf
 * `GET /valuesstart` geladen werden.
 */

// #region store
interface ValuesStartStore {
  data: ApiValuesStart | null
  status: RequestStatus
}

function getInitialState (): ValuesStartStore {
  return { data: null, status: RequestStatus.ok }
}

const store = createStore(getInitialState())
export const useValuesStartStore = createReactHook(store)

const mutations = {
  startLoading (): void {
    store.set({ status: RequestStatus.pending })
  },

  setValuesStart (data: ValuesStartStore['data']): void {
    if (data !== null) {
      setStorageValue('values-start:data', data)
    }
    store.set({ data, status: RequestStatus.ok })
  },

  setStatus (status: RequestStatus): void {
    store.set({ status })
  }
}
// #endregion store

// #region actions
export async function loadValuesStart (): Promise<void> {
  mutations.startLoading()

  try {
    const { body } = await apiGetValuesStart()
    mutations.setValuesStart(body)
  } catch (error) {
    mutations.setStatus(handleRequestError(error))
  }
}
// #endregion actions
