import { mdiChevronRight } from '@mdi/js'
import React, { memo, useCallback, useContext, useMemo } from 'react'
import { useAlert } from 'react-alert'
import { MenuItemUtils } from 'sfportal_components_menu_utils_menuitem/MenuItemUtils'
import { ChildrenProp } from '../../jsx'
import { apiPutCustomProperty } from '../../services/api/officefilesApiService'
import { useProductDetailStore } from '../../stores/productDetailStore'
import classNames from 'classnames'
import { MdiIcon } from '../Generic/MdiIcon'
import { MenuContext } from './MenuContext'
import './MenuItem.scss'

interface Props extends ChildrenProp {
  icon?: string
  label?: string
  /** Ist MenuItem klickbar? */
  enabled?: boolean
  /** MenuItem hervorheben? Z. B. für ausgewählten oder aktiven Eintrag */
  highlight?: boolean
  /**
   * Aktion, die bei Klick ausgeführt werden soll.
   * Wenn einen `function` übergeben wird, wird diese ausgeführt.
   * Wenn ein `string` übergeben wird, wird ein Link erzeugt mit dem
   * Inhalt des Strings als Wert für das `href`-Attribut.
   */
  action: (() => void) | string
}

export const MenuItem = memo(function MenuTextEntry ({
  children,
  icon,
  label,
  enabled = true,
  highlight = false,
  action
}: Props) {
  const menuContext = useContext(MenuContext)

  // Alert Manager um Alerts ins System zu pushen.
  const alert = useAlert()

  const { product } = useProductDetailStore()

  const menuItemClasses = useMemo(
    () => classNames(
      'menu-item',
      { 'menu-item--is-disabled': !enabled },
      { 'menu-item--highlight': highlight }
    ),
    [enabled, highlight]
  )

  const handleMouseUp = useCallback(
    (action: () => void) => {
      if (!enabled) { return }
      menuContext.closeMenu()
      action()
    },
    [enabled, menuContext]
  )

  const getIcon = useCallback(
    () => {
      if (highlight) {
        return <MdiIcon className="menu-item__icon" path={mdiChevronRight} />
      }
      if (icon !== undefined) {
        return <MdiIcon className="menu-item__icon" path={icon} />
      }
      return <div className="menu-item__dummy-icon" />
    },
    [highlight, icon]
  )

  const menuItemContent = useMemo(
    () => <>
      <div className="menu-item__icon-container">{getIcon()}</div>

      {label !== undefined ? (
        <div className="menu-item__label">{label}</div>
      ) : null}

      <div className = "menu-item__children-container">
        { children }
      </div>
    </>,
    [children, getIcon, label]
  )

  async function handleMouseUpString (): Promise<void> {
    if (typeof action === 'function') return
    const urlSplit = action.split('/')
    if (product !== null) {
      await apiPutCustomProperty(
        urlSplit[urlSplit.length - 1],
        JSON.stringify({ productIdTemp: product.id })
      )
      try {
        await MenuItemUtils.handleMouseUpString(action, alert)
      } catch (error) {
        console.error('document was locked')
      }
      menuContext.closeMenu()
    }
  }

  if (typeof action === 'string') {
    return (
      <div
        className={menuItemClasses}
        onMouseUp={handleMouseUpString}
        children={menuItemContent}
      />
    )
  }

  return (
    <div
      className={menuItemClasses}
      onMouseUp={() => handleMouseUp(action)}
      children={menuItemContent}
    />
  )
})
