import { useMemo } from 'react'
import { ApiTask } from 'sfportal_services_api/apiSchemas'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { useProductTreeStore } from 'sfportal_stores/productTreeStore'
import { sortedIndex, sortWithGetter } from 'sfportal_utils/array'
import { emkToSfid } from 'sfportal_utils/system'
import { sortTasksFlatTree } from '../../../common/tasks/sortTasksFlatTree'

interface UseTasksDataResult {
  assetTasks: ApiTask[]
  groupedTasks: Record<ApiTask['name'], ApiTask[]>
  tasksStatus: RequestStatus
}

export function useTasksData (): UseTasksDataResult {
  const { tasks, tasksStatus } = useProductDetailStore()
  const { selectedItem, nodes } = useProductTreeStore()

  const assetTasks = useMemo<ApiTask[]>(() => {
    if (selectedItem === null) return []

    const selectedNode = nodes[selectedItem]
    const sfId = emkToSfid(selectedNode.item.entityMetaKey)

    console.log(tasks)

    const filteredTasks = Object.values(tasks).filter((task) => {
      if (sfId === null) {
        return false
      }
      console.log(task.dataResource.id, sfId)
      return task.dataResource.id === sfId
    })

    console.log(filteredTasks)

    return filteredTasks
  }, [nodes, selectedItem, tasks])

  const groupedTasks = useMemo<Record<ApiTask['name'], ApiTask[]>>(() => {
    const taskGroupsObject: Record<ApiTask['name'], ApiTask[]> = {}

    for (const task of assetTasks) {
      if (taskGroupsObject[task.name] === undefined) {
        taskGroupsObject[task.name] = []
      }

      taskGroupsObject[task.name].splice(
        sortedIndex(taskGroupsObject[task.name], task, sortTasksFlatTree),
        0,
        task
      )
    }

    const taskGroups = Object.entries(taskGroupsObject)
    taskGroups.sort(sortWithGetter(([, tasks]) => tasks[0].groupSortOrder))

    return Object.fromEntries(taskGroups)
  }, [assetTasks])

  return { assetTasks, groupedTasks, tasksStatus }
}
