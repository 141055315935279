import { agent, ProgressEvent, Response, ResponseError } from 'superagent'
import { AjaxError, NetworkError } from '../generic/error'
import { FormBody, ProgressCallback, QueryParams, RequestBody } from '../generic/types'
import { getApiUrl } from '../generic/url'
import { contextError, currentContext } from './context'
import { setFormBody, setHeaders, setQueryParams } from './functions'
import { logout } from '../../../stores/userStore'

type HTTPMethod = 'get' | 'post' | 'patch' | 'put' | 'delete'

interface SendRequestOptions {
  query?: QueryParams
  headers?: Headers
  body?: RequestBody
}

const jsonApi = agent()
  .set('X-Requested-With', 'XMLHttpRequest')
  .set('Content-Type', 'application/json')

export async function sendRequest (
  method: HTTPMethod,
  uri: string,
  options: SendRequestOptions = {}
): Promise<Response> {
  const { query, headers, body } = options
  const context = currentContext
  let request = jsonApi[method](getApiUrl(uri))

  if (query !== undefined) { request = setQueryParams(request, query).request }
  if (headers !== undefined) { request = setHeaders(request, headers).request }
  if (body !== undefined) { request = request.send(body) }

  try {
    const response = await request
    if (currentContext !== context) { throw contextError }
    return response
  } catch (err) {
    const responseError = err as ResponseError

    if (responseError.status === 401) {
      // unauthorized wird ausgeloggt und direkt auf die Login Seite redirected.
      logout()
    }

    if (
      responseError.status === undefined ||
      responseError.response === undefined
    ) { throw new NetworkError() }

    throw new AjaxError({
      status: responseError.status,
      body: responseError.response.body
    })
  }
}

interface SendFormOptions {
  query?: QueryParams
  headers?: Headers
  body: FormBody
  progressCallback?: (event: ProgressEvent) => void
  onProgress?: ProgressCallback
}

type SendForm = (uri: string, options: SendFormOptions) => Promise<Response>

const baseApi = agent()
  .set('X-Requested-With', 'XMLHttpRequest')

export const sendForm: SendForm = async (uri, options) => {
  const { query, headers, body, progressCallback } = options
  const context = currentContext
  let request = baseApi.post(getApiUrl(uri))

  request = setFormBody(request, body).request

  if (query !== undefined) { request = setQueryParams(request, query).request }
  if (headers !== undefined) { request = setHeaders(request, headers).request }

  if (progressCallback !== undefined) {
    request = request.on('progress', progressCallback)
  }

  try {
    const response = await request
    if (currentContext !== context) { throw contextError }
    return response
  } catch (err) {
    const responseError = err as ResponseError

    if (responseError.status === 401) {
      // unauthorized wird ausgeloggt und direkt auf die Login Seite redirected.
      logout()
    }

    if (
      responseError.status === undefined ||
      responseError.response === undefined
    ) { throw new NetworkError() }

    throw new AjaxError({
      status: responseError.status,
      body: responseError.response.body
    })
  }
}
