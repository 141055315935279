export type QueryParams = Record<string, string | string[] | undefined>
export type Headers = Record<string, string | undefined>
export type RequestBody =
  | string
  | FormData
  | Record<string, unknown>
  | unknown[]
export type FormBody = Record<string, string | boolean | File | undefined>

export interface ProgressInfo {
  progress: number
  total: number
  loaded: number
}

export type ProgressCallback = (progressInfo: ProgressInfo) => void

export enum RequestStatus {
  pending = 0,
  unknownError = 1,
  networkError = 2,
  ok = 200,
  passwordChangedUserIsLocked = 227,
  passwordCanBeChangedUserIsLocked = 228,
  unknownRequestError = -400,
  badRequest = 400,
  unauthorizedError = 401,
  forbiddenError = 403,
  notFoundError = 404,
  userIsLocked = 440,
  serverError = 500,
}
