import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import { ApiComment } from '../../services/api/apiSchemas'
import { EmptyIndicator } from '../Generic/EmptyIndicator'
import { Comment } from './Comment'
import './CommentList.scss'
import { useTranslation } from 'react-i18next'

interface Props extends ChildrenProp {
  items: ApiComment[]
}

export const CommentList = memo(function CommentList ({
  items
}: Props) {
  const { t } = useTranslation()

  return (
    <div className="comment-list">
      <div className="comment-list__items">
        {items.map(comment => (
          <Comment key={comment.id} comment={comment} />
        ))}

        {items.length === 0 ? (
          <EmptyIndicator text={t('itemViews.commentList.empty')} compact={true} />
        ) : null}
      </div>
    </div>
  )
})
