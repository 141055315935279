import classNames from 'classnames'
import React, { FormEvent, forwardRef, InputHTMLAttributes, Ref, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useAutoFocus } from '../../hooks/useAutoFocus'
import './Input.scss'

export type FormInputType =
  | 'hidden'
  | 'text'
  | 'search'
  | 'password'
  | 'url'
  | 'email'
  | 'tel'
  | 'number'
  | 'range'
  | 'week'
  | 'month'
  | 'date'
  | 'datetime'
  | 'datetime-local'
  | 'file'
  | 'static'
  | 'select'
  | 'textarea'
  | 'radio'

export interface ExternalInputRef {
  changeInputValue: (value: string) => void
}

interface Props {
  name: string
  id?: string
  label?: string
  type?: FormInputType
  value?: string
  placeholder?: string
  autoComplete?: string
  autoFocus?: boolean
  required?: boolean
  disabled?: boolean
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  onInput?: (value: string) => void
  onInputEvent?: (event: FormEvent<HTMLInputElement>) => void
  direction?: 'row' | 'column'
  checked?: boolean
}

export const Input = forwardRef(({
  name,
  autoComplete,
  placeholder,
  disabled,
  inputProps,
  required = false,
  label = '',
  id = name,
  autoFocus = false,
  value = '',
  type = 'text',
  onInput,
  onInputEvent,
  direction = 'column',
  checked
}: Props, ref: Ref<ExternalInputRef>) => {
  const localRef = useRef<HTMLInputElement>(null)

  useAutoFocus({
    ref: localRef,
    enabled: autoFocus
  })

  const [valueState, setValueState] = useState<string>(value)

  useImperativeHandle(ref, () => ({
    changeInputValue: (newValue: string) => { setValueState(newValue) }
  }))

  const handleInput = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setValueState(event.currentTarget.value)
      onInput?.(event.currentTarget.value)
      onInputEvent?.(event)
    },
    [onInput, onInputEvent]
  )

  const classNamesInput = classNames(['input', `input--${direction}`])

  const classNameInputInput = classNames(['input__input'], {
    'input__input--file': type === 'file',
    'input__input--radio': type === 'radio'
  })

  return (
    <div className={classNamesInput}>
      {label !== '' ? (
        <label className="input__label" htmlFor={id}>
          {label}
        </label>
      ) : null}

      <input
        className={classNameInputInput}
        ref={localRef}
        type={type}
        required={required}
        value={valueState}
        placeholder={placeholder}
        checked={checked}
        id={id}
        name={name}
        autoComplete={autoComplete}
        disabled={disabled}
        {...inputProps}
        onChange={handleInput}
      />
    </div>
  )
})
