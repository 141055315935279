import { Response } from 'superagent'
import { sendRequest } from './superagent'
import { ApiProduct } from './apiSchemas'

interface FetchProductsParams {
  filter?: string
  sort?: string
}

export async function fetchProducts ({
  filter,
  sort
}: FetchProductsParams): Promise<Response> {
  const query = { filter, sort }
  return await sendRequest('get', 'products', { query })
}

interface FetchProductDetailParams {
  productId: ApiProduct['id']
}

export async function fetchProductDetail ({
  productId
}: FetchProductDetailParams): Promise<Response> {
  return await sendRequest('get', `products/${productId}`)
}

export async function fetchProductTree (
  productId: ApiProduct['id'],
  context: () => unknown
): Promise<Response | null> {
  const startingContext = context()
  const result = await sendRequest('get', `products/${productId}/tree`)
  return startingContext === context() ? result : null
}

export async function fetchProductTreeNode (
  productId: ApiProduct['id'],
  treenodeId: string
): Promise<Response> {
  return await sendRequest('get', `products/${productId}/node/${treenodeId}`)
}

export async function getTreeNodeDataresource (
    productId: ApiProduct['id'],
    entityMetakey: string
): Promise<Response> {
  const filter = { query: {entityMetakey, productId: productId.toString()} }
  return await sendRequest('get', `/products/treenode/dataresource`, filter)
}
