import React, { memo } from 'react'
import './LoadingAnimation.scss'

export const LoadingAnimation = memo(function LoadingAnimation () {
  return (
    <div className="loading-animation">
      <div className="loading-animation__track">
        <div className="loading-animation__indicator"/>
      </div>
    </div>
  )
})
