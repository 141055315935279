import React, { memo, useCallback } from 'react'
import { StatusData } from '../../data/statusData'
import { strListIncludes, strListToggle } from '../../utils/string-list'
import { Checkbox } from '../Forms/Checkbox'
import { FilterItem } from './FilterItem'

interface Props {
  label: string
  items: StatusData[]
  value: string
  onChange: (value: string) => void
}

export const SelectFilterField = memo(function SelectFilterField ({
  label, items, value, onChange
}: Props) {
  const handleItemCheck = useCallback(
    (toggledValue: string, checked: boolean) => {
      const newValue = strListToggle(value, toggledValue)
      onChange(newValue)
    },
    [onChange, value]
  )

  const handleEnabledChange = useCallback(
    (value: boolean) => { onChange('') },
    [onChange]
  )

  return (
    <FilterItem
      type="select-list"
      title={label}
      initiallyEnabled={value !== ''}
      onEnabledChange={handleEnabledChange}
      renderItem={Wrapper => <>
        {items.map(item => (
          <Wrapper key={item.id}>
            <Checkbox
              checked={strListIncludes(value, item.label)}
              onChange={checked => handleItemCheck(item.label, checked)}
            >
              {item.label}
            </Checkbox>
          </Wrapper>
        ))}
      </>}
    />
  )
})
