import { mdiSort } from '@mdi/js'
import React, { ButtonHTMLAttributes, DOMAttributes, memo, MouseEvent, useMemo, useRef, useState } from 'react'
import { ChildrenProp } from '../../jsx'
import { Alignment2D } from '../../utils/alignment'
import classNames from 'classnames'
import { Menu } from '../Menu/Menu'
import { ToolbarButton } from './ToolbarButton'
import './ToolbarDropdown.scss'

interface Props extends
  ChildrenProp,
  DOMAttributes<HTMLButtonElement>,
  ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
}

export const ToolbarDropdown = memo(function ToolbarDropdown ({
  children, label, ...rest
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const toolbarDropdownClasses = useMemo(
    () => classNames(
      'toolbar-dropdown',
      { 'toolbar-dropdown--is-open': isOpen },
      { 'toolbar-dropdown--align-right': false }
    ),
    [isOpen]
  )

  function handleButtonMouseDown (event: MouseEvent<HTMLButtonElement>): void {
    if (event.button !== 0) return
    event.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <div className={toolbarDropdownClasses}>
      <div className="toolbar-dropdown__button" ref={buttonRef}>
        <ToolbarButton
          icon={mdiSort}
          label={label}
          onMouseDown={handleButtonMouseDown}
          {...rest}
        />
      </div>

      <Menu
        open={isOpen}
        targetElement={buttonRef.current}
        targetAlignment={Alignment2D.LEFT_BOTTOM}
        menuAlignment={Alignment2D.LEFT_TOP}
        onOpenChange={value => setIsOpen(value)}
      >
        {children}
      </Menu>
    </div>
  )
})
