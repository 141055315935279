import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Subviews } from 'sfportal_components_generic/Subviews'
import { apiGetDataresourceByIdentifier } from 'sfportal_services_api/dataresourceApiService'
import { setCurrentItem, toggleNodeOpenState } from 'sfportal_stores/productTreeStore'
import { noop } from 'sfportal_utils/function'
import { useIsMounted } from 'sfportal_utils/useIsMounted'
import { useGetProjectProductTreeListSubviews } from 'sfportal_views_productdetail_producttree_list/useGetProjectProductTreeListSubviews'
import { FlatTreeNode, Tree } from '../../../../components/Tree/Tree'
import { ChildrenProp } from '../../../../jsx'
import { useProductDetailStore } from '../../../../stores/productDetailStore'
import { ProductTreeSubviewsContext } from './useProductTreeContext'
import { useProductTreeData } from './useProductTreeData'
import { SearchSwitch } from '../detail/SearchSwitch'
import { ApiTreeNode } from '../../../../services/api/apiSchemas'
import { VirtuosoHandle } from 'react-virtuoso'
import { highlighting } from '../../../../utils/highlightingHelper'

type Props = ChildrenProp

export const ProductTree: FC<Props> = () => {
  const isMounted = useIsMounted()

  const { currentListSubview: currentSubview } = useContext(
    ProductTreeSubviewsContext
  )
  const { items, openNodes, itemActions, selectedItem } = useProductTreeData()
  const getProjectProductTreeListSubviews =
    useGetProjectProductTreeListSubviews()
  const { search } = useProductDetailStore()

  const [
    smtreenodeContentsDataresourceId,
    setSmtreenodeContentsDataresourceId
  ] = useState<number | null>(null)

  const [currentFindingIndex, setCurrentFindingIndex] = useState<number>(0)
  const [currentHighlightedNode, setCurrentHighlightedNode] = useState<number>(0)
  const [manuallySelected, setManuallySelected] = useState<boolean>(false)
  const ref = useRef<VirtuosoHandle | null>(null)

  /**
   * function for checking the node recursively if the node or a children contains a search results
   *
   * @param {FlatTreeNode<unknown>} item the node
   * @param {string} metakey optional, specific metakey to search
   * @returns {boolean} if the node contains a child that equals a search result
   */
  const checkNode = useCallback((item: FlatTreeNode<unknown>, metakey?: string): boolean=>{
    const node = item as FlatTreeNode<ApiTreeNode>
    let foundFinding = false

    const check = (internItem:ApiTreeNode)=>{
      if (search?.results?.find(singleRes => `${singleRes.drid}|${singleRes.id}` === (metakey ?? internItem.entityMetaKey))){
        foundFinding = true
        return false
      }
      if (internItem?.nodeType === 'NODE' && internItem?.treenodes !== null){
        if (internItem.treenodes.every(check)){
          foundFinding = true
          return false
        }
      }
      return true
    }

    node.item.treenodes?.every(single=>{
      return check(single);
    })
    return foundFinding
  },[search])

  useEffect(() => {
    if (search?.results === undefined || search.results.length === 0 || manuallySelected){
      return
    }

    const entry = search.results[currentFindingIndex]
    const metakey = `${entry.drid}|${entry?.id}`
    const indexToSelect = items.findIndex(single=> single.item.entityMetaKey === metakey)
    // return if index equals -1, the node is not expanded now (the node expands itself if a child is in the search results)
    if (indexToSelect === -1) {
      return
    }

    ref.current?.scrollToIndex(indexToSelect)
    setCurrentHighlightedNode(indexToSelect)
    //Set Current Item if it's not a node
    if (!items[indexToSelect].hasChildren) {
        setCurrentItem(items[indexToSelect].id)
      return
    }

    setCurrentItem(null)
  }, [currentFindingIndex, items, manuallySelected, search])

  useEffect(() => {
    apiGetDataresourceByIdentifier('smtreenodecontents').then(result => {
      if (!isMounted()) return
      setSmtreenodeContentsDataresourceId(result.body.id)
    }).catch(noop)
  }, [isMounted])

  useEffect(() => {
    if (selectedItem === null) return
    const selectedTreeNode = items.find(item => item.id === selectedItem)
    if (selectedTreeNode === undefined) return
    if (selectedTreeNode.disabled === true) {
      setCurrentItem(null).catch(noop)
    }
  }, [items, selectedItem])

  async function handleItemClick (index: number): Promise<void> {
    if (smtreenodeContentsDataresourceId === null) return
    const node = items[index]
    if (
      node.item.entityMetaKey.startsWith(
        smtreenodeContentsDataresourceId.toString()
      )
    ) {
      return
    }
    if (!isMounted()) return
    setManuallySelected(true)
    setCurrentItem(node.id).catch(noop)
  }

  function handleItemToggle (index: number, isOpen: boolean): void {
    toggleNodeOpenState(Object.values(items)[index].id, isOpen)
  }

  /**
   * function for returning the label
   *
   * @param {FlatTreeNode<unknown>} listNode the node
   * @param {boolean} withHighlighting boolean, for highlighting if a finding exists
   * @param {boolean} alternateColor boolean, if the findings should be colorized in another color
   * @returns {string} the label to use
   */
  const renderLabel = (listNode: FlatTreeNode<unknown>, withHighlighting?: boolean, alternateColor?:boolean): string=>{
    const node = listNode as FlatTreeNode<ApiTreeNode>
    if (search !== null && withHighlighting){
      const searchFind = search.results?.find(single=>`${single.drid}|${single.id}`  === node.item.entityMetaKey)
      if (searchFind){
        return highlighting(search.searchTerms, node.item.name, true, alternateColor).text
      }
    }
    return node.item.name
  }

  /**
   * Switches the current finding index and sets the manually updated
   */
  const switchResults = useCallback(index =>{
    setManuallySelected(false)
    setCurrentFindingIndex(index)
  },[])

  return (
    <div className="product-tree">
      <Subviews
        views={getProjectProductTreeListSubviews()}
        current={currentSubview}
      />
      {search !== null
        ? <SearchSwitch totalCount={search.results?.length ?? 0} currentFindingIndex={currentFindingIndex} switchResultCallback={switchResults}/>
        : null}
      {currentSubview === null ? (
        <Tree
          ref={ref}
          items={items}
          openNodes={openNodes}
          renderLabel={renderLabel}
          isItemClickable={(index) =>
            smtreenodeContentsDataresourceId !== null &&
            !items[index].item.entityMetaKey.startsWith(
              smtreenodeContentsDataresourceId.toString()
            )
          }
          onItemClick={handleItemClick}
          onItemToggle={handleItemToggle}
          itemActions={itemActions}
          selectedItemId={selectedItem}
          searchActive={search !== null && search.results?.length !== 0}
          currentHighlightedNode={currentHighlightedNode}
          checkNodeCallback={checkNode}
        />
      ) : null}
    </div>
  )
}
