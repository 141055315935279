import { useMemo } from 'react'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { useShowPreview } from './useShowPreview'
import { useDetailPaths } from './useDetailPaths'

type DetailTabs = 'preview' | 'metadata' | 'correctionPdf'

interface UseTabsResult {
  availableTabs: DetailTabs[]
}

interface UseFilePreviewTabsProps {
  showCorrectionPdfTab: boolean
}

export function useFilePreviewTabs ({
  showCorrectionPdfTab
}: UseFilePreviewTabsProps): UseTabsResult {
  const { currentFile } = useProductDetailStore()
  const { filePreviewPath } = useDetailPaths()
  const showPreview = useShowPreview()

  const availableTabs = useMemo(() => {
    const tabs: DetailTabs[] = []

    if (showPreview && currentFile !== null) {
      if (filePreviewPath !== null) {
        tabs.push('preview')
      }

      if (process.env.REACT_APP_ENABLE_PRODUCT_DETAIL_METADATA !== 'false') {
        tabs.push('metadata')
      }

      const forceCorrectionPdf =
        process.env.REACT_APP_FORCE_CORRECTION_PDF === 'true'
      const enableCorrectionPdf =
        process.env.REACT_APP_ENABLE_CORRECTION_PDF === 'true'

      if (forceCorrectionPdf || (enableCorrectionPdf && showCorrectionPdfTab)) {
        tabs.push('correctionPdf')
      }
    }

    return tabs
  }, [currentFile, filePreviewPath, showCorrectionPdfTab, showPreview])

  return { availableTabs }
}
