import Icon from '@mdi/react'
import React, { FC, useMemo } from 'react'

interface Props {
  /**
   * Zu zeichnender Pfad. Entspricht dem `d`-Attribut des `<path>`-Elements
   * in einem SVG. Hier wird der Pfad üblicherweise vom npm-Modul `@mdi/js`
   * importiert und hier übergeben.
   * @example
   * ``` ts
   * import { mdiMenuDown } from '@mdi/js'
   * <MdiIcon path={mdiMenuDown} />
   * ```
   */
  path: string | null
  /**
   * Der Faktor, um den das Icon skaliert werden soll.
   */
  scale?: number
  className?: string
  title?: string | null
}

export const MdiIcon: FC<Props> = function MdiIcon ({
  path,
  className,
  scale = 1,
  title
}) {
  const size = useMemo(() => scale * 24, [scale])
  const iconStyle = useMemo(() => ({ width: size, height: size }), [size])

  if (path === null) {
    return <div style={iconStyle} />
  }

  return <Icon className={className} path={path} style={iconStyle} title={title} />
}
