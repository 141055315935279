import { mdiAlert } from '@mdi/js'
import classNames from 'classnames'
import moment from 'moment'
import React, { memo, MouseEvent, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import 'sfportal_components_generic/MdiIcon.scss'
import { ApiTask } from '../../services/api/apiSchemas'
import { MdiIcon } from '../Generic/MdiIcon'
import { ListItem } from './ListItem'
import './ListItemTask.scss'

interface Props {
  listItemType: ApiTask['dataResource']['type'] | 'unknown'
  icon: ReactElement<typeof MdiIcon>
  title: string
  pubtargetIdentifier?: string
  titleSuffix?: string
  meta?: JSX.Element
  dueDate?: string
  modifiedBy?: string
  modifiedDate?: string
  highlight?: boolean
  current?: boolean
  actions?: JSX.Element
  onItemClick?: (event: MouseEvent<HTMLDivElement>) => void
  statusIcons?: Array<ReactElement<typeof MdiIcon>>
}

export const ListItemTask = memo(function ListItemTask ({
  listItemType,
  pubtargetIdentifier,
  icon,
  title,
  titleSuffix,
  meta,
  dueDate,
  modifiedBy,
  modifiedDate,
  highlight,
  current,
  actions,
  onItemClick,
  statusIcons,
}: Props) {
  const listItemClasses = useMemo(
    () =>
      classNames('list-item-task', `list-item-task--type-${listItemType}`, {
        'list-item-task--has-click-action': onItemClick !== undefined,
      }),
    [listItemType, onItemClick],
  )

  const showModifiedByOrDate = useMemo(
    () => modifiedBy !== undefined || modifiedDate !== undefined,
    [modifiedBy, modifiedDate]
  )

  const showModifiedByAndDate = useMemo(
    () => modifiedBy !== undefined && modifiedDate !== undefined,
    [modifiedBy, modifiedDate]
  )

  const showDueDate = useMemo(
    () => dueDate !== undefined && dueDate !== '',
    [dueDate]
  )

  const showPubtargetIdentifier = useMemo(
    () => pubtargetIdentifier !== undefined,
    [pubtargetIdentifier]
  )

  const showMeta = useMemo(
    () =>
      meta !== undefined ||
      showModifiedByOrDate ||
      showDueDate ||
      showPubtargetIdentifier,
    [meta, showDueDate, showModifiedByOrDate, showPubtargetIdentifier]
  )

  /**
   * Lokalisierungs-Funktion.
   */
  const { t } = useTranslation()

  return (
    <ListItem
      className={listItemClasses}
      hoverEffect={onItemClick !== undefined}
      compact={true}
      highlight={highlight}
      current={current}
    >
      <div className="list-item-task__icon-col" onClick={onItemClick}>
        {statusIcons}
        {icon}
      </div>
      <div className="list-item-task__detail-col" onClick={onItemClick}>
        <div className="list-item-task__title">
          {title}
          {titleSuffix !== undefined ? (
            <span className="list-item-task__title-suffix">
              ({titleSuffix})
            </span>
          ) : null}
        </div>

        {showMeta ? (
          <div className="list-item-task__meta">
            {showPubtargetIdentifier ? (
              <div className={'list-item-task__meta-line'}>
                {t('listItem.listItemTask.pubtargetIdentifier')}:{' '}
                <span className={'list-item-task__meta-value'}>
                  {pubtargetIdentifier}
                </span>
              </div>
            ) : null}

            {showDueDate ? (
              <div className="list-item-task__meta-line">
                {t('listItem.listItemTask.due')}:{' '}
                <span className="list-item-task__meta-value--inline-icon">
                  {new Date(dueDate + 'Z').toLocaleDateString()}
                  {moment(new Date(dueDate + 'Z')).isBefore(moment()) ? (
                    <span>
                      <MdiIcon className={'icon__warn'} path={mdiAlert} />
                    </span>
                  ) : null}
                </span>
              </div>
            ) : null}

            {showModifiedByOrDate ? (
              <div className="list-item-task__meta-line">
                {t('listItem.listItemTask.lastChange')}:{' '}
                {modifiedDate !== undefined ? (
                  <span className="list-item-task__meta-value">
                    {new Date(modifiedDate + 'Z').toLocaleString()}
                  </span>
                ) : null}
                {showModifiedByAndDate ? ' ' : null}
                {modifiedBy !== undefined ? (
                  <>
                    von{' '}
                    <span className="list-item-task__meta-value">
                      {modifiedBy}
                    </span>
                  </>
                ) : null}
              </div>
            ) : null}

            {meta}
          </div>
        ) : null}
      </div>
      {actions !== undefined ? (
        <div className="list-item-task__actions-col">{actions}</div>
      ) : null}
    </ListItem>
  )
})
