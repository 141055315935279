import React, { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'sfportal_components_forms/Button'
import { ButtonRow } from 'sfportal_components_forms/ButtonRow'
import { Modal } from 'sfportal_components_generic/Modal'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { ChildrenProp } from '../../jsx'
import { CompleteTaskModalSubmitParams, useFormFields } from './CompleteTaskModal'
import { ApiTask } from '../../services/api/apiSchemas'

interface Props extends ChildrenProp {
  title: string
  confirmButtonLabel: string
  cancelButtonLabel?: string
  visible: boolean
  onConfirm: (submitContent?: CompleteTaskModalSubmitParams) => void
  onCancel: () => void
  tasks?: ApiTask[]
}

export const ConfirmModal: FC<Props> = ({
  children,
  title,
  confirmButtonLabel,
  cancelButtonLabel,
  visible,
  onConfirm,
  onCancel,
  tasks
}) => {
  const { taskActionStatus } = useProductDetailStore()
  const { t } = useTranslation()
  const formRef = useRef<HTMLFormElement>(null)
  const formFields = useMemo(() => {
    if (tasks === undefined) {
      return undefined
    }
    const tasksWithFormfields = tasks.filter((task: ApiTask) => task.formFields !== undefined)

    if (tasksWithFormfields.length === 0) {
      // Durch das Laden der Formfelder beim submit, wird in der gesamten taskliste nach einem Task mit
      // Formfeld gesucht. Falls keiner existiert wird der submit dialog ohne formfelder erstellt.
      return undefined
    }
    return tasksWithFormfields[0].formFields
  }, [tasks])
  const {
    formState,
    formFieldElements
  } = useFormFields(formFields)

  const handleSubmitClick = (): void => {
    if (tasks === undefined || tasks.length === 0 || tasks[0].formFields.length === 0) {
      onConfirm()
    }
    const isValid = formRef.current?.reportValidity() ?? false
    if (!isValid) return

    onConfirm({ comment: '', ...formState })
  }

  return (
    <Modal
      visible={visible}
      title={title}
      type="complete-all-tasks"
      width="normal"
      padding="small"
      verticalAlign="center"
      footer={
        <>
          <ButtonRow>
            <Button
              type="submit"
              disabled={taskActionStatus === RequestStatus.pending}
              onClick={handleSubmitClick}
            >
              {confirmButtonLabel}
            </Button>

            <Button
              type="button"
              buttonStyle="secondary"
              disabled={taskActionStatus === RequestStatus.pending}
              onClick={onCancel}
            >
              {cancelButtonLabel ?? t('common.cancel')}
            </Button>
          </ButtonRow>
        </>
      }
    >
      {tasks !== undefined
        ? <form ref={formRef} style={{ marginBottom: '30px' }}>
          {formFieldElements}
        </form>
        : null}
      {children}
    </Modal>
  )
}
