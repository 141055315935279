import React, { memo, MouseEvent, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { MdiIcon } from '../Generic/MdiIcon'

interface Props {
  label?: string
  icon?: string
  iconOnly?: boolean
  enabled?: boolean
  type?: string
  onClick: (event: MouseEvent<HTMLDivElement>) => void
}

export const ItemViewAction = memo(function ItemViewAction ({
  label, icon, type, iconOnly = false, enabled = true,
  onClick
}: Props) {
  const actionClasses = useMemo(
    () => classNames(
      'item-view__action',
      {
        'item-view__action--icon-only':
          icon !== undefined && (label === undefined || iconOnly)
      },
      { 'item-view__action--is-enabled': enabled },
      { [`item-view__action--type-${String(type)}`]: type !== undefined }
    ),
    [enabled, icon, iconOnly, label, type]
  )

  const titleAttr = useMemo(
    () => label !== undefined && iconOnly ? label : undefined,
    [iconOnly, label]
  )

  const handleActionClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!enabled) { return }
      onClick(event)
    },
    [enabled, onClick]
  )

  return (
    <div className={actionClasses} onClick={handleActionClick} title={titleAttr}>
      {icon !== undefined ? (
        <MdiIcon path={icon} className="item-view__action-icon" />
      ) : null}

      {label !== undefined && !iconOnly ? (
        <div className="item-view__action-label">{label}</div>
      ) : null}
    </div>
  )
})
