import {
  mdiFileFindOutline,
  mdiFilePdfOutline,
  mdiInformationOutline
} from '@mdi/js'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FilePreview } from 'sfportal_components_generic/FilePreview'
import { MetadataItems } from 'sfportal_components_generic/MetadataItems'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import {
  reloadCurrentFile,
  useProductDetailStore
} from 'sfportal_stores/productDetailStore'
import { noop } from 'sfportal_utils/function'
import { AppTabs } from '../../../../components/Layout/AppTabs'
import { LoadingContainer } from '../../../../components/Layout/LoadingContainer'
import { ChildrenProp } from '../../../../jsx'
import { FileTaskMetadataDescriptor } from './TaskMetadataDescriptors'
import { useFilePreviewTabs } from './useFilePreviewTabs'
import { AssetForm } from '../../../Form/AssetForm'
import { EMK } from '../../../../services/api/apiSchemas'

interface Props extends ChildrenProp {
  filePreviewPath: string
  id: EMK
  metadata: FileTaskMetadataDescriptor[]
  metadataStatus: RequestStatus
  showCorrectionPdfTab: boolean
  correctionPreviewPath?: string
}

export const FilePreviewContent: FC<Props> = ({
  filePreviewPath,
  id,
  metadata,
  metadataStatus,
  showCorrectionPdfTab,
  correctionPreviewPath
}) => {
  const { availableTabs } = useFilePreviewTabs({ showCorrectionPdfTab })
  const { currentFileReloading, search } = useProductDetailStore()

  const { t } = useTranslation()

  function handleRetry (): void {
    reloadCurrentFile().catch(noop)
  }

  return (
    <div className="product-detail-view/task-detail__file-preview">
      <AppTabs
        items={[
          {
            tabText: t('productTree.detailView.tabs.preview'),
            tabIcon: mdiFileFindOutline,
            show: availableTabs.includes('preview'),
            body: (
              <FilePreview
                path={filePreviewPath}
                loading={currentFileReloading}
                highlightingAvailable={search?.results?.find(single=>`sf${single.drid}-${single.id}` === id) !== undefined}
              />
            )
          },
          {
            tabText: t('productTree.detailView.tabs.metadata'),
            tabIcon: mdiInformationOutline,
            show: availableTabs.includes('metadata'),
            body: (
              process.env.REACT_APP_ENABLE_SF_METADATA && process.env.REACT_APP_ENABLE_SF_METADATA === 'true'
                ? <AssetForm className="asset-metadata" formTypes={['typeForOverview', 'type']} entityMetakeys={[id]} readonly={true} />
                : <LoadingContainer
                  onRetry={handleRetry}
                  status={metadataStatus}
                  loadingText={t('metadata.loading')}
                  stretch={true}
                >
                  <div className="product-detail-view/task-detail__metadata">
                    <MetadataItems data={metadata} />
                  </div>
                </LoadingContainer>
            )
          },
          {
            tabText: t('tabs.correctionPdf'),
            tabIcon: mdiFilePdfOutline,
            show:
              availableTabs.includes('correctionPdf') &&
              correctionPreviewPath !== undefined,
            body:
              correctionPreviewPath !== undefined ? (
                <FilePreview path={correctionPreviewPath} />
              ) : null
          }
        ]}
      />
    </div>
  )
}
