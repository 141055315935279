import React, { FC, ReactNode, useMemo } from 'react'
import { isDefined } from 'sfportal_utils/global'
import './KeyValueTable.scss'

export type KeyValueTableData = Array<[string, ReactNode]>

interface Props {
  data: KeyValueTableData
}

export const KeyValueTable: FC<Props> = ({ data }) => {
  const filteredData = useMemo(
    () =>
      data.filter(([, value]) => {
        // `null`, `undefined` und Leerstrings rausfiltern.
        if (typeof value === 'string' && value.trim() === '') return false
        return isDefined(value)
      }),
    [data]
  )

  return (
    <table className="key-value-table">
      <tbody>
        {filteredData.map(([key, value], index) => (
          <tr className="key-value-table__row" key={index}>
            <th className="key-value-table__key">{key.trim()}</th>
            <td className="key-value-table__value">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
