import { mdiEmail, mdiEmailOpenOutline, mdiEmailSyncOutline } from '@mdi/js'
import React, { memo, MouseEvent, useCallback, useMemo } from 'react'
import { ApiMessage } from '../../services/api/apiSchemas'
import { ItemView, ItemViewType } from './ItemView'
import { ItemViewAction } from './ItemViewAction'
import { ItemViewHeader } from './ItemViewHeader'
import './MessageItem.scss'

interface Props {
  message: ApiMessage
  readStatusIsPending?: boolean
  open: boolean
  onSetOpen: () => void
  onSetRead: () => void
}

export const MessageItem = memo(function MessageItem ({
  message, readStatusIsPending = false, open, onSetOpen, onSetRead: onSetUnread
}: Props) {
  const handleToggle = useCallback(
    () => { onSetOpen() },
    [onSetOpen]
  )

  const handleReadStatusClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if (readStatusIsPending) { return }
      onSetUnread()
    },
    [onSetUnread, readStatusIsPending]
  )

  const readStatusIcon = useMemo(
    () => {
      if (readStatusIsPending) { return mdiEmailSyncOutline }
      if (message.unread) { return mdiEmail }
      return mdiEmailOpenOutline
    },
    [message.unread, readStatusIsPending]
  )

  return (
    <ItemView
      detail={() => (
        <div
          className="message-item"
          dangerouslySetInnerHTML={{ __html: message.message }}
        />
      )}
      isOpen={open}
      expanded={open}
      onToggle={handleToggle}
      type={message.unread ? ItemViewType.unread : ItemViewType.default}
    >
      <ItemViewAction
        icon={readStatusIcon}
        enabled={!readStatusIsPending}
        onClick={handleReadStatusClick}
      />
      <ItemViewHeader
        title={message.sender}
        description={message.subject}
        onClick={handleToggle}
      />
    </ItemView>
  )
})
