import classNames from 'classnames'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getRequestStatusData } from '../../data/requestStatusData'
import { ChildrenProp } from '../../jsx'
import { RequestStatus } from '../../services/api/generic/types'
import { Button } from '../Forms/Button'
import { LoadingAnimation } from '../Generic/LoadingAnimation'
import './LoadingContainer.scss'

interface Props extends ChildrenProp {
  loadingText: string
  status: RequestStatus
  stretch?: boolean
  onRetry: () => void
}

export const LoadingContainer: FC<Props> = function LoadingContainer ({
  children,
  loadingText,
  status,
  stretch = false,
  onRetry
}) {
  const { t } = useTranslation()
  const statusData = useMemo(() => getRequestStatusData(status, t), [status, t])

  const loading = useMemo(
    (): boolean => status === RequestStatus.pending,
    [status]
  )

  const loadingContainerClasses = useMemo(
    () =>
      classNames(
        'loading-container',
        { 'loading-container--is-loading': loading },
        { 'loading-container--is-stretching': stretch }
      ),
    [loading, stretch]
  )

  return (
    <div className={loadingContainerClasses}>
      {loading ? (
        <div className="loading-container__indicator">
          <div className="loading-container__window">
            <div className="loading-container__text">{loadingText}</div>
            <LoadingAnimation />
          </div>
        </div>
      ) : status !== RequestStatus.ok ? (
        <div className="loading-container__indicator">
          <div className="loading-container__window">
            <div className="loading-container__text">{statusData.longText}</div>
            <Button onClick={onRetry}>{t('loadingContainer.retry')}</Button>
          </div>
        </div>
      ) : null}

      <div className="loading-container__content">{children}</div>
    </div>
  )
}
