interface AjaxErrorOptions {
  status: number
  body: string | Record<string, unknown>
}

export class AjaxError extends Error {
  status: AjaxErrorOptions['status']
  body: AjaxErrorOptions['body']

  constructor ({ status, body }: AjaxErrorOptions) {
    const bodyString = typeof body === 'string' ? body : JSON.stringify(body)
    super(`Ajax error: Status ${status} | ${bodyString}`)
    Object.setPrototypeOf(this, new.target.prototype)
    this.status = status
    this.body = body
  }
}

export class NetworkError extends Error {
  constructor () {
    super('NetworkError: Error sending ajax request.')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
