import {useEffect, useState} from 'react'
import {Transfer, useTransferStore} from '../transferStore'

/**
 * Custom Hook um auf einzelne Transfers zugreifen zu können, für z.b. Upload Process
 * @param uploadid die jeweilige Upload Id
 * @returns undefined oder den Transfer
 */
export const useSingleTransfer = (uploadid: string): Transfer | undefined => {
    const [singleTransfer, setSingleTransfer] = useState<Transfer | undefined>(undefined)
    const {transfers} = useTransferStore()

    useEffect(() => {
        setSingleTransfer(transfers[uploadid])
    }, [transfers[uploadid]])

    return singleTransfer
}