interface RouteDefinition {
  relative: string
  absolute: string
  absoluteWithWildcard: string
}

export function sanitize (path: string): string {
  return encodeURI(path.replace(/([^:]\/)\/+/g, '$1'))
}

export function getPath (path: string): RouteDefinition {
  return {
    absolute:
      sanitize((process.env.REACT_APP_PUBLIC_URL ?? '') + path),
    absoluteWithWildcard:
      sanitize((process.env.REACT_APP_PUBLIC_URL ?? '') + path) + '/*',
    relative:
      sanitize(path)
  }
}

export const routes = {
  login: getPath('/login'),
  tokenLogin: getPath('/login/token'),
  home: getPath('/'),
  user: getPath('/profil'),
  products: getPath('/produkte'),
  messages: getPath('/nachrichten'),
  tasks: getPath('/tasks'),

  productDetail: (productId = ':productId') =>
    getPath(`/produkte/${productId}`),

  productDetailHighlightTask: (
    productId = ':productId',
    taskId = ':taskId'
  ) => getPath(`/produkte/${productId}/aufgabe/${taskId}`),

  productDetailHighlightTaskAsset: (
    productId = ':productId',
    assetId = '*'
  ) => getPath(`/produkte/${productId}/datei/${assetId}`),

  praep: (processInstanceId = ':processInstanceId') => getPath(`/praep/${processInstanceId}`)
} as const

export function getFileUriFromEMK (
  emk: string,
  type: 'preview' | 'download' = 'preview'
): string {
  const baseUri = process.env.REACT_APP_PUBLIC_URL ?? ''

  switch (type) {
    case 'preview':
      return sanitize(`${baseUri}/api/files/${emk}?format=normal`)
    case 'download':
      return sanitize(`${baseUri}/api/files/download/${emk}`)
  }
}

export interface FileInfoObj {
  storagemapping: string
  thumbfilepath: string
  filename: string
}

export function getThumbnailUrl (
  thumbnailFormatName: string, fileinfoObject: FileInfoObj
): string {
  const baseUri = process.env.REACT_APP_PUBLIC_URL ?? ''
  return sanitize(`${baseUri}/api/${fileinfoObject.storagemapping}smbothumbnaildata/${thumbnailFormatName}${fileinfoObject.thumbfilepath}${fileinfoObject.filename}`)
}
