import {
  mdiCalendarCheck,
  mdiFileFindOutline,
  mdiFileSearchOutline,
  mdiInformationOutline
} from '@mdi/js'
import React, { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyIndicator } from 'sfportal_components_generic/EmptyIndicator'
import { Subviews } from 'sfportal_components_generic/Subviews'
import { ApiTreeNode } from 'sfportal_services_api/apiSchemas'
import { useProductTreeStore } from 'sfportal_stores/productTreeStore'
import { useGetProjectProductTreeDetailSubviews } from 'sfportal_views_productdetail_producttree_detail/useGetProjectProductTreeDetailSubviews'
import { AppTabs } from '../../../../components/Layout/AppTabs'
import { FlatTreeNode } from '../../../../components/Tree/Tree'
import { ChildrenProp } from '../../../../jsx'
import { ProductTreeSubviewsContext } from '../list/useProductTreeContext'
import { Metadata } from './Metadata'
import { Preview } from './Preview'
import './ProductTreeDetail.scss'
import { Tasks } from './Tasks'

interface Props extends ChildrenProp {}

export const ProductTreeDetail: FC<Props> = () => {
  const { currentDetailSubview } = useContext(ProductTreeSubviewsContext)
  const { t } = useTranslation()
  const { nodes, selectedItem } = useProductTreeStore()
  const getProjectProductTreeDetailSubviews =
    useGetProjectProductTreeDetailSubviews()

  const selectedNode = useMemo<FlatTreeNode<ApiTreeNode> | null>(() => {
    return selectedItem !== null ? nodes[selectedItem] : null
  }, [nodes, selectedItem])

  return (
    <div className="product-detail-view/product-tree-detail">
      <Subviews
        views={getProjectProductTreeDetailSubviews()}
        current={currentDetailSubview}
      />

      {currentDetailSubview === null ? (
        selectedNode !== null ? (
          <>
            <div className="product-detail-view/product-tree-detail__title">
              {selectedNode.item.name}
            </div>

            <AppTabs
              items={[
                {
                  tabText: t('productTree.detailView.tabs.preview'),
                  tabIcon: mdiFileFindOutline,
                  body: <Preview />
                },
                {
                  tabText: t('productTree.detailView.tabs.metadata'),
                  tabIcon: mdiInformationOutline,
                  body: <Metadata />
                },
                {
                  tabText: t('productTree.detailView.tabs.tasks'),
                  tabIcon: mdiCalendarCheck,
                  body: <Tasks />
                }
              ]}
            />
          </>
        ) : (
          <EmptyIndicator
            dimmed
            stretch
            compact
            text={t('productTree.detailView.empty')}
            icon={mdiFileSearchOutline}
          />
        )
      ) : null}
    </div>
  )
}
