import React, { FC } from 'react'
import { FilterItem } from './FilterItem'
import { Select } from '../Forms/Select'

interface Props {
  name: string
  label: string
  items: Record<string, string>
  value?: string
  onChange: (value: string) => void
}

export const SelectInputFilterField: FC<Props> = ({ name, label, items, value, onChange }: Props) => {
  return (
    <FilterItem
      type="select-input-list"
      title={label}
      initiallyEnabled={value !== ''}
      onEnabledChange={() => onChange('')}>
      <Select name={name} items={items} value={value} onChange={onChange} />
    </FilterItem>
  )
}
