import classNames from 'classnames'
import React, {
  ButtonHTMLAttributes,
  DOMAttributes,
  FC,
  MouseEvent,
  useMemo,
  useRef,
  useState
} from 'react'
import { Menu } from 'sfportal_components_menu/Menu'
import { Alignment2D } from 'sfportal_utils/alignment'
import { ChildrenProp } from '../../jsx'
import { AppHeaderButton } from './AppHeaderButton'
import './AppHeaderDropdown.scss'

interface Props
  extends ChildrenProp,
  DOMAttributes<HTMLButtonElement>,
  ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string
  label?: string
}

export const AppHeaderDropdown: FC<Props> = ({
  children,
  icon,
  label,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const appHeaderDropdownClasses = useMemo(
    () =>
      classNames('app-header-dropdown', {
        'app-header-dropdown--is-open': isOpen
      }),
    [isOpen]
  )

  function handleButtonMouseDown (event: MouseEvent<HTMLButtonElement>): void {
    if (event.button !== 0) return
    event.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <div className={appHeaderDropdownClasses}>
      <div className="app-header-dropdown__button" ref={buttonRef}>
        <AppHeaderButton
          icon={icon}
          label={label}
          onMouseDown={handleButtonMouseDown}
          {...rest}
        />
      </div>

      <Menu
        open={isOpen}
        targetElement={buttonRef.current}
        targetAlignment={Alignment2D.LEFT_BOTTOM}
        menuAlignment={Alignment2D.LEFT_TOP}
        onOpenChange={(value) => setIsOpen(value)}
      >
        {children}
      </Menu>
    </div>
  )
}
