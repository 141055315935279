import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyIndicator } from 'sfportal_components_generic/EmptyIndicator'
import { noop } from 'sfportal_utils/function'
import { LoadingContainer } from '../../../../components/Layout/LoadingContainer'
import { ListItemGroupTasks } from '../../../../components/ListItem/ListItemGroupTasks'
import { ChildrenProp } from '../../../../jsx'
import { useTasksData } from './useTasksData'

interface Props extends ChildrenProp {}

export const Tasks: FC<Props> = () => {
  const { t } = useTranslation()
  const { groupedTasks, tasksStatus } = useTasksData()

  const tasks = useMemo(() => Object.entries(groupedTasks), [groupedTasks])

  return (
    <div className="product-detail-view/product-tree-detail/tasks">
      <LoadingContainer
        status={tasksStatus}
        loadingText={t('tasks.loading')}
        onRetry={noop}
      >
        {tasks.length === 0 ? (
          <EmptyIndicator
            text={t('productTree.detailView.tasks.empty')}
            compact
          />
        ) : (
          tasks.map(([taskName, tasks]) => (
            <ListItemGroupTasks
              key={taskName}
              title={taskName}
              titleSuffix={` (${t('tasks.list.tasksCount', {
                count: tasks.length
              })})`}
              tasks={tasks}
              isExpanded={true}
            />
          ))
        )}
      </LoadingContainer>
    </div>
  )
}
