import React, { FC, FormEvent, TextareaHTMLAttributes, useEffect, useRef, useState } from 'react'
import { useAutoFocus } from '../../hooks/useAutoFocus'
import { ChildrenProp } from '../../jsx'
import './Textarea.scss'

interface Props extends ChildrenProp {
  name: string
  id?: string
  label?: string
  value?: string
  required?: boolean
  placeholder?: string
  autoFocus?: boolean
  disabled?: boolean
  textareaProps?: TextareaHTMLAttributes<HTMLTextAreaElement>
  onInput?: (value: string) => void
}

export const Textarea: FC<Props> = ({
  name,
  placeholder,
  disabled,
  textareaProps,
  label = '',
  value = '',
  required,
  id = name,
  autoFocus = false,
  onInput
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useAutoFocus({
    ref: textareaRef,
    enabled: autoFocus
  })

  const [valueState, setValueState] = useState<string>(value)

  const handleInput = (event: FormEvent<HTMLTextAreaElement>): void => {
    setValueState(event.currentTarget.value)
    onInput?.(event.currentTarget.value)
  }

  useEffect(() => {
    setValueState(value)
  }, [value])

  return (
    <div className="textarea">
      {label !== '' ? (
        <label className="textarea__label" htmlFor={id}>{label}</label>
      ) : null}

      <textarea
        ref={textareaRef}
        value={valueState}
        placeholder={placeholder}
        required={required}
        className="textarea__input"
        id={id}
        name={name}
        disabled={disabled}
        {...textareaProps}
        onChange={handleInput}
      />
    </div>
  )
}
