import { mdiMenuDown, mdiMenuRight } from '@mdi/js'
import React, { memo, useCallback, useMemo } from 'react'
import { ChildrenProp } from '../../jsx'
import classNames from 'classnames'
import { ProgressBar } from '../Generic/ProgressBar'
import './ItemView.scss'
import { ItemViewAction } from './ItemViewAction'
import { useTranslation } from 'react-i18next'

export enum ItemViewType { default, unread }

interface Props extends ChildrenProp {
  canToggle?: boolean
  expanded?: boolean
  isOpen?: boolean
  type?: ItemViewType
  onToggle?: (isOpen: boolean) => void
  progress?: number
  progressPendingWording?: string
  progressDoneWording?: string
  detail?: () => JSX.Element | null
}

export const ItemView = memo(function ItemView ({
  children,
  canToggle = true,
  expanded = false,
  isOpen = false,
  type = ItemViewType.default,
  onToggle,
  progress,
  progressPendingWording,
  progressDoneWording,
  detail
}: Props) {
  const { t } = useTranslation()

  const itemViewClasses = useMemo(
    () => classNames(
      'item-view',
      { 'item-view--is-hoverable': onToggle !== undefined },
      { 'item-view--is-expanded': expanded },
      { 'item-view--is-open': isOpen },
      { 'item-view--is-unread': type === ItemViewType.unread }
    ),
    [expanded, isOpen, onToggle, type]
  )


  const handleToggleOpenClick = useCallback(
    () => {
      if (!canToggle || onToggle === undefined) { return }
      onToggle(!isOpen)
    },
    [canToggle, isOpen, onToggle]
  )

  return (
    <div className={itemViewClasses}>
      <div className="item-view__header">
        {canToggle ? (
          <ItemViewAction
            icon={isOpen ? mdiMenuDown : mdiMenuRight}
            onClick={handleToggleOpenClick}
            type="toggle"
          />
        ) : null}

        {children}

        {progress !== undefined ? (
          <div className="item-view__progress-col">
            <ProgressBar
              progress={progress ?? 0}
              label={
                progress < 100
                  ? (progressPendingWording ?? t('itemViews.itemView.progressPendingDefault'))
                  : (progressDoneWording ?? t('itemViews.itemView.progressDoneDefault'))
              }
            />
          </div>
        ) : null}
      </div>

      {detail !== undefined ? (
        <div className="item-view__body">
          {isOpen ? detail() : null}
        </div>
      ) : null}
    </div>
  )
})
