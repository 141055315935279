import { ApiTreeNode } from 'sfportal_services_api/apiSchemas'
import { FlatTreeNode } from '../../../../components/Tree/Tree'
import { TreeNodeAction } from '../../../../components/Tree/TreeNode'

type ProjectItemActions = (
  node: FlatTreeNode<ApiTreeNode>,
  productItemActions: TreeNodeAction[],
) => TreeNodeAction[]

/**
 * React-Hook, um die Buttons eines Knotens im Produktbaum projektseitig
 * anzupassen. Im Standard-Portal werden einfach die Standard-Buttons
 * zurückgegeben, die in diesem definiert wurden. Projekte können mithilfe
 * dieses Hooks neue Buttons hinzufügen oder individuelle oder alle
 * Standard-Buttons entfernen.
 *
 * **Wichtig:** `productItemActions` muss auch mit zurückgegeben
 * werden, wenn die Standard-Buttons wieder ausgegeben werden sollen.
 * @returns Alle Buttons, die bei einem Knoten im Produktbaum ausgegeben werden
 * sollen.
 */
export function useGetProjectItemActions (): ProjectItemActions {
  return (node, productItemActions) => productItemActions
}
