import { mdiDownload, mdiUpload, mdiFile, mdiPencil } from '@mdi/js'
import React, { memo, useCallback, useMemo } from 'react'
import { getFileUriFromEMK } from '../../routes'
import { ApiFolderAsset, ApiProduct, ApiSharedFolder } from '../../services/api/apiSchemas'
import { setCurrentFile } from '../../stores/productDetailStore'
import { useValuesStore } from '../../stores/valuesStore'
import { noop } from '../../utils/function'
import { getWebDAVUri, MsOfficeApp } from '../../utils/web-dav'
import { ItemViewHeader } from '../ItemViews/ItemViewHeader'
import { ItemViewLink } from '../ItemViews/ItemViewLink'
import { ListItem } from './ListItem'
import './ListItemAsset.scss'
import { ItemViewAction } from '../ItemViews/ItemViewAction'
import { FileInputChangeListener, useFileInput } from '../../hooks/useFileInput'
import { startMetadataWf, uploadFiles } from '../../stores/transferStore'
import { useUserStore } from '../../stores/userStore'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { UploadMetadataWfFile } from '../../services/api/filesApiService'

interface Props {
  item: ApiFolderAsset
  folderId: ApiSharedFolder['id']
  productId: ApiProduct['id']
  readOnly?: ApiSharedFolder['readOnly']
}

/**
 * @deprecated
 * Entfernen, wenn die ListItem unter "Freigegebene Ordner" ersetzt wurden.
 */
export const ListItemAsset = memo(function ListItemAsset ({
  item,
  folderId,
  productId,
  readOnly = false
}: Props) {
  const { data } = useValuesStore()
  const { currentUser } = useUserStore()
  const userId = currentUser !== null ? currentUser.id : undefined
  const alert = useAlert()
  const { t } = useTranslation()

  const description = useMemo(() => {
    const entries = [
      <>{t('listItem.listItemAsset.status')}: <strong>{item.status ?? t('listItem.listItemAsset.unknown')}</strong></>,
      <>{t('listItem.listItemAsset.edited')}: <strong>{item.modifiedBy ?? t('listItem.listItemAsset.unknown')}</strong>, {t('listItem.listItemAsset.at')}
        <strong>{item.modifiedDate ?? t('listItem.listItemAsset.unknown')}</strong></>
    ]

    return entries.reduce((prev, curr) => <>{prev}<br />{curr}</>)
  }, [item.modifiedBy, item.modifiedDate, item.status, t])

  const handlePreviewActionClick = useCallback(() => {
    setCurrentFile(item).catch(noop)
  }, [item])

  const handleFileInputChange = useCallback<FileInputChangeListener>(
    event => {
      const inputElement = event.currentTarget as HTMLInputElement
      const files = Array.from(inputElement.files ?? [])
      const uploadingFileObjects = files.map(file => ({
        filename: file.name,
        file: file,
        uploadid: uuidv4(),
        userid: userId,
        productid: productId,
        entityMetakey: item.id
      }))
      startMetadataWf(uploadingFileObjects.map(uploadingFileObject => ({
        filename: uploadingFileObject.filename,
        uploadid: uploadingFileObject.uploadid,
        userid: uploadingFileObject.userid,
        productid: uploadingFileObject.productid,
        entityMetakey: uploadingFileObject.entityMetakey
      } as UploadMetadataWfFile)), true).catch(noop)

      alert.show(t('upload.started'))

      uploadFiles(uploadingFileObjects.map(uploadingFileObject => ({
        file: uploadingFileObject.file,
        uploadid: uploadingFileObject.uploadid
      })), productId).catch(noop)
    },
    [item.id, userId, productId, t, alert]
  )

  const { handleFileUploadClick } = useFileInput({
    listener: handleFileInputChange,
    multiple: true
  })

  return (
    <ListItem className="list-item-asset" compact={true}>
      <ItemViewHeader
        title={item.name}
        titleIcon={mdiFile}
        description={description}
        onClick={handlePreviewActionClick}
      />

      {item.contentType === 'file/doc' &&
      item.webdavPath !== undefined &&
      item.webdavPath !== '' &&
      data !== null ? (
          <ItemViewLink
            icon={mdiPencil}
            iconOnly={true}
            label={t('listItem.listItemAsset.edit')}
            uri={getWebDAVUri({
              app: MsOfficeApp.word,
              fileUri: `${data.apiurl}/${data.webdavassetpath}/${item.webdavPath}`
            })}
          />
        ) : null}

      {item.contentType === 'file/xls' &&
      item.webdavPath !== undefined &&
      item.webdavPath !== '' &&
      data !== null ? (
          <ItemViewLink
            icon={mdiPencil}
            iconOnly={true}
            label={t('listItem.listItemAsset.edit')}
            uri={getWebDAVUri({
              app: MsOfficeApp.excel,
              fileUri: `${data.apiurl}/${data.webdavassetpath}/${item.webdavPath}`
            })}
          />
        ) : null}

      <ItemViewAction
        icon={mdiUpload}
        enabled={!readOnly}
        iconOnly={true}
        label={t('listItem.listItemAsset.replace')}
        onClick={handleFileUploadClick}
      />
      <ItemViewLink
        icon={mdiDownload}
        iconOnly={true}
        label={t('listItem.listItemAsset.download')}
        uri={getFileUriFromEMK(item.id, 'download')}
        download={true}
      />
    </ListItem>
  )
})
