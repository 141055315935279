import React, { FC, useMemo } from 'react'
import { ChildrenProp } from '../../jsx'
import classNames from 'classnames'
import './ButtonRow.scss'

interface Props extends ChildrenProp {
  align?: 'left' | 'center' | 'right' | 'full-width'
}

export const ButtonRow: FC<Props> = ({ children, align = 'left' }) => {
  const buttonRowClasses = useMemo(
    () => classNames('button-row', `button-row--type-${align}`),
    [align]
  )

  return (
    <div className={buttonRowClasses}>
      {Array.isArray(children) ? (
        children.map((child, index) => (
          <div key={index} className="button-row__item">
            {child}
          </div>
        ))
      ) : (
        <div className="button-row__item">{children}</div>
      )}
    </div>
  )
}
