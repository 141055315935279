import { mdiBookOpenPageVariant, mdiBookshelf, mdiProgressClock } from '@mdi/js'
import {
  navigate,
  RouteComponentProps,
  useLocation,
  useMatch
} from '@reach/router'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingAnimation } from '../components/Generic/LoadingAnimation'
import { Tab } from '../components/Layout/Tab'
import { Tabs } from '../components/Layout/Tabs'
import { TabSpacer } from '../components/Layout/TabSpacer'
import { ChildrenProp } from '../jsx'
import { routes } from '../routes'
import { ApiProduct } from '../services/api/apiSchemas'
import { RequestStatus } from '../services/api/generic/types'
import {
  loadProductDetail,
  loadSharedFilesAvailable,
  loadProductTasks,
  resetProductDetailStore,
  useProductDetailStore
} from '../stores/productDetailStore'
import { loadProductTree } from '../stores/productTreeStore'
import { useTransferStore } from '../stores/transferStore'
import { noop } from '../utils/function'
import './Home.scss'

interface Props extends ChildrenProp, RouteComponentProps {
  // Gibt an ob der Websocket gerade connected wird.
  connectingSocket: boolean
}

export const Home = memo(function Home ({ children, connectingSocket }: Props) {
  const location = useLocation()
  const productDetailMatch = useMatch(
    routes.productDetail().absoluteWithWildcard
  )
  const productDetailStore = useProductDetailStore()
  const { transfers } = useTransferStore()

  /**
   * Liefert die Localization-Funktion
   */
  const { t } = useTranslation()

  const productDetailId = useMemo((): number | null => {
    if (productDetailMatch === null) {
      return null
    }
    const id = parseInt(productDetailMatch.productId)
    if (isNaN(id)) {
      return null
    }
    return id
  }, [productDetailMatch])

  const loadProductData = useCallback(async () => {
    if (productDetailId === null) return
    if (
      productDetailStore.product !== null &&
      productDetailStore.product.id === productDetailId
    ) {
      return
    }

    await loadProductDetail(productDetailId).catch(noop)
    loadSharedFilesAvailable().catch(noop)
    loadProductTasks().catch(noop)
    loadProductTree().catch(noop)
  }, [productDetailId, productDetailStore.product])

  useEffect(() => {
    loadProductData().catch(noop)
  }, [loadProductData])

  type IsCurrent = (route: string) => boolean
  const isCurrent: IsCurrent = (route) => location.pathname === route

  const getProductDetailTabLabel = useCallback(
    (product: ApiProduct | null): string => {
      if (productDetailStore.productStatus === RequestStatus.pending) {
        return t('home.loading')
      }
      if (product === null) {
        return t('home.empty')
      }
      return product.description
    },
    [productDetailStore.productStatus, t]
  )

  const onTabItemActivate = useCallback((route: string) => {
    navigate(route).catch(noop)
  }, [])

  const handleDetailTabActivate = useCallback(() => {
    if (productDetailStore.product === null) {
      return
    }
    const { id } = productDetailStore.product
    const productDetailRoute = routes.productDetail(id.toString()).absolute
    navigate(productDetailRoute).catch(noop)
  }, [productDetailStore.product])

  const handleDetailTabMiddleClick = useCallback(async () => {
    if (productDetailMatch !== null) {
      await navigate(routes.products.absolute).catch(noop)
    }
    await resetProductDetailStore().catch(noop)
  }, [productDetailMatch])

  return (
    <div className="home-view">
      {connectingSocket ? (
        <LoadingAnimation />
      ) : (
        <div className="home-view__container">
          <div className="home-view__tabs">
            {process.env.REACT_APP_ENABLE_TABS !== 'false' ? (
              <Tabs>
                <Tab
                  label={t('home.productTab')}
                  icon={mdiBookshelf}
                  isCurrent={isCurrent(routes.products.absolute)}
                  onActivate={() => onTabItemActivate(routes.products.absolute)}
                />

                {productDetailStore.product !== null ||
                productDetailStore.productStatus === RequestStatus.pending ? (
                    <Tab
                      label={getProductDetailTabLabel(productDetailStore.product)}
                      icon={mdiBookOpenPageVariant}
                      isCurrent={productDetailMatch !== null}
                      onActivate={handleDetailTabActivate}
                      onMiddleMouseButtonClick={handleDetailTabMiddleClick}
                      onClose={handleDetailTabMiddleClick}
                      labelEllipsis={true}
                    />
                  ) : null}

                <TabSpacer />

                {process.env.REACT_APP_ENABLE_RUNNING_TASKS !== 'false' ? (
                  <Tab
                    label={t('home.backgroundTasks')}
                    count={Object.values(transfers).filter((it) => it.progress < 100).length}
                    icon={mdiProgressClock}
                    isCurrent={isCurrent(routes.tasks.absolute)}
                    onActivate={() => onTabItemActivate(routes.tasks.absolute)}
                  />
                ) : null}
              </Tabs>
            ) : null}
          </div>
          <div className="home-view__content">{children}</div>
        </div>
      )}
    </div>
  )
})
