import { useCallback } from 'react'
import { refreshProductFiles, useProductDetailStore } from '../../stores/productDetailStore'
import { useAddSubscriptionToArea } from '../../hooks/useAddSubscriptionToArea'
import { useTranslation } from 'react-i18next'
import { useUserStore } from '../../stores/userStore'
import { useAlert } from 'react-alert'

export function useSubscriptionsForTabContents (): void {
  const { t } = useTranslation()
  const { product } = useProductDetailStore()
  const { currentUser } = useUserStore()
  const alert = useAlert()

  const historicProcessInstanceEndCallback = useCallback((message) => {
    const messageBody = JSON.parse(message.body)
    if (
      messageBody.processdefinitionkey === 'simpleUpload' &&
      currentUser?.id === messageBody.startUserId
    ) {
      if (product !== null) {
        refreshProductFiles(product.id)
      }

      alert.show(t('upload.success'))
    }
  }, [alert, currentUser, product, t])

  useAddSubscriptionToArea(
    'historicprocessinstance',
    'end',
    '*',
    historicProcessInstanceEndCallback,
    'workflow'
  )
}
