import { Data } from '.'
import { TFunction } from 'i18next'

export const productProperties = [
  'description',
  'publicationDate',
  'lectorTeam',
  'author',
  'status',
  'edition',
  'isbn',
  'assignabletasks',
  'owntasks',
  'assignabletasksexternal',
  'owntasksexternal',
  'assignabletasksexternalOrfilter',
  'owntasksexternalOrfilter',
  'candidatetasksexternal',
  'candidatetasksexternalOrfilter',
  'sfclients'
] as const

export type ProductProperty = typeof productProperties[number]

export interface ProductData extends Data<ProductProperty> {
  label: string
}

export const getAllProductData = (t: TFunction): ProductData[] => productProperties
  .map(key => getProductData(key, t))
  .filter((mapping): mapping is ProductData => mapping !== null)

export function getProductData (id: ProductProperty, t: TFunction): ProductData | null {
  switch (id) {
    case 'description':
      return {
        id,
        label: t('global.field.title')
      }
    case 'publicationDate':
      return {
        id,
        label: t('global.field.publicationDate')
      }
    case 'lectorTeam':
      return {
        id,
        label: t('global.field.lectorTeam')
      }
    case 'author':
      return {
        id,
        label: t('global.field.lectorTeam')
      }
    case 'status':
      return {
        id,
        label: t('global.field.status')
      }
    case 'edition':
      return {
        id,
        label: t('global.field.edition')
      }
    case 'isbn':
      return {
        id,
        label: t('global.field.isbn')
      }
    case 'sfclients':
      return {
        id,
        label: t('global.field.datapool')
      }
    default:
      return null
  }
}
