import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import { ApiComment } from '../../services/api/apiSchemas'
import './Comment.scss'

interface Props extends ChildrenProp {
  comment: ApiComment
}

export const Comment = memo(function Comment ({
  comment
}: Props) {
  return (
    <div className="comment">
      <div className="comment__username">{comment.userName}</div>
      <div className="comment__date">{comment.creationDate}</div>
      <div className="comment__body">
        {comment.fullMessage.split('\n').map((messageLine, index) => (
          <p className="comment__body-line" key={index}>
            {messageLine}
          </p>
        ))}
      </div>
    </div>
  )
})
