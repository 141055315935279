import { Response } from 'superagent'
import { sendRequest } from './superagent'
import { EMK } from './apiSchemas'

/**
 * Api Function um custom Properties ins Word Dokument zu setzten.
 *
 * @param id Der EntityMetakey des Smfile Datensatzen, in dessen File custom Properties geschrieben werden sollen.
 * @param keyValueJson Json String als key value pair, die als Properties gesetzt werden.
 */
export async function apiPutCustomProperty (
  id: EMK,
  keyValueJson: string
): Promise<Response> {
  return await sendRequest('put', `/officefiles/${id}/customProperty`, {
    body: keyValueJson
  })
}

export async function fetchOfficeDocumentTree (
  id: EMK,
  context: () => unknown
): Promise<Response | null> {
  const startingContext = context()
  const result = await sendRequest('get', `/officefiles/${id}/documentTree`)
  return startingContext === context() ? result : null
}
