import { createReactHook, createStore } from '@alinnert/tstate'
import { ApiMessage } from '../services/api/apiSchemas'
import { RequestStatus } from '../services/api/generic/types'
import { apiSetMessagesAsRead, fetchMessages } from '../services/api/messagesApiService'
import { removeFromArray } from '../utils/array'
import { handleRequestError } from './allStores'

/**
 * @author Andreas Linnert
 * @file Dieser Store enthält die Systembenachrichtigungen von SiteFusion.
 */

// #region store
export interface MessageStore {
  /** Eine Liste aller Nachrichten. */
  messages: ApiMessage[]
  pendingMessageReadStatuses: Array<ApiMessage['id']>
  status: RequestStatus
}

function getInitialState (): MessageStore {
  return {
    messages: [],
    pendingMessageReadStatuses: [],
    status: RequestStatus.ok
  }
}

const store = createStore(getInitialState())
export const useMessageStore = createReactHook(store)

const mutations = {
  reset (): void {
    store.set(getInitialState())
  },

  startLoading (): void {
    store.set({ status: RequestStatus.pending })
  },

  setMessages (messages: MessageStore['messages']): void {
    store.set({ status: RequestStatus.ok, messages })
  },

  setStatus (status: MessageStore['status']): void {
    store.set({ status })
  },

  startLoadingMessageReadStatus (...messageIds: Array<ApiMessage['id']>): void {
    const pendingStatuses = store.state.pendingMessageReadStatuses
    messageIds.forEach(id => {
      if (pendingStatuses.includes(id)) { return }
      pendingStatuses.push(id)
    })
    store.set({
      pendingMessageReadStatuses: [...store.state.pendingMessageReadStatuses]
    })
  },

  stopLoadingMessageReadStatus (...messageIds: Array<ApiMessage['id']>): void {
    messageIds.forEach(id => {
      removeFromArray(store.state.pendingMessageReadStatuses, id)
    })
    store.set({
      pendingMessageReadStatuses: [...store.state.pendingMessageReadStatuses]
    })
  },

  setMessagesReadStatus (...messageIds: Array<ApiMessage['id']>): void {
    const messages = store.state.messages
      .map(message => ({
        ...message,
        unread: messageIds.includes(message.id)
          ? false
          : message.unread
      }))
    store.set({ messages })
  }
}
// #endregion store

// #region actions
export function resetMessageStore (): void {
  mutations.reset()
}

export async function loadMessages (): Promise<void> {
  mutations.startLoading()

  try {
    const response = await fetchMessages()
    const messages = response.body as ApiMessage[]
    mutations.setMessages(messages)
  } catch (error) {
    mutations.setStatus(handleRequestError(error))
  }
}

export async function markMessagesAsRead (
  ...messageIds: Array<ApiMessage['id']>
): Promise<void> {
  mutations.startLoadingMessageReadStatus(...messageIds)

  try {
    await apiSetMessagesAsRead(...messageIds)
    mutations.setMessagesReadStatus(...messageIds)
  } catch (error) {
    handleRequestError(error)
  }

  mutations.stopLoadingMessageReadStatus(...messageIds)
}
// #endregion actions
