import { SpringConfig, UseTransitionProps, UseSpringProps } from 'react-spring'
import { CSSProperties } from 'react'

export const quickPreset: SpringConfig = {
  mass: 1,
  tension: 800,
  friction: 40
}

export const fadeTransition: UseTransitionProps<boolean, CSSProperties> = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 }
}

const zoomOutFactor = '0.8'
export const zoomOutScale = `scale(${zoomOutFactor}, ${zoomOutFactor})`
export const zoomInScale = 'scale(1, 1)'

interface ZoomFadeSpringProps {
  visible: boolean
  from?: boolean
}

export function zoomFadeSpring (
  { visible, from = false }: ZoomFadeSpringProps
): UseSpringProps<Pick<CSSProperties, 'opacity' | 'transform'>> {
  return {
    from: from ? { opacity: 0, transform: zoomOutScale } : undefined,
    opacity: visible ? 1 : 0,
    transform: visible ? zoomInScale : zoomOutScale
  }
}
