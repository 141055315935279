import { createReactHook, createStore } from '@alinnert/tstate'
import { ProductProperty } from '../data/productData'
import { ApiProduct } from '../services/api/apiSchemas'
import { RequestStatus } from '../services/api/generic/types'
import { fetchProducts } from '../services/api/productsApiService'
import { getStorageValue } from '../services/localStorageService'
import { noop } from '../utils/function'
import { excludeProperties, filterObjectPropertiesByValue } from '../utils/object'
import { handleRequestError } from './allStores'

/**
 * @author Andreas Linnert
 * @file Dieser Store verwaltet die dem Nutzer zugewiesenen Produkte
 */

// #region store
export const sortOrderValues: ['asc', 'desc'] = ['asc', 'desc']
export type SortOrderValue = typeof sortOrderValues[number]

export type ProductFilter = Partial<Record<ProductProperty, string>>
export type ProductSort = {
  sortColumn: ProductProperty
  sortOrder: SortOrderValue
} | {
  sortColumn: null
  sortOrder?: undefined
}

export interface ProductStore {
  /** Eine Liste aller Produkte. */
  products: ApiProduct[]
  status: RequestStatus
  /** Ein Objekt mit allen aktiven Filtern. */
  filter: ProductFilter
  /** Infos über die aktuell gültige Sortierung der Produkte. */
  sort: ProductSort
}

function getInitialState (): ProductStore {
  return {
    products: [],
    status: RequestStatus.ok,
    filter: {},
    sort: { sortColumn: null }
  }
}

const store = createStore(getInitialState())
export const useProductStore = createReactHook(store)

const mutations = {
  reset (): void {
    store.set(getInitialState())
  },

  startLoading (): void {
    store.set({ status: RequestStatus.pending })
  },

  setProducts (products: ProductStore['products']): void {
    store.set({ status: RequestStatus.ok, products })
  },

  setFilter (filter: ProductStore['filter']): void {
    store.set({ filter })
  },

  setSort (sort: ProductStore['sort']): void {
    store.set({ sort })
  },

  setStatus (status: ProductStore['status']): void {
    store.set({ status })
  }
}
// #endregion store

// #region actions
export function resetProductStore (): void {
  mutations.reset()
}

export function setFilter (filter: ProductStore['filter']): void {
  const cleanFilter = filterObjectPropertiesByValue(filter, (key, value) => {
    if (value === '') { return false }
    return true
  })
  mutations.setFilter(cleanFilter)
  loadProducts().catch(noop)
}

export function removeFilter (filterName: keyof ProductFilter): void {
  const newFilter = excludeProperties(
    store.state.filter, [filterName]
  )
  mutations.setFilter(newFilter)
  loadProducts().catch(noop)
}

export function setSort (sort: ProductSort): void {
  mutations.setSort(sort)
  loadProducts().catch(noop)
}

export async function loadProducts (): Promise<void> {
  mutations.startLoading()

  try {
    const defaultProductStatus = 1
    const token = getStorageValue('users:token', { parse: true })
    if (token === null) { return }

    const filter = getFilterQueryString({
      ...store.state.filter,
      assignabletasksexternalOrfilter: 'true',
      owntasksexternalOrfilter: 'true',
      candidatetasksexternalOrfilter: process.env.REACT_APP_CANDIDATE_MAPPING_DEFAULT === 'true' ? 'true' : ''
    })
    const sort = getSortQueryString(store.state.sort)
    const result = await fetchProducts({ filter, sort })
    const products = result.body as ApiProduct[]
    const safeProducts = products
      .map(
        (product): ApiProduct => ({
          ...product,
          statusId: product.statusId !== null
            ? product.statusId
            : defaultProductStatus
        })
      )
      .filter(
        (product, index, products) => {
          const productsWithCurrentId = products
            .filter(innerProduct => innerProduct.id === product.id)

          if (productsWithCurrentId.length === 1) { return true }

          const printbookForCurrentProductIdExists = productsWithCurrentId.some(
            innerProduct => innerProduct.publicationTarget === 'PRINTBOOK'
          )

          if (printbookForCurrentProductIdExists) {
            return product.publicationTarget === 'PRINTBOOK'
          }

          return productsWithCurrentId.indexOf(product) === 0
        }
      )

    mutations.setProducts(safeProducts)
  } catch (error) {
    mutations.setStatus(handleRequestError(error))
  }
}
// #endregion actions

// #region functions
function getFilterQueryString (
  filter: ProductStore['filter']
): string | undefined {
  const filterObjects = Object.entries(filter)
    .map(([field, value]) => ({ field, value }))

  if (filterObjects.length === 0) { return }

  const filterJson = JSON.stringify(filterObjects)
  return filterJson
}

function getSortQueryString (sort: ProductStore['sort']): string | undefined {
  if (sort.sortColumn === null) { return undefined }
  return `${sort.sortOrder === 'asc' ? '+' : '-'}${sort.sortColumn}`
}

export function productExists (id: ApiProduct['id']): boolean {
  return store.state.products.find(it => it.id === id) !== undefined
}
// #endregion functions
