import { mdiCollapseAllOutline, mdiEmailCheckOutline, mdiMessageOutline, mdiRefresh } from '@mdi/js'
import { RouteComponentProps } from '@reach/router'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { EmptyIndicator } from '../components/Generic/EmptyIndicator'
import { MessageItem } from '../components/ItemViews/MessageItem'
import { DockLayout, DockLayoutRef } from '../components/Layout/DockLayout'
import { LoadingContainer } from '../components/Layout/LoadingContainer'
import { Statusbar } from '../components/Statusbar/Statusbar'
import { StatusbarLabel } from '../components/Statusbar/StatusbarLabel'
import { Toolbar } from '../components/Toolbar/Toolbar'
import { ToolbarButton } from '../components/Toolbar/ToolbarButton'
import { useStoredScrollPosition } from '../hooks/useStoredScrollPosition'
import { ApiMessage } from '../services/api/apiSchemas'
import { RequestStatus } from '../services/api/generic/types'
import { loadMessages, markMessagesAsRead, useMessageStore } from '../stores/messageStore'
import { setMessagesScrollPosition, useUiStateStore } from '../stores/uiStateStore'
import { noop } from '../utils/function'
import { useTranslation } from 'react-i18next'

export const Messages = memo(function Messages (params: RouteComponentProps) {
  const { messages, status, pendingMessageReadStatuses } = useMessageStore()
  const [openMessageIds, setOpenMessages] =
    useState<Array<ApiMessage['id']>>([])
  const dockLayoutRef = useRef<DockLayoutRef>(null)
  const { messagesScrollPosition } = useUiStateStore()

  const { t } = useTranslation()

  useStoredScrollPosition({
    elementRef: dockLayoutRef.current?.centerRef,
    scrollPosition: messagesScrollPosition,
    setScrollPosition: setMessagesScrollPosition
  })

  const unreadMessagesCount = useMemo(
    () => messages.filter(message => message.unread).length,
    [messages]
  )

  const isOpen = useCallback(
    (id: ApiMessage['id']): boolean => openMessageIds.includes(id),
    [openMessageIds]
  )

  const handleSetRead = useCallback(
    (id: ApiMessage['id']) => {
      markMessagesAsRead(id).catch(noop)
    },
    []
  )

  const handleSetOpen = useCallback(
    (id: ApiMessage['id']) => {
      if (openMessageIds.includes(id)) {
        setOpenMessages(openMessageIds.filter(currentId => currentId !== id))
      } else {
        setOpenMessages([...openMessageIds, id])
      }
    },
    [openMessageIds]
  )

  const handleRefreshClick = useCallback(
    () => { loadMessages().catch(noop) },
    []
  )

  const handleMarkAllReadClick = useCallback(
    () => {
      const unreadMessageIds = messages
        .filter(message => message.unread)
        .map(message => message.id)

      markMessagesAsRead(...unreadMessageIds).catch(noop)
    },
    [messages]
  )

  const handleCloseAllClick = useCallback(
    () => {
      setOpenMessages([])
    },
    []
  )

  const getHeaderElement = useMemo(
    () => (
      <Toolbar>
        <ToolbarButton
          label={t('common.refresh')}
          icon={mdiRefresh}
          disabled={status === RequestStatus.pending}
          onClick={handleRefreshClick}
        />
        <ToolbarButton
          label={t('messages.markAllRead')}
          icon={mdiEmailCheckOutline}
          disabled={status !== RequestStatus.ok}
          onClick={handleMarkAllReadClick}
        />
        <ToolbarButton
          label={t('messages.collapseAll')}
          icon={mdiCollapseAllOutline}
          disabled={status !== RequestStatus.ok}
          onClick={handleCloseAllClick}
        />
      </Toolbar>
    ),
    [handleCloseAllClick, handleMarkAllReadClick, handleRefreshClick, status, t]
  )

  const getFooterElement = useMemo(
    () => (
      <Statusbar>
        <StatusbarLabel>
          {unreadMessagesCount > 0 ? t('messages.statusLabel.pluralUnread', {
            unreadCount: unreadMessagesCount,
            count: messages.length
          }) : t('messages.statusLabel.noneUnread', {
            count: messages.length
          })}
        </StatusbarLabel>
      </Statusbar>
    ),
    [messages.length, t, unreadMessagesCount]
  )

  const messageReadStatusIsPending = useCallback(
    (id: ApiMessage['id']): boolean => {
      return pendingMessageReadStatuses.includes(id)
    },
    [pendingMessageReadStatuses]
  )

  const getCenterElement = useMemo(
    () => (
      <LoadingContainer
        loadingText={t('messages.loading')}
        status={status}
        onRetry={() => { loadMessages().catch(noop) }}
      >
        {messages.map(item => (
          <MessageItem
            key={item.id}
            message={item}
            readStatusIsPending={messageReadStatusIsPending(item.id)}
            open={isOpen(item.id)}
            onSetRead={() => handleSetRead(item.id)}
            onSetOpen={() => handleSetOpen(item.id)}
          />
        ))}

        {messages.length === 0 && status === RequestStatus.ok ? (
          <EmptyIndicator
            text={t('messages.empty')}
            icon={mdiMessageOutline}
          />
        ) : null}
      </LoadingContainer>
    ),
    [handleSetOpen, handleSetRead, isOpen, messageReadStatusIsPending, messages, status, t]
  )

  return (
    <DockLayout
      className="messages-view"
      ref={dockLayoutRef}
      header={() => getHeaderElement}
      footer={() => getFooterElement}
      center={() => getCenterElement}
    />
  )
})
