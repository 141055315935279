import React, { memo, useMemo } from 'react'
import classNames from 'classnames'
import './EmptyIndicator.scss'
import { MdiIcon } from './MdiIcon'

interface Props {
  text: string
  icon?: string
  compact?: boolean
  dimmed?: boolean
  stretch?: boolean
}

export const EmptyIndicator = memo(function EmptyIndicator ({
  text,
  icon,
  compact = false,
  stretch = false,
  dimmed = false
}: Props) {
  const emptyIndicatorClasses = useMemo(
    () =>
      classNames(
        'empty-indicator',
        { 'empty-indicator--is-compact': compact },
        { 'empty-indicator--is-stretching': stretch },
        { 'empty-indicator--is-dimmed': dimmed }
      ),
    [compact, dimmed, stretch]
  )

  const iconScale = useMemo(() => (compact ? 3 : 4), [compact])

  return (
    <div className={emptyIndicatorClasses}>
      {icon !== undefined ? (
        <div className="empty-indicator__icon">
          <MdiIcon path={icon} scale={iconScale} />
        </div>
      ) : null}
      <div className="empty-indicator__text">{text}</div>
    </div>
  )
})
