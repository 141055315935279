import { useMatch } from '@reach/router'
import { routes } from '../../routes'
import { ApiTask } from '../../services/api/apiSchemas'
import { transformEMK } from '../../utils/system'

export interface UseHighlightedAssetResult {
  highlightedAssetId: ApiTask['dataResource']['id'] | null
  highlightedAssetTaskName: ApiTask['name'] | null
}

/**
 * Liefert Daten der Datei, die hervorgehoben werden soll.
 * Dies wird anhand der URL bestimmt.
 */
export function useHighlightedAsset (
  tasks: ApiTask[]
): UseHighlightedAssetResult {
  const match = useMatch(routes.productDetailHighlightTaskAsset().absolute)
  const taskAssetId = match?.['*'] ?? null

  if (taskAssetId === null) {
    return {
      highlightedAssetId: null,
      highlightedAssetTaskName: null
    }
  }

  const taskAssetEMK = transformEMK(taskAssetId, {
    inputSeparator: '/',
    outputSeparator: '|'
  })

  const highlightedAssetTask =
    tasks.find(
      (task) => task.dataResource.id === (taskAssetEMK ?? taskAssetId)
    ) ?? null

  const highlightedAsset = highlightedAssetTask?.dataResource ?? null

  return {
    highlightedAssetId: highlightedAsset?.id ?? null,
    highlightedAssetTaskName: highlightedAssetTask?.name ?? null
  }
}
