import React, { FC, useMemo } from 'react'
import { FilePreview } from 'sfportal_components_generic/FilePreview'
import { loadDataresourceForNodeId, useProductTreeStore } from 'sfportal_stores/productTreeStore'
import { isDefined } from 'sfportal_utils/global'
import { ChildrenProp } from '../../../../jsx'
import { getFileUriFromEMK } from '../../../../routes'
import { typesToShowDirectly } from '../../tasks/detail/constants'
import { useProductDetailStore } from '../../../../stores/productDetailStore'

interface Props extends ChildrenProp {}

export const Preview: FC<Props> = () => {
  const { selectedItem, nodes } = useProductTreeStore()
  const { product, search} = useProductDetailStore()

  const selectedNode = useMemo(() => {
    if (selectedItem === null) return null
    return nodes[selectedItem]
  }, [nodes, selectedItem])

  const filePreviewPath = useMemo<string | null>(() => {
    if (selectedNode === null) return null

    const dataResource = selectedNode.item.dataResource
    if (!dataResource?.contentType){
      if (product) {
        loadDataresourceForNodeId(product.id, selectedNode.id)
      }
      //return while the dataresource is loading
      return null
    }

    const contentType = dataResource.contentType

    if (isDefined(contentType) && typesToShowDirectly.includes(contentType)) {
      return getFileUriFromEMK(dataResource.id)
    }

    if (
      dataResource.type !== 'asset' &&
      dataResource.type !== 'editordocument'
    ) {
      return null
    }

    if (
      dataResource.previewPath !== null &&
      dataResource.previewPath.trim() !== ''
    ) {
      return getFileUriFromEMK(dataResource.previewPath)
    }

    return null
  }, [product, selectedNode])

  if (filePreviewPath === null) return null

  return <FilePreview path={filePreviewPath}
                      highlightingAvailable={search?.results?.find(single=>`${single.drid}|${single.id}` === selectedNode?.item.entityMetaKey) !== undefined} />
}
