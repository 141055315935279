import React, { FC, useEffect, useState } from 'react'
import './Person.scss'
import { Person } from './Person'
import { getListOfEntity } from '../../services/api/entityApiService'
import { noop } from '../../utils/function'

interface Props {
  value: number
}

interface User {
  id: number
  firstname: string
  lastname: string
}

export const User: FC<Props> = ({ value }) => {
  const [user, setUser] = useState<User>()

  useEffect(() => {
    const filter = encodeURI(`[{"field":"id", "operator": "=", "value": "${value}"}]`)
    getListOfEntity(`/sfusers?filter=${filter}`).then((response) => {
      const body = response.body[0]
      setUser({ id: body.id, firstname: body.firstname, lastname: body.lastname })
    }).catch(noop)
  }, [value])

  return (
    user ? <Person name={`${user.firstname} ${user.lastname}`} /> : null
  )
}
