import { mdiCheck } from '@mdi/js'
import React, { Children, InputHTMLAttributes, KeyboardEvent, memo, useCallback, useMemo } from 'react'
import { ChildrenProp } from '../../jsx'
import classNames from 'classnames'
import { MdiIcon } from '../Generic/MdiIcon'
import './Checkbox.scss'

interface Props extends ChildrenProp {
  name?: string
  id?: string
  checked: boolean
  disabled?: boolean
  checkboxProps?: InputHTMLAttributes<HTMLInputElement>
  onChange: (checked: boolean) => void
}

export const Checkbox = memo(function Checkbox ({
  children,
  checked,
  checkboxProps,
  name,
  id,
  disabled = false,
  onChange
}: Props) {
  const checkboxClasses = useMemo(
    () => classNames(
      'checkbox',
      { 'checkbox--is-disabled': disabled },
      { 'checkbox--is-checked': checked }
    ),
    [disabled, checked]
  )

  const handleCheckboxClick = useCallback(
    () => {
      if (disabled) { return }
      onChange(!checked)
    },
    [disabled, checked, onChange]
  )

  const handleCheckboxKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Space' || event.key === ' ') {
        handleCheckboxClick()
      }
    },
    [handleCheckboxClick]
  )

  return (
    <div
      className={checkboxClasses}
      tabIndex={0}
      onKeyDown={handleCheckboxKeyDown}
      onClick={handleCheckboxClick}
    >
      <input
        className="checkbox__input"
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={handleCheckboxClick}
        {...checkboxProps}
      />

      <div className="checkbox__border">
        {checked ? (
          <MdiIcon className="checkbox__icon" path={mdiCheck} />
        ) : null}
      </div>

      {Children.count(children) > 0 ? (
        <div className="checkbox__label">{children}</div>
      ) : null}
    </div>
  )
})
