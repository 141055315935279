import { mdiCheckboxMarkedCircleOutline, mdiCheckCircle, mdiCheckUnderlineCircle, mdiCircleEditOutline, mdiCommaCircleOutline, mdiDotsHorizontalCircleOutline, mdiEyeCircle, mdiFlashCircle, mdiHelpCircleOutline, mdiMinusCircle, mdiPlusCircleOutline, mdiShareCircle } from '@mdi/js'
import { Data } from '.'
import { ApiProduct } from '../services/api/apiSchemas'
import { getStatusLabelById } from '../stores/valuesStore'

export interface StatusData extends Data<NonNullable<ApiProduct['statusId']>> {
  label: string
  icon: string
}

export function getStatusData (id: ApiProduct['statusId']): StatusData | null {
  if (id === null) { return null }

  const base = { id, label: getStatusLabelById(id) ?? '' }

  switch (id) {
    case 1: return { ...base, icon: mdiPlusCircleOutline }
    case 2: return { ...base, icon: mdiCircleEditOutline }
    case 3: return { ...base, icon: mdiCheckUnderlineCircle }
    case 4: return { ...base, icon: mdiShareCircle }
    case 5: return { ...base, icon: mdiFlashCircle }
    case 6: return { ...base, icon: mdiEyeCircle }
    case 7: return { ...base, icon: mdiCheckCircle }
    case 8: return { ...base, icon: mdiCheckboxMarkedCircleOutline }
    case 9: return { ...base, icon: mdiCommaCircleOutline }
    case 10: return { ...base, icon: mdiMinusCircle }
    case 11: return { ...base, icon: mdiDotsHorizontalCircleOutline }
    default: return { ...base, icon: mdiHelpCircleOutline }
  }
}
