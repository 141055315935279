import {createReactHook, createStore} from '@alinnert/tstate'
import {ApiGenericResponse, ApiJwtAuthenticationDetails} from '../services/api/apiSchemas'
import {sendLogin, sendResetPassword, sendSetNewPassword, sendTokenLogin} from '../services/api/authApiService'
import {AjaxError} from '../services/api/generic/error'
import {RequestStatus} from '../services/api/generic/types'
import {switchContext} from '../services/api/superagent/context'
import {getStorageValue, removeStorageValue, setStorageValue} from '../services/localStorageService'
import {noop} from '../utils/function'
import {handleRequestError, resetAllStores} from './allStores'
import {loadMessages} from './messageStore'
import {loadProducts} from './productStore'
import {loadValues} from './valuesStore'
import {loadClients} from './clientStore'
import {currentURLWithoutQueryParams} from '../utils/url'
import {navigate} from '@reach/router'
import qs from 'query-string'
import {apiGetHearbeat} from '../services/api/heartbeatApiService'

/**
 * @author Andreas Linnert
 * @file Dieser Store enthält Infos zum aktuell angemeldeten Benutzer
 * und Authentifizierungs-Funktionen.
 */

// #region store
export enum LoginViewScreen {
  login, sendEMail, setNewPassword, confirm, multifactor, multifactorMethod, multifactorSetup
}

export interface UserStore {
  /**
     * Das JWT-Token des aktuell eingeloggten Users.
     * `null` bedeutet, dass kein Benutzer eingeloggt ist.
     * Dann wird die Login-Seite angezeigt.
     */
  currentUser: ApiJwtAuthenticationDetails | null
  tokenHeartbeatInterval: NodeJS.Timeout | null
  status: RequestStatus
  statusMessage: string
  currentLoginViewScreen: LoginViewScreen
  mfaMethods: string[]
  mfaQrCodeUrl: string
  mfaValidDaysSetting: number
}

function getInitialState (): UserStore {
  return {
    currentUser: null,
    tokenHeartbeatInterval: null,
    status: RequestStatus.ok,
    statusMessage: '',
    currentLoginViewScreen: LoginViewScreen.login,
    mfaMethods: [''],
    mfaQrCodeUrl: '',
    mfaValidDaysSetting: -1
  }
}

const store = createStore(getInitialState())
export const useUserStore = createReactHook(store)

const mutations = {
  reset (): void {
    store.set(getInitialState())
  },

  startLoading (): void {
    store.set({
      status: RequestStatus.pending,
      statusMessage: ''
    })
  },

  stopLoading (): void {
    store.set({
      status: RequestStatus.ok,
      statusMessage: ''
    })
  },

  setUser (currentUser: UserStore['currentUser']): void {
    store.set({
      currentUser,
      status: RequestStatus.ok,
      statusMessage: ''
    })

    if (currentUser !== null) {
      loadValues().catch(noop)
      loadMessages().catch(noop)
      loadProducts().catch(noop)
      loadClients().catch(noop)
    }
  },

  stopTokenHeartbeatInterval (): void {
    if (store.state.tokenHeartbeatInterval) {
      clearInterval(store.state.tokenHeartbeatInterval)
    }
    store.set({ tokenHeartbeatInterval: null })
  },

  setTokenHeartbeatInterval (tokenHeartbeatInterval: NodeJS.Timeout): void {
    store.set({ tokenHeartbeatInterval })
  },

  setStatus (
    status: UserStore['status'],
    statusMessage: UserStore['statusMessage'] = ''
  ): void {
    store.set({
      currentUser: null,
      status,
      statusMessage
    })
  },

  changeCurrentScreen (screen: UserStore['currentLoginViewScreen']): void {
    store.set({
      currentLoginViewScreen: screen,
      status: RequestStatus.ok,
      statusMessage: ''
    })
  },

  changeCurrentScreenWithStatus (screen: UserStore['currentLoginViewScreen'], status: RequestStatus): void {
    store.set({
      currentLoginViewScreen: screen,
      status: status,
      statusMessage: ''
    })
  },

  setMfaMethods (
    methods: UserStore['mfaMethods']
  ): void {
    store.set({
      mfaMethods: methods
    })
  },

  setMfaQrCodeUrl (
    url: UserStore['mfaQrCodeUrl']
  ): void {
    store.set({
      mfaQrCodeUrl: url
    })
  },

  setMfaValidDaysSetting (
    validDays: UserStore['mfaValidDaysSetting']
  ): void {
    store.set({
      mfaValidDaysSetting: validDays
    })
  }
}

// #endregion store

// #region actions
export function setupUserStore (): void {
  mutations.setUser(getStorageValue('users:token', { parse: true }))
  // Explizite Abfrage auf false, da bei null und undefined auch nicht das if ausgeführt werden soll.
  if (getStorageValue('users:tokenLogin', { parse: true }) === false) {
    // bei einem Login über den Token in der URL soll kein Heartbeat Interval gestartet werden und auch kein initialer refresh
    apiGetHearbeat().catch(noop)
    startTokenHeartbeat()
  }
}

export function resetUserStore (): void {
  mutations.reset()
}

export function startTokenHeartbeat (): void {
  if (store.state.currentUser === null) {
    // Interval soll nur gestartet werden, wenn der User einen Token hat.
    return
  }
  mutations.stopTokenHeartbeatInterval()
  mutations.setTokenHeartbeatInterval(setInterval(async () => {
    try {
      await apiGetHearbeat()
    } catch (error) {
      mutations.stopTokenHeartbeatInterval()
    }
  }, 480000))
}

export async function login (
  username: string, password: string, headers?: Headers
): Promise<void> {
  mutations.startLoading()

  try {
    const { body: token } = await sendLogin({
      username,
      password
    }, headers)
    setStorageValue('users:token', token)
    setStorageValue('users:tokenLogin', false)
    switchContext()
    await resetAllStores()
    mutations.setUser(token)
    // initialer heartbeat um beim login den token einmal zu refreshen.
    // Dies wir benötigt, um zwischen 2 unterscheidlichen logins (von Token auf normalen Login) den neuen token type zu setzen.
    apiGetHearbeat().catch(noop)
    startTokenHeartbeat()
  } catch (error) {
    if (error instanceof AjaxError) {
      if (error.status === 430) {
        // Validierungcode Multifaktor benötigt
        mutations.setMfaValidDaysSetting((error.body as {validDays: number}).validDays)
        setCurrentLoginViewScreen(LoginViewScreen.multifactor)
        return
      } else if (error.status === 432) {
        // Multifaktor Setup benötigt
        mutations.setMfaQrCodeUrl((error.body as {qrCodeUrl: string}).qrCodeUrl)
        setCurrentLoginViewScreen(LoginViewScreen.multifactorSetup)
        return
      } else if (error.status === 433) {
        // Multifaktor Methode muss selektiert werden
        mutations.setMfaMethods((error.body as {mfaMethods: string[]}).mfaMethods)
        setCurrentLoginViewScreen(LoginViewScreen.multifactorMethod)
        return
      }
    }

    mutations.setStatus(handleRequestError(error))
  }
}

export async function tokenLogin (
  token: string
): Promise<void> {
  mutations.startLoading()

  try {
    const {
      body: {
        id,
        username
      }
    } = await sendTokenLogin({ token })
    setStorageValue('users:token', {
      id,
      token,
      username
    })
    setStorageValue('users:tokenLogin', true)
    switchContext()
    await resetAllStores()
    mutations.setUser({
      id,
      token,
      username
    })
  } catch (error) {
    mutations.setStatus(handleRequestError(error))
  }
}

export function logout (): void {
  if (store.state.currentUser !== null) {
    removeStorageValue('users:token')
    mutations.stopTokenHeartbeatInterval()
    switchContext()
    resetAllStores().catch(noop)
  }

  if (qs.parse(globalThis.location.search).login_jwt !== undefined) {
    navigate(currentURLWithoutQueryParams('token')).catch(noop)
  }
}

export async function sendResetPasswordEMail (
  email: string
): Promise<void> {
  mutations.startLoading()

  try {
    const result = await sendResetPassword({ email })
    mutations.changeCurrentScreen(LoginViewScreen.setNewPassword)

    if (result.status === 228) {
      mutations.setStatus(RequestStatus.passwordCanBeChangedUserIsLocked)
      mutations.changeCurrentScreenWithStatus(LoginViewScreen.setNewPassword, RequestStatus.passwordCanBeChangedUserIsLocked)
    } else {
      mutations.changeCurrentScreen(LoginViewScreen.setNewPassword)
    }

  } catch (error) {
    mutations.setStatus(handleRequestError(error))
  }
}

export async function sendNewPassword (
  token: string,
  newPassword: string
): Promise<void> {
  mutations.startLoading()

  try {
    const result = await sendSetNewPassword({
      token,
      newPassword
    })

    //When the Status is 227 (user is locked)
    if (result.status === 227) {
      mutations.setStatus(RequestStatus.passwordChangedUserIsLocked)
      mutations.changeCurrentScreenWithStatus(LoginViewScreen.confirm, RequestStatus.passwordChangedUserIsLocked)
    } else {
      mutations.changeCurrentScreen(LoginViewScreen.confirm)
    }
  } catch (error) {
    try {
      const errorBody = (error as AjaxError).body

      if (typeof errorBody === 'string') {
        const response = JSON.parse(errorBody) as ApiGenericResponse
        mutations.setStatus(handleRequestError(error), response.message)
      } else {
        const message = errorBody.message as string
        mutations.setStatus(handleRequestError(error), message)
      }
    } catch (error) {
      mutations.setStatus(RequestStatus.unknownError)
    }
  }
}

export function setCurrentLoginViewScreen (screen: LoginViewScreen): void {
  mutations.changeCurrentScreen(screen)
}

export const getUserId = (): number | undefined => {
  return store.state.currentUser?.id
}
// #endregion actions
