import React, { ButtonHTMLAttributes, DOMAttributes, memo, useMemo } from 'react'
import classNames from 'classnames'
import { MdiIcon } from '../Generic/MdiIcon'
import './ToolbarButton.scss'

type ToolbarButtonType = 'default' | 'back'

interface Props extends
  DOMAttributes<HTMLButtonElement>,
  ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  icon?: string | null
  buttonStyle?: ToolbarButtonType
}

export const ToolbarButton = memo(function ToolbarButton ({
  label, icon = null, buttonStyle = 'default',
  ...rest
}: Props) {
  const toolbarButtonClasses = useMemo(
    () => classNames(
      'toolbar-button',
      { [`toolbar-button--type-${buttonStyle}`]: buttonStyle !== 'default' }
    ),
    [buttonStyle]
  )

  return (
    <div className={toolbarButtonClasses}>
      <button
        className="toolbar-button__button"
        {...rest}
      >
        {icon !== null ? (
          <span className="toolbar-button__icon">
            <MdiIcon path={icon} className="toolbar-button__icon-image" />
          </span>
        ) : null}
        {label !== undefined ? (
          <span className="toolbar-button__label">{label}</span>
        ) : null}
      </button>
    </div>
  )
})
