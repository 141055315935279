import { mdiImageOff } from '@mdi/js'
import { navigate } from '@reach/router'
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { MetadataRender, StatusRender, Teams } from 'sfportal_components_listitem_utils_listitemproduct/ListItemProductUtils'
import { FileInfoObj, getThumbnailUrl, routes } from '../../routes'
import { ApiProduct } from '../../services/api/apiSchemas'
import { noop } from '../../utils/function'
import { MdiIcon } from '../Generic/MdiIcon'
import { ListItem } from './ListItem'
import './ListItemProduct.scss'
import { apiImageGet } from '../../services/api/filesApiService'
import { useTranslation } from 'react-i18next'
import { resetProductDetailStore } from '../../stores/productDetailStore'

interface Props {
  product: ApiProduct
}

export const ListItemProduct: FC<Props> = function ListItemProduct ({
  product
}) {
  const { t } = useTranslation()

  async function handleItemClick (id: ApiProduct['id']): Promise<void> {
    await resetProductDetailStore()
    navigate(routes.productDetail(id.toString()).absolute).catch(noop)
  }

  const fallbackImage = useMemo(() => (
    <div className="list-item-product__image-placeholder">
      <MdiIcon
        path={mdiImageOff}
        scale={2}
        className="list-item-product__image-placeholder-icon"
      />
    </div>), [])

  const [coverImage, setCoverImage] = useState<ReactElement>(fallbackImage)

  const loadCoverThumbnailImage = useCallback(async (): Promise<ReactElement> => {
    if (product.cover === null) {
      return fallbackImage
    }
    const response = await apiImageGet(parseInt(product.cover.split('-')[1], 10))
    return <img src={getThumbnailUrl('sfbookcoverthumbnail', response?.body as FileInfoObj)} alt="" className="list-item-product__image" />
  }, [fallbackImage, product.cover])

  useEffect(() => {
    if (product.cover === null) {
      return
    }
    loadCoverThumbnailImage().then(result => setCoverImage(result)).catch(noop)
  }, [loadCoverThumbnailImage, product.cover])

  return (
    <ListItem
      className="list-item-product"
      onClick={() => handleItemClick(product.id)}
      hoverEffect={true}
    >
      <div className="list-item-product__image-col">{coverImage}</div>

      <div className="list-item-product__title-col">
        <div className="list-item-product__title">{product.description}</div>
        <div className="list-item-product__details">
          <MetadataRender product={product} />
          <Teams product={product} />
        </div>
      </div>

      <div className="list-item-product__todo-col">
        {product.claimedTasksCount !== null && product.claimedTasksCount > 0 ? (
          <div className="list-item-product__todo">
            <div className="list-item-product__todo-count">
              {product.claimedTasksCount}
            </div>
            <div className="list-item-product__todo-description">
              {product.claimedTasksCount === 1
                ? t('listItem.listItemProduct.taskClaimed.singular')
                : t('listItem.listItemProduct.taskClaimed.plural')}
            </div>
          </div>
        ) : null}

        {product.claimableTasksCount !== null &&
        product.claimableTasksCount > 0 ? (
            <div className="list-item-product__todo">
              <div className="list-item-product__todo-count">
                {product.claimableTasksCount}
              </div>
              <div className="list-item-product__todo-description">
                {product.claimableTasksCount === 1
                  ? t('listItem.listItemProduct.taskClaimable.singular')
                  : t('listItem.listItemProduct.taskClaimable.plural')}
              </div>
            </div>
          ) : null}
      </div>

      <StatusRender product={product} />
    </ListItem>
  )
}
