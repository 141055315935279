import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageTaskMetadataDescriptor } from './TaskMetadataDescriptors'

export function useImageMetadataDescriptors (): ImageTaskMetadataDescriptor[] {
  const { t } = useTranslation()

  const metadataDescriptors = useMemo<ImageTaskMetadataDescriptor[]>(() => {
    return [
      {
        label: t('metadata.properties.filename'),
        getValue: (item) => item.fileName
      },
      {
        label: t('metadata.properties.description'),
        getValue: (item) => item.description
      },
      {
        label: t('metadata.properties.resolution'),
        show: (item) =>
          typeof item.resolutionX === 'number' &&
          typeof item.resolutionY === 'number',
        getValue: (item) => {
          const width = item.resolutionX?.toString() ?? '0'
          const height = item.resolutionY?.toString() ?? '0'
          return `${width} × ${height} DPI`
        }
      },
      {
        label: t('metadata.properties.imagesize'),
        show: (item) =>
          typeof item.width === 'number' && typeof item.height === 'number',
        getValue: (item) => {
          const width = item.width?.toString() ?? '0'
          const height = item.height?.toString() ?? '0'
          return `${width} × ${height} px`
        }
      },
      {
        label: t('metadata.properties.copyright'),
        getValue: (item) => item.copyright
      },
      {
        label: t('metadata.properties.createdBy'),
        getValue: (item) => item.createdBy
      },
      {
        label: t('metadata.properties.createdDate'),
        getValue: (item) => item.createdDate
      },
      {
        label: t('metadata.properties.lastModifiedBy'),
        getValue: (item) => item.modifiedBy
      },
      {
        label: t('metadata.properties.lastModifiedDate'),
        getValue: (item) => item.modifiedDate
      }
    ]
  }, [t])

  return metadataDescriptors
}
