import { createReactHook, createStore } from '@alinnert/tstate'
import { ApiProductStatus, ApiValuesData } from 'sfportal_services_api/apiSchemas'
import { RequestStatus } from '../services/api/generic/types'
import { fetchValues } from '../services/api/valuesService'
import { getStorageValue, removeStorageValue, setStorageValue } from '../services/localStorageService'
import { handleRequestError } from './allStores'

/**
 * @author Andreas Linnert
 * @file Dieser Store enthält statische Werte, die beim Start der Anwendung
 * von der API abgerufen werden. Diese Werte sind benutzerunabhängig und
 * gelten nur für die Anwendung an sich.
 */

// #region store
interface ValuesStore {
  data: ApiValuesData | null
  status: RequestStatus
}

function getInitialState (): ValuesStore {
  return {
    data: null,
    status: RequestStatus.ok
  }
}

const store = createStore(getInitialState())
export const useValuesStore = createReactHook(store)

const mutations = {
  startLoading (): void {
    store.set({ status: RequestStatus.pending })
  },

  setValuesData (data: ValuesStore['data']): void {
    if (data !== null) { cacheValuesData(data) }
    store.set({ data, status: RequestStatus.ok })
  },

  setStatus (status: RequestStatus): void {
    store.set({ status })
  }
}
// #endregion store

// #region functions
function cacheValuesData (data: NonNullable<ValuesStore['data']>): void {
  setStorageValue('values:data', data)
  setStorageValue('values:timestamp', Date.now())
}

function getCachedValuesData (): ValuesStore['data'] {
  const timestamp = getStorageValue('values:timestamp', { parse: true })
  if (timestamp === null) { return null }
  const now = Date.now()
  const difference = now - timestamp
  const oneDay = 86_400_000 // 1000 * 60 * 60 * 24

  if (difference <= 0 || difference > oneDay) { return null }

  const valuesData = getStorageValue('values:data', { parse: true })
  if (valuesData === null) {
    removeStorageValue('values:timestamp')
  }

  return valuesData
}
// #endregion functions

// #region actions
export async function loadValues (): Promise<void> {
  const cachedValues = getCachedValuesData()
  if (cachedValues !== null) {
    mutations.setValuesData(cachedValues)
    return
  }
  mutations.startLoading()

  try {
    const { body } = await fetchValues()
    mutations.setValuesData(body)
  } catch (error) {
    mutations.setStatus(handleRequestError(error))
  }
}

export function getStatusLabelById (id: ApiProductStatus['id']): string | null {
  if (store.state.data === null) { return null }

  return store.state.data.productStatus
    .find(status => status.id === id)?.value ?? null
}
// #endregion actions
