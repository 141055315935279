import React, { memo, useEffect, useMemo } from 'react'
import { tokenLogin } from '../../stores/userStore'
import { RouteComponentProps, useLocation } from '@reach/router'
import qs from 'query-string'
import { noop } from '../../utils/function'
import { LoadingScreen } from './LoadingScreen'
import { useTranslation } from 'react-i18next'

/**
 * Interface für die Login View Properties.
 */
interface Props extends RouteComponentProps {
  /**
   * Gibt an ob der Websocket noch beim connect ist.
   */
  connectingSocket: boolean
}

export const TokenLoginView = memo(function TokenLoginView (
  {
    connectingSocket
  }: Props
) {
  const { t } = useTranslation()

  const location = useLocation()
  const queryParams = useMemo(
    () => qs.parse(location.search),
    [location.search]
  )
  const jwtValue = useMemo(() => {
    if (queryParams.login_jwt === null) return ''
    if (Array.isArray(queryParams.login_jwt)) return queryParams.login_jwt[0]
    return queryParams.login_jwt
  }, [queryParams.login_jwt])

  useEffect(() => {
    tokenLogin(jwtValue).catch(noop)
  }, [jwtValue])

  return (
    <>
      <LoadingScreen
        text={t('login.loading')}
        visible={connectingSocket}
      />
    </>
  )
})
