import { useCallback, useEffect, useState } from 'react'
import { ApiTask } from '../services/api/apiSchemas'

interface UseFormStateReturn {
  updateFormState: (name: string, value: unknown) => void
  formState: Record<string, unknown>
}

export function useFormState (
  formFields?: ApiTask['formFields']
): UseFormStateReturn {
  const [formState, setFormState] = useState<Record<string, unknown>>({})

  const updateFormState = useCallback(
    (name: string, value: unknown): void => {
      setFormState({ ...formState, [name]: value })
    },
    [formState]
  )

  useEffect(
    () => {
      for (const formField of formFields ?? []) {
        if (formState[formField.id] !== undefined) { continue }

        const value = formField.currentValue ?? formField.defaultValue ?? ''

        if (formField.type === 'boolean') {
          updateFormState(formField.id, value === 'true')
          continue
        }

        updateFormState(formField.id, value)
      }
    },
    [formFields, formState, updateFormState]
  )

  return { formState, updateFormState }
}
