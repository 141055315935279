import React, { memo, useCallback } from 'react'
import { Input } from '../Forms/Input'
import { FilterItem } from './FilterItem'

interface Props {
  label: string
  name: string
  id?: string
  value: string
  placeholder?: string
  onChange: (value: string) => void
}

export const TextFilterField = memo(function TextFilterField ({
  label, name, id = name, value, placeholder, onChange
}: Props) {
  const handleInputChange = useCallback(
    (value: string) => {
      onChange(value)
    },
    [onChange]
  )

  const handleEnabledChange = useCallback(
    (value: boolean) => {
      if (!value) { onChange('') }
    },
    [onChange]
  )

  return (
    <FilterItem
      title={label}
      initiallyEnabled={value !== ''}
      onEnabledChange={handleEnabledChange}
    >
      <Input
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onInput={handleInputChange}
      />
    </FilterItem>
  )
})
