import { useMemo } from 'react'
import { ApiTreeNode } from 'sfportal_services_api/apiSchemas'
import { useProductTreeStore } from 'sfportal_stores/productTreeStore'
import { isDefined } from 'sfportal_utils/global'
import { useCustomizeTreeList } from 'sfportal_views_productdetail_producttree_list/useCustomizeTreeList'
import { CustomizeTreeList } from './useCustomizeTreeList'
import { useGetProjectItemActions } from 'sfportal_views_productdetail_producttree_list/useGetProjectItemActions'
import { createTreeList } from '../../../../components/Tree/createTreeList'
import { FlatTreeNode } from '../../../../components/Tree/Tree'
import { TreeNodeAction } from '../../../../components/Tree/TreeNode'

interface UseProductTreeDataResult {
  items: Array<FlatTreeNode<ApiTreeNode>>
  openNodes: Array<FlatTreeNode<ApiTreeNode>>
  itemActions: (node: FlatTreeNode<unknown>) => TreeNodeAction[]
  selectedItem: string | null
}

export function useProductTreeData (): UseProductTreeDataResult {
  const { nodes, openNodeIds, relations, selectedItem } = useProductTreeStore()
  const getProjectItemActions: (
    node: FlatTreeNode<ApiTreeNode>,
    productItemActions: TreeNodeAction[],
  ) => TreeNodeAction[] = useGetProjectItemActions()
  const customizeTreeList: CustomizeTreeList = useCustomizeTreeList()

  const items = useMemo<Array<FlatTreeNode<ApiTreeNode>>>(() => {
    const defaultItems = createTreeList({ nodes, openNodeIds, relations })
    return customizeTreeList(defaultItems)
  }, [customizeTreeList, nodes, openNodeIds, relations])

  const openNodes = useMemo(() => {
    return openNodeIds.map((id) => nodes[id]).filter(isDefined)
  }, [nodes, openNodeIds])

  function itemActions (listNode: FlatTreeNode<unknown> ): TreeNodeAction[] {
    const node = listNode as FlatTreeNode<ApiTreeNode>
    const productItemActions: TreeNodeAction[] = []
    return getProjectItemActions(node, productItemActions)
  }

  return { items, openNodes, itemActions, selectedItem }
}
