import qs from 'query-string'
import { excludeProperties } from './object'

export function currentURLWithoutQueryParams (
  ...paramsToRemove: string[]
): string {
  const queryParams = qs.parse(globalThis.location.search)
  const filteredQueryParams = excludeProperties(queryParams, paramsToRemove)
  const queryString = qs.stringify(filteredQueryParams)
  return globalThis.location.pathname + (
    queryString !== '' ? `?${queryString}` : ''
  )
}
