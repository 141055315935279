import React, { ReactElement, SetStateAction } from 'react'
import { MdiIcon } from 'sfportal_components_generic/MdiIcon'
import { IListItemTaskAssetUtils } from 'sfportal_components_listitem_utils_interfaces/IListItemTaskAssetUtils'
import {ApiTaskDataResourceAsset, EMK} from '../../../../services/api/apiSchemas'
import {mdiFileDocumentOutline, mdiImageOutline, mdiLock} from "@mdi/js";

/**
 * Prüft ob der additional dropdown render überhaupt gerendert werden soll.
 * Produktseitig gibt dieser aktuell false zurück, da alles produktseitige
 * direkt in der task list Komponente geregelt werden sollte.
 */
function additionalDropDownRenderRequirement (): boolean {
  return false
}

/**
 * Liefert ein Array an Elemente, welche bei den Tasks als Status Icons
 * gerendert werden knnen. Produktseitig gibt es aktuell keine Status Icons.
 */
function getStatusIcons (): Array<ReactElement<typeof MdiIcon>> {
  return []
}

const AdditionalDropDownRender: IListItemTaskAssetUtils['AdditionalDropDownRender'] = () => null

const AdditionalRootRender: IListItemTaskAssetUtils['AdditionalRootRender'] = () => null

/**
 * Handelt den Klick auf das Editierungssymbol.
 *
 * @param id Die Id des Assets, welches editiert werden soll.
 * @param webdavUri Die WebdavUri zu den Asset.
 */
function handleEditAssetClick (
  id: EMK,
  webdavUri: string,
  setWebdavUri: (value: SetStateAction<string | null>) => void
): void {
  if (webdavUri === null) return

  // WebdavUri vorher auf `null` setzen, damit das Setzen der gleichen Uri
  // zweimal hintereinander als Änderung erkannt wird. Ansonsten würde
  // der Browser den Link ab dem zweiten Klick ignorieren und das Dokument
  // nicht versuchen zu öffnen.
  setWebdavUri(null)
  setWebdavUri(webdavUri)
}

/**
 * Zusätzliche Downlaod requirement Funktion, die den Download Button ein/ausblenden kann.
 * Im Produkt immer true.
 */
function additionCanUserDownloadRequirement (): boolean {
  return true
}

/**
 * Zusätzliche Upload requirement Funktion, die den Upload Button ein/ausblenden kann.
 * Im Produkt immer true.
 */
function additionCanUserUploadRequirement (): boolean {
  return true
}

/**
 * Delivers a MdiIcon with a Lock Symbol
 */
function getLockIcon(): ReactElement<typeof MdiIcon> {
  return <MdiIcon key={'lockicon'} path={mdiLock} />
}

/**
 * Delivers a MdiIcon. The Symbol of the Icon depends on the Dataresource.
 * @param dataResource Dataresource of the Asset
 */
function getFileIcon(dataResource: ApiTaskDataResourceAsset): ReactElement<typeof MdiIcon> {
  return <MdiIcon path={dataResource.contentType?.startsWith('image') ?? false
      ? mdiImageOutline
      : mdiFileDocumentOutline} />
}

/**
 * Utils mit Funktionen und Komponenten zu Task Asset List Items.
 */
export const ListItemTaskAssetUtils: IListItemTaskAssetUtils = {
  additionalDropDownRenderRequirement,
  getStatusIcons,
  getLockIcon,
  getFileIcon,
  AdditionalDropDownRender,
  AdditionalRootRender,
  handleEditAssetClick,
  additionCanUserDownloadRequirement,
  additionCanUserUploadRequirement
}
