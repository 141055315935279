import React, { memo, ReactNode, useMemo } from 'react'
import { ApiTask } from '../../services/api/apiSchemas'
import { ListItemGroup } from './ListItemGroup'
import { ListItemTaskAsset } from './ListItemTaskAsset'
import { ListItemTaskEditorDocument } from './ListItemTaskEditorDocument'
import { ListItemTaskProduct } from './ListItemTaskProduct'
import { ListItemTaskSmashdocs } from './ListItemTaskSmashdocs'
import { ListItemTaskUnknown } from './ListItemTaskUnknown'
import { ListItemGroupTaskUtils } from 'sfportal_components_listitem_utils_listitemgroup/ListItemGroupTaskUtils'
import { ProductDetailStore, useProductDetailStore, } from '../../stores/productDetailStore'

interface Props {
  title: string;
  titleSuffix?: string;
  highlightGroup?: boolean;
  highlightTasks?: Array<ApiTask['dataResource']['id']>;
  tasks: ApiTask[];
  isExpanded: boolean;
  onIsExpandedChange?: () => void;
}

export const ListItemGroupTasks = memo(function ListItemGroupTasks ({
  title,
  titleSuffix,
  tasks,
  isExpanded,
  highlightGroup,
  highlightTasks,
  onIsExpandedChange,
}: Props) {
  const {
    product,
    currentTask,
    taskActionStatus,
    currentFile
  } =
    useProductDetailStore()

  return (
    <ListItemGroup
      title={title}
      titleSuffix={titleSuffix}
      isOpen={isExpanded}
      highlight={highlightGroup}
      onIsOpenChange={onIsExpandedChange}
      actions={
        <>
          <ListItemGroupTaskUtils.GroupHeaderDropdown
            tasks={tasks}
            title={title}
          />
        </>
      }
    >
      {tasks.map(
        (task): ReactNode => (
          <ListItemGroupTask
            key={task.id}
            task={task}
            currentTask={currentTask}
            currentFile={currentFile}
            taskActionStatus={taskActionStatus}
            product={product}
            highlight={
              'uuid' in task.dataResource &&
              (highlightTasks?.includes(task.dataResource.uuid) ?? false)
            }
          />
        ),
      )}
    </ListItemGroup>
  )
})

interface ListItemGroupTaskProps {
  task: ApiTask;
  highlight: boolean;
  product: ProductDetailStore['product'];
  currentTask: ProductDetailStore['currentTask'];
  taskActionStatus: ProductDetailStore['taskActionStatus'];
  currentFile: ProductDetailStore['currentFile'];
}

const ListItemGroupTask = ({
  task,
  highlight,
  currentTask,
  product,
  taskActionStatus,
  currentFile,
}: ListItemGroupTaskProps) => {
  const ListItemTaskAssetMemo = useMemo(() => {
    return (
      <ListItemTaskAsset
        key={task.id}
        taskActionStatus={taskActionStatus.valueOf()}
        task={task}
        productId={product?.id}
        currentTaskId={currentTask?.id}
      />
    )
  }, [task, currentTask?.id, product?.id, taskActionStatus.valueOf()])

  const ListItemTaskSmashdocsMemo = useMemo(() => {
    return (
      <ListItemTaskSmashdocs
        task={task}
        highlight={highlight}
        taskActionStatus={taskActionStatus}
        currentFile={currentFile}
      />
    )
  }, [task, currentFile, highlight, taskActionStatus])

  const ListItemTaskProductMemo = useMemo(() => {
    return (
      <ListItemTaskProduct
        task={task}
        highlight={highlight}
        taskActionStatus={taskActionStatus}
        currentFile={currentFile}
      />
    )
  }, [task, currentFile, highlight, taskActionStatus])

  const ListItemTaskEditorDocumentMemo = useMemo(() => {
    return (
      <ListItemTaskEditorDocument
        task={task}
        highlight={highlight}
        taskActionStatus={taskActionStatus}
        currentFile={currentFile}
      />
    )
  }, [task, currentFile, highlight, taskActionStatus])

  const ListItemTaskUnknownMemo = useMemo(() => {
    return (
      <ListItemTaskUnknown
        key={task.id}
        task={task}
        taskActionStatus={taskActionStatus}
        currentFile={currentFile}
      />
    )
  }, [task, currentFile, highlight, taskActionStatus])

  switch (task.dataResource.type) {
    case 'asset':
      return ListItemTaskAssetMemo
    case 'smashdocs':
      return ListItemTaskSmashdocsMemo
    case 'product':
      return ListItemTaskProductMemo
    case 'editordocument':
      return ListItemTaskEditorDocumentMemo
    default:
      return ListItemTaskUnknownMemo
  }
}
