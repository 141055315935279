export function booleanFromString (input: string): boolean | undefined {
  if (input.toLowerCase() === 'true') {
    return true
  }
  if (input.toLowerCase() === 'false') {
    return false
  }
}

export function isTruthy (input: unknown): boolean {
  if (typeof input === 'number' && isNaN(input)) return false

  return (
    input !== undefined &&
    input !== null &&
    input !== '' &&
    input !== 0 &&
    input !== false
  )
}

export function isFalsy (input: unknown): boolean {
  return !isTruthy(input)
}
