import { useEffect } from 'react'

interface Params {
  handler: (this: Window, event: FocusEvent) => void
  eventOptions?: boolean | AddEventListenerOptions
}

export function useWindowBlur ({ handler, eventOptions }: Params): void {
  useEffect(() => {
    window.addEventListener('blur', handler, eventOptions)

    return () => {
      window.removeEventListener('blur', handler, eventOptions)
    }
  }, [handler, eventOptions])
}
