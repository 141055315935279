import React, { memo } from 'react'
import './ToolbarLabel.scss'

interface Props {
  label: string
}

export const ToolbarLabel = memo(function ToolbarLabel ({ label }: Props) {
  return (
    <div className="toolbar-label">
      <div className="toolbar-label__text">{label}</div>
    </div>
  )
})
