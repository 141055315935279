/**
 * Mit dieser Funktion kann Code in der Event-Loop eingereiht werden.
 * Ist eine async/await-kompatible Variante von `setTimeout(fn, 0)`
 * @param time Wie lange eine Funktion pausiert werden soll (in ms)
 * @example
 * // 0 Sekunden warten bzw. nachfolgenden Code in Event-Loop einreihen
 * await sleep()
 * // 2 Sekunden warten
 * await sleep(2000)
 */
export async function sleep (time = 0): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/return-await
  return new Promise((resolve, reject) => {
    globalThis.setTimeout(() => { resolve() }, time)
  })
}

/**
 * Prüft eine Variable, ob sie weder `null` noch `undefined` ist.
 * @param value Die Variable, die geprüft werden soll
 */
export function isDefined<T> (
  value: T | null | undefined
): value is NonNullable<T> {
  return value !== null && value !== undefined
}
