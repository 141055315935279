import { RefObject, useCallback, useState } from 'react'
import { useAnimationFrame } from '../../hooks/useAnimationFrame'
import {
  Alignment2D,
  Axis2D,
  getAlignment1D,
  getSafePosition
} from '../../utils/alignment'

export interface UseMenuPositionParams {
  menuRef: RefObject<HTMLDivElement>
  targetElement: HTMLElement | null
  targetAlignment: Alignment2D
  menuAlignment: Alignment2D
}

export interface UseMenuPositionReturn {
  menuTop: number
  menuLeft: number
}

export function useMenuPosition ({
  menuRef,
  targetElement,
  targetAlignment,
  menuAlignment
}: UseMenuPositionParams): UseMenuPositionReturn {
  const [menuTop, setMenuTop] = useState<number>(0)
  const [menuLeft, setMenuLeft] = useState<number>(0)

  const updateMenuPosition = useCallback(() => {
    if (targetElement === null) return

    const menuElement = menuRef.current
    if (menuElement === null) return

    const menuRect = menuElement.getBoundingClientRect()
    const targetRect = targetElement.getBoundingClientRect()

    const { start: startY } = getSafePosition({
      targetAlignment: getAlignment1D(targetAlignment, Axis2D.Y),
      menuAlignment: getAlignment1D(menuAlignment, Axis2D.Y),
      targetStart: targetRect.top,
      targetSize: targetRect.height,
      menuSize: menuRect.height,
      containerStart: 0,
      containerEnd: window.innerHeight
    })

    const { start: startX } = getSafePosition({
      targetAlignment: getAlignment1D(targetAlignment, Axis2D.X),
      menuAlignment: getAlignment1D(menuAlignment, Axis2D.X),
      targetStart: targetRect.left,
      targetSize: targetRect.width,
      menuSize: menuRect.width,
      containerStart: 0,
      containerEnd: window.innerWidth
    })

    if (startY !== menuTop) {
      setMenuTop(startY)
    }
    if (startX !== menuLeft) {
      setMenuLeft(startX)
    }
  }, [
    menuAlignment,
    menuLeft,
    menuRef,
    menuTop,
    targetAlignment,
    targetElement
  ])

  useAnimationFrame(updateMenuPosition)

  return { menuTop, menuLeft }
}
