import { navigate } from '@reach/router'
import classNames from 'classnames'
import React, { ButtonHTMLAttributes, DOMAttributes, FC, useMemo } from 'react'
import { useUserStore } from '../../stores/userStore'
import { noop } from '../../utils/function'
import { MdiIcon } from '../Generic/MdiIcon'
import './AppHeaderButton.scss'

interface Props
  extends DOMAttributes<HTMLButtonElement>,
  ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string
  counter?: number | string
  label?: string
  action?: string | (() => void)
  isCurrent?: boolean
}

export const AppHeaderButton: FC<Props> = function AppHeaderButton ({
  icon,
  counter,
  label,
  action,
  isCurrent = false,
  ...rest
}: Props) {
  const { currentUser } = useUserStore()
  const appHeaderButtonClasses = useMemo(
    () =>
      classNames(
        'app-header-button',
        {
          'app-header-button--hoverable':
            action !== undefined || rest.onMouseDown !== undefined
        },
        { 'app-header-button--current': isCurrent }
      ),
    [action, isCurrent, rest.onMouseDown]
  )

  const showCounter = useMemo((): boolean => {
    if (typeof counter === 'number') {
      return counter > 0
    }
    if (typeof counter === 'string') {
      return counter.length > 0
    }
    return false
  }, [counter])

  function handleButtonClick (): void {
    if (typeof action === 'function') {
      action()
      return
    }

    if (typeof action === 'string') {
      navigate(action).catch(noop)
    }
  }

  if (currentUser === null) {
    return null
  }

  return (
    <div className={appHeaderButtonClasses}>
      <button
        className="app-header-button__button"
        onClick={handleButtonClick}
        {...rest}
      >
        {icon !== undefined ? (
          <MdiIcon className="app-header-button__icon" path={icon} />
        ) : null}

        {label !== undefined || showCounter ? (
          <span className="app-header-button__text">
            {label !== undefined ? (
              <span className="app-header-button__label">{label}</span>
            ) : null}

            {showCounter ? (
              <span className="app-header-button__counter">{counter}</span>
            ) : null}
          </span>
        ) : null}
      </button>
    </div>
  )
}
