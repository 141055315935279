/**
 * @file Eine StringList ist eine Komme-getrennte Werteliste.
 * Alle Funktionen sind pur. D. h. sie ändern die als Parameter übergebenen
 * Werte nicht, sondern erzeugen neue StringLists.
 * @author Andreas Linnert
 */

const separator = ','

export type StringList = string

const notEmpty = (value: string): boolean => value !== ''

/** Erstellt eine StringList aus einem Array. */
export function strListFromArray (items: string[]): StringList {
  return items.filter(notEmpty).join(separator)
}

/** Erstellt ein Array aus einer StringList. */
export function strListToArray (list: StringList): string[] {
  return list.split(separator).filter(notEmpty)
}

/** Fügt einer StringList einen oder mehrere Werte hinzu. */
export function strListAdd (list: StringList, ...items: string[]): StringList {
  return [...list.split(separator), ...items].filter(notEmpty).join(separator)
}

/** Entfernt ein oder mehrere Items aus einer StringList. */
export function strListOmit (list: StringList, ...items: string[]): StringList {
  return strListToArray(list)
    .filter(item => !items.includes(item))
    .join(separator)
}

/** Prüft, ob eine StringList den angegeben Wert enthält. */
export function strListIncludes (list: StringList, item: string): boolean {
  return strListToArray(list).includes(item)
}

/**
 * Fügt einen Wert einer StringList hinzu, wenn er noch nicht existiert.
 * Existiert er bereits, wird er entfernt.
 */
export function strListToggle (list: StringList, item: string): StringList {
  const listItems = strListToArray(list)

  if (listItems.includes(item)) {
    return strListFromArray(
      listItems.filter(currentItem => currentItem !== item)
    )
  }

  return [...listItems, item].filter(notEmpty).join(separator)
}

/** Gibt die Anzahl der Einträge einer StringList zurück. */
export function strListLength (list: StringList): number {
  return strListToArray(list).length
}
