import React, { InputHTMLAttributes, memo, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useAutoFocus } from '../../hooks/useAutoFocus'
import './Input.scss'
import { FormInputType, Input } from './Input'
import { Select } from './Select'
import { Textarea } from './Textarea'
import { getListOfEntity } from '../../services/api/entityApiService'
import './FormInput.scss'
import { User } from '../Badges/User'

interface Datamap {
  data?: Data[]
  displayExpression?: string
  valueExpression?: string
  url?: string
}

interface Data {
  [key: string]: string
}

interface Entity {
  id: number
  identifier: string
  label: string

  [key: string]: string | number | boolean
}

interface Item {
  display: string
  value: string
  prefix?: string
  suffix?: string
}

// Diese Typen können erweitert werden. Vorerst gibt es nur ein Typ.
export type InfoType = 'error'

interface Info {
  type?: InfoType
  icon?: string
  caption?: string
  description?: string
}

interface Props {
  name: string
  id?: string
  label?: string
  role?: string
  type?: FormInputType
  datamap?: Datamap
  info?: Info | undefined | null
  defaultValue?: string
  placeholder?: string
  required?: boolean
  readonly?: boolean
  autoComplete?: string
  autoFocus?: boolean
  disabled?: boolean
  props?: InputHTMLAttributes<HTMLInputElement>
  onChange?: (name: string, value: string) => void
}

export const FormInput = memo(function FormField (
  {
    name,
    autoComplete,
    placeholder,
    disabled,
    required = false,
    readonly = false,
    datamap,
    label = '',
    role,
    info,
    defaultValue,
    id = name,
    autoFocus = false,
    type = 'text',
    onChange
  }: Props) {
  const inputRef = useRef<HTMLInputElement>(null)

  useAutoFocus({
    ref: inputRef,
    enabled: autoFocus
  })

  const [items, setItems] = useState<Item[]>([])

  const [initialValuesLoaded, setInitialValuesLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (datamap && !initialValuesLoaded) {
      if (datamap.data) {
        const display = datamap.displayExpression ? datamap.displayExpression.replaceAll(/[{}]/g, '') : 'identifier'
        const value = datamap.valueExpression ? datamap.valueExpression.replaceAll(/[{}]/g, '') : 'id'
        const _items: Item[] = []

        datamap.data.forEach(data => {
          if (data[display] && data[value]) {
            const item: Item = {
              display: data[display],
              value: data[value]
            }
            _items.push(item)
          }
        })
        if (typeof onChange === 'function' && _items.length > 0) {
          onChange(name, _items[0].value)
        }
        setItems(_items)
        setInitialValuesLoaded(true)
      } else if (datamap.url) {
        getListOfEntity(datamap.url).then((response) => {
          if (response) {
            const entities: Entity[] = response.body
            const _items: Item[] = []

            entities.forEach(entity => {
              const display = datamap.displayExpression ? entity[datamap.displayExpression.replaceAll(/[{}]/g, '')].toString() : (entity.label || entity.identifier)?.toString()
              const _item: Item = {
                display,
                value: datamap.valueExpression ? entity[datamap.valueExpression.replaceAll(/[{}]/g, '')].toString() : entity.id?.toString()
              }
              _items.push(_item)
            })
            if (typeof onChange === 'function' && _items.length > 0) {
              onChange(name, _items[0].value)
            }
            setItems(_items)
            setInitialValuesLoaded(true)
          }
        })
      }
    }
  }, [datamap, name, onChange, initialValuesLoaded])

  useEffect(() => {
    if (initialValuesLoaded) {
      return
    }

    if (typeof onChange === 'function' && items.length > 0) {
      onChange(name, items[0].value)
    }
  }, [items, initialValuesLoaded, name, onChange])

  const convertItemArrayToRecord = (array: Item[]): Record<Item['display'], Item['value']> => {
    return array.reduce<Record<Item['display'], Item['value']>>((acc, item) => ({
      ...acc,
      [item.value]: item.display
    }), {})
  }

  const handleChange = (name: string, value: string): void => {
      onChange?.(name, value)
  }

  const inputTypes = ['hidden', 'text', 'search', 'password', 'url', 'email', 'tel', 'number', 'range', 'week', 'month', 'date', 'datetime', 'datetime-local', 'file']

  const item = useMemo(() => items.find((item: Item) => item.value === defaultValue), [items, defaultValue])

  const staticDisplayValue = useMemo(() => item ? item.display : defaultValue, [item, defaultValue])

  const getFormFieldTemplate = (): ReactElement | string | undefined => {
    let content: string | ReactElement = ''
    if (readonly) {
      if (staticDisplayValue) {
        if (role === 'user') {
          content = <User value={parseInt(staticDisplayValue, 10)} />
        } else {
          content = staticDisplayValue
        }
      }
      return (
        <div className="static">
          <label className="input__label">{label}</label>
          <span>{content}</span>
        </div>
      )
    } else if (type !== 'static') {
      if (inputTypes.includes(type)) {
        return (
          <Input
            label={label}
            type={type}
            onInput={(value: string) => handleChange(name, value)}
            value={defaultValue}
            placeholder={placeholder}
            id={id}
            name={name}
            required={required}
            autoComplete={autoComplete}
            disabled={disabled}
          />
        )
      } else if (type === 'select') {
        return (
          <Select
            name={name}
            onChange={(value: string) => handleChange(name, value)}
            items={convertItemArrayToRecord(items)}
            label={label}
            required={required}
            value={defaultValue}
            disabled={disabled}
          />
        )
      } else if (type === 'textarea') {
        return (
          <Textarea
            name={name}
            onInput={(value: string) => handleChange(name, value)}
            label={label}
            required={required}
            value={defaultValue}
            disabled={disabled} />
        )
      }
    }
  }

  const getInfoTemplate = (): ReactElement | undefined => {
    if (!info) {
      return
    }
    const caption = info?.caption ? <h3>{info.icon ? `${info.icon} ${info?.caption}` : info?.caption}</h3> : null
    const description = info?.description ? <div>{info?.description}</div> : null
    return (
      <div className="form__info">
        {caption}
        {description}
      </div>
    )
  }

  return (
    <div className={`form__field ${info && info.type ? info.type : ''}`}>
      <div> {getFormFieldTemplate()}</div>
      {getInfoTemplate()}
    </div>
  )
})
