import React, { FC } from 'react'
import { ImagePreview } from 'sfportal_components_generic/ImagePreview'
import { MetadataItems } from 'sfportal_components_generic/MetadataItems'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import { reloadCurrentFile } from 'sfportal_stores/productDetailStore'
import { noop } from 'sfportal_utils/function'
import { LoadingContainer } from '../../../components/Layout/LoadingContainer'
import { ChildrenProp } from '../../../jsx'
import { ImageTaskMetadataDescriptor } from '../../ProductDetail/tasks/detail/TaskMetadataDescriptors'
import { EMK } from '../../../services/api/apiSchemas'
import { AssetForm } from '../../Form/AssetForm'
import { useTranslation } from 'react-i18next'

interface Props extends ChildrenProp {
  imagePath: string
  id: EMK
  metadata: ImageTaskMetadataDescriptor[]
  metadataStatus: RequestStatus
}

export const ImagePreviewContent: FC<Props> = ({
  imagePath,
  id,
  metadata,
  metadataStatus
}) => {
  const { t } = useTranslation()

  if (imagePath === null) return null

  return (
    <>
      <div className="product-detail-view/task-detail__preview-image-container">
        <ImagePreview src={imagePath} alt="" />
      </div>

      <div className="product-detail-view/task-detail__metadata-container">
        <div className="product-detail-view/task-detail__headline">
          Metadaten
        </div>
        {
          process.env.REACT_APP_ENABLE_SF_METADATA && process.env.REACT_APP_ENABLE_SF_METADATA === 'true'
            ? <AssetForm className="asset-metadata" formTypes={['typeForOverview', 'type']} entityMetakeys={[id]} readonly={true} />
            : <LoadingContainer
              loadingText={t('metadata.loading')}
              onRetry={() => {
                reloadCurrentFile().catch(noop)
              }}
              status={metadataStatus}
              stretch={true}
            >
              <div className="product-detail-view/task-detail__metadata">
                <MetadataItems data={metadata} />
              </div>
            </LoadingContainer>
        }
      </div>
    </>
  )
}
