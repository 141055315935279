import { Router } from '@reach/router'
import React, { FC, Suspense, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppUtils } from 'sfportal_core_utils_implementations/AppUtils'
import 'sfportal_localization/i18n'
import { loadPortalData } from 'sfportal_stores/portalDataStore'
import { useUiStateStore } from 'sfportal_stores/uiStateStore'
import { Profile } from 'sfportal_views/Profile'
import './App.scss'
import { AppHeader } from './components/AppHeader/AppHeader'
import { ErrorContainer } from './components/Layout/ErrorContainer'
import { routes } from './routes'
import { StaticStompClient } from './services/multicast/staticStompClient'
import { loadValuesStart } from './stores/valuesStartStore'
import { useRedirects } from './useRedirects'
import { noop } from './utils/function'
import { BackgroundTasks } from './views/BackgroundTasks'
import { Home } from './views/Home'
import { LoginView } from './views/Login/LoginView'
import { TokenLoginView } from './views/Login/TokenLoginView'
import { Messages } from './views/Messages'
import { NotFound } from './views/NotFound'
import { ProductDetailView } from './views/ProductDetail/ProductDetailView'
import { Products } from './views/Products'

export const App: FC = () => {
  const { t } = useTranslation()

  const uiState = useUiStateStore()

  const [connectingSocket, setConnectingSocket] = useState<boolean>(!(process.env.REACT_APP_MOCKED_BACKEND && process.env.REACT_APP_MOCKED_BACKEND === 'true'))
  const [forceReload, setForceReload] = useState<boolean>(false)

  useRedirects()

  useEffect(() => {
    // Initialisiert den Stomp Client Singleton. Der connect muss gegeben sein,
    // bevor die Anwendung vollständig lädt, da sonst alle Subscriptions
    // fehlschlagen. Außerdem könnten sonst falsche Daten angezeigt werden, wenn
    // die Subscription noch nicht da ist, aber die dargestellten Daten in der
    // Zeit zwischen initialem fetch und der Subscription geändert wurden.
    StaticStompClient.getInstance(() => {
      setForceReload(true)
    }).then(_ => setConnectingSocket(false)).catch(noop)
  }, [])

  useEffect(() => {
    loadPortalData().catch(noop)
    loadValuesStart().catch(noop)
  }, [])

  const showSocketErrorMessage = useMemo(() => {
    return forceReload && !uiState.appExited
  }, [forceReload, uiState.appExited])

  return (
    <div className="app">
      <div className="app__header">
        <Suspense fallback={<div />}>
          <AppHeader />
        </Suspense>
      </div>

      <div className="app__content">
        <Suspense fallback={<div />}>
          {showSocketErrorMessage
            ? <ErrorContainer errorText={t('app.connectionLost')}
              retryText={t('app.reload')}
              onRetry={() => {
                window.location.reload()
              }} />
            : <Router className="app__router">
              <LoginView path={routes.login.absolute} connectingSocket={connectingSocket} />
              <TokenLoginView path={routes.tokenLogin.absolute} connectingSocket={connectingSocket} />
              <Home path={routes.home.relative} connectingSocket={connectingSocket}>
                <Products path={routes.products.absolute} />
                <ProductDetailView path={routes.productDetail().absoluteWithWildcard} />
                <Messages path={routes.messages.absolute} />
                <BackgroundTasks path={routes.tasks.absolute} />
                <Profile path={routes.user.absolute} />
                {AppUtils.getAdditionalHomes()}
              </Home>
              <NotFound default />
            </Router>
          }
        </Suspense>
      </div>
      <div id={'download-anchor'} />
    </div>
  )
}
