import React, { FC } from 'react'
import { ZoomFadeDiv } from '../../components/Animations/ZoomFadeDiv'
import { Button } from '../../components/Forms/Button'
import { CenteredLayer } from '../../components/Layout/CenteredLayer'
import { LoginViewScreen, setCurrentLoginViewScreen, useUserStore } from '../../stores/userStore'
import { useTranslation } from 'react-i18next'

/**
 * Zeigt einen Screen der die OTP Authentifizierung abschließt in dem ein QR Code angezeigt wird der mit der Authenticator App gescannt werden kann.
 *
 * @returns {FC} Die Komponente
 */
export const MultifactorSetupScreen: FC = () => {
  const {
    currentLoginViewScreen,
    mfaQrCodeUrl
  } = useUserStore()

  const { t } = useTranslation()

  /**
   * Setzt den Screen zum Login zurück.
   */
  const handleToLoginClick = (): void => {
    setCurrentLoginViewScreen(LoginViewScreen.login)
  }

  return (
    <ZoomFadeDiv
      className="login-view__view-item"
      visible={currentLoginViewScreen === LoginViewScreen.multifactorSetup}
    >
      <CenteredLayer>
        <h1 className="login-view__title">{t('login.heading')}</h1>

        <p className="login-view__text">
          {t('login.multifactor.setup.otp.instruction')}
        </p>

        <div className={'login-view__qr-image'}>
          <img src={mfaQrCodeUrl} alt={''}/>
        </div>

        <div className="login-view__buttons">
          <Button
            type="button"
            buttonStyle="primary"
            onClick={handleToLoginClick}
          >{t('login.buttons.backToLogin')}</Button>
        </div>
      </CenteredLayer>
    </ZoomFadeDiv>
  )
}
