export function filterObjectPropertiesByValue<
  T extends Record<string, unknown>
> (
  target: T,
  filterFn: (key: keyof T, value: T[keyof T]) => boolean
): Partial<T> {
  const result: Partial<T> = {}

  for (const key in target) {
    const pass = filterFn(key, target[key])
    if (pass) { result[key] = target[key] }
  }

  return result
}

export function excludeProperties<
  T extends Record<string, unknown>,
  U extends keyof T,
  V extends Omit<T, U>
> (source: T, propNames: U[]): V {
  const result: Record<string, unknown> = {}
  const sourceEntries = Object.entries(source)

  for (const [key, value] of sourceEntries) {
    if (!propNames.includes(key as U)) {
      result[key] = value
    }
  }

  return result as V
}
