import React, { FC, ReactElement } from 'react'
import { LabelItem } from 'sfportal_components_badges/LabelItem'
import { Status } from 'sfportal_components_badges/Status'
import { ContentSectionProps, IProductDetailViewUtils, Props } from 'sfportal_views_productdetail_interfaces/IProductDetailViewUtils'
import { useTranslation } from 'react-i18next'
import { Teams } from '../../../components/ListItem/utils/listitemproduct/ListItemProductUtils'

interface AdditionalMetadataType {
  name: string
  label?: string
  type?: string
}

/**
 * Komponente um Metadaten zu Rendern.
 */
function MetadataDetailsRender ({ product }: Props): ReactElement {
  const { t } = useTranslation()
  const { additionalMetadata } = product

  return <>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.edition')}>{product.edition?.toString()}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.isbn')}>{product.isbn}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.publisher')}>{product.publisher}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.lectorTeam')}>{product.lectorTeam}</LabelItem>
    <LabelItem label={t('listItem.utils.listItemProduct.metadata.clientIdentifier')}>{product.clientIdentifier}</LabelItem>
    <Status label={t('listItem.listItemAsset.status')} value={product.statusId}/>
    {
      additionalMetadata !== undefined && additionalMetadata !== null &&
        Object.keys(additionalMetadata).map(key => {
          const config = additionalMetadata[key]?.hasOwnProperty('config') ? (JSON.parse(additionalMetadata[key].config)).metadata[0] as AdditionalMetadataType : null
          let label = t('listItem.utils.listItemProduct.metadata.' + key)
          if (config?.label !== null && config?.label !== undefined) {
            label = config.label
          }
          return (
            <LabelItem key={key} label={label}>{additionalMetadata[key]['']}</LabelItem>
          )
        })
    }
  </>
}

/**
 * Komponente für Teams der einzelnen Produkte.
 */
const TeamsRender: FC<Props> = (props): ReactElement => {
  return <Teams {...props} />
}

/**
 * Komponente um den Inhalt der Produktseite zu rendern.
 */
function ContentSectionProductDetail ({ children }: ContentSectionProps): ReactElement {
  return <>{children}</>
}

/**
 * Gibt an, ob weitere custom Content gerendert werden soll.
 */
async function additionalContentSectionsRequirement (): Promise<boolean> {
  return false
}

/**
 * Komponente zur Darstellung weiterer Produktdetail Elemente. Wird nur gerendert, wenn die additional Prüfung true ist.
 *
 * @constructor Funktion Komponente.
 */
function AdditionalContentSections (): ReactElement | null {
  return null
}

export const ProductDetailViewUtils: IProductDetailViewUtils = {
  MetadataDetailsRender,
  ContentSectionProductDetail,
  additionalContentSectionsRequirement,
  AdditionalContentSections,
  TeamsRender
}
