import { useState } from 'react'
import { Response } from 'superagent'
import { RequestStatus } from '../services/api/generic/types'
import { handleRequestError } from '../stores/allStores'

export type Callback = () => Promise<Response>
export type LoadDataFn<T> = () => Promise<T | null>
export type UseLocalAjaxReturn<T> = [T | null, RequestStatus, LoadDataFn<T> ]

/**
 * Dieser Hook erlaubt es, einen Ajax-Request abzusetzen, dessen
 * Status und Daten lokal in der aktuellen Instanz einer Komponente
 * gespeichert werden. Das ist hilfreich, wenn der globale Store
 * für bestimmte Arten von Daten ungeeignet sein sollte.
 * @param callback
 * Ein Callback, das den Ajax-Request absendet und ein `Promise<Response>`
 * zurückliefert.
 * @returns
 * `[data, status, fetchData()]`
 * `data`: Die Daten, die der Http-Request zurückliefert
 * `status`: Der `RequestStatus` für diesen Request
 * `fetchData`: Eine Funktion, die den Request anstoßt
 */
export function useHttpRequest<T> (callback: Callback): UseLocalAjaxReturn<T> {
  const [data, setData] = useState<T>()
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.ok)

  async function fetchData (): Promise<T | null> {
    setStatus(RequestStatus.pending)

    try {
      const { body } = await callback()
      setStatus(RequestStatus.ok)
      setData(body as T)
      return body
    } catch (error) {
      setStatus(handleRequestError(error))
      return null
    }
  }

  return [data ?? null, status, fetchData]
}
