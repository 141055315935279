import React, {
  CSSProperties,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { createPortal } from 'react-dom'
import { animated, useTransition } from 'react-spring'
import { usePortalMountTarget } from '../../hooks/usePortalMountTarget'
import { useWindowBlur } from '../../hooks/useWindowBlur'
import { ChildrenProp } from '../../jsx'
import { Alignment2D } from '../../utils/alignment'
import { createSignal, useSignal } from '../../utils/signals'
import { fadeTransition, quickPreset } from '../../values/springPresets'
import './Menu.scss'
import { MenuContext } from './MenuContext'
import { useMenuPosition } from './useMenuPosition'
import { useGlobalMouseDown } from '../../hooks/useGlobalMouseDown'

const closeMenusSignal = createSignal<{ except?: RefObject<HTMLDivElement> }>()

interface Props extends ChildrenProp {
  open: boolean
  targetElement: HTMLElement | null
  targetAlignment?: Alignment2D
  menuAlignment?: Alignment2D
  onOpenChange: (open: boolean) => void
}

export const Menu = memo(function Menu ({
  children,
  open,
  targetElement,
  targetAlignment = Alignment2D.LEFT_BOTTOM,
  menuAlignment = Alignment2D.LEFT_TOP,
  onOpenChange
}: Props) {
  const menuRef = useRef<HTMLDivElement>(null)
  const { mountTarget } = usePortalMountTarget()
  const { menuTop, menuLeft } = useMenuPosition({
    menuRef,
    targetElement,
    targetAlignment,
    menuAlignment
  })
  const transitions = useTransition(open, null, {
    ...fadeTransition,
    config: quickPreset,
    unique: true
  })

  useGlobalMouseDown({
    excludeRef: menuRef,
    handler () {
      onOpenChange(false)
    }
  })

  useWindowBlur({
    handler () {
      onOpenChange(false)
    }
  })

  type TransitionProps = typeof transitions[number]['props']

  const menuStyle = useMemo(
    (): CSSProperties => ({
      position: 'absolute',
      transform: `translate3d(${menuLeft}px, ${menuTop}px, 0)`
    }),
    [menuLeft, menuTop]
  )

  const getAnimatedDivStyles = useCallback(
    (props: TransitionProps): CSSProperties => ({
      ...props,
      visibility:
        props.opacity?.interpolate((o) => (o === 0 ? 'hidden' : 'visible')) ??
        'visible'
    }),
    []
  )

  const closeMenu = useCallback(() => {
    onOpenChange(false)
  }, [onOpenChange])

  useSignal(closeMenusSignal, ({ except }) => {
    if (menuRef === except) {
      return
    }
    onOpenChange(false)
  })

  useEffect(() => {
    if (!open) return
    closeMenusSignal.send({ except: menuRef })
  }, [open])

  const menu = useMemo(
    () => (
      <MenuContext.Provider value={{ closeMenu }}>
        <div style={menuStyle} ref={menuRef}>
          {transitions.map(({ item, key, props }) =>
            item ? (
              <animated.div
                className="menu"
                key={key}
                style={getAnimatedDivStyles(props)}
              >
                {children}
              </animated.div>
            ) : null
          )}
        </div>
      </MenuContext.Provider>
    ),
    [children, closeMenu, getAnimatedDivStyles, menuStyle, transitions]
  )

  return createPortal(menu, mountTarget)
})
