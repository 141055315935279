import { createReactHook, createStore } from '@alinnert/tstate'
import { ClientModel } from '../services/api/apiSchemas'
import { RequestStatus } from '../services/api/generic/types'
import { handleRequestError } from './allStores'
import { getListOfEntity } from '../services/api/entityApiService'

/**
 * @author Artunc Ekici
 * @file Dieser Store enthält die Sfclients von SiteFusion.
 */

// #region store
export interface ClientStore {
  /** Eine Liste aller Clients. */
  clients: ClientModel[]
  clientsForSelectItems: Record<string, string>
  status: RequestStatus
}

function getInitialState (): ClientStore {
  return {
    clients: [],
    clientsForSelectItems: {},
    status: RequestStatus.ok
  }
}

const store = createStore(getInitialState())

export const useClientStore = createReactHook(store)

const convertClientsToRecord = (
  array: ClientModel[]
): Record<ClientModel['identifier'], ClientModel['identifier']> => {
  return array.reduce<Record<ClientModel['identifier'], ClientModel['identifier']>>(
    (acc, item) => ({ ...acc, [item.identifier]: item.identifier }),
    {}
  )
}

const mutations = {
  reset (): void {
    store.set(getInitialState())
  },

  startLoading (): void {
    store.set({ status: RequestStatus.pending })
  },

  setClients (clients: ClientStore['clients']): void {
    store.set({ status: RequestStatus.ok, clients })
  },

  setClientsForSelectItems (clients: ClientStore['clients']): void {
    const selectItems = { '': '', ...convertClientsToRecord(clients) }
    store.set({ status: RequestStatus.ok, clientsForSelectItems: selectItems })
  },

  setStatus (status: ClientStore['status']): void {
    store.set({ status })
  }
}
// #endregion store

// #region actions
export function resetClientStore (): void {
  mutations.reset()
}

export async function loadClients (): Promise<void> {
  mutations.startLoading()

  try {
    const response = await getListOfEntity('sfclients')
    const clients = response.body as ClientModel[]
    mutations.setClients(clients)
    mutations.setClientsForSelectItems(clients)
  } catch (error) {
    mutations.setStatus(handleRequestError(error))
  }
}
// #endregion actions
