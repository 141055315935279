import { mdiFile } from '@mdi/js'
import React, { forwardRef, Ref, useCallback } from 'react'
import { TreeNode, TreeNodeAction } from './TreeNode'
import classNames from 'classnames'
import { ItemContent, Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { noop } from '../../utils/function'

/**
 * Ein generalisierte Repräsentation eines Baumknotens,
 * die für die Verwendung in der Tree-Komponente notwendig ist.
 * Der Typ-Parameter T ist das originale Item,
 * das jede beliebige Form haben kann.
 */
export interface FlatTreeNode<T> {
  /**
   * Eine ID, die aus den Infos aus `item` bezogen wird, aber unabhängig von
   * `item` gespeichert wird. Dadurch kann man immer darauf zugreifen, ohne
   * `item` und `T` in `FlatTreeNode<T>` zu kennen.
   */
  id: string
  /**
   * Die Zahl der Ebene dieses Knotens im Baum.
   * - `0` = Root-Ebene
   * - `1` = Unterebene
   * - usw.
   */
  level: number
  /**
   * Ein Array mit den IDs aller übergeordneten Knoten.
   * Dient zum schnellen Erstellen einer Breadcrumb.
   */
  path: string[]
  /**
   * Gibt an, ob dieses Element Kindelemente besitzt. Wird z. B. benötigt,
   * um einen Aufklapp-Pfeil anzuzeigen.
   */
  hasChildren: boolean
  /**
   * Die ursprünglichen Daten dieses Knotens, wie sie z. B. von der API
   * zurückkamen.
   */
  item: T
  /**
   * Info, ob der Knoten klickbar sein soll oder nicht.
   */
  disabled?: boolean
}

interface Props<T> {
  items: Array<FlatTreeNode<T>>
  openNodes: Array<FlatTreeNode<T>>
  selectedItemId?: string | null
  renderLabel: (item: FlatTreeNode<T>, withHighlighting?: boolean, alternateColor?: boolean) => string
  itemActions?: (item: FlatTreeNode<T>) => TreeNodeAction[]
  isItemClickable?: (index: number) => boolean
  onItemClick?: (index: number) => void
  onItemToggle?: (index: number, isOpen: boolean) => void
  searchActive: boolean
  currentHighlightedNode?: number
  checkNodeCallback: (item: FlatTreeNode<T>) => boolean
}

export const Tree = forwardRef(function Tree <T extends object>(
  {
  items,
  openNodes,
  selectedItemId,
  renderLabel,
  itemActions,
  isItemClickable,
  onItemClick,
  onItemToggle,
  searchActive,
  currentHighlightedNode,
  checkNodeCallback
}: Props<T>, ref:Ref<VirtuosoHandle> )   {

  const handleTreeNodeClick = useCallback(
    (index: number): void => {
      if (onItemClick === undefined) return

      const clickedItem = items[index]
      if (clickedItem === undefined) return

      onItemClick(index)
    },
    [items, onItemClick]
  )

  const handleTreeNodeToggle = useCallback(
    (index: number, isOpen: boolean): void => {
      onItemToggle?.(index, isOpen)
    },
    [onItemToggle]
  )

  const itemContent = useCallback<ItemContent<never, never>>(
    (index: number) => {
      const item = items[index]
      const isOpen = openNodes.some((node) => node.id === item.id)
      const isClickable = isItemClickable?.(index) ?? true

      type TreeNodeProps = Pick<
        Parameters<typeof TreeNode>[0],
        | 'index'
        | 'label'
        | 'icon'
        | 'level'
        | 'onClick'
        | 'actions'
        | 'current'
        | 'disabled'
        | 'highlightedLabel'
      >

      const treeNodeProps: TreeNodeProps = {
        index,
        icon: mdiFile,
        label: renderLabel(item),
        highlightedLabel: renderLabel(item, true, currentHighlightedNode === index),
        level: item.level,
        current: item.id === selectedItemId,
        disabled: item.disabled,
        actions: itemActions?.(item),
        onClick: isClickable ? handleTreeNodeClick : undefined
      }

      if (item.hasChildren) {
        let hasChildSearchResults = false
        if (searchActive){
          if(checkNodeCallback(item)){
            handleTreeNodeToggle(index, true)
            hasChildSearchResults = true
          }
        }
        return (
          <TreeNode
            {...treeNodeProps}
            hasChildren
            open={isOpen}
            //node cant be toggled if there is a child that is in the search results
            onToggle={!hasChildSearchResults ? handleTreeNodeToggle : noop}
          />
        )
      }

      return <TreeNode {...treeNodeProps} hasChildren={false} />
    },
    [
      handleTreeNodeClick,
      handleTreeNodeToggle,
      isItemClickable,
      itemActions,
      items,
      openNodes,
      renderLabel,
      selectedItemId,
      searchActive,
      checkNodeCallback,
      currentHighlightedNode
    ]
  )

  return <Virtuoso ref={ref} totalCount={items.length} itemContent={itemContent} className={classNames('tree-body', {'tree-body__search-active': searchActive })} />
})
