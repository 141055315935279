import { useMemo } from 'react'
import { ApiTaskDataResourceAsset } from 'sfportal_services_api/apiSchemas'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { viewableImages } from './constants'

interface UseShouldShowImagePreviewResult {
  shouldShowImagePreview: boolean
}

export function useShouldShowImagePreview (): UseShouldShowImagePreviewResult {
  const { currentFile } = useProductDetailStore()

  const shouldShowImagePreview = useMemo((): boolean => {
    if (currentFile === null) {
      return false
    }

    const { contentType } = currentFile as ApiTaskDataResourceAsset
    if (contentType === null) {
      return false
    }

    return viewableImages.includes(contentType)
  }, [currentFile])

  return { shouldShowImagePreview }
}
