import React, {memo} from 'react'
import './ProgressBar.scss'

interface Props {
    progress: number
    label?: string
    showPercent?: boolean
}

export const ProgressBar = memo(function ProgressBar(
    {
        progress,
        label,
        showPercent = false
    }: Props) {
    return (
        <div className="progress-bar">
            <div className="progress-bar__label">{label}</div>
            <div className="progress-bar__track">
                <div
                    className="progress-bar__track-value"
                    style={{width: `${progress}%`}} />
            </div>
            {showPercent ? <div>{`${progress.toFixed(progress < 100 ? 2 : 0)}%`}/100%</div> : null}
        </div>
    )
})
