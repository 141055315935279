import classNames from 'classnames'
import React, {
  ButtonHTMLAttributes,
  CSSProperties,
  DOMAttributes,
  FC,
  ReactElement,
  useMemo
} from 'react'
import { ChildrenProp } from '../../jsx'
import { isDefined } from '../../utils/global'
import { MdiIcon } from '../Generic/MdiIcon'
import './Button.scss'
import { ButtonType } from './ButtonType'

// Einmal alle möglichen Button-Propertys holen.
// Das wird benötigt, um die Button-Props von den restlichen Props zu trennen.
const availableButtonProps: string[] = []
for (const propName in HTMLButtonElement.prototype) {
  availableButtonProps.push(propName.toLowerCase())
}

type IconProps =
  | {
    iconType?: 'mdi'
    icon?: string
  }
  | {
    iconType: 'si'
    icon?: ReactElement | null
  }

type ButtonProps = DOMAttributes<HTMLButtonElement> &
ButtonHTMLAttributes<HTMLButtonElement>

interface Props extends ChildrenProp, ButtonProps {
  buttonStyle?: ButtonType
  secondaryIcon?: string
  buttonColor?: string
}

/**
 * Das ist ein Button
 */
export const Button: FC<Props & IconProps> = (props) => {
  /**
   * Alle Properties vom nativen `<button>`-Element, die nicht spezifisch für
   * diese Komponente definiert wurden.
   */
  const buttonProps = useMemo<ButtonProps>(() => {
    const result: ButtonProps = {}
    for (const propName of Object.keys(props)) {
      if (availableButtonProps.includes(propName.toLowerCase())) {
        const value = props[propName as keyof ButtonProps]
        result[propName as keyof ButtonProps] = value
      }
    }
    return result
  }, [props])

  const buttonStyles = useMemo<CSSProperties>(() => {
    const styles: CSSProperties = {}

    if (props.buttonColor !== undefined) {
      styles.backgroundColor = props.buttonColor
      styles.borderColor = props.buttonColor
    }

    return styles
  }, [props.buttonColor])

  const buttonClasses = useMemo(
    () =>
      classNames(
        'button',
        `button--type-${props.buttonStyle ?? 'primary'}`,
        { 'button--has-custom-color': props.buttonColor !== undefined },
        props.className ?? ''
      ),
    [props.buttonColor, props.buttonStyle, props.className]
  )

  const hasBody = useMemo(() => isDefined(props.children), [props.children])

  return (
    <button {...buttonProps} className={buttonClasses} style={buttonStyles}>
      {props.icon !== undefined ? (
        props.iconType === undefined || props.iconType === 'mdi' ? (
          <MdiIcon
            path={props.icon ?? null}
            className="button__icon button__icon--type-mdi"
          />
        ) : props.iconType === 'si' ? (
          <div className="button__icon button__icon--type-si">{props.icon}</div>
        ) : null
      ) : null}

      {hasBody ? <div className="button__body">{props.children}</div> : null}

      {props.secondaryIcon !== undefined ? (
        <MdiIcon
          path={props.secondaryIcon}
          className="button__secondary-icon"
        />
      ) : null}
    </button>
  )
}
