import { mdiClose } from '@mdi/js'
import React, { memo, useCallback, useMemo } from 'react'
import { getProductData, ProductProperty } from '../../data/productData'
import { strListToArray } from '../../utils/string-list'
import { MdiIcon } from '../Generic/MdiIcon'
import './FilterItemBadge.scss'
import { useTranslation } from 'react-i18next'

interface Props {
  filterKey: ProductProperty
  filterValue: string
  onRemove: (label: ProductProperty) => void
}

export const FilterItemBadge = memo(function FilterItemBadge ({
  filterKey, filterValue, onRemove
}: Props) {
  const { t } = useTranslation()

  const filterItem = useMemo(
    () => getProductData(filterKey, t),
    [filterKey, t]
  )

  const getValueLabel = useCallback(
    (label: string, value: string): string => {
      if (label === 'status' as ProductProperty) {
        const valueItems = strListToArray(value)
        if (valueItems.length === 1) {
          return valueItems[0]
        }

        return t('filters.filterItemBadge.valueLabel', { label: valueItems.length.toString() })
      }

      return value
    },
    [t]
  )

  if (filterItem === null) return null

  return (
    <div className="filter-item-badge">
      <div className="filter-item-badge__content">
        <div className="filter-item-badge__label">{filterItem.label}</div>
        <div className="filter-item-badge__value">
          {getValueLabel(filterItem.id, filterValue)}
        </div>
      </div>

      <div
        className="filter-item-badge__action"
        onClick={() => onRemove(filterKey)}
      >
        <MdiIcon path={mdiClose} className="filter-item-badge__icon" />
      </div>
    </div>
  )
})
