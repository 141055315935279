/**
 * Rotiert durch die Werte des Arrays `items`.
 * Es wird der Wert zurückgegeben, der im Array nach dem Wert `currentValue`
 * kommt. Ist `currentValue` der letzte Wert in `items`, wird der erste Wert
 * zurückgegeben.
 */
export function cycleValue<T> (items: T[], currentValue: T): T | null {
  const currentValueIndex = items.indexOf(currentValue)
  if (currentValueIndex === -1) { return null }
  const nextIndex = currentValueIndex + 1

  if (nextIndex === items.length) { return items[0] }
  return items[nextIndex]
}

type CompareFunction<T> = (a: T, b: T) => number

/**
 * Erstellt eine Compare-Funktion für `Array.prototype.sort()`,
 * die ein Array aus Objekten anhand des Werts einer Property in den Objekten
 * auf erster Ebene sortiert.
 * @param property Die Property, nach der sortiert werden soll
 * @param invert Gibt an, ob in umgekehrter Reihenfolge sortiert werden soll
 * @example
 * const array = [{ title: 'foo' }, { title: 'bar' }]
 * array.sort(sortByProperty('title'))
 */
export function sortByProperty<T> (
  property: keyof T,
  invert = false
): CompareFunction<T> {
  const invertValue = invert ? -1 : 1

  return (itemA: T, itemB: T): number => {
    const propertyA = itemA[property]
    const propertyB = itemB[property]
    // Nicht sortierbare properties kommen ans Ende
    if (!['string', 'number'].includes(typeof propertyA)) { return Infinity }
    if (!['string', 'number'].includes(typeof propertyB)) { return -Infinity }
    if (propertyA < propertyB) { return -1 * invertValue }
    if (propertyA > propertyB) { return 1 * invertValue }
    return 0
  }
}

/**
 * Erstellt eine Compare-Funktion für `Array.prototype.sort()`,
 * die ein Array anhand des Werts sortiert, der mithilfe der Getter-Funktion
 * ermittelt wird. Dies kann eine tiefer verschachtelte Property sein
 * oder eine ganz anderer Wert.
 * @param propertyGetter
 * Die Getter-Funktion, die den Wert zurückgibt,
 * nach dem das Array sortiert werden soll.
 * @param invert Gibt an, ob in umgekehrter Reihenfolge sortiert werden soll
 * @example
 * const array = [
 *   { user: { name: 'Hans' } },
 *   { user: { name: 'Sabine' } },
 *   { user: { name: 'Fritz' } }
 * ]
 * array.sort(sortWithGetter(item => item.user.name))
 * // oder
 * array.sort(sortWithGetter(item => item.user.name.length))
 */
export function sortWithGetter<T> (
  propertyGetter: (item: T) => string | number,
  invert = false
): CompareFunction<T> {
  const invertValue = invert ? -1 : 1

  return (itemA: T, itemB: T): number => {
    const propertyA = propertyGetter(itemA)
    const propertyB = propertyGetter(itemB)
    // Nicht sortierbare properties kommen ans Ende
    if (!['string', 'number'].includes(typeof propertyA)) { return Infinity }
    if (!['string', 'number'].includes(typeof propertyB)) { return -Infinity }
    if (propertyA < propertyB) { return -1 * invertValue }
    if (propertyA > propertyB) { return 1 * invertValue }
    return 0
  }
}

/**
 * Type für Entities, die nach einem Property sortiert werden können.
 */
export type SortableBy<S extends string> = {
  [key in S]: string
}

/**
 * Liefert den Index für ein sortiertes Array,
 * an dem die übergebene Value sortiert eingefügt werden kann.
 * Falls das übergebene Array nicht sortiert ist, wird zwar ein index
 * zurückgegeben der allerdings falsch ist.
 *
 * @param array Das sortierte Array
 * @param value Die neue Value die Sortiert eingebaut werden soll.
 * @param sortingValueFn Sortier-Funktion, die angibt, ob der Value vor oder nach
 * dem aktuellen Element eingefügt werden soll.
 */
export function sortedIndex<S extends string> (
  array: Array<SortableBy<S>>,
  value: SortableBy<S>,
  sortingValueFn: (currentElement: SortableBy<S>, value: SortableBy<S>) => number
): number {
  let low = 0
  let high = array.length

  while (low < high) {
    const mid = low + high >>> 1
    if (sortingValueFn(array[mid], value) < 0) {
      low = mid + 1
    } else {
      high = mid
    }
  }
  return low
}

/**
 * Erstellt eine Filter-Funktion für `Array.prototype.filter`,
 * die `null`-Werte im Array ausschließt und somit den Typ eines Arrays
 * von `Array<T | null>` zu `T[]` ändern kann.
 * @example
 * products.filter(isNonNull<Product>())
 */
export function isNonNull<T> (): (item: T | null) => item is T {
  return (item): item is T => item !== null
}

/**
 * Entfernt Elemente aus einem Array.
 * @param array Das Array, aus dem Elemente entfernt werden sollen
 * @param items Die Elemente, die entfernt werden sollen
 */
export function removeFromArray<T> (array: T[], ...items: T[]): void {
  for (const item of items) {
    if (!array.includes(item)) { continue }
    array.splice(array.indexOf(item), 1)
  }
}

/**
 * Erzeugt eine Kopie eines Array, das jedoch nicht die Elemente enthält,
 * die entfernt werden sollen.
 * @param array Das Array, das gefiltert werden soll.
 * @param items Die Elemente, die in dem neuen Array
 * nicht enthalten sein sollen.
 */
export function arrayWithout<T> (array: T[], ...items: T[]): T[] {
  return array.filter(item => !items.includes(item))
}

/**
 * Prüft ein Array, ob es überhaupt ein Array ist und mindestens ein Element
 * beinhaltet. Außerdem wird auf TypeScript-Ebene geprüft, ob das Array ein
 * Array aus Elementen vom Typ `T` ist.
 * @param array Das Array, das geprüft werden soll.
 */
export function arrayHasItems<T> (array: T[]): array is T[] {
  return Array.isArray(array) && array.length > 0
}
