import {mdiFileDocumentOutline, mdiFileOutline} from '@mdi/js'
import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import { ApiTask } from '../../services/api/apiSchemas'
import { ProductDetailStore, } from '../../stores/productDetailStore'
import { ListItemTask } from './ListItemTask'
import { useTaskActions } from './useTaskActions'
import {MdiIcon} from "../Generic/MdiIcon";

interface Props extends ChildrenProp {
  task: ApiTask;
  showTaskName?: boolean;
  highlight?: boolean;
  taskActionStatus: ProductDetailStore['taskActionStatus'];
  currentFile: ProductDetailStore['currentFile'];
}

export const ListItemTaskUnknown = memo(function ListItemTaskUnknown ({
  task,
  highlight,
  showTaskName = false,
  taskActionStatus,
  currentFile,
}: Props) {
  const taskActions = useTaskActions({
    task,
    taskActionStatus
  })

  return (
    <>
      {taskActions.taskModalElement}

      <ListItemTask
        listItemType="unknown"
        icon={<MdiIcon path={mdiFileOutline} />}
        title={task.dataResource.name}
        titleSuffix={showTaskName ? task.name : undefined}
        highlight={highlight}
        current={currentFile === task.dataResource}
        dueDate={task.dueDate}
        pubtargetIdentifier={task.pubtargetIdentifier}
        actions={<>{taskActions.taskActionElements}</>}
      />
    </>
  )
})
