import { reloadPreview, setCurrentFileReloading, useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { noop } from 'sfportal_utils/function'
import { useIsMounted } from 'sfportal_utils/useIsMounted'
import { useAddSubscriptionToArea } from '../../../../hooks/useAddSubscriptionToArea'
import { Message } from '@stomp/stompjs'
import { useCallback } from 'react'

export function useSubscriptions (): void {
  const isMounted = useIsMounted()

  const { currentFile } = useProductDetailStore()

  /**
   * Wird beim immer aufgerufen wenn ein Workflow gestartet oder beendet wurde.
   * Falls es der FilePreview Workflow zur aktuell geöffneten Datei ist, wird der übegebene Callback ausgeführt.
   *
   * @param message Die Message der Subscription.
   * @param callback Der Callback der ausgeführt wird.
   */
  const executeAfterFilePreviewWorkflowChanged = useCallback((
    message: Message,
    callback: () => void
  ): void => {
    const parameters: {
      businessKey: string
      processdefinitionkey: 'metadataextraction'
    } = JSON.parse(message.body)

    if (
      currentFile === undefined ||
      parameters.businessKey === '' ||
      parameters.businessKey === null
    ) {
      return
    }

    const splitMetaKey = parameters.businessKey.split('|')
    const entityId = parseInt(splitMetaKey[1], 10)
    const dataresource = parseInt(splitMetaKey[0], 10)
    const splitCurrentEntity = currentFile?.id?.replace('sf', '').split('-')
    if (splitCurrentEntity === undefined) return

    const currentEntityId = parseInt(splitCurrentEntity[1], 10)
    const currentDataresource = parseInt(splitCurrentEntity[0], 10)

    if (
      parameters.processdefinitionkey === 'metadataextraction' &&
      currentDataresource === dataresource &&
      currentEntityId === entityId
    ) {
      callback()
    }
  }, [currentFile])

  const historicProcessInstaceStartedCallback = useCallback((message) => {
    executeAfterFilePreviewWorkflowChanged(message, () => {
      setCurrentFileReloading(true)
    })
  }, [executeAfterFilePreviewWorkflowChanged])

  // Subscription auf den Start von Workflows.
  useAddSubscriptionToArea(
    'historicprocessinstance',
    'start',
    '*',
    historicProcessInstaceStartedCallback,
    'workflow'
  )

  const historicProcessInstanceEndedCallback = useCallback((message) => {
    executeAfterFilePreviewWorkflowChanged(message, () => {
      reloadPreview()
        .then((_) => {
          if (!isMounted()) return
          setCurrentFileReloading(false)
        })
        .catch(noop)
    })
  }, [executeAfterFilePreviewWorkflowChanged, isMounted])

  // Subscription auf das Ende von Workflows.
  useAddSubscriptionToArea(
    'historicprocessinstance',
    'end',
    '*',
    historicProcessInstanceEndedCallback,
    'workflow'
  )
}
