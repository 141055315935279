import { useCallback, useEffect, useRef } from 'react'

/**
 * Dieser Hook liefert eine Funktion `isMounted` zurück. Diese muss in
 * `useEffect`-Hooks verwendet werden, die asynchronen Code ausführen, nach
 * welchem anschließend der State geändert wird. Ansonsten kann es passieren,
 * dass der State einer Komponente geändert wird, die nicht mehr gemountet ist.
 * Das führt zu einer Fehlerausgabe in der Browser-Konsole.
 *
 * Mehr Infos dazu hier:
 * https://www.benmvp.com/blog/handling-async-react-component-effects-after-unmount/
 *
 * @example
 * ``` ts
 * const [someState, setSomeState] = useState()
 * const isMounted = useIsMounted()
 *
 * useEffect(() => {
 *   doSomethingAsync().then((result) => {
 *     if (!isMounted()) return
 *     setSomeState(result)
 *   })
 * }, [isMounted])
 * ```
 */
export function useIsMounted (): () => boolean {
  const isMountedRef = useRef<boolean>(true)

  const isMounted = useCallback((): boolean => isMountedRef.current, [])

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return isMounted
}
