import { useMemo } from 'react'
import { ApiTaskDataResourceAsset, instanceOfApiFolderAsset } from 'sfportal_services_api/apiSchemas'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'

export function useShowPreview (): boolean {
  const { currentFile } = useProductDetailStore()

  const showPreview = useMemo<boolean>(() => {
    if (currentFile === null) return false

    if (
      currentFile.type !== 'editordocument' &&
      currentFile.type !== 'smashdocs' &&
      currentFile.type !== 'asset' &&
      currentFile.type !== 'folderasset'
    ) {
      return false
    }

    if (instanceOfApiFolderAsset(currentFile)) {
      // Folder Assets haben keine Privileges.
      // Privileges sind nur für Task Assets da die Priviledges im Workflow definiert werden.
      // Jedes Folder Asset ist grundsätzlich in der Preview erlaubt.
      return true
    }

    const privileges = (currentFile as ApiTaskDataResourceAsset).assetPrivileges
    return privileges?.canUserViewPreview ?? false
  }, [currentFile])

  return showPreview
}
