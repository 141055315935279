import { mdiFolder, mdiUpload } from '@mdi/js'
import React, { memo, useCallback, useState } from 'react'
import { FileInputChangeListener, useFileInput } from '../../hooks/useFileInput'
import { ApiProduct, ApiSharedFolder } from '../../services/api/apiSchemas'
import { noop } from '../../utils/function'
import { EmptyIndicator } from '../Generic/EmptyIndicator'
import { ListItemAsset } from '../ListItem/ListItemAsset'
import { ItemView } from './ItemView'
import { ItemViewAction } from './ItemViewAction'
import { ItemViewHeader } from './ItemViewHeader'
import { useUserStore } from '../../stores/userStore'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { UploadMetadataWfFile } from '../../services/api/filesApiService'
import { v4 as uuidv4 } from 'uuid'
import { startMetadataWf, uploadFiles } from '../../stores/transferStore'
import { splitEMK } from '../../utils/system'
import { apiGetDataresourceByIdentifier } from '../../services/api/dataresourceApiService'
import { startWorkflow } from '../../services/api/workflowApiService'

interface Props {
  id: ApiSharedFolder['id']
  name: ApiSharedFolder['name']
  configkey: ApiSharedFolder['configkey']
  content: ApiSharedFolder['content']
  readOnly?: ApiSharedFolder['readOnly']
  rootLevel?: boolean
  productId: ApiProduct['id']
}

interface FolderWorkflow {
  name: string
  key: string
  folderConfigKey: string | null | undefined
  workflowStartMessage: string | null | undefined
}

export const SharedFolderListItem = memo(function SharedFolderListItem ({
  id,
  name,
  content,
  readOnly = false,
  rootLevel = false,
  configkey,
  productId
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { currentUser } = useUserStore()
  const userId = currentUser !== null ? currentUser.id : undefined
  const alert = useAlert()
  const { t } = useTranslation()

  const handleFileInputChange = useCallback<FileInputChangeListener>(
    event => {
      const inputElement = event.currentTarget as HTMLInputElement
      const files = Array.from(inputElement.files ?? [])
      const uploadingFileObjects = files.map(file => ({
        filename: file.name,
        file: file,
        uploadid: uuidv4(),
        folderid: id,
        userid: userId,
        productid: productId
      }))

      startMetadataWf(uploadingFileObjects.map(uploadingFileObject => ({
        filename: uploadingFileObject.filename,
        uploadid: uploadingFileObject.uploadid,
        folderid: uploadingFileObject.folderid,
        userid: uploadingFileObject.userid,
        productid: uploadingFileObject.productid
      } as UploadMetadataWfFile))).then(_ => {
        alert.show(t('upload.started'))
        uploadFiles(uploadingFileObjects.map(uploadingFileObject => ({ file: uploadingFileObject.file, uploadid: uploadingFileObject.uploadid })), productId).catch(noop)
      }).catch(noop)
    },
    [id, productId, userId, alert, t]
  )

  const handleWorkflowStartClick = useCallback(
    async (workflow) => {
      const emk = splitEMK(id, { separator: '-' })
      const resultDr = await apiGetDataresourceByIdentifier('sfpublicationchannels')
      const response = await startWorkflow(productId, workflow.key, `${String(resultDr.body.id)}|${productId}`, {
        userId: userId,
        folderId: emk?.contentId
      })
      if (response.ok && typeof workflow.workflowStartMessage === 'string') {
        alert.success(<>{workflow.workflowStartMessage}</>)
      }
    },
    [id, productId, userId, alert, startWorkflow, apiGetDataresourceByIdentifier, splitEMK]
  )

  const { handleFileUploadClick } = useFileInput({
    listener: handleFileInputChange,
    multiple: true
  })

  const getWorkflowButtons = () => {
    if (typeof process.env.REACT_APP_FOLDER_WORKFLOWS === 'string') {
      const config = process.env.REACT_APP_FOLDER_WORKFLOWS
      const folderWorkflows = JSON.parse(config) as FolderWorkflow[]
      return folderWorkflows.map(workflow => {
        if (workflow.folderConfigKey === null || workflow.folderConfigKey === undefined || workflow.folderConfigKey === configkey) {
          return <ItemViewAction
            key={workflow.key}
            label={t(workflow.name)}
            onClick={async () => await handleWorkflowStartClick(workflow)}
          />
        } else {
          return null
        }
      })
    }
    return null
  }

  return (
    <ItemView
      canToggle={true}
      onToggle={() => setIsOpen(!isOpen)}
      isOpen={isOpen}
      expanded={rootLevel}
      detail={() => <>
        {content === null || content.length === 0 ? (
          <EmptyIndicator text={t('itemViews.sharedFolderListItem.empty')} compact={true} />
        ) : (
          content.map(item =>
            item.type === 'folder'
              ? (
                <SharedFolderListItem
                  key={item.id}
                  id={item.id}
                  configkey={item.configkey}
                  readOnly={item.readOnly}
                  name={item.name}
                  content={item.content}
                  productId={productId}
                />
              )
              : item.type === 'folderasset'
                ? <ListItemAsset key={item.id} folderId={id} readOnly={readOnly} productId={productId} item={item} />
                : null
          )
        )}
      </>}
    >
      <ItemViewHeader
        title={name}
        titleIcon={rootLevel ? undefined : mdiFolder}
        onClick={() => setIsOpen(!isOpen)}
      />
      {getWorkflowButtons()}
      <ItemViewAction
        icon={mdiUpload}
        enabled={!readOnly}
        iconOnly={true}
        label={t('itemViews.sharedFolderListItem.fileUpload')}
        onClick={handleFileUploadClick}
      />
    </ItemView>
  )
})
