import {
  Google,
  Microsoft,
  Microsoftazure,
  Okta
} from '@icons-pack/react-simple-icons'
import React, { ReactElement } from 'react'
import {
  siGoogle,
  siMicrosoft,
  siMicrosoftazure,
  siOkta
} from 'simple-icons/icons'

interface SizeProps {
  width: number
  height: number
}

// Wichtig: Ungerade Zahl verwenden, damit Linien oder Lücken in der Mitte eines
// Icons scharf bleiben. Bspw. im Microsoft-Logo.
const defaultLength = 21
const defaultSize: SizeProps = { width: defaultLength, height: defaultLength }

export interface SSOLinkDefaults {
  label: string
  color: string
  icon: ReactElement
}

export function useGetSSOLinkDefaults (): (type: string) => SSOLinkDefaults | null {
  return (type) => {
    switch (type) {
      case 'google':
        return {
          label: 'Google',
          color: `#${siGoogle.hex}`,
          icon: <Google {...defaultSize} />
        }
      case 'microsoft':
        return {
          label: 'Microsoft',
          color: `#${siMicrosoft.hex}`,
          icon: <Microsoft {...defaultSize} />
        }
      case 'azure':
        return {
          label: 'Microsoft Azure',
          color: `#${siMicrosoftazure.hex}`,
          icon: <Microsoftazure {...defaultSize} />
        }
      case 'okta':
        return {
          label: 'Okta',
          color: `#${siOkta.hex}`,
          icon: <Okta {...defaultSize} />
        }
      default:
        return null
    }
  }
}
