import React, { memo } from 'react'
import { MdiIcon } from '../Generic/MdiIcon'

interface Props {
  title?: string | JSX.Element
  titleIcon?: string
  description?: string | JSX.Element
  onClick?: () => void
}

export const ItemViewHeader = memo(function ItemViewHeader ({
  title, titleIcon, description, onClick
}: Props) {
  return (
    <div className="item-view__header-content" onClick={onClick}>
      {titleIcon !== undefined ? (
        <span className="item-view__icon">
          <MdiIcon path={titleIcon} />
        </span>
      ) : null}

      <div className="item-view__text-area">
        <div className="item-view__title">
          <span className="item-view__title-text">{title}</span>
        </div>
        {description !== undefined && description !== '' ? (
          <div className="item-view__description">{description}</div>
        ) : null}
      </div>
    </div>
  )
})
