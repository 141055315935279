import { useEffect, useMemo } from 'react'

export interface UsePortalMountTargetReturn {
  mountTarget: HTMLDivElement
}

export function usePortalMountTarget (): UsePortalMountTargetReturn {
  const mountTarget = useMemo(() => document.createElement('div'), [])

  useEffect(() => {
    document.body.appendChild(mountTarget)
    return () => { document.body.removeChild(mountTarget) }
  }, [mountTarget])

  return { mountTarget }
}
