import { Response } from 'superagent'
import { sendRequest } from './superagent'
import { ApiMessage } from './apiSchemas'

export async function fetchMessages (): Promise<Response> {
  return await sendRequest('get', 'messages')
}

export async function apiSetMessagesAsRead (
  ...messageIds: Array<ApiMessage['id']>
): Promise<Response> {
  const query = { messageId: messageIds.map(id => id.toString()) }
  return await sendRequest('post', 'messages/notice', { query })
}
