import { SortableBy } from 'sfportal_utils/array'

/**
 * Sortierungs function, welche die Tasks nach der Baumstruktur im Backend
 * sortiert (flach).
 *
 * @param element Das aktuelle Element, gegen welches verglichen wird.
 * @param value Der neue Wert.
 */
export const sortTasksFlatTree = (
  element: SortableBy<'pathorder'>,
  value: SortableBy<'pathorder'>
): number => {
  if (value.pathorder === element.pathorder) {
    // Elemente sind gleich
    return 0
  } else if (value.pathorder.startsWith(element.pathorder)) {
    // Element ist überhalb der value
    // Negativen Wert returnen um value nach element zu platzieren
    return -1
  } else if (element.pathorder.startsWith(value.pathorder)) {
    // Element ist unterhalb der value
    // Positiven Wert returnen um value nach Element zu platzieren
    return 1
  }

  const valuePathOrderSplit = value.pathorder.split('-')
  const elementPathOrderSplit = element.pathorder.split('-')

  const iterationLength =
    valuePathOrderSplit.length < elementPathOrderSplit.length
      ? valuePathOrderSplit.length
      : elementPathOrderSplit.length

  for (let i = 0; i < iterationLength; i++) {
    // Erstes unterschiedliche Level finden
    if (valuePathOrderSplit[i] !== elementPathOrderSplit[i]) {
      const currentValueNumber = parseInt(valuePathOrderSplit[i], 10)
      const currentElementNumber = parseInt(elementPathOrderSplit[i], 10)
      return currentElementNumber - currentValueNumber
    }
  }

  // Falls keine Sortierung zutrifft an letzter Stelle platzieren.
  return -1
}
