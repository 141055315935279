import i18next from 'i18next'
import backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { noop } from 'sfportal_utils/function'
import LanguageDetector from 'i18next-browser-languagedetector'

/**
 * i18next Bibliothek Konfiguration.
 */
i18next
  .use(backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_PUBLIC_URL ?? ''}/locales/{{lng}}/{{ns}}.json`
    },
    fallbackLng: [process.env.REACT_APP_DEFAULT_LANG ?? 'en'],
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    },
    ns: ['custom', 'default'],
    defaultNS: 'custom',
    fallbackNS: 'default'
  })
  .catch(noop)
