import React, { FC, FormEvent, useRef, useState } from 'react'
import { MdiIcon } from '../../../components/Generic/MdiIcon'
import { mdiMagnify } from '@mdi/js'
import { useTranslation } from 'react-i18next'
import './ProductSearchTab.scss'
import { sendRequest } from '../../../services/api/superagent'
import { ExternalInputRef, Input } from '../../../components/Forms/Input'
import { setSearch, useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { SearchResult } from '../../../interfaces/Search'
import { generateSearchTerms } from '../../../utils/highlightingHelper'
import { LoadingSpinner } from '../../../components/Animations/LoadingSpinner'
import { useProductTreeData } from '../productTree/list/useProductTreeData'
import { ApiTreeNode } from '../../../services/api/apiSchemas'

interface Props {
  onSearchFound: () => void
}

/**
 * Component for the Search-Tab
 * @param {Props} props the Props
 * @param {Props['onSearchFound']} props.onSearchFound Is triggering if the Search is not empty
 * @returns {FC} the Search Component
 */
export const ProductSearchTab: FC<Props> = (
  {
    onSearchFound
  }
) => {
  const { t } = useTranslation()
  const {
    product,
    search
  } = useProductDetailStore()
  const { items } = useProductTreeData()
  const [searchTerm, setSearchTerm] = useState<string>(search?.searchTerm ?? '')
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const ref = useRef<ExternalInputRef>(null)
  const searchLimit = parseInt(process.env.REACT_APP_PRODUCT_TREE_SEARCH_LIMIT ?? '1000')

  /**
   * Resets the search
   */
  const removeSearch = (): void => {
    setSearchTerm('')
    ref.current?.changeInputValue('')
    setSearch(null)
  }

  /**
   * Submits the Search and saves the result in the Store
   *
   * @param {FormEvent} e the FormEvent, when the user hits enter
   */
  const submit = async (e?: FormEvent): Promise<void> => {
    if (e) {
      e.preventDefault()
    }
    if (isSearchLoading) {
      return
    }
    if (searchTerm === '') {
      removeSearch()
      return
    }
    setIsSearchLoading(true)

    // Interpolation, to avoid that the string is changing by reference between sending and resolving the Promise
    const fixedSearchTerm = `${searchTerm.trim()}`
    const result = (await sendRequest('post', 'search', {
      body: {
        searchtext: searchTerm,
        start: 1,
        findSystemImages: false,
        xpathExpression: null,
        sortColumn: 'id',
        sortAscending: true,
        limit: searchLimit,
        highlighting: true,
        highlightingFields: [
          'identifier'
        ],
        dataresourceSearchFilters: [
          {
            dataresource: 'smtextdocuments',
            searchFilters: [
              {
                filtertype: 'productUsageFilter',
                productId: product?.id
              }
            ]
          },
          {
            dataresource: 'smfiles',
            searchFilters: [
              {
                filtertype: 'productUsageFilter',
                productId: product?.id
              }
            ]
          },
          {
            dataresource: 'smlawstructures',
            searchFilters: [
              {
                filtertype: 'productUsageFilter',
                productId: product?.id
              }
            ]
          },
          {
            dataresource: 'smlooseleafstructures',
            searchFilters: [
              {
                filtertype: 'productUsageFilter',
                productId: product?.id
              }
            ]
          },
          {
            dataresource: 'smmagazinestructures',
            searchFilters: [
              {
                filtertype: 'productUsageFilter',
                productId: product?.id
              }
            ]
          },
          {
            dataresource: 'smtreenodecontents',
            searchFilters: [
              {
                filtertype: 'productUsageFilter',
                productId: product?.id
              }
            ]
          },
          {
            dataresource: 'smonlinestructures',
            searchFilters: [
              {
                filtertype: 'productUsageFilter',
                productId: product?.id
              }
            ]
          }
        ]
      }
    })).body as SearchResult

    const searchTerms = generateSearchTerms(fixedSearchTerm)

    const sortedArray: SearchResult['results'] = []

    if (result.results) {
      // Sort the results to match the tree sort (for circle through the search findings in the tree)

      /**
       * function to go through the children recursively and add the finding to the sorted Array
       *
       * @param {ApiTreeNode[]} nodes the nodes to go through
       */
      const checkRecursive = (nodes: ApiTreeNode[]): void => {
        nodes.forEach(single => {
          const splitted = single.entityMetaKey.split('|')
          const find = result.results?.find(single => single.id.toString() === splitted[1])
          if (find) {
            sortedArray.push(find)
          }
          if (single.treenodes !== null) {
            checkRecursive(single.treenodes)
          }
        })
      }

      //Filter to only loop through the first level, we circle through the children on our own recursively
      Object.values(items).filter(single => single.level === 0).forEach(item => {
        const splitted = item.item.entityMetaKey.split('|')
        //check if the node/its finding is already in the sorted array
        const find = result.results?.find(single => single.id.toString() === splitted[1])
        if (find !== undefined) {
          sortedArray.push(find)
        }

        if (item.hasChildren && item.item.treenodes) {
          checkRecursive(item.item.treenodes)
        }
      })

      result.results = sortedArray
    }

    setSearch({
      searchTerm: fixedSearchTerm,
      searchTerms, ...result
    })
    setIsSearchLoading(false)
    if (result.totalCount !== 0) {
      onSearchFound()
    }
  }

  return <div className={'product-search-tab'}>
    <div className={'search-input-wrapper'}>
      <form onSubmit={submit} className={'search-form'}>
        <Input ref={ref} name={'search'} onInput={setSearchTerm} value={searchTerm} />
      </form>
      <div className={'search-icon'} onClick={submit}>
        {isSearchLoading
          ? <LoadingSpinner />
          : <MdiIcon path={mdiMagnify} scale={1} />}
      </div>
    </div>

    {!isSearchLoading && search !== null
      ? <div className={'search-result-wrapper'}>
        {search.totalCount === searchLimit
          ? <span>{t('products.search.maxResults', { limit: searchLimit })}</span>
          : null}
        {search.totalCount === 0
          ? <span>{t('products.search.noResult')}</span>
          : null}
        {search.message !== undefined
          ? <span>{t('products.search.error')}</span>
          : null}
        <span onClick={removeSearch} className={'reset-search'}>
          {t('products.search.button.remove')}
        </span>
      </div>
      : null}
  </div>
}
