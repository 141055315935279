import React, { forwardRef, ReactElement, useImperativeHandle, useMemo, useRef } from 'react'
import { ForwardRef } from '../../jsx'
import './DockLayout.scss'

export interface DockLayoutProps {
  className?: string
  header?: (() => JSX.Element | null) | ReactElement
  left?: (() => JSX.Element | null) | ReactElement
  center?: (() => JSX.Element | null) | ReactElement
  right?: (() => JSX.Element | null) | ReactElement
  footer?: (() => JSX.Element | null) | ReactElement
  cover?: (() => JSX.Element | null) | ReactElement
}

export interface DockLayoutRef {
  headerRef: HTMLDivElement | null
  leftRef: HTMLDivElement | null
  centerRef: HTMLDivElement | null
  rightRef: HTMLDivElement | null
  footerRef: HTMLDivElement | null
}

export const DockLayout = forwardRef(function DockLayout ({
  className = '',
  header, left, center, right, footer, cover
}: DockLayoutProps, ref: ForwardRef<DockLayoutRef>) {
  const dockLayoutClasses = ['dock-layout', className]
    .filter(cls => cls !== '')
    .join(' ')

  const headerRef = useRef<HTMLDivElement>(null)
  const leftRef = useRef<HTMLDivElement>(null)
  const centerRef = useRef<HTMLDivElement>(null)
  const rightRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  const coverContent = useMemo(() => {
    return (typeof cover === 'function' ? cover() : cover) ?? null
  }, [cover])
  const headerContent = useMemo(() => {
    return (typeof header === 'function' ? header() : header) ?? null
  }, [header])
  const leftContent = useMemo(() => {
    return (typeof left === 'function' ? left() : left) ?? null
  }, [left])
  const centerContent = useMemo(() => {
    return (typeof center === 'function' ? center() : center) ?? null
  }, [center])
  const rightContent = useMemo(() => {
    return (typeof right === 'function' ? right() : right) ?? null
  }, [right])
  const footerContent = useMemo(() => {
    return (typeof footer === 'function' ? footer() : footer) ?? null
  }, [footer])

  const hasAnyBodyContent = useMemo(
    () => {
      return (
        leftContent !== null ||
          centerContent !== null ||
          rightContent !== null)
    },
    [centerContent, leftContent, rightContent]
  )

  useImperativeHandle(ref, () => ({
    get headerRef () { return headerRef.current },
    get leftRef () { return leftRef.current },
    get centerRef () { return centerRef.current },
    get rightRef () { return rightRef.current },
    get footerRef () { return footerRef.current }
  }))

  return (
    <div className={dockLayoutClasses}>
      {coverContent !== null ? coverContent : null}

      {headerContent !== null ? (
        <div
          className="dock-layout__header"
          ref={headerRef}
          children={headerContent}
        />
      ) : null}

      {hasAnyBodyContent ? (
        <div className="dock-layout__body">
          {leftContent !== null ? (
            <div
              className="dock-layout__left"
              ref={leftRef}
              children={leftContent}
            />
          ) : null}

          {centerContent !== null ? (
            <div
              className="dock-layout__center"
              ref={centerRef}
              children={centerContent}
            />
          ) : null}

          {rightContent !== null ? (
            <div
              className="dock-layout__right"
              ref={rightRef}
              children={rightContent}
            />
          ) : null}
        </div>
      ) : null}

      {footerContent !== null ? (
        <div
          className="dock-layout__footer"
          ref={footerRef}
          children={footerContent}
        />
      ) : null}
    </div>
  )
})
