import { navigate, useLocation, useMatch } from '@reach/router'
import qs from 'query-string'
import { useCallback, useEffect, useMemo } from 'react'
import { routes } from './routes'
import { LoginViewScreen, setCurrentLoginViewScreen, useUserStore } from './stores/userStore'
import { noop } from './utils/function'
import { currentURLWithoutQueryParams } from './utils/url'
import { LoginViewQueryParams } from './views/Login/SetNewPasswordScreen'

export function useRedirects (): void {
  const loginMatch = useMatch(routes.login.absolute)
  const tokenLoginMatch = useMatch(routes.tokenLogin.absolute)
  const homeMatch = useMatch(routes.home.absolute)
  const location = useLocation()
  const { currentUser } = useUserStore()

  const isLoginRoute = useMemo(() => loginMatch !== null || tokenLoginMatch !== null, [loginMatch, tokenLoginMatch])
  const isHomeRoute = useMemo(() => homeMatch !== null, [homeMatch])
  const isLoggedIn = useMemo(() => currentUser !== null, [currentUser])
  const queryParams = useMemo(
    () => qs.parse(location.search),
    [location.search]
  )
  const hasRedirectParam = useMemo(
    () => queryParams.redirect !== undefined,
    [queryParams.redirect]
  )

  const targetRouteAfterLogin = useMemo(
    () => {
      const { redirect } = queryParams
      if (typeof redirect !== 'string' || redirect === '/') {
        return routes.products.absolute
      }
      return redirect
    },
    [queryParams]
  )

  const jwtValue = useMemo(() => {
    if (queryParams.login_jwt === null) { return '' }
    if (queryParams.login_jwt === undefined) { return '' }
    if (Array.isArray(queryParams.login_jwt)) { return queryParams.login_jwt[0] }
    return queryParams.login_jwt
  }, [queryParams.login_jwt])

  const getLoginRouteWithRedirect = useCallback<(pathname: string, isToken?: boolean) => string>(
    (pathname, isToken = false): string => {
      if (pathname === '' || pathname === '/') {
        return isToken
          ? `${routes.tokenLogin.absolute}?login_jwt=${jwtValue}`
          : routes.login.absolute
      }
      return isToken
        ? `${routes.tokenLogin.absolute}?login_jwt=${jwtValue}&redirect=${pathname}`
        : `${routes.login.absolute}?redirect=${pathname}`
    }, [jwtValue]
  )

  useEffect(() => {
    const queryParamsExists = queryParams !== undefined && queryParams !== null
    const passwordResetTokenExists = Object.keys(queryParams).includes(LoginViewQueryParams.passwordResetToken)

    if (isLoginRoute && isLoggedIn) {
      navigate(targetRouteAfterLogin).catch(noop)
    } else if (isLoginRoute && queryParamsExists && passwordResetTokenExists) {
      setCurrentLoginViewScreen(LoginViewScreen.setNewPassword)
      navigate(routes.login.absolute).catch(noop)
    } else if (!isLoginRoute && !isLoggedIn) {
      navigate(getLoginRouteWithRedirect(location.pathname, jwtValue !== '')).catch(noop)
    } else if (isHomeRoute && isLoggedIn) {
      navigate(routes.products.absolute).catch(noop)
    } else if (!isLoginRoute && isLoggedIn && hasRedirectParam) {
      navigate(currentURLWithoutQueryParams('redirect')).catch(noop)
    } else if (!isLoginRoute && isLoggedIn && queryParams.login_jwt !== null) {
      navigate(currentURLWithoutQueryParams('login_jwt')).catch(noop)
    }
  }, [getLoginRouteWithRedirect, hasRedirectParam, isHomeRoute, isLoggedIn, isLoginRoute, jwtValue, location.pathname, queryParams.login_jwt, targetRouteAfterLogin, queryParams])
}
