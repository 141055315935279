import React, { memo } from 'react'
import './MenuTitle.scss'

interface Props {
  label: string
}

export const MenuTitle = memo(function MenuTitle ({ label }: Props) {
  return (
    <div className="menu-title">
      <div className="menu-title__label">{label}</div>
    </div>
  )
})
