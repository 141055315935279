import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import React, { FC, Fragment, ReactNode } from 'react'
import { MdiIcon } from '../Generic/MdiIcon'
import './AppTabs.scss'

export interface AppTabsItem {
  identifier?: string
  tabText: string
  tabIcon?: string
  tabCounter?: number
  body: ReactNode
  show?: boolean
}

interface Props {
  items: AppTabsItem[]
  onChange?: (index: number) => void
  currentTabIndex?: number
}

/**
 * Generische Tabs, die überall verwendet werden können.
 */
export const AppTabs: FC<Props> = ({ items, onChange, currentTabIndex }) => {
  return (
    <div className="app-tabs">
      <Tab.Group onChange={(index: number) => onChange?.(index)} selectedIndex={currentTabIndex} >
        <Tab.List className="app-tabs__tab-list">
          {items.map((item, index) => {
            if (item.show === false) return null

            return (
              <Tab key={index} as={Fragment}>
                {({ selected }) => (
                  <button
                    className={classNames('app-tabs__tab', {
                      'app-tabs__tab--current': selected
                    })}
                  >
                    {item.tabIcon !== undefined ? (
                      <MdiIcon
                        className="app-tabs__tab-icon"
                        path={item.tabIcon}
                      />
                    ) : null}

                    <div className="app-tabs__tab-text">{item.tabText}</div>

                    {item.tabCounter !== undefined ? (
                      <div className="app-tabs__tab-counter">
                        {item.tabCounter}
                      </div>
                    ) : null}
                  </button>
                )}
              </Tab>
            )
          })}
        </Tab.List>

        <Tab.Panels className="app-tabs__tab-panels">
          {items.map((item, index) => {
            if (item.show === false) return null
            return (
              <Tab.Panel key={index} className="app-tabs__tab-panel">
                {item.body}
              </Tab.Panel>
            )
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
