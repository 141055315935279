import { Response } from 'superagent'
import { sendRequest } from './superagent'
import { EMK } from './apiSchemas'

export interface Value {
  dataresource: number
  entity: ValueEntity
}

export interface ValueEntity {
  id: number
  identifier: string

  [key: string]: string | number | boolean
}

/**
 * Liefert die Entities.
 *
 * @param entityMetakeys Die EntityMetakeys.
 */
export async function getEntities (
  entityMetakeys: EMK[]
): Promise<Response> {
  return await sendRequest('post', 'entity/get', { body: entityMetakeys })
}

/**
 * Liefert eine Liste einer Entity.
 *
 * @param url URL aus Entity-Datamap Konfiguration.
 */
export async function getListOfEntity (
  url: string
): Promise<Response> {
  url = url.startsWith('/') ? url.substr(1) : url
  if (url.includes('?')) {
    const pathAndQueryParams = url.split('?')
    let path = pathAndQueryParams[0]
    const query = pathAndQueryParams[1]
    if (pathAndQueryParams[0].includes('/')) {
      path = path.split('/')[0]
    }
    url = `${path}?${query}`
  } else {
    url = url.includes('/') ? url.split('/')[0] : url
  }

  url = decodeURI(url)
  url = encodeURI(url)
  return await sendRequest('get', `entity/list/${url}`)
}

/**
 * Liefert eine Liste einer Entity.
 *
 * @param values Die Werte.
 */
export async function saveEntities (
  values: Value[]
): Promise<Response> {
  return await sendRequest('post', 'entity/save', { body: values })
}
