import { mdiStickerCircleOutline } from '@mdi/js'
import React, { memo, useCallback, useMemo } from 'react'
import { getStatusData, StatusData } from '../../data/statusData'
import { ApiProduct } from '../../services/api/apiSchemas'
import classNames from 'classnames'
import { MdiIcon } from '../Generic/MdiIcon'
import { LabelItem } from './LabelItem'
import './Status.scss'
import { useTranslation } from 'react-i18next'

interface Props {
  value: ApiProduct['statusId']
  label?: string
}

export const Status = memo(function Status ({
  value, label = ''
}: Props) {
  const { t } = useTranslation()
  const statusItem = getStatusData(value)
  const statusClasses = useMemo(
    () => {
      if (value === null) { return '' }

      return classNames(
        'status',
        { [`status--${value.toString()}`]: value !== undefined }
      )
    },
    [value]
  )

  const getUnknownStatusBadge = useCallback(
    (): JSX.Element => (
      <div className={statusClasses}>
        <MdiIcon className="status__icon" path={mdiStickerCircleOutline} />
        <span className="status__label">{t('badges.status.unknown')}</span>
      </div>
    ),
    [statusClasses, t]
  )

  const getKnownStatusBadge = useCallback(
    (statusItem: StatusData): JSX.Element => (
      <div className={statusClasses}>
        <MdiIcon className="status__icon" path={statusItem.icon} />
        <span className="status__label">{statusItem.label}</span>
      </div>
    ),
    [statusClasses]
  )

  const statusBadge = useMemo(
    (): JSX.Element => {
      return statusItem !== null
        ? getKnownStatusBadge(statusItem)
        : getUnknownStatusBadge()
    },
    [getKnownStatusBadge, getUnknownStatusBadge, statusItem]
  )

  if (value === null) { return null }

  return label === '' ? statusBadge : (
    <LabelItem label={label}>
      {statusBadge}
    </LabelItem>
  )
})
