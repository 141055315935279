import { navigate } from '@reach/router'
import React, { FormEvent, memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { noop } from 'sfportal_utils/function'
import { ZoomFadeDiv } from '../../components/Animations/ZoomFadeDiv'
import { Button } from '../../components/Forms/Button'
import { Input } from '../../components/Forms/Input'
import { RequestErrorMessage } from '../../components/Generic/RequestErrorMessage'
import { CenteredLayer } from '../../components/Layout/CenteredLayer'
import { ChildrenProp } from '../../jsx'
import { routes } from '../../routes'
import { RequestStatus } from '../../services/api/generic/types'
import { LoginViewScreen, sendResetPasswordEMail, setCurrentLoginViewScreen, useUserStore } from '../../stores/userStore'
import { LoadingScreen } from './LoadingScreen'

/**
 * Properties für den SendEmailScreen
 */
interface Props extends ChildrenProp {
  /**
   * Gibt an ob der "zurück zum Login" Button auf der Komponenten angezeigt
   * werden soll.
   */
  showBackToLogin?: boolean
}

export const SendEmailScreen = memo(function SendEmailScreen (
  { showBackToLogin = true }: Props
) {
  const [email, setEmail] = useState('')

  const { status, currentLoginViewScreen } = useUserStore()
  const { t } = useTranslation()

  const isCurrentScreen = useMemo(
    () => currentLoginViewScreen === LoginViewScreen.sendEMail,
    [currentLoginViewScreen]
  )

  const isLoading = useMemo(
    () => status === RequestStatus.pending,
    [status]
  )

  async function handleSubmit (event: FormEvent): Promise<void> {
    event.preventDefault()
    await sendResetPasswordEMail(email)
  }

  function handleBackToLogin (): void {
    setCurrentLoginViewScreen(LoginViewScreen.login)
  }

  function handleAbortTEMP (): void {
    if (globalThis.history.length > 0) {
      navigate(-1).catch(noop)
    } else {
      navigate(routes.products.absolute).catch(noop)
    }
  }

  return (
    <>
      <LoadingScreen
        text={t('login.sendEmailScreen.loading')}
        visible={isCurrentScreen && isLoading}
      />

      <ZoomFadeDiv
        className="login-view__view-item"
        visible={isCurrentScreen && !isLoading}
      >
        <CenteredLayer>
          <form className="login-view__form" onSubmit={handleSubmit}>
            <h1 className="login-view__title">{t('login.sendEmailScreen.title')}</h1>

            <p className="login-view__text">
              {t('login.sendEmailScreen.content')}
            </p>

            <div className="login-view__inputs">
              <Input
                label={t('common.email')}
                type="email"
                id="email"
                name="email"
                autoComplete="username"
                value={email}
                onInput={setEmail}
                autoFocus={true}
              />
            </div>

            <RequestErrorMessage status={status}/>

            <div className="login-view__buttons">
              <Button type="submit">{t('login.sendEmailScreen.submit')}</Button>

              {showBackToLogin ? (
                <Button
                  type="button"
                  buttonStyle="secondary"
                  onClick={handleBackToLogin}
                >
                  {t('login.sendEmailScreen.back')}
                </Button>
              ) : (
                <Button
                  type="button"
                  buttonStyle="secondary"
                  onClick={handleAbortTEMP}
                >
                  {t('common.cancel')}
                </Button>
              )}
            </div>
          </form>
        </CenteredLayer>
      </ZoomFadeDiv>
    </>
  )
})
