import classNames from 'classnames'
import React, { FC, useMemo } from 'react'
import { ChildrenProp } from '../../jsx'
import './Tabs.scss'

interface Props extends ChildrenProp {
  compact?: boolean
}

export const Tabs: FC<Props> = function Tabs ({
  children,
  compact = false
}: Props) {
  const tabsType = useMemo(
    (): string => (compact ? 'compact' : 'normal'),
    [compact]
  )

  const tabsClasses = useMemo(
    () => classNames('tabs', { [`tabs--type-${tabsType}`]: true }),
    [tabsType]
  )

  return <div className={tabsClasses}>{children}</div>
}
