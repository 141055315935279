import { mdiBook } from '@mdi/js'
import React, { memo, useMemo } from 'react'
import { ChildrenProp } from '../../jsx'
import { ApiTask, ApiTaskDataResourceProduct, } from '../../services/api/apiSchemas'
import { ProductDetailStore } from '../../stores/productDetailStore'
import { ListItemTask } from './ListItemTask'
import { useTaskActions } from './useTaskActions'
import {MdiIcon} from "../Generic/MdiIcon";

interface Props extends ChildrenProp {
  task: ApiTask;
  showTaskName?: boolean;
  highlight?: boolean;
  taskActionStatus: ProductDetailStore['taskActionStatus'];
  currentFile: ProductDetailStore['currentFile'];
}

export const ListItemTaskProduct = memo(function ListItemTaskProduct ({
  task,
  highlight,
  showTaskName = false,
  taskActionStatus,
  currentFile,
}: Props) {
  const taskActions = useTaskActions({
    task,
    taskActionStatus
  })

  const dataResource = useMemo(
    () => task.dataResource as ApiTaskDataResourceProduct,
    [task.dataResource],
  )

  return (
    <>
      {taskActions.taskModalElement}

      <ListItemTask
        listItemType={'product'}
        icon={<MdiIcon path={mdiBook} />}
        pubtargetIdentifier={task.pubtargetIdentifier}
        title={dataResource.name}
        titleSuffix={showTaskName ? task.name : undefined}
        highlight={highlight}
        current={currentFile === dataResource}
        actions={<>{taskActions.taskActionElements}</>}
      />
    </>
  )
})
